import React, {useMemo} from 'react';
import {
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {useEcommerce} from '../../contexts/EcommerceContext';
import isologo from '../../assets/isologo.svg';

import styles from './StorePage.module.scss';

interface SocialLink {
  socialNetwork:
    | 'facebook'
    | 'instagram'
    | 'twitter'
    | 'pinterest'
    | 'linktree'
    | 'youtube';
  icon?: string;
  socialUrl: string;
}

const StoreFooter = () => {
  const [state] = useEcommerce();

  const socialLinks: SocialLink[] = useMemo(
    () => [
      {socialNetwork: 'facebook', socialUrl: state.settings.COMPANY_FACEBOOK},
      {socialNetwork: 'instagram', socialUrl: state.settings.COMPANY_INSTAGRAM},
      {socialNetwork: 'twitter', socialUrl: state.settings.COMPANY_TWITTER},
      {socialNetwork: 'pinterest', socialUrl: state.settings.COMPANY_PINTEREST},
      {socialNetwork: 'youtube', socialUrl: state.settings.COMPANY_YOUTUBE},
      {
        socialNetwork: 'linktree',
        icon: 'fa-link',
        socialUrl: state.settings.COMPANY_LINKTREE,
      },
    ],
    [state],
  );

  const availableSocialLinks = useMemo(
    () => socialLinks.filter((socialLink) => socialLink.socialUrl),
    [socialLinks],
  );

  return (
    <footer className={`py-1 ${styles.storeFooter}`}>
      <Container fluid className="px-lg-4 px-md-2">
        <Row>
          <Col md={12} lg={4} className={styles.powered}>
            <a
              href="https://2blink.app"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-reset"
            >
              Powered by 2Blink <Image src={isologo} width={20} />
            </a>
          </Col>
          <Col md={6} lg={4} className={styles.social}>
            {availableSocialLinks.map((socialLink: SocialLink) => (
              <a
                href={`${socialLink.socialUrl}`}
                key={socialLink.socialNetwork}
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  icon={
                    socialLink.icon
                      ? (socialLink.icon as IconName)
                      : ['fab', socialLink.socialNetwork as IconName]
                  }
                />
              </a>
            ))}
          </Col>
          <Col md={6} lg={4} className={styles.companyInfo}>
            {state.settings.COMPANY_ADDRESS ? (
              <div>
                <OverlayTrigger
                  placement="top"
                  trigger="click"
                  rootClose
                  overlay={
                    <Tooltip>
                      {`${state.settings.COMPANY_ADDRESS},
                  ${state.settings.COMPANY_ZIP_CODE},
                  ${state.settings.COMPANY_CITY},
                  ${state.settings.COMPANY_COUNTRY}`}
                    </Tooltip>
                  }
                >
                  <Icon icon={['fas', 'location-dot']} />
                </OverlayTrigger>
              </div>
            ) : null}
            {state.settings.COMPANY_PHONE_NUMBER ? (
              <div>
                <a href={`tel:${state.settings.COMPANY_PHONE_NUMBER}`}>
                  <Icon icon={['fas', 'phone']} />
                </a>
              </div>
            ) : null}
            {state.settings.COMPANY_EMAIL ? (
              <div>
                <a href={`mailto:${state.settings.COMPANY_EMAIL}`}>
                  <Icon icon={['fas', 'envelope']} />
                </a>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default StoreFooter;
