import React, {useCallback} from 'react';
import {SessionViewMode} from '../types/ShopProduct';

type PageName = 'storefront' | 'favorites';

const BLINK_UID = document.BLINK_BLINK_UID;

const useSessionViewMode = (pageName: PageName) => {
  const localStorageKey = `bl:v2:${BLINK_UID}:userConfig.${pageName}.viewMode`;

  const setSessionViewMode = useCallback(
    (newValue: SessionViewMode) => {
      const oldValue = window.localStorage.getItem(localStorageKey);

      window.localStorage.setItem(localStorageKey, newValue);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: localStorageKey,
          newValue,
          oldValue,
        }),
      );
    },
    [localStorageKey],
  );

  const subscribe = useCallback(
    (listener: () => void) => {
      window.addEventListener('storage', (e) => {
        if (e.key === localStorageKey) {
          listener();
        }
      });
      return () => window.removeEventListener('storage', listener);
    },
    [localStorageKey],
  );

  const getSnapshot = useCallback(
    () => localStorage.getItem(localStorageKey) as SessionViewMode,
    [localStorageKey],
  );

  const store = React.useSyncExternalStore(subscribe, getSnapshot);
  return [store, setSessionViewMode] as const;
};

export default useSessionViewMode;
