import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import * as Yup from 'yup';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import {Card} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import LayoutButtons from '../../layouts/utils';
import UserForm from '../../components/Forms/UserForm';

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  user_phone: Yup.number(),
  user_company_name: Yup.string(),
  user_company_tax_id: Yup.number(),
  user_shipping_address: Yup.string(),
  client_ref: Yup.string(),
  custom_fields: Yup.object(),
  price_list: Yup.number().nullable(),
  seller: Yup.number().nullable(),
});

const UsersFormPage = () => {
  const api = useApi();
  const params = useParams();
  const navigate = useNavigate();
  const formRef = useRef();
  const [utilsState] = useUtils();
  const [initialValues, setInitialValues] = useState({
    type: 'customer',
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    user_phone: '',
    user_company_name: '',
    user_company_tax_id: '',
    user_shipping_address: '',
    client_ref: '',
    custom_fields: {},
    price_list: null,
    seller: null,
  });
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    const fetchUser = async (id) => {
      const user = await api.get(`/user/${id}`);
      setInitialValues(user);
    };

    if (params.id) {
      fetchUser(params.id);
    }
  }, [api, setInitialValues, params.id]);

  useEffect(() => {
    const fetchSellers = async () => {
      const response = await api.get('/seller/');

      setSellers(response.results);
    };

    fetchSellers();
  }, [api]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const customFieldsInitialValues = utilsState.userCustomFields.reduce((accum, current) => {
        accum[current.name] = '';

        return accum;
      }, {});

      try {
        if (params.id) {
          await api.put(`user/${params.id}`, {
            ...values,
            custom_fields: {
              ...customFieldsInitialValues,
              ...values.custom_fields,
            },
          });
          toast.success(`¡Usuario ${values.username} actualizado!`, {
            hideProgressBar: true,
          });
          navigate('/admin/users/', {replace: true});
        } else {
          await api.post('user/', {
            ...values,
            custom_fields: {
              ...customFieldsInitialValues,
              ...values.custom_fields,
            },
          });
          toast.success(`¡Nuevo usuario ${values.username} creado!`, {
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      } catch (error) {
        Object.values(error.data).forEach((message) => {
          toast.error(message.join(), {
            hideProgressBar: true,
          });
        });
      }
    },
    [api, params.id, navigate, utilsState],
  );

  const extraButton = useMemo(
    () => ({
      variant: 'primary',
      label: 'Guardar',
      onClick: () => {
        formRef.current.submitForm();
      },
    }),
    [],
  );

  validationSchema.withMutation((schema) => {
    const customFieldsValidation = utilsState.userCustomFields.reduce((accum, current) => {
      if (current.type === 'url') {
        accum[current.name] = Yup.string().url();
      } else {
        accum[current.name] = Yup.string();
      }
      return accum;
    }, {});

    schema.fields.custom_fields.withMutation((fieldSchema) =>
      fieldSchema.shape(customFieldsValidation),
    );
  });

  return (
    <Card>
      <Card.Body>
        <UserForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
          priceLists={utilsState.priceLists}
          sellers={sellers}
          customFields={utilsState.userCustomFields}
          ref={formRef}
        >
          <LayoutButtons extraButton={extraButton} />
        </UserForm>
      </Card.Body>
    </Card>
  );
};

export default UsersFormPage;
