import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import {Card, Col, Row, Form} from 'react-bootstrap';
import SelectField from '../../components/FormFields/SelectField';
import {PURCHASE_ORDER_STATUSES} from '../../resources';

import styles from './PurchaseOrdersFormPage.module.scss';

const StatusCard = ({purchaseOrder, editPurchaseOrder}) => {
  const {t} = useTranslation('pages');
  return (
    <Card bg="dark" text="light" className="mb-1">
      <Card.Body className="px-2 py-1">
        <Formik
          enableReinitialize
          initialValues={{
            status: purchaseOrder.status || 'draft',
          }}
          onSubmit={(values) => {
            editPurchaseOrder(purchaseOrder.id, values);
          }}
        >
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={formikProps.handleSubmit}
              className={styles.form}
            >
              <Row>
                <Col>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t('PurchaseOrdersFormPage.StatusCard.title')}
                      </strong>
                    </Form.Label>
                    <SelectField
                      name="status"
                      options={PURCHASE_ORDER_STATUSES}
                      onChange={(e) => {
                        formikProps.submitForm();
                        formikProps.handleChange(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default StatusCard;
