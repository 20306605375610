import React, {useEffect, useRef, useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import placeholder from '../../assets/placeholder-image.png';
import styles from './StoreProduct.module.scss';
import NewProductSVG from '../../assets/NewProductSVG';
import {getVideoThumbnail} from '../../utils';

const DEFAULT_INTERVAL = 5000;

interface CarouselProductProps {
  images: string[];
  video: string;
  newProduct: boolean;
}

const CarouselProduct = ({images, video, newProduct}: CarouselProductProps) => {
  const [index, setIndex] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const thumbnailScrollRef = useRef<HTMLDivElement | null>(null);

  const stopAutoSlide = () => {
    if (autoSlide) {
      setAutoSlide(false);
    }
  };

  useEffect(() => {
    const focusScroll = () => {
      thumbnailScrollRef.current?.children[index].scrollIntoView({
        behavior: 'smooth',
        // Moves focused image to the center of scroll, desired behaviour
        inline: 'center',
        // Prevents vertical realign, to not harm UX on mobile
        block: 'nearest',
      });
    };
    focusScroll();
  }, [index]);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const displayedImages = images.length === 0 ? [placeholder] : images;

  const carouselFooterImages = [
    ...displayedImages.map((image) => ({type: 'image', source: image})),
  ];

  if (video) {
    carouselFooterImages.push({
      type: 'video',
      source: getVideoThumbnail(video),
    });
  }

  return (
    <React.Fragment>
      <Carousel
        indicators={false}
        controls={video.length > 0 || images.length > 1}
        activeIndex={index}
        interval={autoSlide ? DEFAULT_INTERVAL : null}
        onSelect={handleSelect}
        className={styles.carousel}
        prevIcon={<Icon icon="angle-left" onClick={stopAutoSlide} />}
        nextIcon={<Icon icon="angle-right" onClick={stopAutoSlide} />}
      >
        {displayedImages.map((image) => (
          <Carousel.Item key={image} onClick={stopAutoSlide}>
            <div className="d-flex">
              {newProduct && <NewProductSVG className={styles.newIcon} />}
              <img src={image} alt={image} width="100%" />
            </div>
          </Carousel.Item>
        ))}
        {video && (
          <Carousel.Item onClick={stopAutoSlide}>
            <iframe
              width="100%"
              height="400px"
              src={video}
              title="video"
              allow="autoplay; fullscreen; picture-in-picture"
            />
          </Carousel.Item>
        )}
      </Carousel>
      {carouselFooterImages.length > 1 && (
        <div className={styles.carouselIndicators} ref={thumbnailScrollRef}>
          {carouselFooterImages.map((image, i) => (
            <button
              type="button"
              onClick={() => {
                handleSelect(i);
                stopAutoSlide();
              }}
              key={image.source}
            >
              <div className={styles.carouselImageContainer}>
                <img
                  src={image.source}
                  alt={image.source}
                  className={i === index ? styles.active : undefined}
                />
                {image.type === 'video' ? (
                  <div className={styles.carouselImageVideo}>
                    <Icon size="xl" className={styles.videoIcon} icon="play" />
                  </div>
                ) : null}
              </div>
            </button>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default CarouselProduct;
