import React from 'react';
import {CellProps, Column} from 'react-table';
import {useTranslation} from 'react-i18next';
import {
  CombinationQuantitySetter,
  ShopProduct,
  ShopProductCombination,
  ShopProductCombinationVariant,
  ShopProductVariant,
} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';
import {CombinationInput} from './QuantityInputs';

const variantColumn = (
  variant: ShopProductVariant,
): Column<ShopProductCombination> => ({
  Header: variant.name,
  accessor: (originalRow) => {
    const optionId = originalRow.variants.find(
      (combinationVariant) => combinationVariant.variant === variant.id,
    )?.option;
    const option = variant.options.find(({id}) => optionId === id);
    return option ? option.name : 'UNKNOWN';
  },
});

const quantityColumnCell = (
  product: ShopProduct,
  setCombinationQuantity?: CombinationQuantitySetter,
) => {
  const Component = (props: CellProps<ShopProductCombination>) => {
    const combination = props.row.original;

    if (setCombinationQuantity) {
      return (
        <CombinationInput
          product={product}
          combinationId={combination.id}
          setCombinationQuantity={setCombinationQuantity}
          enableTooltip
        />
      );
    }

    return <strong>{product.quantities[combination.id]}</strong>;
  };
  return Component;
};
const quantityColumn = (
  product: ShopProduct,
  setCombinationQuantity?: CombinationQuantitySetter,
): Column<ShopProductCombination> => ({
  Header: 'Cantidad',
  accessor: 'id', // TODO: change
  Cell: quantityColumnCell(product, setCombinationQuantity),
});

const packColumn: Column<ShopProductCombination> = {
  Header: 'Pack',
  accessor: 'pack',
};

const skuColumn = (product: ShopProduct): Column<ShopProductCombination> => ({
  Header: 'SKU',
  accessor: (originalRow) => originalRow.code || product.ref,
});

const priceColumn = (
  product: ShopProduct,
  currency: string,
  hidePrices: boolean = false,
): Column<ShopProductCombination> => ({
  Header: 'Precio',
  Cell: PriceColumnCell(product, currency, hidePrices),
});

const PriceColumnCell = (product: ShopProduct, currency: string, hidePrices: boolean) => {
  const Component = (props: CellProps<ShopProductCombination>) => {
    const originalRow = props.row.original;
    const price = product.offer_price || product.default_price;
    const {t} = useTranslation('components');

    return (
      <span>
        {hidePrices
          ? t('CombinationMatrix.Column.price.mustLogin')
          : formatMoney(originalRow.price || price, currency)}
      </span>
    );
  };
  return Component;
};

const mobileColumn = (
  product: ShopProduct,
  currency: string,
): Column<ShopProductCombination> => ({
  Header: 'Producto',
  Cell: MobileColumnCell(product, currency),
});

const MobileColumnCell = (product: ShopProduct, currency: string) => {
  const Component = (props: CellProps<ShopProductCombination>) => {
    const combination = props.row.original;

    const optionVariantName = (
      combinationVariant: ShopProductCombinationVariant,
    ): string => {
      const variant = product.variants.find(
        (productVariant) => combinationVariant.variant === productVariant.id,
      );
      const option = variant?.options.find(
        (variantOption) => variantOption.id === combinationVariant.option,
      );
      return option ? `- ${option.name} ` : 'UNKNOWN';
    };

    return (
      <React.Fragment>
        <p>
          x
          {
            product.packs.find((pack) => pack.name === combination.pack)
              ?.quantity
          }{' '}
          {combination.variants.map(optionVariantName)}
        </p>
        <p>
          {combination.code || product.ref}{' '}
          {product.volume_discounts.length === 0 && (
            <span>
              -{' '}
              {formatMoney(
                combination.price || product.default_price,
                currency,
              )}
            </span>
          )}
        </p>
      </React.Fragment>
    );
  };
  return Component;
};

export {
  variantColumn,
  quantityColumn,
  packColumn,
  skuColumn,
  priceColumn,
  mobileColumn,
};
