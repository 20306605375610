import React, {useMemo, useState} from 'react';

const LayoutContext = React.createContext();

const LayoutProvider = ({children}) => {
  const [topBar, setTopBar] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttons, setButtons] = useState([]);

  const state = useMemo(
    () => ({
      topBar,
      title,
      buttons,
    }),
    [topBar, title, buttons],
  );

  const actions = useMemo(
    () => ({
      setTopBar,
      setTitle,
      setButtons,
      clearButtons: () => setButtons(() => []),
    }),
    [setTopBar, setTitle, setButtons],
  );

  const value = useMemo(() => [state, actions], [state, actions]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

const useLayout = () => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('`useLayout` must be used within a `LayoutContext`.');
  }
  return context;
};

export {LayoutContext, LayoutProvider, useLayout};
