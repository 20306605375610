import React from 'react';
import {Formik} from 'formik';
import {Row, Col, Form} from 'react-bootstrap';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import ImageUploadField from '../../components/FormFields/ImageUploadField';
import LayoutButtons from '../../layouts/utils';
import youtube from '../../assets/youtube_logo.svg';
import vimeo from '../../assets/vimeo_logo.svg';

import styles from './ProductsFormPage.module.scss';

const formatURLVideo = (url) => {
  let formatURL = url;

  if (url.includes('vimeo') && !url.includes('player')) {
    const embedURL = 'https://player.vimeo.com/video/';
    const vimeoCode = url.split('/');
    formatURL = `${embedURL}${vimeoCode[vimeoCode.length - 1]}`;
  }

  if (url.includes('youtube') && !url.includes('embed')) {
    const embebedURL = 'https://www.youtube.com/embed/';
    const youtubeCode = url.split('=');
    formatURL = `${embebedURL}${youtubeCode[youtubeCode.length - 1]}`;
  }

  return formatURL;
};

const StepMultimedia = (props) => {
  const {t} = useTranslation('pages');
  const {product} = props.data;

  const validationSchema = Yup.object().shape({
    video: Yup.string().url(
      `${t('ProductsFormPage.form.validations.messages.url.valid')}`,
    ),
  });

  return (
    <Row className={styles.StepPhotos}>
      <Col xs={12} className={styles.stepTitle}>
        <h1>{t('ProductsFormPage.Stepper.Steps.stepMultimedia.title')}</h1>
        <p>{t('ProductsFormPage.Stepper.Steps.stepMultimedia.description')}</p>
      </Col>
      <Col xs={7}>
        <Formik
          enableReinitialize
          initialValues={{
            images: product.images || [],
            video: product.video || '',
          }}
          onSubmit={(values) => {
            props.setData((prevState) => {
              const prevProduct = prevState.product;
              const newProduct = {
                ...prevProduct,
                images: values.images,
                video: formatURLVideo(values.video),
              };
              return {
                ...prevState,
                product: newProduct,
              };
            });
            props.actions.handleNext();
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form noValidate onSubmit={formikProps.handleSubmit}>
              <Row>
                <Col xs={12} className="position-relative mb-2">
                  <Form.Group>
                    <ImageUploadField
                      name="images"
                      multiple
                      category="catalog"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="position-relative mb-2">
                  <Form.Group>
                    <Form.Label>
                      {t(
                        'ProductsFormPage.Stepper.Steps.stepMultimedia.fields.video.label',
                      )}
                    </Form.Label>
                    <Form.Control
                      type="url"
                      name="video"
                      size="lg"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.video}
                      isInvalid={
                        formikProps.touched && formikProps.errors.video
                      }
                    />
                    <Form.Control.Feedback tooltip type="invalid">
                      {formikProps.errors.video}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <LayoutButtons
                handleBack={props.actions.handleBack}
                handleNext={formikProps.submitForm}
              />
            </Form>
          )}
        </Formik>
      </Col>
      <Col xs={5}>
        <h4>Tips</h4>
        <ul>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.type')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.real')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.object')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.drag')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.generic')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.default')}
          </li>
          <li>
            {t('ProductsFormPage.Stepper.Steps.stepMultimedia.tips.platforms')}
            <div>
              <img src={youtube} alt="youtube" />
              <img src={vimeo} alt="vimeo" />
            </div>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default StepMultimedia;
