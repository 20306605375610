import React from 'react';
import {useNavigate} from 'react-router';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {DefaultCombinationInput} from '../../components/ShopProduct/QuantityInputs';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';

import styles from './StoreProduct.module.scss';

interface ProductTitleProps {
  title: string;
  sku: string;
  showClose: boolean;
}

const ProductTitle = ({title, sku, showClose = false}: ProductTitleProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('components');

  return (
    <div className={styles.productTitle}>
      <h1>
        {title} <span>{sku}</span>
      </h1>
      {showClose && (
        <Button
          className="rounded-pill d-flex align-items-center"
          onClick={() => navigate(-1)}
        >
          <Icon icon="share" flip="horizontal" className="px-1" />
          <div className="h-auto fs-6 pe-1">
            {t('ShopProduct.ProductTitle.goBack')}
          </div>
        </Button>
      )}
    </div>
  );
};

interface ProductTitleMobileProps {
  product: ShopProduct;
  setCombinationQuantity: CombinationQuantitySetter;
}

const ProductTitleMobile = ({
  product,
  setCombinationQuantity,
}: ProductTitleMobileProps) => (
  <div className={styles.productTitleMobile}>
    <h1>
      {product.name} <span>{product.ref}</span>
    </h1>
    <DefaultCombinationInput
      product={product}
      setCombinationQuantity={setCombinationQuantity}
    />
  </div>
);

export {ProductTitle, ProductTitleMobile};
