import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import {Card, Col, Row, Form} from 'react-bootstrap';
import * as Yup from 'yup';
import TextField from '../../components/FormFields/TextField';
import EditButtons from './EditButtons';

import styles from './PurchaseOrdersFormPage.module.scss';

const validationSchemaCustomer = Yup.object().shape({
  customer_name: Yup.string().required('Required'),
  customer_company_name: Yup.string().required('Required'),
  customer_email: Yup.string()
    .email('Please enter a valid email')
    .required('Required'),
  customer_phone: Yup.string().required('Required'),
  company_tax_id: Yup.string(),
});

const CustomerCard = ({purchaseOrder, editPurchaseOrder}) => {
  const [editingCustomer, setEditingCustomer] = useState(false);
  const {t} = useTranslation('pages');

  return (
    <Card bg="primary" text="light" className="h-100">
      <Card.Body className="px-2 py-1">
        <Formik
          enableReinitialize
          initialValues={{
            customer_name: purchaseOrder.customer_name || '',
            customer_company_name: purchaseOrder.customer_company_name || '',
            customer_email: purchaseOrder.customer_email || '',
            customer_phone: purchaseOrder.customer_phone || '',
            shipping_address: purchaseOrder.shipping_address || '',
            company_tax_id: purchaseOrder.company_tax_id || '',
          }}
          onSubmit={(values) => {
            editPurchaseOrder(purchaseOrder.id, values);
            setEditingCustomer(false);
          }}
          validationSchema={validationSchemaCustomer}
        >
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={formikProps.handleSubmit}
              className={styles.form}
            >
              <Row>
                <Card.Title>
                  <span className="text-white">
                    {t('PurchaseOrdersFormPage.CustomerCard.title')}
                  </span>
                  <EditButtons
                    formProps={formikProps}
                    editing={editingCustomer}
                    setEditing={setEditingCustomer}
                  />
                </Card.Title>
                <Col>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.username',
                        )}
                      </strong>
                    </Form.Label>
                    <p>{purchaseOrder.username || 'anónimo'}</p>
                  </Form.Group>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.customerCompanyName',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="customer_company_name"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.companyTaxID',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="company_tax_id"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.customerPhone',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="customer_phone"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.customerName',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="customer_name"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.customerEmail',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="customer_email"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                  <Form.Group className="position-relative">
                    <Form.Label>
                      <strong>
                        {t(
                          'PurchaseOrdersFormPage.CustomerCard.labels.shippingAddress',
                        )}
                      </strong>
                    </Form.Label>
                    <TextField
                      name="shipping_address"
                      disabled={!editingCustomer}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CustomerCard;
