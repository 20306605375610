import React, {useState, useCallback, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {Card, Spinner} from 'react-bootstrap';
import Stepper from '../../components/Stepper';
import StepSelectFile from './StepSelectFile';
import StepConfigureColumns from './StepConfigureColumns';
import StepFinal from './StepFinal';
import {useApi} from '../../contexts/ApiContext';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import {useLayoutTitle} from '../../hooks/layout';
import styles from './ProductsImportPage.module.scss';
import {useUtils} from '../../contexts/UtilsContext';
import ProductImportSchedule from './ProductImportSchedule';

const ProductsImportPage = () => {
  const api = useApi();
  const [utilsState] = useUtils();
  useLayoutTitle({
    label: 'Importar productos',
    icon: 'fa-solid fa-cart-shopping',
  });

  const [currentSchedule, setCurrentSchedule] = useState({
    schedule: null,
    status: 'SETUP',
  });

  const [modalState, setModalState] = useState({
    type: null,
    title: null,
    body: null,
    onSubmit: null,
  });

  const [data, setData] = useState({
    count_products: 0,

    id: null,
    fields_mapping: [],
    fields_names: [],
    file_rows: [],
  });

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecurringImports = async () => {
      setIsLoading(true);
      const response = await api.get('/recurring-product-import/');

      setCurrentSchedule((prevState) => ({
        ...prevState,
        ...response,
      }));
      setIsLoading(false);
    };
    const fetchCountProducts = async () => {
      const response = await api.get('/product-helper/count-products/');
      setData((prevState) => ({
        ...prevState,
        count_products: response.count_products,
      }));
    };
    fetchRecurringImports();
    fetchCountProducts();
    setIsLoading(true);
  }, [api]);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    await api.delete(`/recurring-product-import/`);
    setCurrentSchedule({
      schedule: [],
    });
    setIsLoading(false);
    toast.success('Se ha eliminado su importador', {hideProgressBar: true});
  }, [api]);

  const handleSave = async (values) => {
    setIsLoading(true);
    await api.put(`/recurring-product-import/`, {schedule: values.schedule});
    setCurrentSchedule((prevState) => ({
      ...prevState,
      schedule: values.schedule,
    }));
    setIsLoading(false);
    toast.success('Se ha guardado su configuración', {hideProgressBar: true});
  };

  const handleSubmit = useCallback(
    (customData) => {
      const upload = async () => {
        try {
          const response = await api.post(
            `/product-import/${customData.id}`,
            customData,
          );
          setData((prevState) => ({
            ...prevState,
            count_products: response.count_products,
          }));
          navigate('/admin/products');
          if (data.schedule && data.schedule.length) {
            toast.success('Se ha configurado su importador', {
              hideProgressBar: true,
            });
          }
        } catch (e) {
          toast.error(e.data, {hideProgressBar: true});
        }
      };

      upload();
    },
    [api, data, navigate],
  );

  const closeModal = useCallback(
    () => setModalState((prevModalState) => ({...prevModalState, type: null})),
    [setModalState],
  );

  const triggerNotifyModal = useCallback(
    () =>
      setModalState({
        type: 'confirmation',
        title: 'Desea elminar su importador?',
        onSubmit: handleDelete,
      }),
    [handleDelete],
  );

  if (isLoading) {
    return (
      <Card className={styles.card}>
        <Card.Body>
          <div className={styles.spinner}>
            <Spinner animation="border" variant="secondary" />
          </div>
        </Card.Body>
      </Card>
    );
  }

  if (currentSchedule.schedule.length) {
    return (
      <React.Fragment>
        <ConfirmationModal
          show={modalState.type === 'confirmation'}
          closeModal={closeModal}
          title={modalState.title}
          body={modalState.body}
          onSubmit={modalState.onSubmit}
        />
        <ProductImportSchedule
          currentSchedule={currentSchedule}
          handleSave={handleSave}
          triggerNotifyModal={triggerNotifyModal}
          options={utilsState.scheduleOptions}
        />
      </React.Fragment>
    );
  }

  return (
    <Stepper setData={setData} data={data} handleSubmit={handleSubmit}>
      <Stepper.Step label="Importar archivo" bg="none">
        <StepSelectFile />
      </Stepper.Step>
      <Stepper.Step label="Asociar columnas">
        <StepConfigureColumns />
      </Stepper.Step>
      <Stepper.Step label="Verificar" bg="none">
        <StepFinal />
      </Stepper.Step>
    </Stepper>
  );
};

export default ProductsImportPage;
