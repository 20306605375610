import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {formatMoney} from '../../utils';
import BuyButton from '../../components/BuyButton';
import CartTable from '../../components/ShopProduct/CartTable';
import {ShopProductsContainer} from '../../components/ShopProduct/Container';

import styles from './StoreCartPage.module.scss';

const StoreCartPage = () => {
  const [state] = useEcommerce();
  const taxesEnabled = state.settings.COMPANY_PRICE_WITH_TAXES;

  const {t} = useTranslation('pages');

  return (
    <Container fluid className="px-1 px-lg-4 mt-2">
      <Row className="g-2">
        <Col md={12} lg={2} className="mb-2 mb-lg-0">
          {state.priceList?.label ? (
            <Card bg="secondary" className={`mb-2 ${styles.card}`}>
              <Card.Body>
                <p className="fs-6">{t('StoreCheckoutPage.CheckoutSummary.priceList')}</p>
                <p className="mb-0 fs-6 fw-bold">{state.priceList.label}</p>
              </Card.Body>
            </Card>
          ) : null}
          <Card bg="primary" className={`mb-2 ${styles.card}`}>
            <Card.Body>
              <p className="fs-6">{t('StoreCheckoutPage.CheckoutSummary.cartSummary')}</p>
              <p className="mb-0 fs-6 fw-bold ">
                {formatMoney(state.cart.total, state.settings.COMPANY_CURRENCY)}
              </p>
              {taxesEnabled ? (
                <div className=" d-flex fw-bold justify-content-end">+IVA</div>
              ) : null}
            </Card.Body>
          </Card>
          <Card bg="secondary" className={`mb-2 ${styles.card}`}>
            <Card.Body>
              <p className="fs-6">{t('StoreCheckoutPage.CheckoutSummary.unitsLabel')}</p>
              <p className="mb-0 fs-6 fw-bold">
                {t('StoreCheckoutPage.CheckoutSummary.units')} {state.cart.quantity}
              </p>
            </Card.Body>
          </Card>
          {state.settings.COMPANY_ALLOW_QUICK_ORDER ? (
            <LinkContainer to="/quick-order/">
              <Card bg="primary" className={`mb-2 ${styles.card}`} role="button">
                <Card.Body className="d-flex justify-content-center align-items-center">
                  <Icon icon={['fas', 'bolt']} className="me-1" />
                  <p className="mb-0">{t('StoreCartPage.callToActionButtons.quickOrder')}</p>
                </Card.Body>
              </Card>
            </LinkContainer>
          ) : null}
        </Col>
        <Col md={12} lg={10}>
          <ShopProductsContainer productsEndpoint={`/blink/${state.uid}/cart/`}>
            <CartTable />
          </ShopProductsContainer>
          <BuyButton />
        </Col>
      </Row>
    </Container>
  );
};

export default StoreCartPage;
