import React from 'react';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import {Navigate} from 'react-router';
import {toast} from 'react-toastify';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {useSession} from '../../contexts/SessionContext';
import UsernameField from '../../components/LoginFields/UsernameField';
import PasswordField from '../../components/LoginFields/PasswordField';
import circles from './circles.svg';
import woman from './woman.svg';
import man from './man.svg';
import logo from '../../assets/logo.svg';
import {redirectToResetPassword} from '../../utils';

import styles from './LoginPage.module.scss';

const LoginPage = ({navigateTo}) => {
  const api = useApi();
  const [, sessionActions, selectors] = useSession();
  const {t} = useTranslation('pages');

  const handleSubmit = async (values, actions) => {
    try {
      await api.login(values);
      const data = await api.getSession();
      sessionActions.setSession(data);
    } catch (e) {
      if (e.data?.detail) {
        toast.error(e.data?.detail, {hideProgressBar: true});
      }
      if (e.data?.username) {
        actions.setFieldError('username', e.data.username[0]);
      }
      if (e.data?.password) {
        actions.setFieldError('password', e.data.password[0]);
      }
    }
  };

  // Navigate away if the user is authenticated as a registered customer.
  // We could've used useNavigate from inside a useEffect to achieve the same.
  if (selectors.isAdminSession) {
    return <Navigate to={navigateTo} />;
  }

  return (
    <Container fluid className={styles.container}>
      <div className={styles.circles}>
        <img src={circles} alt="circles" />
      </div>
      <Row>
        <Col
          xs={12}
          sm={8}
          md={6}
          className="position-relative d-flex justify-content-center align-items-center flex-column"
        >
          <img src={woman} alt="woman" className={styles.woman} />
          <img src={logo} alt="2blink" className="mb-4" width={400} />
          <h1>Customize in a blink</h1>
        </Col>
        <Col xs={12} sm={8} md={4} className={styles.rightColumn}>
          <Container className="align-middle">
            <h1 className="fw-light">
              Let&#39;s get
              <br />
              <span>started!</span>
            </h1>
            <Formik
              initialValues={{
                username: '',
                password: '',
              }}
              onSubmit={handleSubmit}
            >
              {(form) => (
                <Form noValidate onSubmit={form.handleSubmit}>
                  <UsernameField />
                  <PasswordField />
                  <div className="text-center pb-1">
                    <Button
                      size="sm"
                      variant="link"
                      className="text-light text-decoration-none"
                      onClick={redirectToResetPassword}
                    >
                      {}
                      {t('LoginPage.ForgotYourPassword')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <Button
                      size="lg"
                      variant="primary-inverse"
                      type="submit"
                      className="d-flex mx-auto"
                    >
                      {form.isSubmitting ? (
                        <Spinner animation="border" />
                      ) : (
                        <div>{t('LoginPage.SignIn')}</div>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        </Col>
        <Col md={2} className="position-relative">
          <img src={man} alt="man" className={styles.man} />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
