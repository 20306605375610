import React from 'react';
import {useField} from 'formik';
import ImageUpload from '../ImageUpload';

const ImageUploadField = ({
  name,
  multiple,
  category,
  enableLinkAttach = false,
  fullWidth = false,
}) => {
  const [field, , helpers] = useField(name);

  return (
    <ImageUpload
      enableLinkAttach={enableLinkAttach}
      setValue={helpers.setValue}
      multiple={multiple}
      category={category}
      content={field.value}
      onUpload={(fileUrls) => {
        if (multiple) {
          if (enableLinkAttach) {
            helpers.setValue([
              ...field.value,
              ...fileUrls.map((url) => ({url, link_to: ''})),
            ]);
          } else {
            helpers.setValue([...field.value, ...fileUrls]);
          }
        } else {
          helpers.setValue(fileUrls[0]);
        }
      }}
      onDelete={helpers.setValue}
      onSwap={helpers.setValue}
      fullWidth={fullWidth}
      name={name}
    />
  );
};

export default ImageUploadField;
