import {useField} from 'formik';
import React, {FC, FocusEventHandler, KeyboardEvent} from 'react';
import {Form} from 'react-bootstrap';

type TypeFieldProps = {
  name: string;
  type: 'email' | 'url' | 'password';
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: 'lg' | 'sm';
  onFocus?: FocusEventHandler<Element>;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  tabIndex?: number;
};

const TypeField: FC<TypeFieldProps> = ({
  name,
  placeholder,
  disabled,
  autoFocus,
  size,
  onFocus,
  onKeyUp,
  type,
  tabIndex = undefined,
}) => {
  const [fieldProps, metaProps] = useField(name);

  return (
    <React.Fragment>
      <Form.Control
        type={type}
        size={size || 'lg'}
        name={fieldProps.name}
        onChange={fieldProps.onChange}
        value={fieldProps.value || ''}
        isInvalid={Boolean(metaProps.touched && metaProps.error)}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
      />
      <Form.Control.Feedback tooltip type="invalid">
        {metaProps.error}
      </Form.Control.Feedback>
    </React.Fragment>
  );
};

export default TypeField;
