import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useApi} from '../../contexts/ApiContext';
import {useSession} from '../../contexts/SessionContext';

const LogoutPage = () => {
  const navigate = useNavigate();
  const [, sessionActions, selectors] = useSession();
  const api = useApi();

  useEffect(() => {
    api.logout();

    const customerLogin = async () => {
      await api.login();
      const session = await api.getSession();
      sessionActions.setSession(session);
    };

    if (selectors.isFullAnonymousSession) {
      customerLogin();
    }

    sessionActions.finish();
    navigate('/');
  }, [api, sessionActions, navigate, selectors]);

  return null;
};

export default LogoutPage;
