import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const ConfirmationModal = ({
  show,
  closeModal,
  title,
  body = null,
  onSubmit,
}) => (
  <Modal show={show} onHide={closeModal}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {body && <Modal.Body>{body}</Modal.Body>}
    <Modal.Footer>
      <Button variant="secondary" onClick={closeModal}>
        Cancelar
      </Button>
      <Button
        autoFocus
        variant="primary"
        onClick={() => {
          onSubmit();
          closeModal();
        }}
      >
        Confirmar
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmationModal;
