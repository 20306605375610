import React from 'react';
import {Container, Row, Button, Col} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useLayoutButtons} from '../../hooks/layout';
import styles from './SectionBottomBar.module.scss';

const SectionBottomBar = () => {
  const [buttons] = useLayoutButtons();

  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col xs={6} />
        <Col xs={6}>
          {buttons.map(({label, variant, icon, onClick, disabled}) => (
            <Button
              key={label}
              onClick={onClick}
              size="lg"
              variant={variant}
              disabled={disabled}
            >
              {icon && <Icon icon={icon} />} {label}
            </Button>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default SectionBottomBar;
