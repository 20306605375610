import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import {Button, Form} from 'react-bootstrap';
import TextField from '../../components/FormFields/TextField';
import NumberField from '../../components/FormFields/NumberField';
import TypeField from '../../components/FormFields/TypeField';
import SelectField from '../../components/FormFields/SelectField';
import optionsFormatter from '../../components/Forms/utils';

type RegisterFormProps = {
  customFields: CustomField[];
  validationSchema: any;
  initialValues: Object;
  handleSubmit: (values: Object) => void;
};

const RegisterForm = ({
  customFields,
  validationSchema,
  initialValues,
  handleSubmit,
}: RegisterFormProps) => {
  const {t} = useTranslation('pages');
  const fieldMap = {
    text: <TextField name="" />,
    select: <SelectField name="" options={[]} />,
    url: <TypeField name="" type="url" />,
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <Form noValidate onSubmit={form.handleSubmit}>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.first_name')}
            </Form.Label>
            <TextField name="first_name" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.last_name')}
            </Form.Label>
            <TextField name="last_name" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.user_phone')}
            </Form.Label>
            <NumberField name="user_phone" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.email')}
            </Form.Label>
            <TypeField name="email" type="email" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.user_company_name')}
            </Form.Label>
            <TextField name="user_company_name" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.user_company_tax_id')}
            </Form.Label>
            <TextField name="user_company_tax_id" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('StoreRegisterUserPage.form.fields.user_shipping_address')}
            </Form.Label>
            <TextField name="user_shipping_address" />
          </Form.Group>
          {customFields.map((customField) => {
            if (customField.visible && customField.edit_permission) {
              return (
                <Form.Group
                  className="mb-2 position-relative"
                  key={customField.id}
                >
                  <Form.Label>{customField.label}</Form.Label>
                  {React.cloneElement(
                    fieldMap[customField.type as keyof typeof fieldMap],
                    {
                      name: `custom_fields.${customField.name}`,
                      options: optionsFormatter(customField.options),
                    },
                  )}
                </Form.Group>
              );
            }

            return null;
          })}
          <Button
            variant="primary"
            type="button"
            size="lg"
            onClick={() => form.submitForm()}
            className="d-block mx-auto"
          >
            {t('StoreRegisterUserPage.form.button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
