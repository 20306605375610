import React, {useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import LayoutButtons from '../../layouts/utils';
import VolumeDiscounts from '../../components/ShopProduct/VolumeDiscounts';
import {CombinationsFilters} from '../../components/ShopProduct';
import CarouselProduct from '../StoreProductPage/CarouselProduct';
import {ProductTitle} from '../StoreProductPage/ProductTitle';
import ProductPrice from '../StoreProductPage/ProductPrice';
import ProductDescription from '../StoreProductPage/ProductDescription';
import {formatPrice} from './utils';

import styles from './ProductsFormPage.module.scss';

const toCategoryTree = (category, categories) => {
  const node = {
    ...category,
    children: [],
  };

  const children = categories.filter(
    (element) => element.parent === category.id,
  );

  children.forEach((child) =>
    node.children.push(toCategoryTree(child, categories)),
  );

  return node;
};

const StepFinal = ({allCategories, actions, data}) => {
  const {t} = useTranslation('pages');
  const {product} = data;

  const categories = useMemo(() => {
    const productCategories = [...product.categories].map((category) =>
      allCategories.asMap.get(category),
    );

    const rootCategories = productCategories.filter(
      (category) => !category.parent,
    );

    return rootCategories.map((category) =>
      toCategoryTree(category, productCategories),
    );
  }, [allCategories.asMap, product.categories]);

  const customFields = product.custom_fields.reduce((accum, customField) => {
    accum[customField.field] = customField.value;
    return accum;
  }, {});

  const priceRange = useMemo(() => formatPrice(product), [product]);

  const newProduct = useMemo(
    () => ({
      ...product,
      categories,
      custom_fields: customFields,
      quantity: 0,
      price_range: priceRange,
    }),
    [categories, customFields, product, priceRange],
  );

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} className={styles.stepTitle}>
          <h1>{t('ProductsFormPage.Stepper.Steps.stepFinal.title')}</h1>
          <p>{t('ProductsFormPage.Stepper.Steps.stepFinal.description')}</p>
        </Col>
        <Col xs={6}>
          <CarouselProduct
            images={newProduct.images}
            video={newProduct.video}
          />
        </Col>
        <Col xs={6}>
          <ProductTitle title={newProduct.name} sku={newProduct.default_code} />
          <ProductPrice product={newProduct} currency="USD" />
          {newProduct.volume_discounts.length > 0 && (
            <VolumeDiscounts
              product={newProduct}
              currency="USD"
              direction="row"
            />
          )}
          <ProductDescription product={newProduct} />
          <CombinationsFilters product={newProduct} />
        </Col>
      </Row>
      <LayoutButtons
        handleBack={actions.handleBack}
        handleNext={actions.handleNext}
      />
    </React.Fragment>
  );
};

export default StepFinal;
