import React, {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import {Card, Tab, Nav, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import {useLayoutTitle} from '../../hooks/layout';
import LayoutButtons from '../../layouts/utils';
import SettingsForm from './SettingsForm';
import {ConfirmationModal} from '../../components/Modals';
import i18n from '../../translations/i18n';

import styles from './SettingsPage.module.scss';

const SETTINGS_LABELS = {
  general_information: i18n.t(
    'pages:SettingsPage.Tabs.titles.general_information',
  ),
  web_operation: i18n.t('pages:SettingsPage.Tabs.titles.web_operation'),
  store_configuration: i18n.t(
    'pages:SettingsPage.Tabs.titles.store_configuration',
  ),
  store_design: i18n.t('pages:SettingsPage.Tabs.titles.store_design'),
  integrations: i18n.t('pages:SettingsPage.Tabs.titles.integrations'),
  email: i18n.t('pages:SettingsPage.Tabs.titles.email'),
};

const SettingsPage = () => {
  const api = useApi();
  const [utilsState, utilsActions] = useUtils();
  useLayoutTitle({
    label: 'Configuración',
    icon: 'fa-solid fa-gear',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [settingsGroup, setSettingsGroup] = useState([]);
  const [initialsPerGroup, setInitialsPerGroup] = useState([]);
  const [type, setType] = useState('general_information');
  const [modal, setModal] = useState({
    show: false,
    onSubmit: null,
    onClose: null,
  });

  useEffect(() => {
    const fetchSettingDefinitions = async () => {
      const responseSettingDefinition = await api.get('/setting-definition');

      const settingsPerGroup = responseSettingDefinition.results.reduce(
        (accum, current) => {
          accum[current.group] = accum[current.group] || [];
          accum[current.group].push(current);
          return accum;
        },
        {},
      );

      const initialsValuesPerGroup = responseSettingDefinition.results.reduce(
        (accum, current) => {
          accum[current.group] = accum[current.group] || {};
          accum[current.group][current.name] =
            utilsState.settings[current.name] || current.default_value;
          return accum;
        },
        {},
      );

      setSettingsGroup(settingsPerGroup);
      setInitialsPerGroup(initialsValuesPerGroup);
      setSettings(responseSettingDefinition.results);
    };
    fetchSettingDefinitions();
  }, [api, utilsState.settings]);

  const handleSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      Promise.all(
        Object.keys(values)
          .filter((name) => !(values[name] === initialsPerGroup[type][name]))
          .map((name) =>
            api.post('/setting', {
              name,
              value: values[name],
            }),
          ),
      ).then(async () => {
        const fetchSettings = async () => {
          const settingsList = await api.get('/setting');
          const fetchedSettings = settingsList.results.reduce(
            (accum, current) => {
              accum[current.name] = current.value;
              return accum;
            },
            {},
          );

          utilsActions.setSettings(fetchedSettings);
        };

        await fetchSettings();
        setIsLoading(false);

        toast.success('La configuración se actualizó correctamente', {
          hideProgressBar: true,
        });
      });
    },
    [api, utilsActions, setIsLoading, initialsPerGroup, type],
  );

  const formRef = useRef({});
  const extraButton = useMemo(
    () => ({
      variant: 'primary',
      label: 'Guardar',
      onClick: () => {
        formRef.current[type].submitForm();
      },
    }),
    [type],
  );

  const closeModal = () => {
    setModal({
      show: false,
      onSubmit: null,
      onClose: null,
    });
  };

  const submitModal = async (key) => {
    await formRef.current[type].submitForm();

    setType(key);
  };

  const handleOnSelect = (key) => {
    const form = formRef.current[type];
    if (form.isValid && form.dirty) {
      setModal({
        show: true,
        onSubmit: () => submitModal(key),
        onClose: closeModal,
      });
    } else if (form.isValid && !form.dirty) {
      setType(key);
    } else {
      toast.error('Tienes campos requeridos vacíos o con errores!', {
        hideProgressBar: true,
      });
    }
  };

  if (settings.length === 0) {
    return null;
  }

  return (
    <Tab.Container
      defaultActiveKey={type}
      activeKey={type}
      onSelect={(key) => handleOnSelect(key)}
    >
      <Card className={styles.settings}>
        <Card.Header>
          <Nav justify variant="pills">
            {Object.keys(settingsGroup).map((key) => (
              <Nav.Item key={key}>
                <Nav.Link eventKey={key} className="fw-bold">
                  {SETTINGS_LABELS[key]}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Tab.Content>
              {Object.entries(settingsGroup).map(([key, values]) => (
                <SettingsForm
                  key={key}
                  group={key}
                  settings={values}
                  initialValues={initialsPerGroup[key]}
                  handleSubmit={handleSubmit}
                  ref={formRef}
                />
              ))}
              <LayoutButtons extraButton={extraButton} />
            </Tab.Content>
          )}
        </Card.Body>
      </Card>
      <ConfirmationModal
        show={modal.show}
        closeModal={modal.onClose}
        title="Tiene cambios sin guardar, desea guardar?"
        onSubmit={modal.onSubmit}
      />
    </Tab.Container>
  );
};

export default SettingsPage;
