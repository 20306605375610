import React, {useCallback} from 'react';
import {Card} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import DataTree from '../../components/DataTree';
import {useLayoutTitle} from '../../hooks/layout';

import styles from './ProductsCategories.module.scss';

const ProductsCategoriesPage = () => {
  const api = useApi();
  const [utilsState, utilsActions] = useUtils();
  useLayoutTitle({
    label: 'Categorías',
    icon: 'fa-solid fa-cart-shopping',
  });

  const fetchCategories = useCallback(async () => {
    const response = await api.get('/category/');

    utilsActions.setCategories(response);
  }, [api, utilsActions]);

  const handleCreateCategory = async (newTitle, parentId) => {
    try {
      await api.post('/category/', {
        title: newTitle,
        parent: parentId || null,
      });
      fetchCategories();
    } catch (e) {
      toast.error('Error creating category', {hideProgressBar: true});
    }
  };

  const handleDeleteCategory = async (nodeId) => {
    try {
      await api.delete(`/category/${nodeId}/`);
      fetchCategories();
    } catch (e) {
      toast.error('Error deleting category', {hideProgressBar: true});
    }
  };

  const handleEditCategory = async (nodeId, newTitle) => {
    try {
      await api.put(`/category/${nodeId}/`, {title: newTitle});
      fetchCategories();
    } catch (e) {
      toast.error('Error editing category', {hideProgressBar: true});
    }
  };

  const handleSwap = async (catA, catB) => {
    try {
      await api.post('/product-helper/swap-category-order/', {
        category_a: catA,
        category_b: catB,
      });
      fetchCategories();
    } catch (e) {
      toast.error('Error rearranging categories', {hideProgressBar: true});
    }
  };

  return (
    <Card>
      <Card.Body>
        <div className={styles.title}>
          <h1>Gestor de Categorías</h1>
          <p>
            Creá categorías y sub categorías. Además, podés ordenarlas y
            eliminarlas.
          </p>
        </div>
        {utilsState.categories ? (
          <DataTree
            data={utilsState.categories.tree}
            handleCreate={handleCreateCategory}
            handleDelete={handleDeleteCategory}
            handleEdit={handleEditCategory}
            handleSwap={handleSwap}
          />
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ProductsCategoriesPage;
