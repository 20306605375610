/* eslint-disable react/jsx-props-no-spreading */
import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Card} from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import useWebsocketChannel from '../../hooks/useWebsocketChannel';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {ImporterRow, InvalidImporterData, S3Fields, SerializerValidatedData} from './types';

import styles from './StoreQuickOrderPage.module.scss';

type ImporterDataReducerAccum = {
  validRows: SerializerValidatedData[];
  invalidRows: InvalidImporterData[];
};

type ImporterQuickOrder = {
  setValidImporterData: Dispatch<SetStateAction<SerializerValidatedData[]>>;
  setInvalidImporterData: Dispatch<SetStateAction<InvalidImporterData[]>>;
  fetchStore: (uid: string) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const ImportQuickOrder = ({
  setValidImporterData,
  setInvalidImporterData,
  fetchStore,
  setIsLoading,
}: ImporterQuickOrder) => {
  const api = useApi();
  const {t} = useTranslation('pages');
  const [{uid}] = useEcommerce();

  const fetchImporter = useCallback(
    async (notification: ImporterNotification) => {
      if (notification.importer_file_id) {
        const importerRows = await api.get(`/importer/${notification.importer_file_id}/file-row/`);

        const {validRows, invalidRows} = importerRows.results.reduce(
          (accum: ImporterDataReducerAccum, current: ImporterRow) => {
            if (current.serializer_validated_data) {
              const validRowIndex = accum.validRows.findIndex(
                (validRow) =>
                  validRow.default_code === current.serializer_validated_data?.default_code,
              );

              if (validRowIndex !== -1) {
                accum.validRows[validRowIndex].quantity +=
                  current.serializer_validated_data.quantity;
                return accum;
              }

              accum.validRows.push(current.serializer_validated_data);

              return accum;
            }

            const invalidRowIndex = accum.invalidRows.findIndex(
              (invalidRow) => invalidRow.data.default_code === current.serializer_data.default_code,
            );

            if (invalidRowIndex !== -1) {
              return accum;
            }

            if (current.serializer_errors) {
              accum.invalidRows.push({
                error: current.serializer_errors,
                data: current.serializer_data,
              });
            }

            return accum;
          },
          {validRows: [], invalidRows: []},
        );

        setValidImporterData(validRows);
        setInvalidImporterData(invalidRows);
      }

      if (notification.refresh && uid) {
        fetchStore(uid);
        toast.success(`${t('StoreQuickOrderPage.products.imported.success')}`, {
          hideProgressBar: true,
        });
      }
    },
    [api, uid, fetchStore, t, setInvalidImporterData, setValidImporterData],
  );

  const onDropAccepted = async ([acceptedFile]: File[]) => {
    setValidImporterData([]);
    setInvalidImporterData([]);
    setIsLoading(true);
    const response = await api.post('/importer/file-signature/', {
      file_name: acceptedFile.name,
      content_type: acceptedFile.type,
    });
    const presignedPost = response.presigned_post;
    const importerFileUri = response.importer_file_uri;
    const formData = new FormData();
    Object.entries(presignedPost.fields as S3Fields).forEach(([field, value]) => {
      formData.append(field, value);
    });
    formData.append('file', acceptedFile);
    await fetch(presignedPost.url, {body: formData, method: 'post'});

    await api.post('/importer/file-process/', {
      importer_file_uri: importerFileUri,
    });

    setIsLoading(false);

    return importerFileUri;
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted,
    noKeyboard: true,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 200 * 1000000,
  });

  useWebsocketChannel('importer', fetchImporter);

  return (
    <Card className="mb-2">
      <Card.Body>
        {/*
          TODO: Implement some self made Dropzone component like in ImportProductsPage for mantain the structure of application.
        */}
        <div {...getRootProps()} className={styles.dropzone}>
          <input {...getInputProps()} />
          <p className="mb-0">{t('StoreQuickOrderPage.Dropzone.description')}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ImportQuickOrder;
