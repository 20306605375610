import React, {useCallback, useState} from 'react';
import {OverlayTrigger, Popover, Button, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {Discount} from '../../types/Ecommerce';
import {formatMoney} from '../../utils';

import styles from './OrderSummary.module.scss';

type OrderSummaryProps = {
  subtotal: string;
  discounts: string;
  quantity: number;
  total: string;
  currency: string;
  priceList?: string | null;
  taxesEnabled: boolean;
  fromEcommerce?: {
    allowCheckout: boolean;
    showPrices: boolean;
    minimumPurchase: number;
  };
  companyDiscounts?: Discount[];
  styleProp?: string;
};

const OrderSummary: React.FC<OrderSummaryProps> = ({
  subtotal,
  discounts,
  quantity,
  total,
  currency,
  priceList,
  taxesEnabled,
  fromEcommerce,
  companyDiscounts,
  styleProp,
}) => {
  const {t} = useTranslation('components');

  const navigate = useNavigate();

  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const handleToggle = useCallback(
    (nextShow: boolean) => {
      if (fromEcommerce?.showPrices) {
        setDetailVisible(nextShow);
      }
    },
    [fromEcommerce?.showPrices],
  );

  const appliedDiscount = companyDiscounts
    ?.slice()
    .sort((a, b) => a.min_amount - b.min_amount)
    .reverse()
    .find((x) => x.min_amount < Number.parseFloat(subtotal));

  const isCartMeetsMinimumPurchase =
    Number(total) >= Number(fromEcommerce?.minimumPurchase) && Number(total) > 0;

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      onToggle={handleToggle}
      show={detailVisible}
      rootClose
      overlay={
        <Popover className={styles.popover}>
          <Popover.Body className="p-0">
            <p>
              {t('OrderSummary.subtotal', {
                quantity,
              })}
              <span>{formatMoney(subtotal, currency)}</span>
            </p>
            {!!companyDiscounts?.length && (
              <p className="text-danger">
                {t('OrderSummary.appliedDiscount', {
                  discount: appliedDiscount?.discount || 0,
                })}
                <span>{formatMoney(discounts, currency)}</span>
              </p>
            )}
            <p className="text-secondary">
              {t('OrderSummary.total')}
              <span>
                {formatMoney(total, currency)}
                {taxesEnabled ? <span>+IVA</span> : null}
              </span>
            </p>
            {fromEcommerce && (
              <div>
                <Row className="d-flex justify-content-center">
                  {!fromEcommerce.allowCheckout && (
                    <p className="d-flex justify-content-center text-center text-danger pt-1 pb-0 w-75 opacity-75">
                      {!isCartMeetsMinimumPurchase
                        ? t('OrderSummary.minimumPurchaseInfo', {
                            amount: formatMoney(fromEcommerce.minimumPurchase, currency),
                          })
                        : t('OrderSummary.anonCheckoutRestrictionMessage')}
                    </p>
                  )}
                </Row>
                <Row className="d-flex py-1 mx-0">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="rounded-pill"
                      onClick={() => {
                        navigate('cart/');
                        setDetailVisible(false);
                      }}
                    >
                      {t('OrderSummary.seeCart')}
                    </Button>
                  </Col>
                  <Col className="d-flex">
                    <Button
                      variant="primary"
                      className="rounded-pill text-nowrap"
                      disabled={!fromEcommerce.allowCheckout}
                      onClick={() => {
                        navigate('checkout/');
                        setDetailVisible(false);
                      }}
                    >
                      {t('OrderSummary.endPurchase')}
                    </Button>
                  </Col>
                </Row>
                {priceList ? (
                  <p className="d-block text-center">
                    {t('OrderSummary.priceList')} {priceList}
                  </p>
                ) : null}
              </div>
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <div className={`${styleProp}`}>
        <Button variant="transparent" className={`${styles.summaryButton} fs-5`}>
          <Icon
            className={`me-1 text-secondary ${fromEcommerce ? 'px-2' : ''}`}
            icon="cart-shopping"
          />
          {fromEcommerce?.showPrices && (
            <React.Fragment>
              <strong className="me-1">{formatMoney(total, currency)}</strong>
              {detailVisible ? <Icon icon="angle-up" /> : <Icon icon="angle-down" />}
            </React.Fragment>
          )}
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default OrderSummary;
