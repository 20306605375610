import {Formik} from 'formik';
import React, {useCallback} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

const FormModal = ({
  show,
  closeModal,
  title,
  initialValues,
  onSubmit,
  body,
  validationSchema,
  disableIfInvalid = false,
}) => {
  const submitAndClose = useCallback(
    (values) => {
      onSubmit(values);
      closeModal();
    },
    [closeModal, onSubmit],
  );

  return (
    <Modal show={show} onHide={closeModal}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitAndClose}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form noValidate onSubmit={formikProps.handleSubmit}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Cerrar
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!(!disableIfInvalid || formikProps.isValid)}
              >
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FormModal;
