import React from 'react';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';

const CheckGroupField = ({name, value, label, disabled, onChange, checked}) => {
  const [fieldProps] = useField({
    name,
    value,
    type: 'checkbox',
  });

  return (
    <Form.Check
      type="checkbox"
      name={fieldProps.name}
      onChange={onChange || fieldProps.onChange}
      label={label}
      value={fieldProps.value}
      disabled={disabled}
      checked={checked || fieldProps.checked}
    />
  );
};

export default CheckGroupField;
