import React, {useMemo} from 'react';
import {Formik} from 'formik';
import {Col, Form, Row} from 'react-bootstrap';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import i18n from '../../translations/i18n';
import {useUtils} from '../../contexts/UtilsContext';
import LayoutButtons from '../../layouts/utils';
import NumberField from '../../components/FormFields/NumberField';

import styles from './ProductsFormPage.module.scss';

const validationSchema = Yup.object().shape({
  prices: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .positive(
          `${i18n.t(
            'ProductsFormPage.form.validations.messages.number.positive',
          )}`,
        ),
    }),
  ),
});

const StepPriceLists = ({actions, data, setData}) => {
  const {t} = useTranslation('pages');
  const [utilsState] = useUtils();

  const initialValues = useMemo(() => {
    const priceLists = utilsState.priceLists.map((priceList) => {
      const productPrice = data.product.prices.find(
        (price) => price.price_list === priceList.id,
      );

      if (productPrice) {
        return productPrice;
      }

      return {
        id: null,
        amount: null,
        price_list: priceList.id,
        name: priceList.name,
        label: priceList.label,
      };
    });

    const defaultPriceList = {
      amount: data.product.default_price,
      name: `${t(
        'ProductsFormPage.Stepper.Steps.stepPriceLists.defaultPriceList.name',
      )}`,
      label: `${t(
        'ProductsFormPage.Stepper.Steps.stepPriceLists.defaultPriceList.label',
      )}`,
      isDefault: true,
    };

    return {
      prices: [defaultPriceList, ...priceLists],
    };
  }, [
    utilsState.priceLists,
    data.product.prices,
    data.product.default_price,
    t,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setData((prevState) => {
          const prevProduct = prevState.product;
          const newProduct = {
            ...prevProduct,
            prices: values.prices.reduce((accum, current) => {
              if (current.amount && !current.isDefault) {
                accum.push(current);
              }

              return accum;
            }, []),
          };
          return {
            ...prevState,
            product: newProduct,
          };
        });
        actions.handleNext();
      }}
    >
      {(formikProps) => (
        <Form noValidate onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col xs={12} className={styles.stepTitle}>
              <h1>
                {t('ProductsFormPage.Stepper.Steps.stepPriceLists.title')}
              </h1>
              <p>
                {t('ProductsFormPage.Stepper.Steps.stepPriceLists.description')}
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <strong>
                {t(
                  'ProductsFormPage.Stepper.Steps.stepPriceLists.table.headers.list',
                )}
              </strong>
            </Col>
            <Col>
              <strong>
                {t(
                  'ProductsFormPage.Stepper.Steps.stepPriceLists.table.headers.price',
                )}
              </strong>
            </Col>
          </Row>
          {initialValues.prices.map((priceList, idx) => (
            <Row className="mb-1" key={priceList.name}>
              <Col className="d-flex align-items-center">{priceList.name}</Col>
              <Col>
                <NumberField
                  name={`prices.${idx}.amount`}
                  symbol="$"
                  disabled={priceList.isDefault}
                  helpText={
                    priceList.isDefault
                      ? t(
                          'ProductsFormPage.Stepper.Steps.stepPriceLists.defaultPriceList.help',
                        )
                      : ''
                  }
                />
              </Col>
            </Row>
          ))}
          <LayoutButtons
            handleBack={actions.handleBack}
            handleNext={formikProps.submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StepPriceLists;
