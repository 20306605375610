import React, {FC, ReactElement, useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useUtils} from '../../contexts/UtilsContext';
import {useApi} from '../../contexts/ApiContext';
import {ConfirmationModal, FormModal} from '../../components/Modals';
import TextField from '../../components/FormFields/TextField';
import {useLayout} from '../../contexts/LayoutContext';
import {useLayoutTitle} from '../../hooks/layout';
import ControlCard from '../../components/Grid/ControlCard';
import {PriceList, UnsavedPriceList} from '../../types/Ecommerce';

import styles from './PriceListPage.module.scss';

const PriceListForm = () => {
  const {t} = useTranslation('pages');
  return (
    <Row>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>{t('PriceListsPage.form.fields.internalName')}*</Form.Label>
        <TextField name="name" />
      </Form.Group>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>{t('PriceListsPage.form.fields.label')}</Form.Label>
        <TextField
          name="label"
          helpText={
            t('PriceListsPage.form.fields.labelHelp') ||
            'Dejar en blanco si no desea que el usuario sepa el nombre de la lista de precios asignada.'
          }
        />
      </Form.Group>
    </Row>
  );
};
const CallToActionButtons: FC<{createModal: () => void}> = ({createModal}) => {
  const {t} = useTranslation('pages');
  return (
    <Button
      variant="secondary"
      onClick={createModal}
      className={styles.ctaButton}
    >
      <Icon icon="money-check-dollar" /> <strong>+</strong>{' '}
      {t('PriceListsPage.addButton')}
    </Button>
  );
};

type ModalState = {
  title: string;
  type: string;
  body: ReactElement<any, any> | null;
  onClose: (() => void) | null;
  onSubmit: ((values: UnsavedPriceList) => void) | null;
  initialValues?: UnsavedPriceList;
};

const PriceListsPage = () => {
  const api = useApi();
  const [, actions] = useLayout();
  const [utilsState, utilsActions] = useUtils();
  const [modal, setModal] = useState<ModalState>({
    title: '',
    body: null,
    type: '',
    onClose: null,
    onSubmit: null,
    initialValues: {name: '', label: undefined},
  });
  const {t} = useTranslation('pages');

  useLayoutTitle({
    label: 'Listas de Precio',
    icon: 'cart-shopping',
  });

  const fetchPriceLists = useCallback(async () => {
    const response = await api.get('/price-list');
    utilsActions.setPriceLists(response.results);
  }, [api, utilsActions]);

  const closeModal = () =>
    setModal({
      title: '',
      type: '',
      body: null,
      onClose: null,
      onSubmit: null,
      initialValues: {
        name: '',
        label: '',
      },
    });

  const createPriceList = useCallback(
    () =>
      setModal({
        title: t('PriceListsPage.modals.form.createTitle'),
        type: 'form',
        body: <PriceListForm />,
        onClose: closeModal,
        onSubmit: async (values: UnsavedPriceList) => {
          try {
            await api.post('/price-list', values);
            fetchPriceLists();
            toast.success(t('PriceListsPage.form.success.create'), {
              hideProgressBar: true,
            });
          } catch (e: any) {
            Object.values(e.data).map((error: any) =>
              toast.error(`Error: ${error.join(', ')}`, {
                hideProgressBar: true,
              }),
            );
          }
        },
        initialValues: {
          name: '',
          label: '',
        },
      }),
    [api, fetchPriceLists, t],
  );

  const editPriceList = (priceList: PriceList) =>
    setModal({
      title: t('PriceListsPage.modals.form.editTitle'),
      type: 'form',
      body: <PriceListForm />,
      onClose: closeModal,
      onSubmit: async (values: UnsavedPriceList) => {
        try {
          await api.put(`/price-list/${priceList.id}`, values);
          toast.success(t('PriceListsPage.form.success.edit'), {
            hideProgressBar: true,
          });
          fetchPriceLists();
        } catch (e: any) {
          Object.values(e.data).map((error: any) =>
            toast.error(`Error: ${error.join(', ')}`, {hideProgressBar: true}),
          );
        }
      },
      initialValues: {
        name: priceList.name,
        label: priceList.label,
      },
    });

  const deletePriceList = (priceList: PriceList) =>
    setModal({
      title: t('PriceListsPage.modals.confirmation.deleteTitle'),
      type: 'confirmation',
      body: <p>{t('PriceListsPage.modals.confirmation.deleteBody')}</p>,
      onClose: closeModal,
      onSubmit: async () => {
        try {
          await api.delete(`/price-list/${priceList.id}`);
          toast.success(t('PriceListsPage.form.success.delete'), {
            hideProgressBar: true,
          });
          fetchPriceLists();
        } catch (e: any) {
          Object.values(e.data).map((error: any) =>
            toast.error(`Error: ${error.join(', ')}`, {hideProgressBar: true}),
          );
        }
      },
    });

  useEffect(() => {
    actions.setTopBar(<CallToActionButtons createModal={createPriceList} />);

    return () => {
      actions.setTopBar(null);
    };
  }, [actions, createPriceList]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('PriceListsPage.form.errors.required') || 'Requerido',
    ),
    label: Yup.string(),
  });
  return (
    <React.Fragment>
      <FormModal
        show={modal.type === 'form'}
        title={modal.title}
        closeModal={modal.onClose}
        body={modal.body}
        onSubmit={modal.onSubmit}
        initialValues={modal.initialValues}
        validationSchema={validationSchema}
      />
      <ConfirmationModal
        // TODO: type ConfirmationModal to remove this assertion
        body={modal.body as any}
        show={modal.type === 'confirmation'}
        closeModal={modal.onClose}
        title={modal.title}
        onSubmit={modal.onSubmit}
      />
      <Row className={styles.priceListsPage}>
        <Col sm={12} md={6} lg={4}>
          <ControlCard title={t('PriceListsPage.defaultPriceList.name')}>
            <Card.Body>
              <Card.Text className="fw-bold">
                {t('PriceListsPage.defaultPriceList.help')}
              </Card.Text>
            </Card.Body>
          </ControlCard>
        </Col>
        {utilsState.priceLists?.map((priceList: PriceList) => (
          <Col key={priceList.name} xs={4}>
            <ControlCard
              title={priceList.name}
              handleDelete={() => deletePriceList(priceList)}
              handleEdit={() => editPriceList(priceList)}
            >
              <Card.Body>
                <Card.Text>
                  {t('PriceListsPage.form.fields.label')}:{' '}
                  {`${priceList.label || '-'}`}
                </Card.Text>
              </Card.Body>
            </ControlCard>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default PriceListsPage;
