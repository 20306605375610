import React, {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import SectionTopBar from './SectionTopBar';
import SectionSideBar from './SectionSideBar';
import SectionBottomBar from './SectionBottomBar';
import SectionMain from './SectionMain';

import styles from './PrivateLayout.module.scss';

const PrivateLayout = ({children}) => {
  const [, utilsActions] = useUtils();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsList = await api.get('/setting');
      const settings = settingsList.results.reduce((accum, current) => {
        accum[current.name] = current.value;
        return accum;
      }, {});

      utilsActions.setSettings(settings);
    };

    const fetchUserCustomFields = async () => {
      const response = await api.get('/user/custom-field/');

      utilsActions.setUserCustomFields(response.results);
    };

    const fetchCategories = async () => {
      const response = await api.get('/category/');

      utilsActions.setCategories(response);
    };

    const fetchPriceLists = async () => {
      const response = await api.get('/price-list');
      utilsActions.setPriceLists(response.results);
    };

    setIsLoading(true);
    fetchSettings();
    fetchCategories();
    fetchUserCustomFields();
    fetchPriceLists();
    setIsLoading(false);
  }, [api, utilsActions]);

  return (
    <div className={styles.container}>
      <section className={styles.sidebarContainer}>
        <SectionSideBar />
      </section>
      {isLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <section className={styles.mainContainer}>
          <section className={styles.mainTop}>
            <SectionTopBar />
          </section>
          <section className={styles.main}>
            <SectionMain>{children}</SectionMain>
          </section>
          <section className={styles.mainBottom}>
            <SectionBottomBar />
          </section>
        </section>
      )}
    </div>
  );
};

export default PrivateLayout;
