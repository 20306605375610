import React, {FC} from 'react';
import {sanitize} from '../../utils';

const SanitizedHTML: FC<{dirtyHTML: string}> = ({dirtyHTML}) => {
  const sanitizedHTML = sanitize(dirtyHTML);
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{__html: sanitizedHTML}} />;
};

export default SanitizedHTML;
