const optionsFormatter = (options) => {
  if (options.length > 0) {
    return options.reduce(
      (accum, current) => {
        accum.push([
          String(current.option).toLocaleLowerCase(),
          String(current.option),
        ]);
        return accum;
      },
      [['', '']],
    );
  }

  return null;
};

export default optionsFormatter;
