import React, {useCallback, useMemo, useState} from 'react';
import {Button, Form, Col, Row} from 'react-bootstrap';
import styles from './DataTree.module.scss';
import Node from './Nodes';
import {ConfirmationModal, FormModal, MODAL_TYPES} from '../Modals';
import TextField from '../FormFields/TextField';
import {getTreeNodeIds, categoryFormValidationSchema} from './utils';

const CreateNodeForm = () => (
  <Row>
    <Col>
      <Form.Group className="position-relative">
        <TextField
          name="title"
          placeholder="Ingrese nombre de categoría"
          autoFocus
        />
      </Form.Group>
    </Col>
  </Row>
);

const EditNodeForm = () => (
  <Row>
    <Col>
      <Form.Group className="position-relative">
        <TextField
          name="title"
          placeholder="Ingrese nombre de categoría"
          autoFocus
          onFocus={(event) => event.target.select()}
        />
      </Form.Group>
    </Col>
  </Row>
);

const DataTree = ({
  data,
  handleCreate,
  handleDelete,
  handleEdit,
  handleSelect,
  selectedData,
  handleSwap,
  disableCheckbox = false,
  initialExpandedNodes,
}) => {
  const [expandedNodes, setExpandedNodes] = useState(
    initialExpandedNodes || new Set(),
  );
  const [modalState, setModalState] = useState({
    type: null,
    initialValues: null,
    title: null,
    body: null,
    onSubmit: null,
  });

  const closeModal = useCallback(
    () => setModalState((prevModalState) => ({...prevModalState, type: null})),
    [setModalState],
  );

  const toggleExpand = useCallback(
    (id) =>
      setExpandedNodes((prevExpanded) => {
        const newExpanded = new Set(prevExpanded);
        if (prevExpanded.has(id)) {
          newExpanded.delete(id);
        } else {
          newExpanded.add(id);
        }
        return newExpanded;
      }),
    [setExpandedNodes],
  );
  const isExpanded = useCallback(
    (nodeId) => expandedNodes.has(nodeId),
    [expandedNodes],
  );

  const isSelected = useCallback(
    (nodeId) => {
      if (handleSelect) {
        return selectedData.has(nodeId);
      }
      return false;
    },
    [handleSelect, selectedData],
  );

  const triggerCreateModal = useMemo(
    () =>
      handleCreate &&
      ((node) => {
        setModalState({
          type: MODAL_TYPES.form,
          title: 'Crear categoría',
          initialValues: {
            title: '',
          },
          body: <CreateNodeForm />,
          onSubmit: (values) => {
            handleCreate(values.title, node?.id);
            if (node && !isExpanded(node.id)) {
              toggleExpand(node.id);
            }
          },
          validationSchema: categoryFormValidationSchema,
        });
      }),
    [handleCreate, setModalState, isExpanded, toggleExpand],
  );

  const triggerEditModal = useMemo(
    () =>
      handleEdit &&
      ((node) =>
        setModalState({
          type: MODAL_TYPES.form,
          title: 'Editar categoría',
          initialValues: {
            title: node.title,
          },
          body: <EditNodeForm />,
          onSubmit: (values) => handleEdit(node.id, values.title),
          validationSchema: categoryFormValidationSchema,
        })),
    [handleEdit, setModalState],
  );

  const triggerDeleteModal = useMemo(
    () =>
      handleDelete &&
      ((node) =>
        setModalState({
          type: MODAL_TYPES.confirmation,
          title: 'Desea borrar la cateogría?',
          onSubmit: () => {
            const deletedIds = getTreeNodeIds(node);
            handleDelete(node.id);
            // Reconcile the state of the expanded and selected children nodes.
            deletedIds.forEach((nodeId) => {
              if (isExpanded(nodeId)) {
                toggleExpand(nodeId);
              }
              if (isSelected(nodeId)) {
                handleSelect(nodeId);
              }
            });
          },
        })),
    [handleDelete, isExpanded, toggleExpand, isSelected, handleSelect],
  );

  return (
    <React.Fragment>
      {handleCreate && (
        <Button className="mb-1" onClick={() => triggerCreateModal(null)}>
          Nueva categoría
        </Button>
      )}
      <ul className={styles.tree}>
        {data.map((node) => (
          <Node
            key={node.id}
            node={node}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            isSelected={isSelected}
            handleSelect={handleSelect}
            triggerCreateModal={triggerCreateModal}
            triggerEditModal={triggerEditModal}
            triggerDeleteModal={triggerDeleteModal}
            handleSwap={handleSwap}
            disableCheckbox={disableCheckbox}
          />
        ))}
      </ul>
      <ConfirmationModal
        show={modalState.type === MODAL_TYPES.confirmation}
        closeModal={closeModal}
        title={modalState.title}
        onSubmit={modalState.onSubmit}
      />
      <FormModal
        show={modalState.type === MODAL_TYPES.form}
        closeModal={closeModal}
        title={modalState.title}
        initialValues={modalState.initialValues}
        body={modalState.body}
        onSubmit={modalState.onSubmit}
        validationSchema={modalState.validationSchema}
      />
    </React.Fragment>
  );
};

export default DataTree;
