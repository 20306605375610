/* eslint react/jsx-props-no-spreading: off */
import React from 'react';

const SelectionCheckbox = ({indeterminate, ...rest}) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <input className="form-check-input" type="checkbox" ref={ref} {...rest} />
  );
};

const addSelectionColumn = (allowSelection) => {
  if (!allowSelection) {
    return () => {};
  }

  return (hooks) =>
    hooks.visibleColumns.push((columns) => [
      {
        Header: ({getToggleAllRowsSelectedProps}) => (
          <SelectionCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({row}) => (
          <SelectionCheckbox {...row.getToggleRowSelectedProps()} />
        ),
      },
      ...columns,
    ]);
};

const FILTERABLE_TYPES = {
  CHOICES: 'choices',
  DATERANGE: 'daterange',
};

export default addSelectionColumn;

export {FILTERABLE_TYPES};
