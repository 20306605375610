import React, {FC} from 'react';
import {useField} from 'formik';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const RichTextField: FC<{
  name: string;
  maxCharacters: number;
  availableControls: string[][] | undefined;
}> = ({name, maxCharacters, availableControls}) => {
  const [fieldProps, metaProps, helpers] = useField<string>(name);

  return (
    <div>
      <RichTextEditor
        content={fieldProps.value}
        setValue={helpers.setValue}
        maxCharacters={maxCharacters}
        availableControls={availableControls}
      />
      {metaProps.touched && metaProps.error && <div>{metaProps.error}</div>}
    </div>
  );
};

export default RichTextField;
