import React, {useState} from 'react';
import {Col, Row, useAccordionButton} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import VolumeDiscounts from './VolumeDiscounts';
import {CombinationsMatrixAndFilters} from './CombinationsMatrix';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';

import styles from './ShopProduct.module.scss';

export interface ExpandedRowContentProps {
  product: ShopProduct;
  currency: string;
  setCombinationQuantity?: CombinationQuantitySetter;
}

interface ExpandedRowToggleProps {
  eventKey: string;
  disabled?: boolean;
}
const ExpandedRowToggle = ({eventKey, disabled}: ExpandedRowToggleProps) => {
  const [toggled, setToggled] = useState(false);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setToggled((prevState) => !prevState);
  });

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className={`${styles.expandedRowToggle}`}
      disabled={disabled}
    >
      <Icon icon="angle-down" className={toggled ? styles.active : ''} />
    </button>
  );
};

const RegularExpandedRowContent = ({
  product,
  currency,
  setCombinationQuantity,
}: ExpandedRowContentProps) => {
  const hasVolumeDiscounts = product.volume_discounts.length > 0;
  const hasMultipleCombinations = product.combinations.length > 1;

  if (!hasVolumeDiscounts && !hasMultipleCombinations) {
    return null;
  }

  return (
    <Row>
      <Col md={12} lg={hasMultipleCombinations ? 3 : 12}>
        {hasVolumeDiscounts && (
          <VolumeDiscounts
            product={product}
            currency={currency}
            direction="column"
          />
        )}
      </Col>
      <Col md={12} lg={hasVolumeDiscounts ? 9 : 12}>
        {hasMultipleCombinations && (
          <CombinationsMatrixAndFilters
            product={product}
            currency={currency}
            setCombinationQuantity={setCombinationQuantity}
          />
        )}
      </Col>
    </Row>
  );
};

export {ExpandedRowToggle, RegularExpandedRowContent};
