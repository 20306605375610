import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useTable, useRowSelect, usePagination} from 'react-table';
import {useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useApi} from '../../contexts/ApiContext';
import addSelectionColumn from './utils';
import {useDataTable} from './store';
import DataTableView from './DataTableView';

import styles from './DataTable.module.scss';
import {formatDateISO} from '../../utils';

const RemoteDataTable = ({
  source,
  isLoading,
  isInitialized,
  setIsLoading,
  defaultPageSize,
  columns,
  buttons = [],
  orderChoices,
  allowSelection,
  allowSearch,
  searchPlaceholder,
  allowOrder,
}) => {
  const api = useApi();
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [queryParams, setQueryParams] = useSearchParams();
  const [refresh, setRefresh] = useState(false);

  const Cell = useCallback(
    (cell) => (
      <div className={styles.optionButtons}>
        {buttons
          .filter((button) => !button.header)
          .map((button) => (
            <Button
              variant="link"
              size={button.size}
              onClick={async () => {
                try {
                  await button.onClick(cell);
                } catch (e) {
                  toast.error(e.data, {hideProgressBar: true});
                } finally {
                  setRefresh(Boolean(button.refresh));
                }
              }}
              key={button.key}
              disabled={
                button.isDisabledForCell
                  ? button.isDisabledForCell(cell)
                  : false
              }
            >
              <Icon icon={button.icon} />
            </Button>
          ))}
      </div>
    ),
    [buttons],
  );

  const Header = useCallback(
    (cell) => (
      <div className={styles.optionButtons}>
        {buttons
          .filter((button) => button.header)
          .map((button) => (
            <Button
              variant="link"
              onClick={async () => {
                if (!cell.selectedFlatRows.length) {
                  return;
                }

                try {
                  await button.onClick(cell);
                } catch (e) {
                  toast.error(e.data, {hideProgressBar: true});
                } finally {
                  setRefresh(Boolean(button.refresh));
                }
              }}
              key={button.key}
              disabled={cell.selectedFlatRows.length === 0}
            >
              <Icon icon={button.icon} />
            </Button>
          ))}
      </div>
    ),
    [buttons],
  );

  const addButtonsColumn = useCallback(
    (hooks) => {
      if (buttons.length === 0) {
        return;
      }

      hooks.visibleColumns.push((col) => [
        ...col,
        {
          id: 'buttonsColumn',
          Header,
          Cell,
        },
      ]);
    },
    [Header, Cell, buttons],
  );

  const [state, actions] = useDataTable();

  const {
    // common
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      manualPagination: true,
      pageCount,
      // common
      columns,
      data: state.data,
      initialState: {
        pageIndex: queryParams.has('page')
          ? Number(queryParams.get('page')) - 1
          : 0,
        pageSize: queryParams.get('per_page') || defaultPageSize,
      },
    },
    usePagination,
    useRowSelect,
    addSelectionColumn(allowSelection),
    addButtonsColumn,
  );

  // Compute the `filters` query parameter.
  const filterParams = useMemo(() => {
    const filters = source.filters || [];
    if (Object.keys(state.filters).length > 0) {
      if (state.filters.submitted_on_from) {
        filters.submitted_on_from = formatDateISO(
          state.filters.submitted_on_from,
        );
      } else if (state.filters.submitted_on_to) {
        filters.submitted_on_to = formatDateISO(state.filters.submitted_on_to);
      }
      filters.push(state.filters);
    }
    if (filters.length > 0) {
      return {filters: JSON.stringify(filters)};
    }

    return {};
  }, [state.filters, source.filters]);

  // Compute the `searchParams` query parameter.
  const searchParams = useMemo(() => {
    if (!allowSearch || state.searchQuery === '') {
      return {};
    }
    return {
      search: state.searchQuery,
    };
  }, [allowSearch, state.searchQuery]);

  const orderingParams = useMemo(() => {
    if (!allowOrder || state.ordering === '') {
      return {};
    }

    return {
      ordering: state.ordering,
    };
  }, [allowOrder, state.ordering]);

  useEffect(() => {
    if (refresh && pageIndex !== 0) {
      gotoPage(0);
    }
  }, [gotoPage, refresh, pageIndex]);

  const gotoPageWrapper = (newPageIndex) => {
    setQueryParams((prevParams) => {
      if (newPageIndex === 0) {
        prevParams.delete('page');
      } else {
        prevParams.set('page', newPageIndex + 1);
      }
      return prevParams;
    });

    gotoPage(newPageIndex);
  };

  const currentPage = pageIndex + 1;

  const nextPageWrapper = () => {
    setQueryParams((prevParams) => {
      prevParams.set('page', currentPage + 1);
      return prevParams;
    });
    nextPage();
  };

  const previousPageWrapper = () => {
    setQueryParams((prevParams) => {
      if (pageIndex - 1 === 0) {
        prevParams.delete('page');
      } else {
        prevParams.set('page', currentPage - 1);
      }
      return prevParams;
    });
    previousPage();
  };

  const setPageSizeWrapper = (newPerPage) => {
    setQueryParams((prevParams) => {
      if (newPerPage === defaultPageSize) {
        prevParams.delete('per_page');
      } else {
        prevParams.set('per_page', newPerPage);
      }
      return prevParams;
    });
    setPageSize(newPerPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await api.get(source.url, {
        page: queryParams.get('page') || pageIndex + 1,
        per_page: queryParams.get('per_page') || pageSize,
        ...orderingParams,
        ...filterParams,
        ...searchParams,
      });
      if (response) {
        actions.setData(response.results);
        setTotalItems(response.count);
        setPageCount(Math.ceil(response.count / pageSize));
      }
      setIsLoading(false);
    };

    // @HINT in the next call, refresh `false` will trigger the actual fetch
    if (refresh) {
      setRefresh(false);
    } else {
      fetchData();
    }
  }, [
    api,
    actions,
    setIsLoading,
    source.url,
    filterParams,
    pageIndex,
    pageSize,
    refresh,
    searchParams,
    orderingParams,
    queryParams,
  ]);

  // @FIXME This hook will render at ProductsPage but we need data for render the component and
  // this component will only render at RemoteDataTable
  // useLayoutTopBar(data.length > 0 ? 'CTA' : null);

  // @HINT we don't suppor multiple groups
  const headerGroup = useMemo(() => headerGroups[0], [headerGroups]);
  return (
    <DataTableView
      isLoading={isLoading}
      isInitialized={isInitialized}
      allowSelection={allowSelection}
      allowSearch={allowSearch}
      searchPlaceholder={searchPlaceholder}
      allowOrder={allowOrder}
      orderChoices={orderChoices}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroup={headerGroup}
      page={page}
      prepareRow={prepareRow}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageCount={pageCount}
      gotoPage={gotoPageWrapper}
      nextPage={nextPageWrapper}
      previousPage={previousPageWrapper}
      setPageSize={setPageSizeWrapper}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalItems={totalItems}
      defaultPageSize={defaultPageSize}
      scrollToTop
    />
  );
};

export default RemoteDataTable;
