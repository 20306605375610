import React, {createContext, useContext, useMemo, useReducer} from 'react';
import {asMap} from '../utils';

const SCHEDULE_OPTIONS = [
  {label: '00:00hs', value: '00'},
  {label: '06:00hs', value: '06'},
  {label: '08:00hs', value: '08'},
  {label: '09:00hs', value: '09'},
  {label: '10:00hs', value: '10'},
  {label: '11:00hs', value: '11'},
  {label: '12:00hs', value: '12'},
  {label: '13:00hs', value: '13'},
  {label: '14:00hs', value: '14'},
  {label: '15:00hs', value: '15'},
  {label: '16:00hs', value: '16'},
  {label: '17:00hs', value: '17'},
  {label: '18:00hs', value: '18'},
  {label: '19:00hs', value: '19'},
];

const initialState = {
  settings: {},
  categories: null,
  scheduleOptions: SCHEDULE_OPTIONS,
  userCustomFields: [],
  priceLists: [],
};

const UtilsContext = createContext();

const types = {
  UTILS_SET_SETTINGS: 'UTILS_SET_SETTINGS',
  UTILS_SET_CATEGORIES: 'UTILS_SET_CATEGORIES',
  UTILS_SET_USER_CUSTOM_FIELDS: 'UTILS_USER_CUSTOM_FIELDS',
  UTILS_SET_PRICE_LISTS: 'UTILS_PRICE_LISTS',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.UTILS_SET_SETTINGS: {
      return {
        ...state,
        settings: action.payload.settings,
      };
    }

    case types.UTILS_SET_CATEGORIES: {
      return {
        ...state,
        categories: {
          tree: action.payload.tree,
          list: action.payload.list,
          map: asMap(action.payload.list, (category) => category.id),
        },
      };
    }

    case types.UTILS_SET_USER_CUSTOM_FIELDS: {
      return {
        ...state,
        userCustomFields: action.payload,
      };
    }

    case types.UTILS_SET_PRICE_LISTS: {
      return {
        ...state,
        priceLists: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UtilsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const actions = useMemo(
    () => ({
      setSettings: (settings) =>
        dispatch({
          type: types.UTILS_SET_SETTINGS,
          payload: {
            settings,
          },
        }),
      setCategories: (categories) =>
        dispatch({
          type: types.UTILS_SET_CATEGORIES,
          payload: categories,
        }),
      setUserCustomFields: (customFields) =>
        dispatch({
          type: types.UTILS_SET_USER_CUSTOM_FIELDS,
          payload: customFields,
        }),
      setPriceLists: (priceLists) =>
        dispatch({
          type: types.UTILS_SET_PRICE_LISTS,
          payload: priceLists,
        }),
    }),
    [],
  );

  const value = useMemo(() => [state, actions], [state, actions]);

  return (
    <UtilsContext.Provider value={value}>{children}</UtilsContext.Provider>
  );
};

const useUtils = () => {
  const context = useContext(UtilsContext);

  if (context === undefined) {
    throw new Error('`useUtils` must be used within a `UtilsContext`.');
  }

  return context;
};

export {UtilsContext, UtilsProvider, useUtils};
