import {useField} from 'formik';
import React, {FC, FocusEventHandler} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

type NumberFieldProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: 'lg' | 'sm';
  onFocus?: FocusEventHandler<Element>;
  symbol?: string;
  helpText?: string;
};

const NumberField: FC<NumberFieldProps> = ({
  name,
  placeholder,
  disabled,
  size,
  autoFocus,
  onFocus,
  symbol,
  helpText,
}) => {
  const [fieldProps, metaProps] = useField(name);

  return (
    <React.Fragment>
      <InputGroup hasValidation>
        {symbol ? <InputGroup.Text>{symbol}</InputGroup.Text> : null}
        <Form.Control
          type="number"
          size={size || 'lg'}
          name={fieldProps.name}
          onChange={fieldProps.onChange}
          value={fieldProps.value || ''}
          isInvalid={Boolean(metaProps.touched && metaProps.error)}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          onFocus={onFocus}
          className={disabled ? 'text-muted' : undefined}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {metaProps.error}
        </Form.Control.Feedback>
      </InputGroup>
      {helpText ? <Form.Text muted>{helpText}</Form.Text> : null}
    </React.Fragment>
  );
};

export default NumberField;
