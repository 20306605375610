import {useField} from 'formik';
import React, {FC, FocusEventHandler, KeyboardEvent} from 'react';
import {Form} from 'react-bootstrap';

type TextFieldProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: 'lg' | 'sm';
  onFocus?: FocusEventHandler<Element>;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  helpText?: string;
};

const TextField: FC<TextFieldProps> = ({
  name,
  placeholder,
  disabled,
  autoFocus,
  size,
  onFocus,
  onKeyUp,
  tabIndex,
  helpText,
}) => {
  const [fieldProps, metaProps] = useField(name);

  return (
    <React.Fragment>
      <Form.Control
        type="text"
        size={size || 'lg'}
        name={fieldProps.name}
        onChange={fieldProps.onChange}
        value={fieldProps.value || ''}
        isInvalid={Boolean(metaProps.touched && metaProps.error)}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
      />
      {helpText ? <Form.Text muted>{helpText}</Form.Text> : null}
      <Form.Control.Feedback tooltip type="invalid">
        {metaProps.error}
      </Form.Control.Feedback>
    </React.Fragment>
  );
};

export default TextField;
