import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router';
import {Row, Col, Button, OverlayTrigger, Popover, Card} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {toast} from 'react-toastify';
import ProductsFormPage from '../ProductsFormPage';
import ProductsImportPage from '../ProductsImportPage';
import ProductsCategoriesPage from '../ProductsCategoriesPage';
import CallToActionProductsAdd from '../../components/CallToAction/CallToActionProductsAdd';
import CallToActionProductsImport from '../../components/CallToAction/CallToActionProductsImport';
import {useLayoutTitle} from '../../hooks/layout';
import DataTable from '../../components/DataTable';
import {useApi} from '../../contexts/ApiContext';
import {useLayout} from '../../contexts/LayoutContext';
import {useUtils} from '../../contexts/UtilsContext';
import ProductsVariantsPage from '../ProductsVariantsPage';
import {formatMoney} from '../../utils';
import placeholder from '../../assets/placeholder-image.png';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';
import ProductImportHistoryPage from '../ProductsImportPage/ProductsImportHistoryPage';
import PriceListsPage from '../PriceListsPage/PriceListsPage';

import styles from './ProductsPage.module.scss';

const orderChoices = [
  {
    value: 'name',
    label: 'A → Z',
  },
  {
    value: '-name',
    label: 'Z → A',
  },
  {
    value: 'default_price',
    label: 'Menor precio',
  },
  {
    value: '-default_price',
    label: 'Mayor precio',
  },
];

const ImageWithTitleColumnCell = (props) => {
  const product = props.row.original;
  return (
    <div className={styles.imageTitleColumn}>
      <div>
        <img src={product.images[0] || placeholder} alt={product.name} />
      </div>
      <p>{product.name}</p>
    </div>
  );
};

const popover = (arrTitleCategories, setShow) => (
  <Popover className={styles.popover}>
    <Row className="ms-0 me-0">
      <Col className={styles.categories}>
        <Button variant="link" type="button" onClick={() => setShow(false)}>
          <Icon icon="fa-solid fa-xmark" />
        </Button>
        <h6>Categorias</h6>
        {arrTitleCategories.map((category) => (
          <p className="mb-0" key={category}>
            {category}
          </p>
        ))}
      </Col>
    </Row>
  </Popover>
);

const PopoverCategories = ({categoriesAsMap, categoryIds}) => {
  const [showCategories, setshowCategories] = useState(false);

  const arrTitleCategories =
    categoriesAsMap.size > 0
      ? categoryIds.map((categoryId) => categoriesAsMap.get(categoryId).title)
      : [];

  if (arrTitleCategories.length > 1) {
    return (
      <p className={styles.categoriesButton}>
        {arrTitleCategories[0]}
        <OverlayTrigger
          show={showCategories}
          trigger="click"
          placement="bottom"
          overlay={popover(arrTitleCategories, setshowCategories)}
        >
          <button type="button" onClick={() => setshowCategories(true)}>
            + {arrTitleCategories.length - 1}
          </button>
        </OverlayTrigger>
      </p>
    );
  }

  return <p className="mb-0">{arrTitleCategories[0] ? arrTitleCategories[0] : '-'}</p>;
};

const ProductsPageIndex = () => {
  const [utilsState] = useUtils();
  const [countProducts, setCountProducts] = useState(null);
  const api = useApi();
  const navigate = useNavigate();
  const [, actions] = useLayout();

  useEffect(() => {
    actions.setTopBar(<CallToActionButtons />);

    return () => {
      actions.setTopBar(null);
    };
  }, [actions]);

  useLayoutTitle({
    label: 'Productos',
    icon: 'fa-solid fa-cart-shopping',
  });

  useEffect(() => {
    const fetchCountProducts = async () => {
      const response = await api.get('/product-helper/count-products/');

      setCountProducts(response.count_products);
    };

    fetchCountProducts();
  }, [api]);

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-trash',
        onClick: async (cell) => {
          await Promise.all(
            cell.selectedFlatRows.map((row) => api.delete(`/product/${row.original.id}/`)),
          );

          toast.success('Se eliminaron exitosamente los productos', {
            hideProgressBar: true,
          });
        },
        refresh: true,
        key: 'delete',
        header: true,
      },
      {
        icon: 'fa-solid fa-pencil',
        onClick: (cell) => navigate(`/admin/products/edit/${cell.row.original.id}/`),
        key: 'edit',
      },
      {
        icon: 'fa-solid fa-trash',
        onClick: async (cell) => {
          await api.delete(`/product/${cell.row.original.id}/`);
          toast.success('Se eliminó exitosamente el producto', {
            hideProgressBar: true,
          });
        },
        refresh: true,
        key: 'delete',
      },
    ],
    [navigate, api],
  );

  const renderCategories = useCallback(
    (originalRow) => {
      const categoriesMap = utilsState.categories !== null ? utilsState.categories.map : new Map();

      return (
        <PopoverCategories categoriesAsMap={categoriesMap} categoryIds={originalRow.categories} />
      );
    },
    [utilsState.categories],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        Cell: ImageWithTitleColumnCell,
      },
      {
        Header: 'SKU',
        accessor: 'default_code',
      },
      {
        Header: 'Categorias',
        accessor: (originalRow) => renderCategories(originalRow),
      },
      {
        Header: 'Stock',
        accessor: (originalRow) => (originalRow.inventory ? 'Yes' : 'No'),
      },
      {
        Header: 'Precio',
        accessor: (originalRow) =>
          formatMoney(originalRow.default_price, utilsState.settings?.COMPANY_CURRENCY),
      },
    ],
    [renderCategories, utilsState],
  );

  const filterables = useMemo(
    () =>
      utilsState.categories !== null
        ? [
            {
              field: 'categories',
              label: 'Categorias',
              type: 'choices',
              choices: utilsState.categories.tree.map((category) => ({
                value: category.id,
                label: category.title,
              })),
            },
          ]
        : [],
    [utilsState.categories],
  );

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {};
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  const showTable = utilsState.categories !== null && countProducts !== null;

  if (!showTable) {
    return (
      <Row>
        <Col lg={{offset: 6, span: 3}}>
          <CallToActionProductsAdd />
        </Col>
        <Col lg={3}>
          <CallToActionProductsImport />
        </Col>
      </Row>
    );
  }

  return (
    <div className={styles.productsTable}>
      <Card>
        <Card.Body>
          <DataTable
            buttons={buttons}
            columns={columns}
            orderChoices={orderChoices}
            filterables={filterables}
            initialFilters={initialFilters}
            source={{
              type: 'remote',
              url: '/product',
            }}
            allowSelection
            allowSearch
            searchPlaceholder="Busca productos por nombre o código..."
            allowOrder
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const CallToActionButtons = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.ctaButtons}>
      <Button onClick={() => navigate('/admin/products/add')} variant="secondary">
        <Icon icon="fa-solid fa-cart-shopping" />+ Agregar producto
      </Button>
      <Button onClick={() => navigate('/admin/products/import')} variant="dark">
        <Icon icon="fa-solid fa-file-arrow-up" className="text-secondary" />+ Importar productos
      </Button>
    </div>
  );
};

const ProductsPage = () => (
  <Routes>
    <Route path="/categories" element={<ProductsCategoriesPage />} />
    <Route path="/import" element={<ProductsImportPage />} />
    <Route path="/import/history" element={<ProductImportHistoryPage />} />
    <Route path="/add" element={<ProductsFormPage />} />
    <Route path="/edit/:id" element={<ProductsFormPage />} />
    <Route path="/variants" element={<ProductsVariantsPage />} />
    <Route path="/price-list" element={<PriceListsPage />} />
    <Route index element={<ProductsPageIndex />} />
  </Routes>
);

export default ProductsPage;
