import React, {useCallback} from 'react';
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Popover,
  Button,
} from 'react-bootstrap';
import {Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {v4 as uuid4} from 'uuid';
import {useTranslation} from 'react-i18next';
import i18n from '../../translations/i18n';
import LayoutButtons from '../../layouts/utils';
import DataTree from '../../components/DataTree/DataTree';
import TableForm from '../../components/TableForm';
import TextField from '../../components/FormFields/TextField';
import {
  makeEmptyCustomField,
  makeEmptyProductPack,
  makeProductPack,
} from './utils';
import NumberField from '../../components/FormFields/NumberField';
import SwitchField from '../../components/FormFields/SwitchField';
import CheckGroupField from '../../components/FormFields/CheckGroupField';
import TextareaField from '../../components/FormFields/TextareaField';
import SearchParamsSearchBar from '../../components/SearchParams/SearchParamsSearchBar';

import styles from './ProductsFormPage.module.scss';

const MESSAGE_REQUIRED = `${i18n.t(
  'pages:ProductsFormPage.form.validations.messages.required',
)}`;

const MESSAGE_NUMBER_TYPE = `${i18n.t(
  'pages:ProductsFormPage.form.validations.messages.number.type',
)}`;

const MESSAGE_NUMBER_POSITIVE = `${i18n.t(
  'pages:ProductsFormPage.form.validations.messages.number.positive',
)}`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE_REQUIRED),
  short_description: Yup.string().required(MESSAGE_REQUIRED),
  default_price: Yup.number()
    .typeError(MESSAGE_NUMBER_TYPE)
    .required(MESSAGE_REQUIRED)
    .positive(MESSAGE_NUMBER_POSITIVE),
  offer_price: Yup.number()
    .typeError(MESSAGE_NUMBER_TYPE)
    .positive(MESSAGE_NUMBER_POSITIVE),
  barcode: Yup.number().typeError(MESSAGE_NUMBER_TYPE),
  inventory: Yup.boolean(),
  width: Yup.number()
    .positive(MESSAGE_NUMBER_POSITIVE)
    .typeError(MESSAGE_NUMBER_TYPE),
  height: Yup.number()
    .positive(MESSAGE_NUMBER_POSITIVE)
    .typeError(MESSAGE_NUMBER_TYPE),
  custom_fields: Yup.array().of(
    Yup.object().shape({
      field: Yup.string().required(MESSAGE_REQUIRED),
      value: Yup.string().required(MESSAGE_REQUIRED),
    }),
  ),
  packs: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(MESSAGE_REQUIRED),
      quantity: Yup.number().min(1).integer().required(MESSAGE_REQUIRED),
    }),
  ),
});

const customFieldsColumns = [
  {
    name: 'field',
    component: (
      <TextField
        placeholder={`${i18n.t(
          'pages:ProductsFormPage.Stepper.Steps.stepDetails.fields.customFields.placeholders.field',
        )}`}
      />
    ),
  },
  {
    name: 'value',
    component: (
      <TextField
        placeholder={`${i18n.t(
          'pages:ProductsFormPage.Stepper.Steps.stepDetails.fields.customFields.placeholders.value',
        )}`}
      />
    ),
  },
];

const packsColumns = [
  {
    name: 'name',
    component: (
      <TextField
        placeholder={`${i18n.t(
          'pages:ProductsFormPage.Stepper.Steps.stepDetails.fields.packs.placeholders.name',
        )}`}
      />
    ),
  },
  {
    name: 'quantity',
    component: (
      <NumberField
        placeholder={`${i18n.t(
          'pages:ProductsFormPage.Stepper.Steps.stepDetails.fields.packs.placeholders.quantity',
        )}`}
      />
    ),
  },
];

const VariantOptions = ({options}) => {
  const {t} = useTranslation('pages');
  if (options.length === 0) {
    return `${t(
      'ProductsFormPage.Stepper.Steps.stepDetails.fields.variants.options.message.noOptions',
    )}`;
  }

  return options.map((option) => option.name).join(', ');
};

const VariantLabel = ({variant}) => (
  <OverlayTrigger
    placement="right"
    overlay={
      <Tooltip>
        <VariantOptions options={variant.options} />
      </Tooltip>
    }
  >
    <span className="d-flex align-items-center">
      {variant.name} (<code>{variant.code}</code>)
    </span>
  </OverlayTrigger>
);

const VariantsPills = ({selectedVariants}) => {
  const {setFieldValue} = useFormikContext();

  const deleteSelectedVariant = useCallback(
    (variant) => {
      const newValue = selectedVariants.filter(
        (selectedVariant) => variant.id !== selectedVariant.id,
      );
      setFieldValue('variants', newValue);
    },
    [setFieldValue, selectedVariants],
  );

  if (selectedVariants.length === 0) {
    return null;
  }

  return (
    <div className={styles.pills}>
      {selectedVariants.map((variant) => (
        <div key={variant.id}>
          <button type="button" onClick={() => deleteSelectedVariant(variant)}>
            <Icon icon="fa fa-xmark" />
          </button>
          <VariantLabel variant={variant} />
        </div>
      ))}
    </div>
  );
};

const VariantSelection = ({variants, countVariants}) => {
  const {t} = useTranslation('pages');
  const {values, setFieldValue} = useFormikContext();
  const maxSelectedVariants = 2;
  const selectedVariants = values.variants;

  // Determines whether a checkbox for a variant is disabled or not.
  const isVariantCheckboxDisabled = useCallback(
    (variantId, options) => {
      if (
        selectedVariants.find(
          (selectedVariant) => selectedVariant.id === variantId,
        )
      ) {
        // Variants that are already selected can be unchecked.
        return false;
      }

      if (options.length === 0) {
        // Variants with no options can't be selected.
        return true;
      }

      if (selectedVariants.length >= maxSelectedVariants) {
        // Can't select more than `maxSelectedVariants` variants.
        return true;
      }

      return false;
    },
    [selectedVariants],
  );

  const handleSelectVariant = useCallback(
    (variant) => {
      if (
        selectedVariants.find(
          (selectedVariant) => selectedVariant.id === variant.id,
        )
      ) {
        const newValue = selectedVariants.filter(
          (selectedVariant) => variant.id !== selectedVariant.id,
        );
        setFieldValue('variants', newValue);
      } else {
        setFieldValue('variants', [...selectedVariants, variant]);
      }
    },
    [setFieldValue, selectedVariants],
  );

  const filteredVariants = variants.filter(
    (variant) =>
      !selectedVariants.find(
        (selectedVariant) => selectedVariant.id === variant.id,
      ),
  );

  return (
    <Form.Group className="mb-2 position-relative">
      <Form.Label>
        {t('ProductsFormPage.Stepper.Steps.stepDetails.fields.variants.label')}
      </Form.Label>
      <VariantsPills selectedVariants={selectedVariants} />
      <OverlayTrigger
        trigger="click"
        placement="top-end"
        rootClose
        overlay={
          <Popover className={styles.variantsSelectionPopover}>
            {/* We implement the search bar component here, but we were still using a 'search' query param.
            it's necessary change the 'SearchParamsSearchBar' for recieve different names for query params to
            avoid the possibility, if we implement others 'SearchParamsSearchBar' here, they use the same query param. */}
            <VariantsPills selectedVariants={selectedVariants} />
            <SearchParamsSearchBar />
            <ul>
              {filteredVariants.map((variant) => (
                <CheckGroupField
                  key={variant.id}
                  name="variants"
                  label={<VariantLabel variant={variant} />}
                  onChange={() => handleSelectVariant(variant)}
                  value={variant.id}
                  disabled={isVariantCheckboxDisabled(
                    variant.id,
                    variant.options,
                  )}
                />
              ))}
            </ul>
            {countVariants && (
              <div>
                {t(
                  'ProductsFormPage.Stepper.Steps.stepDetails.fields.variants.quantity',
                  {
                    count: countVariants,
                  },
                )}
              </div>
            )}
          </Popover>
        }
      >
        <Button variant="primary" size="sm" className="d-block">
          {selectedVariants.length === maxSelectedVariants ? (
            <Icon icon="fa fa-pencil" />
          ) : (
            <Icon icon="fa fa-plus" />
          )}
        </Button>
      </OverlayTrigger>
    </Form.Group>
  );
};

const DetailsForm = ({formikProps, allCategories, variants, countVariants}) => {
  const {t} = useTranslation('pages');
  const {setFieldValue, values} = formikProps;

  const handleSelect = useCallback(
    (nodeId) => {
      const newSelected = new Set(values.categories);
      if (values.categories.has(nodeId)) {
        newSelected.delete(nodeId);
      } else {
        newSelected.add(nodeId);
        // Select all the ancestors down to the root:
        let currentParent = allCategories.asMap.get(nodeId).parent;
        while (currentParent != null) {
          newSelected.add(currentParent);
          currentParent = allCategories.asMap.get(currentParent).parent;
        }
      }

      setFieldValue('categories', newSelected, false);
    },
    [setFieldValue, values.categories, allCategories.asMap],
  );

  return (
    <Form noValidate onSubmit={formikProps.handleSubmit}>
      <Row className={styles.stepDetails}>
        <Col xs={12} className={styles.stepTitle}>
          <h1>{t('ProductsFormPage.Stepper.Steps.stepDetails.title')}</h1>
          <p>{t('ProductsFormPage.Stepper.Steps.stepDetails.description')}</p>
        </Col>
        <Col>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.name.label',
              )}
              <span>*</span>
            </Form.Label>
            <TextField name="name" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t('ProductsFormPage.Stepper.Steps.stepDetails.fields.sku.label')}
            </Form.Label>
            <TextField name="default_code" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.shortDescription.label',
              )}
              <span>*</span>
            </Form.Label>
            <TextField name="short_description" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.barcode.label',
              )}
            </Form.Label>
            <NumberField name="barcode" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.brand.label',
              )}
            </Form.Label>
            <TextField name="brand" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.width.label',
              )}
            </Form.Label>
            <NumberField name="width" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.height.label',
              )}
            </Form.Label>
            <NumberField name="height" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.categories.label',
              )}
            </Form.Label>
            <DataTree
              data={allCategories.asTree}
              selectedData={values.categories}
              handleSelect={handleSelect}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.price.label',
              )}
              <span>*</span>
            </Form.Label>
            <NumberField name="default_price" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.offerPrice.label',
              )}
            </Form.Label>
            <NumberField name="offer_price" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative stock">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.stock.label',
              )}
            </Form.Label>
            <SwitchField
              name="inventory"
              label={t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.stock.field.label',
              )}
            />
          </Form.Group>
          <Form.Group className="mb-2 position-relative stock">
            <Form.Label>
              {t('ProductsFormPage.Stepper.Steps.stepDetails.fields.new.label')}
            </Form.Label>
            <SwitchField name="new" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative description">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.description.label',
              )}
            </Form.Label>
            <TextareaField name="description" />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.customFields.label',
              )}
            </Form.Label>
            <TableForm
              name="custom_fields"
              columns={customFieldsColumns}
              makeElement={makeEmptyCustomField}
              elementKey={(elem) => elem.uuid}
              maxElements={8}
            />
          </Form.Group>
          <Form.Group className="mb-2 position-relative">
            <Form.Label>
              {t(
                'ProductsFormPage.Stepper.Steps.stepDetails.fields.packs.label',
              )}
            </Form.Label>
            <TableForm
              name="packs"
              columns={packsColumns}
              makeElement={makeEmptyProductPack}
              elementKey={(elem) => elem.uuid}
              minElements={1}
            />
          </Form.Group>
          <VariantSelection variants={variants} countVariants={countVariants} />
        </Col>
      </Row>
      <LayoutButtons handleNext={formikProps.submitForm} />
    </Form>
  );
};

const StepDetails = (props) => {
  const {product, variants, countVariants} = props.data;

  const {allCategories} = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: product.name || '',
        short_description: product.short_description || '',
        default_code: product.default_code || '',
        default_price: product.default_price || '',
        offer_price: product.offer_price || '',
        description: product.description || '',
        barcode: product.barcode || '',
        brand: product.brand || '',
        inventory: product.inventory,
        width: product.width || '',
        height: product.height || '',
        custom_fields: product.custom_fields || [],
        categories: new Set(product.categories || []),
        packs:
          product.packs.length === 0
            ? [makeProductPack({name: 'Unit', quantity: 1})]
            : product.packs,
        variants: product.variants || [],
        new: product.tags.includes('new'),
      }}
      onSubmit={(values) => {
        props.setData((prevState) => {
          const prevProduct = prevState.product;
          const prevTags = prevState.product.tags;
          const newTags = values.new
            ? [...prevTags, 'new']
            : prevTags.filter((tag) => tag !== 'new');
          const newProduct = {...prevState.product, ...values, tags: newTags};

          // TODO: make a better comparison to check whether packs changed or not.
          const packsChanged =
            JSON.stringify(prevProduct.packs) !==
            JSON.stringify(newProduct.packs);

          // TODO: make a better comparison to check whether variants changed or not.
          const variantsChanged =
            JSON.stringify(prevProduct.variants.sort()) !==
            JSON.stringify(newProduct.variants.sort());

          // We set a default SKU by using the last characters of a uuid4 if it's
          // not explicitly set by the user.
          if (!values.default_code.trim()) {
            newProduct.default_code = uuid4().slice(-8);
          }

          if (!values.offer_price) {
            newProduct.offer_price = null;
          }

          return {
            ...prevState,
            product: newProduct,
            shouldResetCombinations:
              prevState.shouldResetCombinations ||
              packsChanged ||
              variantsChanged,
            shouldDisableCombinationsInventory: !newProduct.inventory,
            shouldResetCombinationsInventory:
              !prevState.inventory && newProduct.inventory,
          };
        });
        props.actions.handleNext();
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <DetailsForm
          allCategories={allCategories}
          formikProps={formikProps}
          variants={variants}
          countVariants={countVariants}
        />
      )}
    </Formik>
  );
};

export default StepDetails;
