import React, {useCallback, useMemo, useRef} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useShopProductsContainer} from './Container';
import SearchParamsSearchBar from '../SearchParams/SearchParamsSearchBar';
import {useEcommerce} from '../../contexts/EcommerceContext';
import SearchParamsCategorySelector from '../SearchParams/SearchParamsCategorySelector';
import {ShopProduct} from '../../types/ShopProduct';
import {useApi} from '../../contexts/ApiContext';
import Paginator from './Paginator';
import SortBy from './SortBy';
import ShopGrid from './ShopGrid';
import CountDisplay from './CountDisplay';
import ViewSwitch from './ViewSwitch';
import ShopTable from './ShopTable';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import MobileFilter from './MobileFilter';
import VIEW_MODE_OPTIONS from './constants';
import SelectedCategoryTitle from './SelectedCategoryTitle';
import SelectedCategoryBreadcrumb from './SelectedCategoryBreadcrum';

interface ShopGridTableProps {
  refreshAfterFavorite: boolean;
  scrollToTop: boolean;
  sessionViewMode: string;
  setSessionViewMode: (value: string) => void;
}
const ShopGridTable = ({
  refreshAfterFavorite = false,
  scrollToTop = false,
  sessionViewMode,
  setSessionViewMode,
}: ShopGridTableProps) => {
  const api = useApi();
  const {mutateProduct, retrieveProducts} = useShopProductsContainer();
  const [{uid, categories, settings}] = useEcommerce();
  const {isMobile} = useDeviceWindow();

  const scrollElement = useRef(null);

  const toggleFavorite = useCallback(
    async (product: ShopProduct) => {
      if (product.favorite) {
        await api.delete(`/blink/${uid}/favorites/${product.id}`);
      } else {
        await api.post(`/blink/${uid}/favorites/${product.id}`);
      }
      mutateProduct(product.id, (prevProduct) => ({
        ...prevProduct,
        favorite: !prevProduct.favorite,
      }));
      if (refreshAfterFavorite) {
        retrieveProducts();
      }
    },
    [api, uid, mutateProduct, refreshAfterFavorite, retrieveProducts],
  );

  const hasCategories = useMemo<Boolean>(
    () => Number(categories?.tree.length) > 0,
    [categories],
  );

  const currentViewMode =
    sessionViewMode ||
    settings.COMPANY_DEFAULT_STORE_VIEW ||
    VIEW_MODE_OPTIONS.grid;

  return (
    <Row className="g-2">
      <SelectedCategoryBreadcrumb />
      <SelectedCategoryTitle />
      {!isMobile && hasCategories ? (
        <Col xs={2}>
          <SearchParamsCategorySelector />
        </Col>
      ) : null}
      <Col md={12} lg={hasCategories ? 10 : 12}>
        <Row className="mb-2 g-2">
          <Col xs={6} ref={scrollElement}>
            <SearchParamsSearchBar />
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <ViewSwitch
              viewMode={currentViewMode}
              setViewMode={setSessionViewMode}
            />
          </Col>
          {isMobile ? (
            <Col
              xs="auto"
              className="d-flex align-items-center justify-content-center"
            >
              <MobileFilter />
            </Col>
          ) : (
            <React.Fragment>
              <Col className="d-flex align-items-center justify-content-center">
                <CountDisplay />
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <SortBy />
              </Col>
            </React.Fragment>
          )}
        </Row>
        {currentViewMode === VIEW_MODE_OPTIONS.grid ? (
          <ShopGrid toggleFavorite={toggleFavorite} />
        ) : (
          <ShopTable toggleFavorite={toggleFavorite} />
        )}
      </Col>
      <Paginator
        perPage={72}
        scrollElement={scrollToTop ? scrollElement : null}
      />
    </Row>
  );
};

export default ShopGridTable;
