import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSession} from '../../contexts/SessionContext';
import {useEcommerce} from '../../contexts/EcommerceContext';

const StoreImpersonateUserNotice = () => {
  const [sessionState] = useSession();
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');

  const IMPERSONATE = `bl:v2:${state.uid}:impersonate`;
  const isImpersonate = window.localStorage.getItem(IMPERSONATE);

  if (!isImpersonate) {
    return null;
  }

  return (
    <div className="w-100 text-center p-1 bg-primary">
      <p className="mb-0">
        {t('StorePage.StoreImpersonateUserNotice.message')}
        <strong>
          {sessionState.data.user.username}
          {sessionState.data.user.info.company_name
            ? ` (${sessionState.data.user.info.company_name})`
            : null}
        </strong>
      </p>
    </div>
  );
};

export default StoreImpersonateUserNotice;
