import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useShopProductsContainer} from './Container';
import placeholder from '../../assets/placeholder-image.png';
import {useEcommerce} from '../../contexts/EcommerceContext';
import NewProductSVG from '../../assets/NewProductSVG';
import ProductPrice from './ProductPrice';
import {ShopProduct} from '../../types/ShopProduct';
import {customerAllowedToSeePrices} from '../../utils';
import {useSession} from '../../contexts/SessionContext';

import styles from './ShopProduct.module.scss';

interface ShopGridCellProps {
  product: ShopProduct;
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const ShopGridCell = ({product, toggleFavorite}: ShopGridCellProps) => {
  const [state] = useEcommerce();
  const [, , selectors] = useSession();
  const navigate = useNavigate();

  const currency = state.settings?.COMPANY_CURRENCY;
  const taxesEnabled = state.settings?.COMPANY_PRICE_WITH_TAXES;
  const hidePrices = !customerAllowedToSeePrices(state.settings, selectors);

  return (
    <Col xs={6} md={6} lg={3}>
      <Card className={styles.grid} onClick={() => navigate(`/product/${product.id}`)}>
        <Card.Header className={styles.gridHeader}>
          <div className={styles.headerButtons}>
            <button
              type="button"
              className={`${styles.favoriteButton} ${product.favorite ? styles.active : undefined}`}
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite(product);
              }}
            >
              <Icon icon="heart" />
            </button>
            {product.tags?.some((tag) => tag.name === 'new') && (
              <NewProductSVG className={styles.newIconTable} />
            )}
          </div>
          <Card.Img
            variant="top"
            className={styles.productImage}
            src={product.images[0] || placeholder}
          />
        </Card.Header>
        <Card.Body className={styles.gridBody}>
          <React.Fragment>
            <p className={`${styles.gridProductTitle} fw-bold`}>{product.name}</p>
            <p className={`${styles.highlightCell} text-muted fw-bold`}>
              {product.custom_fields['2bk_show'] ? (
                product.custom_fields['2bk_show']
              ) : (
                <React.Fragment>&nbsp;</React.Fragment>
              )}
            </p>
          </React.Fragment>
          <Card.Text>{product.ref}</Card.Text>
          <ProductPrice
            product={product}
            currency={currency}
            taxesEnabled={taxesEnabled}
            hidePrices={hidePrices}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

interface ShopGridProps {
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const ShopGrid = ({toggleFavorite}: ShopGridProps) => {
  const {products} = useShopProductsContainer();

  return (
    <Row className="gx-2 gy-1">
      {products.map((product) => (
        <ShopGridCell key={product.id} product={product} toggleFavorite={toggleFavorite} />
      ))}
    </Row>
  );
};

export default ShopGrid;
