import React from 'react';
import SearchParamsSortBy, {
  SortChoice,
} from '../SearchParams/SearchParamsSortBy';

const sortChoices: SortChoice[] = [
  {
    value: 'name',
    label: 'A → Z',
  },
  {
    value: '-name',
    label: 'Z → A',
  },
  {
    value: 'default_price',
    label: 'Menor precio',
  },
  {
    value: '-default_price',
    label: 'Mayor precio',
  },
  {
    value: 'min_path',
    label: 'Categoría',
  },
  {
    value: '-min_path',
    label: 'Última categoría',
  },
];

const SortBy = () => <SearchParamsSortBy choices={sortChoices} />;

export default SortBy;
