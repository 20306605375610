import React, {useMemo, useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useWebSocket} from '../../contexts/WebSocketContext';
import DataTable from '../../components/DataTable';
import {FILTERABLE_TYPES} from '../../components/DataTable/utils';
import ReactiveButton from '../../components/ReactiveButton/ReactiveButton';
import {useLayoutTitle} from '../../hooks/layout';
import {PRODUCT_IMPORT_STATUSES} from '../../resources';
import {copyToClipboard, formatDateDay, formatDateTime} from '../../utils';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';

const CellStatus = ({value, row}) => {
  const websocket = useWebSocket();

  const [progress, setProgress] = useState('');

  // @TODO extract into a hook
  // useChannel('<channel>', callback)
  // usage:
  //    useWebsocketChannel('product_import_progress', (notification) => {})
  useEffect(() => {
    const listener = websocket.addEventListener('message', (event) => {
      const notification = JSON.parse(event.data)?.notification;

      if (!notification) {
        return;
      }

      if (notification.type === 'product_import_progress') {
        if (row.original.id === notification.product_import_id) {
          setProgress(`(${notification.progress}%)`);
        }
      }
    });

    return () => websocket.removeEventListener('message', listener);
  }, [websocket, row]);

  const label =
    {
      IMPORTING: 'En progreso',
      IMPORTED: 'Finalizado',
      IMPORT_ERROR: 'Fallida',
    }[value] || value;

  return (
    <div className="text-center">
      {label} {progress}
    </div>
  );
};

const columns = [
  {
    id: 'created_on_date',
    Header: 'Día de creación',
    accessor: 'created_on',
    Cell: ({value}) => <span className="fw-bold">{formatDateDay(value)}</span>,
  },
  {
    id: 'created_on_hour',
    Header: <div className="text-center">Hora de creación</div>,
    accessor: 'created_on',
    Cell: ({value}) => (
      <div className="fw-bold text-center">
        {value ? formatDateDay(value) : '-'}
      </div>
    ),
  },
  {
    id: 'started_on_hour',
    Header: <div className="text-center">Hora de inicio</div>,
    accessor: 'started_on',
    Cell: ({value}) => (
      <div className="fw-bold text-center">
        {value ? formatDateTime(value) : '-'}
      </div>
    ),
  },
  {
    id: 'finished_on_hour',
    Header: <div className="text-center">Hora de finalización</div>,
    accessor: 'finished_on',
    Cell: ({value}) => (
      <div className="fw-bold text-center">
        {value ? formatDateTime(value) : '-'}
      </div>
    ),
  },
  {
    Header: <div className="text-center">Tipo de importación</div>,
    accessor: 'is_manual_import',
    Cell: ({value, row}) => (
      <div className="fw-bold text-center">
        {`${value ? 'Manual' : 'Automático'} - ${
          row.original.import_type === 'update' ? 'Update' : 'Replace'
        }`}
      </div>
    ),
  },
  {
    Header: <div className="text-center">Estado</div>,
    accessor: 'status',
    Cell: ({value, row}) => <CellStatus value={value} row={row} />,
  },
  {
    Header: <div className="text-center">Identificador</div>,
    accessor: 'file_hash',
    Cell: ({value}) => (
      <div className="d-flex justify-content-center">
        <ReactiveButton
          notificationText="¡Identificador copiado!"
          defaultIcon="fa-clipboard"
          secondaryIcon="fa-clipboard-check"
          handleClick={() => copyToClipboard(value)}
        />
      </div>
    ),
  },
];

const filterables = [
  {
    field: 'status',
    label: 'Estado',
    type: FILTERABLE_TYPES.CHOICES,
    choices: PRODUCT_IMPORT_STATUSES.map(([value, label]) => ({
      value,
      label,
    })),
  },
];

const orderChoices = [
  {
    value: 'created_on',
    label: 'Más antiguas',
  },
  {
    value: '-created_on',
    label: 'Más recientes',
  },
];

const ProductImportHistoryPage = () => {
  useLayoutTitle({
    label: 'Historial de importaciones',
    icon: 'fa-solid fa-history',
  });

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {status: ['IMPORTED', 'IMPORTING', 'IMPORT_ERROR']};
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  return (
    <div>
      <Card className="mt-3">
        <Card.Body>
          <DataTable
            columns={columns}
            source={{
              type: 'remote',
              url: `/product-import/`,
            }}
            allowOrder
            allowSearch
            searchPlaceholder="Busca importaciones por identificador..."
            orderChoices={orderChoices}
            filterables={filterables}
            initialFilters={initialFilters}
            allowSelection={false}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductImportHistoryPage;
