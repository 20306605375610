import React from 'react';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';

const CheckField = ({name, label, value, disabled, type}) => {
  const [fieldProps] = useField(name);

  return (
    <Form.Check
      type={type || 'checkbox'}
      name={fieldProps.name}
      onChange={fieldProps.onChange}
      label={label}
      value={value}
      disabled={disabled}
      checked={fieldProps.value.includes(String(value))}
    />
  );
};

export default CheckField;
