import React, {useState, useEffect, useMemo, useReducer} from 'react';
import {useSearchParams} from 'react-router-dom';
import RemoteDataTable from './RemoteDataTable';
import LocalDataTable from './LocalDataTable';
import {reducer, types, DataTableContext} from './store';

const DataTable = ({
  title = null,
  source,
  filterables,
  initialFilters,
  defaultPageSize = 25,
  columns,
  buttons = [],
  orderChoices = [],
  allowSelection = false,
  allowSearch = false,
  searchPlaceholder,
  allowOrder = false,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();

  const [state, dispatch] = useReducer(reducer, {
    title,
    data: source.data || [],
    filters: initialFilters || {},
    filterables: filterables || [],
    searchQuery: queryParams.get('search') || '',
    ordering: queryParams.get('ordering') || '',
  });

  const actions = useMemo(
    () => ({
      setFilters: (filters) => {
        setQueryParams((prevParams) => {
          if (Object.entries(filters).length === 0) {
            prevParams.delete('filters');
          } else {
            prevParams.set(
              'filters',
              `${Object.entries(filters).reduce(
                (accum, [label, value]) =>
                  accum === ''
                    ? `${label}=${value}`
                    : `${label}=${value}&${accum}`,
                '',
              )}`,
            );
          }
          return prevParams;
        });
        dispatch({
          type: types.DATA_TABLE_SET_FILTERS,
          payload: {
            filters: filters || {},
          },
        });
      },
      setData: (data) => {
        dispatch({
          type: types.DATA_TABLE_SET_DATA,
          payload: {
            data: data || [],
          },
        });
      },
      setSearchQuery: (newSearchQuery) => {
        setQueryParams((prevParams) => {
          if (!newSearchQuery) {
            prevParams.delete('search');
          } else {
            prevParams.set('search', newSearchQuery);
          }
          return prevParams;
        });
        dispatch({
          type: types.DATA_TABLE_SET_SEARCH,
          payload: {
            searchQuery: newSearchQuery || '',
          },
        });
      },
      setOrdering: (newOrder) => {
        setQueryParams((prevParams) => {
          if (!newOrder) {
            prevParams.delete('ordering');
          } else {
            prevParams.set('ordering', newOrder);
          }
          return prevParams;
        });
        dispatch({
          type: types.DATA_TABLE_SET_ORDER,
          payload: {
            ordering: newOrder || '',
          },
        });
      },
    }),
    [dispatch, setQueryParams],
  );

  useEffect(() => {
    actions.setData(source.data);
  }, [actions, source.data]);

  const value = useMemo(() => [state, actions], [state, actions]);

  const Component = {
    remote: RemoteDataTable,
    local: LocalDataTable,
  }[source.type];

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, isInitialized]);

  if (children && state.data.length === 0 && isInitialized) {
    return children;
  }

  return (
    <DataTableContext.Provider value={value}>
      <Component
        source={source}
        isInitialized={isInitialized}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultPageSize={defaultPageSize}
        columns={columns}
        buttons={buttons}
        orderChoices={orderChoices}
        allowSelection={allowSelection}
        allowSearch={allowSearch}
        allowOrder={allowOrder}
        searchPlaceholder={searchPlaceholder}
      >
        {children}
      </Component>
    </DataTableContext.Provider>
  );
};

export default DataTable;
