import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Button, Card} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {formatMoney} from '../../utils';

import styles from './StoreCheckoutPage.module.scss';

type StoreCartReviewProps = {
  cartReviewItems: CartReviewItem[];
  fetchCartReview: () => Promise<void>;
};

const StoreCartReviewView = ({
  cartReviewItems,
  fetchCartReview,
}: StoreCartReviewProps) => {
  const api = useApi();
  const [state, actions] = useEcommerce();
  const {t} = useTranslation('pages');
  const {uid} = state;

  const removeCartReviewItem = async (id: string) => {
    const response = await api.post(`/blink/${uid}/review/${id}`, {
      action: 'remove',
    });

    actions.setCart(response);
    fetchCartReview();
  };

  const keepCartReviewItem = async (id: string) => {
    const response = await api.post(`/blink/${uid}/review/${id}`, {
      action: 'keep',
    });

    actions.setCart(response);
    fetchCartReview();
  };

  const renderDiff = (
    reason: string,
    [before, after]: string[] | boolean[],
  ) => {
    const currency = state.settings.COMPANY_CURRENCY;
    if (reason.includes('price')) {
      return `${formatMoney(before, currency)} → ${formatMoney(
        after,
        currency,
      )}`;
    }

    return t('StoreCartReview.cartReviewItem.diff.stock');
  };

  return (
    <Card className="mt-2 mx-2">
      <Card.Body>
        <Card.Title className="mb-2">
          <h1>{t('StoreCartReview.title')}</h1>
        </Card.Title>
        {cartReviewItems.map(({id, name, reason, diff}) => (
          <div key={id} className={styles.cartReviewItem}>
            <div>
              <p>{name}</p>
              <p className="mb-0">
                <strong>{renderDiff(reason, diff)}</strong>
              </p>
            </div>
            <div>
              <Button
                variant="secondary"
                title={`${t('StoreCartReview.cartReviewItem.buttons.remove')}`}
                onClick={() => removeCartReviewItem(id)}
              >
                <Icon icon={['fas', 'trash']} />
              </Button>
              {!reason.includes('stock') ? (
                <Button
                  variant="secondary"
                  title={`${t('StoreCartReview.cartReviewItem.buttons.keep')}`}
                  className="ms-1"
                  onClick={() => keepCartReviewItem(id)}
                >
                  <Icon icon={['fas', 'check']} />
                </Button>
              ) : null}
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default StoreCartReviewView;
