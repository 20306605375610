import React from 'react';
import {Container} from 'react-bootstrap';
import styles from './SectionMain.module.scss';

const SectionMain = ({children}) => (
  <Container fluid className={styles.container}>
    {children}
  </Container>
);

export default SectionMain;
