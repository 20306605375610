import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {Card} from 'react-bootstrap';
import {useSession} from '../../contexts/SessionContext';
import seatedMan from './seated-man.svg';
import styles from './CallToAction.module.scss';

const CallToActionBlinkCreate = () => {
  const [sessionState] = useSession();

  return (
    <LinkContainer to="/admin/blinks/add">
      <Card bg="primary" text="light" className={styles.card}>
        <Card.Body className="position-relative">
          <div>
            <Card.Title className="fw-light text-capitalize">{`Hola, ${sessionState.data.user.username}!`}</Card.Title>
            <Card.Text className="fs-3">+ Crear un nuevo blink</Card.Text>
          </div>
          <div className={styles.seatedMan}>
            <img src={seatedMan} alt="seated-man" />
          </div>
        </Card.Body>
      </Card>
    </LinkContainer>
  );
};

export default CallToActionBlinkCreate;
