import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import LayoutButtons from '../../layouts/utils';
import ReactiveButton from '../../components/ReactiveButton/ReactiveButton';
import {copyToClipboard} from '../../utils';

import styles from './BlinksFormPage.module.scss';

const StepFinal = ({actions, data}) => {
  const {t} = useTranslation('pages');
  const url = window.location;
  const BLINK_LINK = `${url.protocol}//${url.hostname}/blink/${data.uid}`;

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.containerTop}>
          <Container fluid>
            <Row>
              <Col xl={8}>
                <h3>{t('BlinksFormPage.StepFinal.title')}</h3>
              </Col>
              <Col xl={4}>
                <h4>
                  <Trans
                    i18nKey="BlinksFormPage.StepFinal.products_count"
                    values={{count: data.countProducts}}
                  />
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className={styles.linkContainer}>
          <Row>
            <Col>
              <p>{BLINK_LINK}</p>
              <ReactiveButton
                notificationText={t(
                  'BlinksFormPage.StepFinal.buttons.copy.success_message',
                )}
                defaultIcon="fa-clipboard"
                secondaryIcon="fa-clipboard-check"
                handleClick={() => copyToClipboard(BLINK_LINK)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <LayoutButtons
        handleBack={actions.handleBack}
        handleNext={actions.handleNext}
      />
    </React.Fragment>
  );
};

export default StepFinal;
