import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';

interface DeviceWindow {
  width: number;
  height: number;
  isMobile: boolean;
}

const MAX_MOBILE_WIDTH = 991;

function getDeviceWindow(): DeviceWindow {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= MAX_MOBILE_WIDTH,
  };
}

const DeviceWindowContext = createContext<DeviceWindow | undefined>(undefined);

const DeviceWindowProvider = ({children}: PropsWithChildren) => {
  const [deviceWindow, setDeviceWindow] = useState(getDeviceWindow());

  useEffect(() => {
    const onWindowResize = () => {
      setDeviceWindow(getDeviceWindow());
    };

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return (
    <DeviceWindowContext.Provider value={deviceWindow}>
      {children}
    </DeviceWindowContext.Provider>
  );
};

const useDeviceWindow = (): DeviceWindow => {
  const context = useContext(DeviceWindowContext);
  if (context === undefined) {
    throw new Error(
      '`useDeviceWindow` must be used within a `DeviceWindowProvider` component',
    );
  }
  return context;
};

export {DeviceWindowProvider, useDeviceWindow};
