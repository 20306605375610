import React, {FC} from 'react';
import {Carousel as BaseCarousel} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import styles from './BannerCarousel.module.scss';

export interface BannerImage {
  url: string;
  link_to: string;
}

const BannerCarousel: FC<{content: BannerImage[]; maxHeight: number}> = ({
  content,
  maxHeight,
}) => (
  <BaseCarousel
    nextIcon={<Icon icon="chevron-right" />}
    prevIcon={<Icon icon="chevron-left" />}
    fade
    indicators={false}
    className={`px-0 ${styles.banner}`}
  >
    {content?.map((banner, index) => (
      <BaseCarousel.Item key={`${banner.url + index}`} style={{maxHeight}}>
        <a href={banner.link_to ? banner.link_to : undefined}>
          <img alt={`banner-${index}`} src={banner.url} width="100%" />
        </a>
      </BaseCarousel.Item>
    ))}
  </BaseCarousel>
);

export default BannerCarousel;
