import React from 'react';
import {Navigate, Outlet} from 'react-router';

type ProtectedRoutesProps = {
  isAllowed: boolean;
  to: string;
};

const ProtectedRoutes = ({isAllowed, to}: ProtectedRoutesProps) => {
  if (!isAllowed) {
    return <Navigate to={to} />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
