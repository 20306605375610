import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import LayoutButtons from '../../layouts/utils';
import {useApi} from '../../contexts/ApiContext';
import TextField from '../../components/FormFields/TextField';
import SelectField from '../../components/FormFields/SelectField';

const StepDetails = (props) => {
  const {t} = useTranslation('pages');
  const api = useApi();

  const handleSubmit = async (values) => {
    try {
      let results;
      if (props.data.id) {
        results = await api.put(`/blink/${props.data.id}`, {
          title: values.title,
          filters: props.data.filters,
          status: values.status,
        });
      } else {
        results = await api.post('/blink', {
          title: values.title,
          filters: props.data.filters,
          status: values.status,
        });
      }
      props.setData((prevState) => ({...prevState, ...results}));
      props.actions.handleNext();
    } catch (e) {
      toast.error(e.data, {hideProgressBar: true});
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(
        256,
        t('BlinksFormPage.StepDetails.Form.Messages.error.max_length', {
          max: 256,
        }),
      )
      .notRequired(),
    status: Yup.string().required(
      t('BlinksFormPage.StepDetails.Form.Messages.error.required'),
    ),
  });

  const STATUS_OPTIONS = [
    ['draft', t('BlinksFormPage.StepDetails.Form.Select.options.draft')],
    [
      'published',
      t('BlinksFormPage.StepDetails.Form.Select.options.published'),
    ],
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: props.data.title || '',
        status: props.data.status || 'draft',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(form) => (
        <Form noValidate onSubmit={form.handleSubmit}>
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-2 position-relative">
                <Form.Label>
                  {t('BlinksFormPage.StepDetails.Form.labels.title')}
                </Form.Label>
                <TextField name="title" />
              </Form.Group>
              <Form.Group className="mb-2 position-relative">
                <Form.Label>
                  {t('BlinksFormPage.StepDetails.Form.labels.status')}
                </Form.Label>
                <SelectField name="status" options={STATUS_OPTIONS} />
              </Form.Group>
            </Col>
          </Row>
          <LayoutButtons
            handleBack={props.actions.handleBack}
            handleNext={form.submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StepDetails;
