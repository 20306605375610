import React, {useState, useMemo} from 'react';
import {Card, Tab, Nav, Badge} from 'react-bootstrap';

import styles from './Stepper.module.scss';

const Step = ({children, step, activeStep, actions, data, setData}) => (
  <Tab.Pane eventKey={`event-pane-${step}`}>
    <Card.Body>
      {step === activeStep
        ? React.Children.map(children, (child) =>
            React.cloneElement(child, {
              actions,
              data,
              setData,
            }),
          )
        : null}
    </Card.Body>
  </Tab.Pane>
);

const Stepper = ({children, data, setData, handleSubmit}) => {
  const [activeStep, setActiveStep] = useState(0);

  const STEPS_COUNT = useMemo(() => children.length || 1, [children]);

  const actions = useMemo(
    () => ({
      handleBack: () => {
        setActiveStep((prevState) => (prevState > 1 ? prevState - 1 : 0));
      },
      handleNext: (extraData) => {
        if (activeStep === STEPS_COUNT - 1) {
          handleSubmit({...data, ...extraData});
        } else {
          setActiveStep((prevState) =>
            prevState < STEPS_COUNT - 1 ? prevState + 1 : prevState,
          );
        }
      },
    }),
    [STEPS_COUNT, activeStep, setActiveStep, handleSubmit, data],
  );

  const steps = useMemo(
    () =>
      React.Children.map(children, (child, index) => ({
        step: index,
        label: child.props.label,
        bg: child.props.bg,
      })),
    [children],
  );

  return (
    <Tab.Container activeKey={`event-pane-${activeStep}`}>
      <Nav className={styles.tabContainer}>
        {steps.map(({step, label}) => {
          let bg;
          let text;

          if (step === activeStep) {
            bg = 'primary';
            text = 'text-primary';
          } else if (step < activeStep) {
            bg = 'secondary';
            text = 'text-secondary';
          } else {
            bg = 'light';
            text = 'text-dark';
          }

          return (
            <Nav.Item key={`event-pane-${step}`}>
              <Nav.Link className={text} eventKey={`event-pane-${step}`}>
                <Badge bg={bg} className={`${styles.badge}`}>
                  {step + 1}
                </Badge>{' '}
                {label}
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
      <Card bg={steps[activeStep].bg} className={styles.card}>
        <Tab.Content className={styles.tabContent}>
          {React.Children.map(children, (child, step) =>
            React.cloneElement(child, {
              step,
              activeStep,
              actions,
              data,
              setData,
            }),
          )}
        </Tab.Content>
      </Card>
    </Tab.Container>
  );
};

export default Object.assign(Stepper, {
  Step,
});
