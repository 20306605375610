import React, {useEffect, useMemo, useState} from 'react';
import {Row, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import i18n from '../../translations/i18n';
import LayoutButtons from '../../layouts/utils';
import TextField from '../../components/FormFields/TextField';
import NumberField from '../../components/FormFields/NumberField';
import SwitchField from '../../components/FormFields/SwitchField';
import {getProductOptionsMap, makeCombination} from './utils';
import {useApi} from '../../contexts/ApiContext';

import styles from './ProductsFormPage.module.scss';

const validationSchema = Yup.object().shape({
  combinations: Yup.array().of(
    Yup.object().shape({
      pack: Yup.string(),
      variants: Yup.array().of(
        Yup.object().shape({
          variant: Yup.number(),
          option: Yup.number(),
        }),
      ),
      price: Yup.number()
        .typeError(
          `${i18n.t('ProductsFormPage.form.validations.messages.number.type')}`,
        )
        .positive(
          `${i18n.t(
            'ProductsFormPage.form.validations.messages.number.positive',
          )}`,
        ),
      code: Yup.string(),
      inventory: Yup.boolean(),
    }),
  ),
});

const CombinationsHeader = ({headers, pricingDisabled}) => {
  const {t} = useTranslation('pages');

  return (
    <Row className="mb-1">
      {headers.map((header, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Col key={idx}>
          <b>{header}</b>
        </Col>
      ))}
      <Col>
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className={pricingDisabled ? '' : 'd-none'}>
              {t(
                'ProductsFormPage.Stepper.Steps.stepPacks.table.headers.tooltip.description',
              )}
            </Tooltip>
          }
        >
          <b>
            {t('ProductsFormPage.Stepper.Steps.stepPacks.table.headers.price')}
          </b>
        </OverlayTrigger>
      </Col>
      <Col>
        <b>
          {t('ProductsFormPage.Stepper.Steps.stepPacks.table.headers.code')}
        </b>
      </Col>
      <Col>
        <b>
          {t('ProductsFormPage.Stepper.Steps.stepPacks.table.headers.stock')}
        </b>
      </Col>
    </Row>
  );
};

const CombinationsRow = ({
  productOptionsMap,
  combination,
  idx,
  price,
  defaultCode,
  pricingDisabled,
  inventoryDisabled,
}) => (
  <Row className="mb-1">
    <Col className="position-relative">{combination.pack}</Col>
    {combination.variants.map(({option}) => (
      <Col className="position-relative" key={option}>
        {productOptionsMap.get(option).name}
      </Col>
    ))}
    <Col className="position-relative">
      <NumberField
        name={`combinations.${idx}.price`}
        placeholder={price}
        disabled={pricingDisabled}
      />
    </Col>
    <Col className="positivon-relative">
      <TextField name={`combinations.${idx}.code`} placeholder={defaultCode} />
    </Col>
    <Col className="d-flex align-items-center position-relative">
      <SwitchField
        name={`combinations.${idx}.inventory`}
        disabled={inventoryDisabled}
      />
    </Col>
  </Row>
);

const CombinationsForm = ({
  headers,
  productOptionsMap,
  price,
  defaultCode,
  pricingDisabled,
  inventoryDisabled,
}) => {
  const {values} = useFormikContext();

  const {combinations} = values;

  return (
    <React.Fragment>
      <CombinationsHeader headers={headers} pricingDisabled={pricingDisabled} />
      {combinations.map((combination, idx) => (
        <CombinationsRow
          key={combination.id}
          productOptionsMap={productOptionsMap}
          combination={combination}
          idx={idx}
          price={price}
          defaultCode={defaultCode}
          pricingDisabled={pricingDisabled}
          inventoryDisabled={inventoryDisabled}
        />
      ))}
    </React.Fragment>
  );
};

const StepCombinations = (props) => {
  const {
    product,
    shouldResetCombinations,
    shouldDisableCombinationsInventory,
    shouldResetCombinationsInventory,
    useVolumeDiscounts,
  } = props.data;
  const defaultCode = product.default_code;
  const price = product.offer_price || product.default_price;
  const api = useApi();
  const [defaultCombinations, setCombinationsArray] = useState([]);
  const {t} = useTranslation('pages');

  const productOptionsMap = getProductOptionsMap(product.variants);

  const headers = useMemo(
    () => ['Pack', ...product.variants.map((variant) => variant.name)],
    [product.variants],
  );

  useEffect(() => {
    const fetchDefaultCombinations = async () => {
      const response = await api.post('/product-helper/default-combinations/', {
        pack_names: product.packs.map((pack) => pack.name),
        variants: product.variants.map((variant) => String(variant.id)),
        inventory: product.inventory,
      });
      setCombinationsArray(response.combinations.map(makeCombination));
    };

    fetchDefaultCombinations();
  }, [api, product]);

  const initialValues = useMemo(() => {
    if (!product.id || shouldResetCombinations) {
      return {combinations: defaultCombinations};
    }

    if (shouldResetCombinationsInventory && product.combinations.length === 1) {
      return {
        combinations: product.combinations.map((combination) => ({
          ...combination,
          inventory: true,
        })),
      };
    }

    return {combinations: product.combinations};
  }, [
    defaultCombinations,
    product,
    shouldResetCombinations,
    shouldResetCombinationsInventory,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.setData((prevState) => {
          const prevProduct = prevState.product;
          const newProduct = {
            ...prevProduct,
            combinations: values.combinations,
          };
          return {
            ...prevState,
            product: newProduct,
            shouldResetCombinations: false,
          };
        });
        props.actions.handleNext();
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form noValidate onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col xs={12} className={styles.stepTitle}>
              <h1>{t('ProductsFormPage.Stepper.Steps.stepPacks.title')}</h1>
              <p>{t('ProductsFormPage.Stepper.Steps.stepPacks.description')}</p>
            </Col>
          </Row>
          <CombinationsForm
            headers={headers}
            productOptionsMap={productOptionsMap}
            price={price}
            defaultCode={defaultCode}
            pricingDisabled={useVolumeDiscounts}
            inventoryDisabled={shouldDisableCombinationsInventory}
          />

          <LayoutButtons
            handleBack={props.actions.handleBack}
            handleNext={formikProps.submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StepCombinations;
