export const PURCHASE_ORDER_STATUSES = [
  ['draft', 'Borrador'],
  ['new', 'Nueva'],
  ['in_preparation', 'En Preparación'],
  ['invoiced', 'Facturada'],
  ['shipped', 'Despachada'],
  ['cancelled', 'Cancelada'],
  ['delivered', 'Enviada'],
];

export const PRODUCT_IMPORT_STATUSES = [
  ['IMPORTED', 'Exitosa'],
  ['IMPORTING', 'En progreso'],
  ['IMPORT_ERROR', 'Fallida'],
];
