import React, {FC} from 'react';
import {RichTextEditor as RichTextEditorBase, Link} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import {Underline} from '@tiptap/extension-underline';
import {TextAlign} from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';

import CharacterCount from '@tiptap/extension-character-count';

type ControlType =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'link'
  | 'separator'
  | 'bulletList'
  | 'orderedList'
  | 'clear'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'alignLeft'
  | 'alignCenter'
  | 'alignJustify'
  | 'alignRight';

const CONTROLS_MAP: Record<ControlType, JSX.Element> = {
  bold: <RichTextEditorBase.Bold key="bold" />,
  italic: <RichTextEditorBase.Italic key="italic" />,
  underline: <RichTextEditorBase.Underline key="underline" />,
  link: <RichTextEditorBase.Link key="link" />,
  separator: <RichTextEditorBase.Hr key="separator" />,
  bulletList: <RichTextEditorBase.BulletList key="bullet_list" />,
  orderedList: <RichTextEditorBase.OrderedList key="ordered_list" />,
  clear: <RichTextEditorBase.ClearFormatting key="clear" />,
  h1: <RichTextEditorBase.H1 key="h1" />,
  h2: <RichTextEditorBase.H2 key="h2" />,
  h3: <RichTextEditorBase.H3 key="h3" />,
  h4: <RichTextEditorBase.H4 key="h4" />,
  alignLeft: <RichTextEditorBase.AlignLeft key="align_left" />,
  alignCenter: <RichTextEditorBase.AlignCenter key="align_center" />,
  alignJustify: <RichTextEditorBase.AlignJustify key="align_justify" />,
  alignRight: <RichTextEditorBase.AlignRight key="align_right" />,
};

const renderCustomControls = (controlGroup: string[], index: number) => (
  <RichTextEditorBase.ControlsGroup key={`control-group-${index}`}>
    {controlGroup.map((control) => CONTROLS_MAP[control as ControlType])}
  </RichTextEditorBase.ControlsGroup>
);

type RichTextEditorProps = React.ComponentProps<typeof RichTextEditorBase>;

const RichTextEditor: FC<
  {
    content: string;
    setValue: any;
    maxCharacters: number;
    availableControls: string[][] | undefined;
  } & Partial<RichTextEditorProps>
> = ({content, setValue, maxCharacters, availableControls}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      CharacterCount.configure({limit: maxCharacters}),
      TextAlign.configure({types: ['heading', 'paragraph']}),
      Underline,
    ],
    content,
    onUpdate() {
      if (editor?.isEmpty) {
        setValue('');
      } else {
        setValue(editor?.getHTML());
      }
    },
  });

  return (
    <RichTextEditorBase editor={editor}>
      <RichTextEditorBase.Toolbar sticky>
        {availableControls?.map(renderCustomControls)}
      </RichTextEditorBase.Toolbar>
      <RichTextEditorBase.Content />
    </RichTextEditorBase>
  );
};

export default RichTextEditor;
