import {useSearchParams} from 'react-router-dom';
import {FILTERABLE_TYPES} from '../components/DataTable/utils';

const useSearchParamsFilters = (filterables) => {
  const [queryParams] = useSearchParams();
  const filters = new URLSearchParams(queryParams.get('filters'));
  const currentFilters = {};

  filterables.forEach((filterable) => {
    if (filters.has(filterable.field)) {
      if (filterable.type === FILTERABLE_TYPES.CHOICES) {
        currentFilters[filterable.field] = [
          ...filters.get(filterable.field).split(','),
        ];
      }
    } else {
      if (filters.has(`${filterable.field}_from`)) {
        currentFilters[`${filterable.field}_from`] = filters.get(
          `${filterable.field}_from`,
        );
      }
      if (filters.has(`${filterable.field}_to`)) {
        currentFilters[`${filterable.field}_to`] = filters.get(
          `${filterable.field}_to`,
        );
      }
    }
  });

  const hasFilters = Object.keys(currentFilters).length;

  return {currentFilters, hasFilters};
};

export default useSearchParamsFilters;
