import {pathStripSlashes} from '../utils';

const STORAGE = window.localStorage;
const BLINK_UID = document.BLINK_BLINK_UID;
const STORAGE_KEY_TOKEN = `bl:v2:${BLINK_UID}:token`;
const STORAGE_KEY_TOKEN_REFRESH = `bl:v2:${BLINK_UID}:token-refresh`;

const ADMIN_KEY_TOKEN = `bl:v2:${BLINK_UID}:admin:token`;
const ADMIN_KEY_TOKEN_REFRESH = `bl:v2:${BLINK_UID}:admin:token-refresh`;
const IMPERSONATE_KEY = `bl:v2:${BLINK_UID}:impersonate`;

class ApiError extends Error {
  constructor(json, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = json.status;
    this.data = json.data;
  }
}

class ApiErrorInvalidToken extends Error {}

function setLocalToken(token) {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN : STORAGE_KEY_TOKEN;

  if (!token) {
    STORAGE.removeItem(key);
  } else {
    STORAGE.setItem(key, token);
  }
}

function setLocalTokenRefresh(tokenRefresh) {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN_REFRESH : STORAGE_KEY_TOKEN_REFRESH;

  if (!tokenRefresh) {
    STORAGE.removeItem(key);
  } else {
    STORAGE.setItem(key, tokenRefresh);
  }
}

function resetLocalTokens() {
  setLocalToken(null);
  setLocalTokenRefresh(null);
}

function getLocalToken() {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN : STORAGE_KEY_TOKEN;

  return STORAGE.getItem(key);
}

function getLocalTokenRefresh() {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN_REFRESH : STORAGE_KEY_TOKEN_REFRESH;

  return STORAGE.getItem(key);
}

function setImpersonate() {
  STORAGE.setItem(IMPERSONATE_KEY, true);
}

function resetImpersonate() {
  STORAGE.removeItem(IMPERSONATE_KEY);
}

function getImpersonateKey() {
  return STORAGE.getItem(IMPERSONATE_KEY);
}

async function request(method, path, data) {
  const token = getLocalToken();

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  headers['X-BLINK-UID'] = BLINK_UID;

  const searchParams = method === 'get' && data ? new URLSearchParams(data) : null;

  const extra = {};
  if (data && method !== 'get') {
    extra.body = JSON.stringify(data);
  }

  const url = `/api/v1/${pathStripSlashes(path)}/`;
  const response = await fetch(searchParams ? `${url}?${searchParams.toString()}` : url, {
    method,
    headers,
    ...extra,
  });

  if (![200, 201, 204, 400, 401].includes(response.status)) {
    throw new ApiError({
      status: response.status,
      data: response.statusText,
    });
  }

  // Parse the Content-Disposition header so we can download files from the API.
  const responseContentDisposition = response.headers.get('Content-Disposition');
  if (responseContentDisposition) {
    const dispositionFields = responseContentDisposition.split(';');
    const dispositionType = dispositionFields[0];
    if (dispositionType === 'attachment') {
      const dispositionFilename = dispositionFields[1]?.split('filename=')[1];
      const downloadBlob = await response.blob();
      const downloadFileUrl = URL.createObjectURL(downloadBlob);
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = downloadFileUrl;
      if (dispositionFilename) {
        downloadAnchor.download = dispositionFilename;
      }
      downloadAnchor.click();
    }

    // TODO: Maybe return early?
  }

  let json;
  try {
    json = await response.json();
  } catch (e) {
    json = {};
  }

  if ([200, 201, 204].includes(response.status)) {
    return json;
  }

  if (response.status === 401 && path !== '/token-refresh' && json.code === 'token_not_valid') {
    const refresh = getLocalTokenRefresh();

    // clean tokens in case the refresh goes wrong
    resetLocalTokens();
    if (refresh) {
      const refreshData = await request('post', '/token-refresh', {refresh});
      if (response.status === 401 && refreshData.access) {
        setLocalToken(refreshData.access);
        setLocalTokenRefresh(refreshData.refresh);

        // retry request after refresh
        return request(method, path, data);
      }
    }
  } else if (
    response.status === 401 &&
    path === '/token-refresh' &&
    json.code === 'token_not_valid'
  ) {
    throw new ApiErrorInvalidToken();
  }

  throw new ApiError({
    status: response.status,
    data: json,
  });
}

export {
  BLINK_UID,
  request,
  ApiErrorInvalidToken,
  setLocalToken,
  setLocalTokenRefresh,
  resetLocalTokens,
  getLocalToken,
  getLocalTokenRefresh,
  setImpersonate,
  resetImpersonate,
  getImpersonateKey,
};
