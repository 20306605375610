import React, {Dispatch, SetStateAction, useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {CellProps, Column, HeaderProps} from 'react-table';
import {useShopProductsContainer} from './Container';
import Table from './Table';
import {useApi} from '../../contexts/ApiContext';
import {ShopProduct} from '../../types/ShopProduct';
import {
  ExpandRowButton,
  discountColumn,
  imageWithTitleAndPriceRangeColumn,
  priceForListColumn,
  quantityColumn,
  subtotalColumn,
} from './TableColumns';
import {useUtils} from '../../contexts/UtilsContext';
import Paginator from './Paginator';
import {DeleteButton} from './TableButtons';
import {RegularExpandedRowContent} from './ExpandedRow';
import {useSession} from '../../contexts/SessionContext';
import {customerAllowedToSeePrices} from '../../utils';

import styles from './ShopProduct.module.scss';

type PurchaseOrderSetter = Dispatch<SetStateAction<Record<string, any>>>;

const ButtonsColumnCell = (setPurchaseOrder: PurchaseOrderSetter) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;
    const api = useApi();
    const params = useParams();
    const purchaseOrderId = params.id;

    const deleteProduct = useCallback(async () => {
      const response = await api.delete(`/purchase-order/${purchaseOrderId}/item/${product.id}`);
      setPurchaseOrder((prevPurchaseOrder) => ({
        ...prevPurchaseOrder,
        ...response.purchase_order,
      }));
    }, [api, product.id, purchaseOrderId]);

    return (
      <div className={styles.optionButtons}>
        <ExpandRowButton product={product} />
        <DeleteButton deleteProduct={deleteProduct} confirmationText="Desea quitar el producto?" />
      </div>
    );
  };
  return Component;
};
const ButtonsColumnHeader = (setPurchaseOrder: PurchaseOrderSetter) => {
  const Component = (props: HeaderProps<ShopProduct>) => {
    const api = useApi();
    const params = useParams();
    const purchaseOrderId = params.id;

    const selectedProductIds = useMemo<number[]>(
      // @ts-ignore because of bad react table v7 typescript support.
      () => props.selectedFlatRows.map((row) => row.original.id),
      // @ts-ignore because of bad react table v7 typescript support.
      [props.selectedFlatRows],
    );

    const deleteSelectedProducts = useCallback(async () => {
      await Promise.all(
        selectedProductIds.map(async (productId: number) => {
          try {
            const response = await api.delete(
              `/purchase-order/${purchaseOrderId}/item/${productId}`,
            );
            setPurchaseOrder((prevPurchaseOrder) => ({
              ...prevPurchaseOrder,
              ...response.purchase_order,
            }));
          } catch (e: any) {
            toast.error(e.data, {hideProgressBar: true});
          }
        }),
      );
    }, [api, purchaseOrderId, selectedProductIds]);

    return (
      <div className={styles.optionButtons}>
        <DeleteButton
          deleteProduct={deleteSelectedProducts}
          confirmationText="Desea quitar los productos?"
          disabled={selectedProductIds.length === 0}
        />
      </div>
    );
  };
  return Component;
};
const buttonsColumn = (setPurchaseOrder: PurchaseOrderSetter): Column<ShopProduct> => ({
  id: 'buttons',
  Cell: ButtonsColumnCell(setPurchaseOrder),
  Header: ButtonsColumnHeader(setPurchaseOrder),
});

interface PurchaseOrderItemsTableProps {
  setPurchaseOrder: PurchaseOrderSetter;
  priceList: boolean;
}
const PurchaseOrderItemsTable = ({setPurchaseOrder, priceList}: PurchaseOrderItemsTableProps) => {
  const api = useApi();
  const {mutateProduct} = useShopProductsContainer();
  const {id: purchaseOrderId} = useParams();
  const [{settings}] = useUtils();
  const [, , selectors] = useSession();
  const currency: string = settings.COMPANY_CURRENCY;

  const hidePrices = !customerAllowedToSeePrices(settings, selectors);

  const setCombinationQuantity = useCallback(
    async (productId: number, combinationId: string, newQuantity: number) => {
      const result = await api.post(`/purchase-order/${purchaseOrderId}/item/${productId}`, {
        combination: combinationId,
        quantity: newQuantity,
      });

      mutateProduct(productId, (prevProduct) => ({
        ...prevProduct,
        ...result.product,
      }));

      setPurchaseOrder((prevPurchaseOrder) => ({
        ...prevPurchaseOrder,
        ...result.purchase_order,
      }));
    },
    [api, mutateProduct, purchaseOrderId, setPurchaseOrder],
  );

  const columns = useMemo<Column<ShopProduct>[]>(() => {
    if (priceList) {
      return [
        imageWithTitleAndPriceRangeColumn(currency, hidePrices),
        discountColumn,
        priceForListColumn(currency),
        subtotalColumn(currency),
        quantityColumn(setCombinationQuantity),
        buttonsColumn(setPurchaseOrder),
      ];
    }

    return [
      imageWithTitleAndPriceRangeColumn(currency, hidePrices),
      subtotalColumn(currency),
      quantityColumn(setCombinationQuantity),
      buttonsColumn(setPurchaseOrder),
    ];
  }, [setPurchaseOrder, setCombinationQuantity, currency, priceList, hidePrices]);

  return (
    <React.Fragment>
      <Table
        currency={currency}
        setCombinationQuantity={setCombinationQuantity}
        columns={columns}
        enableSelection
        ExpandedRowContentComponent={RegularExpandedRowContent}
      />
      <Paginator perPage={72} />
    </React.Fragment>
  );
};

export default PurchaseOrderItemsTable;
