import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row, Form} from 'react-bootstrap';
import EditButtons from './EditButtons';
import TextareaField from '../../components/FormFields/TextareaField';

import styles from './PurchaseOrdersFormPage.module.scss';

const validationSchemaComments = Yup.object().shape({
  comments: Yup.string().max(500, 'Maximum 500 characters'),
});

const CommentsCard = ({purchaseOrder, editPurchaseOrder}) => {
  const [editingComment, setEditingComment] = useState(false);
  const {t} = useTranslation('pages');

  return (
    <Card bg="white" text="dark">
      <Card.Body className="px-2 py-1">
        <Formik
          enableReinitialize
          initialValues={{
            comments: purchaseOrder.comments || '',
          }}
          onSubmit={(values) => {
            editPurchaseOrder(purchaseOrder.id, values);
            setEditingComment(false);
          }}
          validationSchema={validationSchemaComments}
        >
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={formikProps.handleSubmit}
              className={styles.form}
            >
              <Row>
                <Card.Title>
                  <span className="text-primary">
                    {t('PurchaseOrdersFormPage.CommentsCard.title')}
                  </span>
                  <EditButtons
                    formProps={formikProps}
                    editing={editingComment}
                    setEditing={setEditingComment}
                    dark
                  />
                </Card.Title>
                <Col>
                  <Form.Group className="position-relative">
                    <TextareaField
                      name="comments"
                      disabled={!editingComment}
                      rows={3}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CommentsCard;
