import React, {
  useMemo,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from 'react';
import {Row, Col, Container, Card, Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {Trans, useTranslation} from 'react-i18next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import DataTable from '../../components/DataTable';
import DataTree from '../../components/DataTree';
import LayoutButtons from '../../layouts/utils';
import {isObjectEmpty, replaceSetsWithArrays} from '../../utils';

import styles from './BlinksFormPage.module.scss';

const FilterCategoriesPreview = ({value}) => {
  const api = useApi();
  const [mapping, setMapping] = useState({});

  // TODO: maybe keep using the category list endpoint and transform it into a mapping clientside?
  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(
        '/product-helper/lite-details-categories',
        {
          filters: [JSON.stringify({ids: value})],
        },
      );
      setMapping(response.mapping);
    };
    fetchData();
  }, [api, value]);

  return (
    <ul>
      {[...value].map((categoryId) => (
        /* @TODO use a spinner while loading */
        <li key={categoryId}>
          {mapping[categoryId] ? mapping[categoryId].title : categoryId}
        </li>
      ))}
    </ul>
  );
};

const FilterNamePreview = ({value}) => <small>{value}</small>;

const FilterCategoriesLabel = ({field, value}) => {
  const {t} = useTranslation('pages');
  const parts = [
    `${t('BlinksFormPage.StepConfigureProducts.fields.labels.categories')}`,
  ];
  if (field.isDirty(value)) {
    parts.push(
      `${t('BlinksFormPage.StepConfigureProducts.fields.selected', {
        value: value.size,
      })}`,
    );
  }
  return <React.Fragment>{parts.join(': ')}</React.Fragment>;
};

const FilterNameLabel = ({field, value}) => {
  const {t} = useTranslation('pages');
  const parts = [
    `${t('BlinksFormPage.StepConfigureProducts.fields.labels.name')}`,
  ];
  if (field.isDirty(value)) {
    parts.push(value);
  }
  return <React.Fragment>{parts.join(': ')}</React.Fragment>;
};

const FIELDS = {
  categories: {
    initial: new Set(),
    isDirty: (val) => val !== undefined && val instanceof Set && val.size !== 0,
    previewComponent: <FilterCategoriesPreview />,
    labelComponent: <FilterCategoriesLabel />,
  },
  name: {
    initial: '',
    isDirty: (val) =>
      val !== undefined && typeof val === 'string' && val.length !== 0,
    previewComponent: <FilterNamePreview />,
    labelComponent: <FilterNameLabel />,
  },
};

const FIELDS_NAMES = Object.keys(FIELDS);

const types = {
  FILTER_FIELD_SELECT: 'FILTER_FIELD_SELECT',
  FILTER_FIELD_REMOVE: 'FILTER_FIELD_REMOVE',
  FILTER_FIELD_REMOVE_ALL: 'FILTER_FIELD_REMOVE_ALL',
  FILTER_SET_VALUE: 'FILTER_SET_VALUE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.FILTER_FIELD_SELECT: {
      const {fieldName} = action.payload;

      const filter = Object.keys(state.filter)
        .concat([fieldName])
        .reduce((accum, current) => {
          if (
            current === fieldName ||
            state.filter[current] !== FIELDS[current].initial
          ) {
            accum[current] = state.filter[current] || FIELDS[fieldName].initial;
          }

          return accum;
        }, {});

      return {
        ...state,
        fieldName,
        filter,
      };
    }
    case types.FILTER_FIELD_REMOVE: {
      const {fieldName} = action.payload;
      return {
        ...state,
        fieldName: null,
        filter: {
          ...state.filter,
          [fieldName]: undefined,
        },
      };
    }
    case types.FILTER_FIELD_REMOVE_ALL: {
      return {
        ...state,
        fieldName: null,
        filter: {},
      };
    }
    case types.FILTER_SET_VALUE: {
      const {value} = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.fieldName]: value,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const StepDetails = (props) => {
  const {data, setData} = props;
  const [utilsState] = useUtils();
  const api = useApi();
  const {t} = useTranslation('pages');

  const [state, dispatch] = useReducer(reducer, {
    // @TODO use Formik for filter object
    filter: {},
    fieldName: null,
    countProducts: 0,
  });

  const filterByNameSource = useMemo(
    () => ({
      type: 'remote',
      url: '/product',
      filters: [state.filter],
    }),
    [state.filter],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
    ],
    [],
  );

  const params = useMemo(() => {
    const paramFilters = [...data.filters];

    if (Object.keys(state.filter).length > 0) {
      paramFilters.push(state.filter);
    }

    if (paramFilters.length > 0) {
      return {
        filters: JSON.stringify(paramFilters, replaceSetsWithArrays),
      };
    }

    return {};
  }, [data.filters, state.filter]);

  useEffect(() => {
    const fetchCountProducts = async () => {
      const response = await api.get('/product-helper/count-products/', {
        ...params,
      });
      setData((prevState) => ({
        ...prevState,
        countProducts: response.count_products,
      }));
    };
    fetchCountProducts();
  }, [api, params, setData]);

  const handleFilterSelectField = (fieldName) => {
    dispatch({
      type: types.FILTER_FIELD_SELECT,
      payload: {
        fieldName,
      },
    });
  };

  const handleFilterRemoveField = (fieldName) => {
    dispatch({
      type: types.FILTER_FIELD_REMOVE,
      payload: {
        fieldName,
      },
    });
  };

  const handleFilterSetValue = useCallback((value) => {
    dispatch({
      type: types.FILTER_SET_VALUE,
      payload: {
        value,
      },
    });
  }, []);

  const handleSelectCategory = useCallback(
    (value) => {
      const newSelected = new Set(state.filter.categories);
      if (newSelected.has(value)) {
        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }
      dispatch({
        type: types.FILTER_SET_VALUE,
        payload: {
          value: newSelected,
        },
      });
    },
    [state.filter.categories],
  );

  const handleNext = useCallback(() => {
    if (data.filters.length === 0) {
      toast.error(
        t('BlinksFormPage.StepConfigureProducts.filters.error.message'),
        {
          hideProgressBar: true,
        },
      );
    } else {
      props.actions.handleNext();
    }
  }, [props.actions, data.filters, t]);

  const handleAddFilter = useMemo(() => {
    if (isObjectEmpty(state.filter)) {
      return null;
    }

    return {
      variant: 'primary',
      label: t('BlinksFormPage.StepConfigureProducts.extraButton.label'),
      onClick: () => {
        const cleaned = Object.keys(state.filter).reduce((accum, current) => {
          if (FIELDS[current].isDirty(state.filter[current])) {
            accum[current] =
              state.filter[current] instanceof Set
                ? Array.from(state.filter[current])
                : state.filter[current];
          }
          return accum;
        }, {});

        if (Object.keys(cleaned).length === 0) {
          return;
        }
        setData((prevState) => ({
          ...prevState,
          filters: [...prevState.filters, cleaned],
        }));

        dispatch({
          type: types.FILTER_FIELD_REMOVE_ALL,
        });
      },
    };
  }, [setData, state.filter, dispatch, t]);

  return (
    <div className={styles.container}>
      <div className={styles.containerTop}>
        <Container fluid>
          <Row>
            <Col xl={8}>
              <h3>{t('BlinksFormPage.StepConfigureProducts.title')}</h3>
            </Col>
            <Col xl={4}>
              <h4>
                <Trans
                  i18nKey="BlinksFormPage.StepConfigureProducts.products_count"
                  values={{count: data.countProducts}}
                />
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.containerMain}>
        <div className={styles.sidebar}>
          <Card>
            <Card.Body>
              <Card.Title>
                {t('BlinksFormPage.StepConfigureProducts.selectionCard.title')}
              </Card.Title>
              {data.filters.length !== 0 ? (
                data.filters.map((filter, index) => (
                  /* eslint react/no-array-index-key: off */
                  <React.Fragment key={index}>
                    <div className={styles.sidebarFilter}>
                      <Icon
                        onClick={() => {
                          setData((prevState) => ({
                            ...prevState,
                            filters: prevState.filters.filter(
                              (f, i) => i !== index,
                            ),
                          }));
                        }}
                        icon="fa-solid fa-times-circle"
                      />
                      {FIELDS_NAMES.filter(
                        (fieldName) => filter[fieldName] !== undefined,
                      ).map((fieldName) => {
                        const PreviewComponent =
                          FIELDS[fieldName].previewComponent.type;
                        return (
                          <div key={fieldName}>
                            <strong>
                              {t(
                                `BlinksFormPage.StepConfigureProducts.fields.labels.${fieldName}`,
                              )}
                            </strong>
                            <br />
                            <PreviewComponent value={filter[fieldName]} />
                          </div>
                        );
                      })}
                    </div>
                    <hr data-content="Or" />
                  </React.Fragment>
                ))
              ) : (
                <div className="text-center">
                  <small>
                    <Trans i18nKey="BlinksFormPage.StepConfigureProducts.selectionCard.description" />
                  </small>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
        <div className={styles.main}>
          <Container fluid>
            <Row>
              <Col xl={4}>
                {FIELDS_NAMES.map((fieldName) => {
                  const className =
                    (state.fieldName === fieldName && styles.current) ||
                    (state.filter[fieldName] !== undefined && styles.active) ||
                    '';

                  const LabelComponent = FIELDS[fieldName].labelComponent.type;

                  return (
                    <div
                      className={`${styles.btn} ${className} text-reset`}
                      onClick={() => handleFilterSelectField(fieldName)}
                      onKeyPress={() => handleFilterSelectField(fieldName)}
                      role="button"
                      tabIndex="0"
                      key={fieldName}
                    >
                      {state.filter[fieldName] !== undefined && (
                        <Icon
                          onClick={(event) => {
                            event.stopPropagation();
                            handleFilterRemoveField(fieldName);
                          }}
                          icon="fa-solid fa-times-circle"
                        />
                      )}
                      <div>
                        <LabelComponent
                          field={FIELDS[fieldName]}
                          value={state.filter[fieldName]}
                        />
                      </div>
                    </div>
                  );
                })}
              </Col>
              <Col xl={8}>
                {state.fieldName === 'name' && (
                  <div className={styles.filterByTitle}>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search by product name"
                        onChange={(event) =>
                          handleFilterSetValue(event.target.value)
                        }
                        value={state.filter.name}
                      />
                    </InputGroup>
                    <DataTable
                      columns={columns}
                      source={filterByNameSource}
                      allowSelection={false}
                    />
                  </div>
                )}
                {state.fieldName === 'categories' && (
                  <div className={styles.filterByCategories}>
                    <DataTree
                      data={utilsState.categories.tree}
                      selectedData={state.filter.categories}
                      handleSelect={handleSelectCategory}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <LayoutButtons extraButton={handleAddFilter} handleNext={handleNext} />
    </div>
  );
};

export default StepDetails;
