import React, {useState} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';

const ReactiveButton = ({
  handleClick,
  defaultIcon,
  secondaryIcon,
  notificationText,
  children,
}) => {
  const [pressed, setPressed] = useState(false);

  const icon = pressed ? secondaryIcon || defaultIcon : defaultIcon;
  const variant = pressed ? 'secondary' : 'primary';
  return (
    <Button
      active
      variant={variant}
      onClick={() => {
        setPressed(true);
        handleClick();
        toast.success(notificationText, {
          hideProgressBar: true,
        });
        setTimeout(() => {
          setPressed(false);
        }, 500);
      }}
    >
      <Icon icon={icon} />
      {children}
    </Button>
  );
};

export default ReactiveButton;
