import React from 'react';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';

const TextareaField = ({name, rows, size, disabled, placeholder}) => {
  const [fieldProps, metaProps] = useField(name);

  return (
    <React.Fragment>
      <Form.Control
        size={size || 'lg'}
        name={name}
        type="text"
        value={fieldProps.value}
        onChange={fieldProps.onChange}
        onBlur={fieldProps.onBlur}
        placeholder={placeholder}
        as="textarea"
        rows={rows || 3}
        isInvalid={metaProps.touched && metaProps.error}
        disabled={disabled}
      />
      <Form.Control.Feedback tooltip type="invalid">
        {metaProps.error}
      </Form.Control.Feedback>
    </React.Fragment>
  );
};

export default TextareaField;
