import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {ShopProduct} from '../../types/ShopProduct';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {useApi} from '../../contexts/ApiContext';
import {CombinationsMatrixAndFilters} from '../../components/ShopProduct';
import {CombinationInput} from '../../components/ShopProduct/QuantityInputs';
import {SerializerValidatedData} from './types';

import styles from './StoreQuickOrderPage.module.scss';

type QuickOrderQuantityMethodProps = {
  product: ShopProduct;
  setProduct: Dispatch<SetStateAction<ShopProduct | null>>;
  validImporterData: SerializerValidatedData[];
  setValidImporterData: Dispatch<SetStateAction<SerializerValidatedData[]>>;
};

type NewProductValue = {
  quantity: number;
  quantities: {
    combination_id: number;
  };
  total: string;
};

type ManualQuickOrderProps = {
  validImporterData: SerializerValidatedData[];
  setValidImporterData: Dispatch<SetStateAction<SerializerValidatedData[]>>;
};

const QUERY_PER_PAGE = 10;

const QuickOrderQuantityMethod = ({
  product,
  setProduct,
  validImporterData,
  setValidImporterData,
}: QuickOrderQuantityMethodProps) => {
  const [{uid, settings}, {setCart}] = useEcommerce();
  const api = useApi();

  const mutateImportedProducts = useCallback(
    (newProductValues: NewProductValue) => {
      const validProductIndex = validImporterData.findIndex(
        (validData) => validData.product_id === product.id,
      );

      if (validProductIndex !== -1) {
        setValidImporterData((prevState) => {
          const newValues = prevState;

          if (newProductValues.quantity === 0) {
            newValues.splice(validProductIndex, 1);

            return newValues;
          }

          newValues[validProductIndex] = {
            ...prevState[validProductIndex],
            quantity: newProductValues.quantity,
          };

          return newValues;
        });
      } else {
        setValidImporterData((prevState) => [
          ...prevState,
          {
            default_code: product.ref,
            quantity: newProductValues.quantity,
            product_id: product.id,
            product_name: product.name,
          },
        ]);
      }
    },
    [validImporterData, setValidImporterData, product],
  );

  const setCombinationQuantity = useCallback(
    async (productId: number, combinationId: string, newQuantity: number) => {
      const result = await api.post(`/blink/${uid}/${productId}/`, {
        combination: combinationId,
        quantity: newQuantity,
      });

      setProduct((prevState) => ({...prevState, ...result.product}));

      mutateImportedProducts(result.product);

      setCart(result.cart);
    },
    [api, uid, setCart, setProduct, mutateImportedProducts],
  );

  return (
    <div className="mt-3">
      <div className={product.combinations.length === 1 ? styles.single : undefined}>
        <h4>{product.name}</h4>
        {product.combinations.length > 1 ? (
          <CombinationsMatrixAndFilters
            product={product}
            setCombinationQuantity={setCombinationQuantity}
            currency={settings.COMPANY_CURRENCY}
          />
        ) : (
          <CombinationInput
            product={product}
            combinationId={product.combinations[0].id}
            setCombinationQuantity={setCombinationQuantity}
            enableTooltip
          />
        )}
      </div>
    </div>
  );
};

const ManualQuickOrder = ({validImporterData, setValidImporterData}: ManualQuickOrderProps) => {
  const [products, setProducts] = useState<ShopProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ShopProduct | null>(null);
  const [state] = useEcommerce();
  const api = useApi();
  const {t} = useTranslation();

  return (
    <Card className={styles.manualQuickOrder}>
      <Card.Body>
        <p>
          <strong>{t('pages:StoreQuickOrderPage.ManualQuickOrder.description')}</strong>
        </p>
        <AsyncTypeahead
          id="manual-quick-order"
          className="position-relative"
          isLoading={isLoading}
          labelKey="ref"
          placeholder={`${t('components:typeahead.placeholder')}`}
          emptyLabel={`${t('components:typeahead.emptyLabel')}`}
          searchText={`${t('components:typeahead.searchText')}`}
          promptText={`${t('components:typeahead.promptText')}`}
          delay={800}
          options={products}
          useCache={false}
          filterBy={() => true}
          clearButton
          onChange={(selected) => {
            const typedSelected = selected.at(0) as ShopProduct;
            setSelectedProduct(typedSelected);
          }}
          onSearch={async (query) => {
            setIsLoading(true);
            const result = await api.get(`/blink/${state.uid}/product/`, {
              search: query,
              page: 1,
              per_page: QUERY_PER_PAGE,
            });
            setProducts(result.results);
            setIsLoading(false);
          }}
          renderMenuItemChildren={(option) => {
            const typedOptions = option as ShopProduct;
            return <span>{typedOptions.name}</span>;
          }}
        />
        {selectedProduct ? (
          <QuickOrderQuantityMethod
            product={selectedProduct}
            setProduct={setSelectedProduct}
            validImporterData={validImporterData}
            setValidImporterData={setValidImporterData}
          />
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ManualQuickOrder;
