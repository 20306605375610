import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as components_en from './components/en.json';
import * as pages_en from './pages/en.json';
import * as components_es from './components/es.json';
import * as pages_es from './pages/es.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  resources: {
    es: {
      pages: pages_es,
      components: components_es,
    },
    en: {
      pages: pages_en,
      components: components_en,
    },
  },
  load: 'languageOnly',
  lng: navigator.language,
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: ['es', 'en'],
  ns: ['pages', 'components'],
  nsSeparator: ':',
});

export default i18n;
