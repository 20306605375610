import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CellProps, Column, HeaderProps} from 'react-table';
import {Col, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useShopProductsContainer} from './Container';
import SearchParamsSearchBar from '../SearchParams/SearchParamsSearchBar';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {ShopProduct} from '../../types/ShopProduct';
import {useApi} from '../../contexts/ApiContext';
import Table from './Table';
import {DeleteButton} from './TableButtons';
import Paginator from './Paginator';
import {
  quantityColumn,
  imageWithTitleAndPriceRangeColumn,
  expandedRowAndDeleteColumn,
  quantityColumnMobile,
  subtotalColumn,
  ExpandRowButton,
  discountColumn,
  priceForListColumn,
} from './TableColumns';
import SortBy from './SortBy';
import CountDisplay from './CountDisplay';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import {RegularExpandedRowContent} from './ExpandedRow';
import MobileFilter from './MobileFilter';
import {customerAllowedToSeePrices} from '../../utils';
import {useSession} from '../../contexts/SessionContext';

import styles from './ShopProduct.module.scss';

const ButtonsColumnCell = (props: CellProps<ShopProduct>) => {
  const api = useApi();
  const [{uid}, {setCart}] = useEcommerce();
  const {t} = useTranslation('components');
  const product = props.row.original;

  const deleteProduct = useCallback(async () => {
    const response = await api.delete(`/blink/${uid}/${product.id}`);
    setCart(response.cart);
  }, [api, uid, setCart, product.id]);

  return (
    <div className={styles.optionButtons}>
      <ExpandRowButton product={product} />
      <DeleteButton
        deleteProduct={deleteProduct}
        confirmationText={t('ShopProduct.DeleteButton.confirmationTextSingle')}
      />
    </div>
  );
};

const ButtonsColumnHeader = (props: HeaderProps<ShopProduct>) => {
  const api = useApi();
  const [{uid}, {setCart}] = useEcommerce();
  const {t} = useTranslation('components');

  const selectedProductIds = useMemo<number[]>(
    // @ts-ignore because of bad react table v7 typescript support.
    () => props.selectedFlatRows.map((row) => row.original.id),
    // @ts-ignore because of bad react table v7 typescript support.
    [props.selectedFlatRows],
  );

  const deleteSelectedProducts = useCallback(async () => {
    await Promise.all(
      selectedProductIds.map(async (productId: number) => {
        try {
          const response = await api.delete(`/blink/${uid}/${productId}`);
          setCart(response.cart);
        } catch (e: any) {
          toast.error(e.data, {hideProgressBar: true});
        }
      }),
    );
  }, [api, uid, setCart, selectedProductIds]);

  return (
    <div className={styles.optionButtons}>
      <DeleteButton
        deleteProduct={deleteSelectedProducts}
        confirmationText={t('ShopProduct.DeleteButton.confirmationTextMulti')}
        disabled={selectedProductIds.length === 0}
      />
    </div>
  );
};
const buttonsColumn: Column<ShopProduct> = {
  id: 'buttons',
  Header: ButtonsColumnHeader,
  Cell: ButtonsColumnCell,
};

const CartTable = () => {
  const api = useApi();
  const [{settings, uid, priceList}, {setCart}] = useEcommerce();
  const [, , selectors] = useSession();
  const {isMobile} = useDeviceWindow();
  const {mutateProduct} = useShopProductsContainer();
  const currency = settings.COMPANY_CURRENCY;

  const hidePrices = !customerAllowedToSeePrices(settings, selectors);

  const setCombinationQuantity = useCallback(
    async (productId: number, combinationId: string, newQuantity: number) => {
      const result = await api.post(`/blink/${uid}/${productId}/`, {
        combination: combinationId,
        quantity: newQuantity,
      });

      mutateProduct(productId, (prevProduct) => ({
        ...prevProduct,
        ...result.product,
      }));

      setCart(result.cart);
    },
    [api, uid, mutateProduct, setCart],
  );

  const columns = useMemo<Column<ShopProduct>[]>(() => {
    if (isMobile) {
      return [
        imageWithTitleAndPriceRangeColumn(currency, hidePrices),
        quantityColumnMobile(setCombinationQuantity),
        expandedRowAndDeleteColumn,
      ];
    }

    if (priceList) {
      return [
        imageWithTitleAndPriceRangeColumn(currency, hidePrices),
        discountColumn,
        priceForListColumn(currency),
        subtotalColumn(currency),
        quantityColumn(setCombinationQuantity),
        buttonsColumn,
      ];
    }

    return [
      imageWithTitleAndPriceRangeColumn(currency, hidePrices),
      subtotalColumn(currency),
      quantityColumn(setCombinationQuantity),
      buttonsColumn,
    ];
  }, [isMobile, currency, setCombinationQuantity, priceList, hidePrices]);

  return (
    <Row className="g-2">
      <Col xs={12}>
        <Row className="mb-2 g-2">
          <Col xs={6}>
            <SearchParamsSearchBar />
          </Col>
          {isMobile ? (
            <Col className="d-flex align-items-center justify-content-end">
              <MobileFilter />
            </Col>
          ) : (
            <React.Fragment>
              <Col className="d-flex align-items-center justify-content-center">
                <CountDisplay />
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <SortBy />
              </Col>
            </React.Fragment>
          )}
        </Row>
        <Table
          currency={currency}
          setCombinationQuantity={setCombinationQuantity}
          columns={columns}
          enableSelection={!isMobile}
          ExpandedRowContentComponent={RegularExpandedRowContent}
        />
      </Col>
      <Paginator perPage={72} />
    </Row>
  );
};

export default CartTable;
