import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {
  UserPurchaseOrderItemsTable,
  ShopProductsContainer,
} from '../../components/ShopProduct';
import {useApi} from '../../contexts/ApiContext';

const StoreUserPurchaseOrderItemsPage = () => {
  const [formattedCode, setFormattedCode] = useState();
  const {t} = useTranslation('pages');
  const api = useApi();
  const params = useParams();

  const purchaseOrderId = params.id;

  useEffect(() => {
    const fetchFormattedCode = async () => {
      const response = await api.get(
        `/purchase-order-helper/${purchaseOrderId}/formatted-code`,
      );

      setFormattedCode(response.formatted_code);
    };

    fetchFormattedCode();
  }, [api, purchaseOrderId]);

  return (
    <div className="mt-2 px-1 px-lg-4">
      <h1>
        <Icon icon="fas fa-bag-shopping" />{' '}
        {t('StoreUserPurchaseOrderItemsPage.title', {
          formatted_code: formattedCode,
        })}
      </h1>
      <ShopProductsContainer
        productsEndpoint={`/purchase-order/${purchaseOrderId}/item/`}
      >
        <UserPurchaseOrderItemsTable />
      </ShopProductsContainer>
    </div>
  );
};

export default StoreUserPurchaseOrderItemsPage;
