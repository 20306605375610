/* eslint react/jsx-props-no-spreading: off, no-console: off */
import React, {useRef, useMemo, useState, useCallback} from 'react';
import {
  Row,
  Col,
  Pagination,
  Form,
  Dropdown,
  Button,
  Spinner,
} from 'react-bootstrap';
import {Formik, useField} from 'formik';
import {useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import {useDataTable} from './store';
import SearchBar from '../SearchBar';
import useDebounce from '../../hooks/useDebounce';
import {FILTERABLE_TYPES} from './utils';
import CheckField from '../FormFields/CheckField';
import 'react-datepicker/dist/react-datepicker.css';
import {formatDateISO, scrollToRef} from '../../utils';

import styles from './DataTable.module.scss';

const PER_PAGE_CHOICES = [25, 50, 75, 100];

const FilterButton = React.forwardRef(({onClick}, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className={styles.filterButton}
  >
    <Icon icon="fa-solid fa-filter" /> Filtros
  </Button>
));

const ChoiceFilterable = ({filterable}) => (
  <div>
    <div>
      <h6 className="dropdown-header">{filterable.label}</h6>
    </div>
    <div className={styles.choiceFilterable}>
      {filterable.choices.map((choice) => (
        <div key={choice.value} className="dropdown-item">
          <CheckField
            name={filterable.field}
            label={choice.label}
            value={choice.value}
          />
        </div>
      ))}
    </div>
  </div>
);

const DateRangeFilterable = ({filterable}) => {
  const [fieldProps, , helperProps] = useField(filterable.field);
  const startDate = useMemo(() => fieldProps.value.from, [fieldProps]);
  const endDate = useMemo(() => fieldProps.value.to, [fieldProps]);

  const setDateRangeValue = useCallback(
    (from, to) => helperProps.setValue({from, to}),
    [helperProps],
  );

  const setStartDate = useCallback(
    (from) => setDateRangeValue(from, endDate),
    [endDate, setDateRangeValue],
  );

  const setEndDate = useCallback(
    (to) => setDateRangeValue(startDate, to),
    [startDate, setDateRangeValue],
  );

  return (
    <div>
      <div>
        <h6 className="dropdown-header">{filterable.label}</h6>
      </div>
      <div className="mx-1">
        <div className="dropdown-item">
          <p className="mb-0">Desde:</p>
          <DatePicker
            selected={startDate}
            onChange={setStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            isClearable={startDate !== null}
            placeholderText="Seleccione fecha de inicio"
          />
        </div>
        <div className="dropdown-item">
          <p className="mb-0">Hasta:</p>
          <DatePicker
            selected={endDate}
            onChange={setEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            isClearable={endDate !== null}
            placeholderText="Seleccione fecha de fin"
          />
        </div>
      </div>
    </div>
  );
};

const FilterablesFields = ({filterables}) => (
  <div className="d-flex flex-row">
    {filterables.map((filterable) => {
      switch (filterable.type) {
        case FILTERABLE_TYPES.CHOICES:
          return (
            <ChoiceFilterable key={filterable.field} filterable={filterable} />
          );

        case FILTERABLE_TYPES.DATERANGE:
          return (
            <DateRangeFilterable
              key={filterable.field}
              filterable={filterable}
            />
          );

        default:
          console.error('Invalid filterable type');
          return null;
      }
    })}
  </div>
);

const DataTableFilter = ({gotoPage}) => {
  const ref = useRef();
  const [state, actions] = useDataTable();

  const [queryParams] = useSearchParams();

  const filters = useMemo(
    () => new URLSearchParams(queryParams.get('filters')),
    [queryParams],
  );

  const initialValues = useMemo(
    () =>
      (state.filterables || []).reduce((accum, filterable) => {
        switch (filterable.type) {
          case FILTERABLE_TYPES.CHOICES: {
            if (
              state.filters[filterable.field] &&
              Object.entries(state.filters).length > 0
            ) {
              accum[filterable.field] = [...state.filters[filterable.field]];
            } else {
              accum[filterable.field] = [];
            }
            break;
          }

          case FILTERABLE_TYPES.DATERANGE:
            accum[filterable.field] = {
              from: filters.has('submitted_on_from')
                ? new Date(filters.get('submitted_on_from'))
                : null,
              to: filters.has('submitted_on_to')
                ? new Date(filters.get('submitted_on_to'))
                : null,
            };
            break;

          default:
            console.error('Invalid filterable type');
            break;
        }
        return accum;
      }, {}),
    [state.filterables, state.filters, filters],
  );

  return state.filterables.length > 0 ? (
    <Dropdown ref={ref} drop="start" className={styles.filterDropdown}>
      <Dropdown.Toggle as={FilterButton} />
      <Dropdown.Menu>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            const newValues = Object.entries(values).reduce(
              (accum, [field, value]) => {
                const {type} = state.filterables.find(
                  (filterable) => filterable.field === field,
                );
                switch (type) {
                  case FILTERABLE_TYPES.CHOICES:
                    // Only submit the filter if at least a choice is selected.
                    if (value.length > 0) {
                      accum[field] = value;
                    }
                    break;

                  case FILTERABLE_TYPES.DATERANGE:
                    {
                      const {from, to} = value;
                      // Only submit the filters that don't have a null value.
                      if (from !== null) {
                        accum[`${field}_from`] = formatDateISO(from);
                      }
                      if (to !== null) {
                        accum[`${field}_to`] = formatDateISO(to);
                      }
                    }
                    break;

                  default:
                    console.error('Invalid filterable type');
                    break;
                }
                return accum;
              },
              {},
            );
            gotoPage(0);
            actions.setFilters(newValues);
            ref.current.click();
          }}
        >
          {(form) => (
            <Form
              className="d-flex flex-column"
              noValidate
              onSubmit={form.handleSubmit}
            >
              <FilterablesFields filterables={state.filterables} />
              <hr className="dropdown-divider" />
              <div className="d-flex justify-content-center">
                <Button type="submit">Aplicar</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

const DataTableView = ({
  isLoading,
  isInitialized,
  allowSelection,
  allowSearch,
  searchPlaceholder,
  allowOrder,
  orderChoices,
  getTableProps,
  getTableBodyProps,
  headerGroup,
  page,
  prepareRow,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  totalItems,
  scrollToTop = false,
}) => {
  const [queryParams] = useSearchParams();
  const [state, actions] = useDataTable();
  const [searchQuery, setSearchQuery] = useState(
    queryParams.get('search') || '',
  );
  const [ordering, setOrdering] = useState(queryParams.get('ordering') || '');

  const scrollElement = useRef(null);

  const handleSearch = useCallback(
    () => allowSearch && actions.setSearchQuery(searchQuery),
    [searchQuery, actions, allowSearch],
  );

  const handleOrder = useCallback(
    () => allowOrder && actions.setOrdering(ordering),
    [actions, ordering, allowOrder],
  );

  useDebounce(handleSearch, 800);
  useDebounce(handleOrder, 800);

  return !isInitialized ? (
    <Spinner className="d-block mx-auto" animation="border" variant="primary" />
  ) : (
    <div className={`pt-1 ${styles.container}`}>
      <div className={`${styles.overlay} ${isLoading ? styles.loading : ''}`} />
      <Row>
        <Col lg={5} className="d-flex">
          {state.title && <div className="flex-grow-1">{state.title}</div>}
          {allowSearch && (
            <div className={`me-2 ${!state.title && 'w-100'}`}>
              <SearchBar
                size="sm"
                value={searchQuery}
                setValue={(value) => {
                  gotoPage(0);
                  setSearchQuery(value);
                }}
                placeholder={searchPlaceholder}
              />
            </div>
          )}
        </Col>
        <Col
          lg={7}
          className={styles.tableInteractionButtons}
          ref={scrollElement}
        >
          {allowOrder && (
            <div className={styles.orderingFilter}>
              <p>
                Ordenar por
                <select
                  value={ordering}
                  onChange={(e) => {
                    setOrdering(e.target.value);
                    gotoPage(0);
                  }}
                >
                  <option disabled value="">
                    {' '}
                  </option>
                  {orderChoices.map((order) => (
                    <option key={order.value} value={order.value}>
                      {order.label}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          )}
          <div className={styles.showingResults}>
            <p>
              <span>{totalItems || state.data.length}</span> resultados
            </p>
          </div>
          <div>
            <Form.Select
              style={{backgroundColor: '#fff'}}
              value={queryParams.get('per_page') || pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                gotoPage(0);
              }}
            >
              {PER_PAGE_CHOICES.map((perPage) => (
                <option
                  key={perPage}
                  value={perPage}
                  disabled={pageSize === perPage}
                >
                  {perPage}
                </option>
              ))}
            </Form.Select>
          </div>
          <div>
            <DataTableFilter gotoPage={gotoPage} />
          </div>
        </Col>
      </Row>
      <div className="table-responsive">
        <table
          className={`${styles.table} ${
            allowSelection ? styles.selectable : ''
          } table table-striped`}
          {...getTableProps()}
        >
          <thead>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
            {!page.length && (
              <tr>
                <td className="table-info" colSpan={headerGroup.headers.length}>
                  No se encontraron resultados
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <Pagination className="justify-content-center mb-0">
          <Pagination.First
            onClick={() => {
              gotoPage(0);
              if (scrollToTop) {
                scrollToRef(scrollElement);
              }
            }}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => {
              previousPage();
              if (scrollToTop) {
                scrollToRef(scrollElement);
              }
            }}
            disabled={!canPreviousPage}
          />
          <Pagination.Item disabled>{`Página ${
            pageIndex + 1
          } de ${pageCount}`}</Pagination.Item>
          <Pagination.Next
            onClick={() => {
              nextPage();
              if (scrollToTop) {
                scrollToRef(scrollElement);
              }
            }}
            disabled={!canNextPage}
          />
          <Pagination.Last
            onClick={() => {
              gotoPage(pageCount - 1);
              if (scrollToTop) {
                scrollToRef(scrollElement);
              }
            }}
            disabled={!canNextPage}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataTableView;
