import React, {useCallback, useRef} from 'react';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useDrag, useDrop} from 'react-dnd';
import {hasChildren} from './utils';
import styles from './DataTree.module.scss';

const NodeNavigation = ({node, isExpanded, toggleExpand}) => {
  if (!hasChildren(node)) {
    return null;
  }

  return (
    <Icon
      onClick={() => toggleExpand(node.id)}
      icon={`fa-solid ${isExpanded ? 'fa-minus-square' : 'fa-plus-square'}`}
      title={isExpanded ? 'Collapse' : 'Expand'}
    />
  );
};

const NodeContent = ({
  node,
  isSelected,
  handleSelect,
  disableCheckbox,
  children,
}) => {
  if (handleSelect === undefined) {
    return children;
  }

  if (disableCheckbox) {
    return (
      <button
        type="button"
        onClick={() => handleSelect(node.id)}
        className={`text-decoration-none ${isSelected ? 'active' : null}`}
      >
        {children}
      </button>
    );
  }

  return (
    <Form.Check className="form-check-inline" type="checkbox">
      <Form.Check.Input
        type="checkbox"
        onChange={() => handleSelect(node.id)}
        checked={isSelected}
      />
      <Form.Check.Label>{children}</Form.Check.Label>
    </Form.Check>
  );
};

const NodeActions = ({
  node,
  triggerCreateModal,
  triggerEditModal,
  triggerDeleteModal,
}) => (
  <React.Fragment>
    {triggerCreateModal && (
      <Icon
        title="Add"
        onClick={() => triggerCreateModal(node)}
        icon="fa-solid fa-plus"
      />
    )}
    {triggerEditModal && (
      <Icon
        title="Edit"
        onClick={() => triggerEditModal(node)}
        icon="fa-solid fa-pen-to-square"
      />
    )}
    {triggerDeleteModal && (
      <Icon
        title="Delete"
        onClick={() => triggerDeleteModal(node)}
        icon="fa-solid fa-trash"
      />
    )}
  </React.Fragment>
);

const Node = ({
  node,
  isExpanded,
  toggleExpand,
  isSelected,
  handleSelect,
  triggerCreateModal,
  triggerEditModal,
  triggerDeleteModal,
  handleSwap,
  disableCheckbox,
}) => {
  const ref = useRef(null);

  // Swap the current node with the one being dragged.
  const swapWithNode = useCallback(
    (item) => handleSwap(item.id, node.id),
    [node, handleSwap],
  );

  // Whether the given node can be dropped on the current one.
  const canDropNode = useCallback(
    (item) => {
      // Can't swap a node with itself.
      if (item.id === node.id) {
        return false;
      }

      // Can't swap non-sibling nodes.
      if (item.parent !== node.parent) {
        return false;
      }

      return true;
    },
    [node],
  );

  const [, drag, preview] = useDrag(() => ({
    type: 'node',
    item: {id: node.id, parent: node.parent},
  }));

  const [{isOver, canDrop}, drop] = useDrop(() => ({
    accept: 'node',
    drop: swapWithNode,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
    canDrop: canDropNode,
  }));

  const dropClass = canDrop
    ? styles['valid-drag-hover']
    : styles['invalid-drag-hover'];
  const hoverClass = handleSwap && isOver ? dropClass : null;

  return (
    <li>
      <span ref={handleSwap && preview(drop(ref))} className={hoverClass}>
        {handleSwap && (
          <span ref={drag}>
            <Icon
              icon="fa-solid fa-bars"
              title="Hold to reorder"
              className={styles.grabbable}
            />
          </span>
        )}
        <NodeNavigation
          node={node}
          isExpanded={isExpanded(node.id)}
          toggleExpand={toggleExpand}
        />
        <NodeContent
          node={node}
          isSelected={isSelected(node.id)}
          handleSelect={handleSelect}
          disableCheckbox={disableCheckbox}
        >
          {node.title}
        </NodeContent>
        <NodeActions
          node={node}
          triggerCreateModal={triggerCreateModal}
          triggerEditModal={triggerEditModal}
          triggerDeleteModal={triggerDeleteModal}
        />
      </span>
      {/* Children */}
      {hasChildren(node) && isExpanded(node.id) ? (
        <ul>
          {node.children.map((childNode) => (
            <Node
              key={childNode.id}
              node={childNode}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
              isSelected={isSelected}
              handleSelect={handleSelect}
              triggerCreateModal={triggerCreateModal}
              triggerEditModal={triggerEditModal}
              triggerDeleteModal={triggerDeleteModal}
              handleSwap={handleSwap}
              disableCheckbox={disableCheckbox}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default Node;
