import React from 'react';
import {useShopProductsContainer} from './Container';

import styles from './ShopProduct.module.scss';

const CountDisplay = () => {
  const {totalProducts} = useShopProductsContainer();

  return (
    <div className={styles.countProducts}>
      <p>
        <span>{totalProducts}</span> resultados
      </p>
    </div>
  );
};

export default CountDisplay;
