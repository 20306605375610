import * as Yup from 'yup';

// Determines whether a node has children or not.
export const hasChildren = (node) => node.children.length > 0;

// Traverses a tree in a preorder traversal.
const traverseTree = (node, func) => {
  func(node);
  node.children.forEach((child) => traverseTree(child, func));
};

// Returns a set with the ids of all the nodes in the given tree or sub-tree.
export const getTreeNodeIds = (node) => {
  const nodeIds = new Set();
  traverseTree(node, (someNode) => nodeIds.add(someNode.id));
  return nodeIds;
};

export const categoryFormValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
});
