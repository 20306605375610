import React, {useState} from 'react';
import {Formik} from 'formik';
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import CheckField from '../../components/FormFields/CheckField';

import styles from './ProductImportSchedule.module.scss';

const ProductImportSchedule = ({
  options,
  currentSchedule,
  handleSave,
  triggerNotifyModal,
}) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Card>
        <Card.Body>
          <Formik
            enableReinitialize
            initialValues={{schedule: currentSchedule.schedule}}
            onSubmit={() => {}}
          >
            {(formProps) => (
              <Form>
                <div className="d-flex justify-content-between">
                  <h1>Configuración del Importador de Productos</h1>
                  {currentSchedule.status !== 'SETUP' && (
                    <OverlayTrigger
                      placement="top"
                      className="align-items-center"
                      show={toggleTooltip}
                      onToggle={(nextShow) => setToggleTooltip(nextShow)}
                      overlay={
                        <Tooltip>
                          {currentSchedule.status === 'SUSPENDED_BAD_FORMAT' &&
                            'Cambio en formato de archivo'}
                          {currentSchedule.status ===
                            'SUSPENDED_ERROR_THRESHOLD' &&
                            'Muchos registros inválidos'}
                        </Tooltip>
                      }
                    >
                      <div className={styles.tooltip}>
                        <div className="fw-bold text-danger">
                          IMPORTADOR SUSPENDIDO
                        </div>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <Row className="d-flex">
                  <Card>
                    <Card.Body>
                      <div className="d-flex">
                        <div className="fs-5 fw-bold mb-0">
                          <div className="mb-1">
                            Su catálogo se obtiene del siguiente link:
                          </div>
                          <a
                            href={currentSchedule.file_url}
                            download
                            className="fw-bol d-flex justify-content-center text-decoration-none"
                          >
                            <div className="fs-6 fw-bold mb-0">
                              {currentSchedule.file_url}
                            </div>
                          </a>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Row>
                <Row className="d-flex pt-2">
                  <Card>
                    <Card.Body>
                      <div className="fs-5 fw-bold mb-1">
                        Su importador está configurado para iniciarse a las:
                      </div>
                      {options.map((option) => (
                        <CheckField
                          disabled={currentSchedule.status !== 'SETUP'}
                          key={option.value}
                          name="schedule"
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                    </Card.Body>
                  </Card>
                </Row>
                <div className="d-flex flex-row justify-content-between pt-3">
                  <Button
                    variant="primary"
                    className="rounded-pill"
                    onClick={() => navigate('history')}
                  >
                    Ver historial
                  </Button>
                  <div>
                    <Button className="me-2" onClick={triggerNotifyModal}>
                      Eliminar
                    </Button>
                    <Button
                      disabled={
                        currentSchedule.status !== 'SETUP' ||
                        formProps.values.schedule.length === 0 ||
                        formProps.values.schedule
                          .sort((v1, v2) => v1.value < v2.value)
                          .join('') ===
                          formProps.initialValues.schedule
                            .sort((v1, v2) => v1.value < v2.value)
                            .join('')
                      }
                      onClick={() =>
                        handleSave({schedule: formProps.values.schedule})
                      }
                    >
                      Guardar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductImportSchedule;
