import React, {useMemo} from 'react';
import {Column} from 'react-table';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {ShopProduct} from '../../types/ShopProduct';
import Table from './Table';
import Paginator from './Paginator';
import {
  defaultCodeColumn,
  imageWithTitleColumn,
  priceRangeColumn,
  imageWithTitleAndPriceRangeColumn,
  subtotalColumn,
  quantityColumnMobile,
  quantityColumn,
  expandRowToggleColumn,
} from './TableColumns';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import {RegularExpandedRowContent} from './ExpandedRow';
import {useSession} from '../../contexts/SessionContext';
import {customerAllowedToSeePrices} from '../../utils';

const UserPurchaseOrderItemsTable = () => {
  const [{settings}] = useEcommerce();
  const [, , selectors] = useSession();
  const {isMobile} = useDeviceWindow();
  const currency = settings.COMPANY_CURRENCY;
  const hidePrices = !customerAllowedToSeePrices(settings, selectors);

  const columns = useMemo<Column<ShopProduct>[]>(
    () =>
      isMobile
        ? [
            imageWithTitleAndPriceRangeColumn(currency, hidePrices),
            quantityColumnMobile(),
            expandRowToggleColumn(),
          ]
        : [
            imageWithTitleColumn,
            priceRangeColumn(currency, hidePrices),
            defaultCodeColumn,
            subtotalColumn(currency),
            quantityColumn(),
            expandRowToggleColumn(),
          ],
    [isMobile, currency, hidePrices],
  );

  return (
    <React.Fragment>
      <Table
        currency={currency}
        columns={columns}
        ExpandedRowContentComponent={RegularExpandedRowContent}
      />
      <Paginator perPage={72} />
    </React.Fragment>
  );
};

export default UserPurchaseOrderItemsTable;
