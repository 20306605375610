import * as React from 'react';

const NewProductSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={157}
    height={80}
    viewBox="0 0 117.75 60"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M2 1h115v58H2Zm0 0" />
      </clipPath>
      <clipPath id="b">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="c">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="d">
        <path d="M1 .063h116.5v59.87H1Zm0 0" />
      </clipPath>
      <clipPath id="e">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="f">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="g">
        <path d="M3 2h109v53H3Zm0 0" />
      </clipPath>
      <clipPath id="h">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="i">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="j">
        <path d="M13 24h7v20h-7Zm0 0" />
      </clipPath>
      <clipPath id="k">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="l">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="m">
        <path d="M20 22h16v21H20Zm0 0" />
      </clipPath>
      <clipPath id="n">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="o">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="p">
        <path d="M37 21h14v20H37Zm0 0" />
      </clipPath>
      <clipPath id="q">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="r">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="s">
        <path d="M52 19h14v21H52Zm0 0" />
      </clipPath>
      <clipPath id="t">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="u">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="v">
        <path d="M65 18h15v20H65Zm0 0" />
      </clipPath>
      <clipPath id="w">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="x">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="y">
        <path d="M81 16h15v20H81Zm0 0" />
      </clipPath>
      <clipPath id="z">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="A">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="B">
        <path d="M95 15h7v20h-7Zm0 0" />
      </clipPath>
      <clipPath id="C">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
      <clipPath id="D">
        <path d="M-1.883 8.348 115.453-1.5l4.453 53.063L2.57 61.41Zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <g clipPath="url(#c)">
          <path
            fill="white"
            d="M101.684 49.77c-1.75.246-3.664.253-5.52.253-1.371.004-2.664.012-3.777.118-15.801 1.496-31.813 3.496-47.297 5.425l-3.55.442c-6.33.793-12.88 1.61-19.321 2.351-.528.063-1.008.121-1.446.176-3.73.469-4.21.524-6.636-2.082a.75.75 0 0 0-.64-.238l-.505.058c-.043.004-.082 0-.164-.035a.479.479 0 0 1-.183-.113l-8-8.828C2.633 45.078 2.754 40.785 2.859 37c.04-1.254.07-2.438.024-3.488a54.466 54.466 0 0 0-.192-2.239c-.265-2.64-.953-9.656.204-10.566l8.988-7.066c.406-.317.785-.63 1.148-.934 1.664-1.371 2.86-2.363 4.965-2.664 5.297-.7 10.426-1.32 14.313-1.785 8.98-1.074 17.968-2.11 26.957-3.14l10.668-1.231c5.48-.633 10.96-1.27 16.433-1.938 2.832-.344 4.25-.52 6.77.317l11.691 3.879c2.383.785 2.547 1.101 2.867 2.293.07.265.293.48.567.539a.291.291 0 0 0 .101.015l2.555.68s.074.105.113.281c1.531 6.426 3.27 13.715 4.469 20.875.121.742.32 1.531.516 2.293.664 2.633 1.285 5.121-.485 6.652L105.84 48.2c-.903.785-2.262 1.297-4.145 1.57Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#d)">
      <g clipPath="url(#e)">
        <g clipPath="url(#f)">
          <path
            style={{fill: 'var(--bs-primary)'}}
            d="M116.246 30.7c-1.176-7.04-2.824-13.99-4.476-20.923-.082-.336-.286-.738-.657-.836-.875-.23-1.75-.464-2.625-.695-.02-.004-.035.004-.054-.004-.418-1.574-.942-2.012-3.36-2.816-3.902-1.29-7.797-2.586-11.699-3.875-2.676-.887-4.188-.707-7.102-.348-5.644.688-11.289 1.34-16.933 1.992-12.371 1.43-24.75 2.836-37.121 4.317-4.75.562-9.492 1.148-14.235 1.77-2.875.378-4.285 1.972-6.57 3.769-2.988 2.355-5.984 4.715-8.976 7.07-1.872 1.461-.415 11.055-.305 13.43.191 4.199-.969 11.031 1.957 14.258 2.664 2.941 5.328 5.886 8 8.828.129.14.277.238.426.297.16.074.343.125.562.101.168-.015.332-.043.5-.062 3.004 3.226 3.633 2.734 8.73 2.148 7.387-.855 14.77-1.789 22.153-2.71 15.98-1.997 31.96-3.997 47.996-5.516 4-.38 10.734.609 13.875-2.122 3.227-2.812 6.461-5.617 9.691-8.425 2.727-2.371.758-6.434.22-9.649Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#g)">
      <g clipPath="url(#h)">
        <g clipPath="url(#i)">
          <path
            style={{fill: 'var(--bs-secondary)'}}
            d="M4.816 45.418c-.421-.473-.113-4.332-.156-5.09-.11-1.93-.223-3.867-.332-5.797-.168-3.09-1.824-11.746.356-13.457 3.175-2.504 6.351-5 9.535-7.504 2.816-2.215 4.62-2.25 8.285-2.715 5.687-.722 11.383-1.398 17.074-2.066 12.36-1.453 24.723-2.851 37.082-4.297 2.543-.293 5.082-.597 7.625-.898 1.363-.164 4.742-1.239 5.969-.828 3.21 1.066 6.422 2.129 9.64 3.191 1.68.563 3.368 1.117 5.055 1.676 2.207.73 1.39 1.137 1.977 3.61 1.43 6.054 2.734 12.136 3.765 18.277.145.847 1.18 5.726 1.121 5.78-1.09.942-2.171 1.884-3.257 2.829-2.715 2.36-5.426 4.715-8.14 7.078-1.005.875-7.169.43-8.688.563-3.262.285-6.52.605-9.778.94-7.472.759-14.937 1.634-22.402 2.528-15.395 1.844-30.758 3.903-46.172 5.617-1.945-2.148-3.89-4.292-5.836-6.445l-2.703-2.984Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#j)">
      <g clipPath="url(#k)">
        <g clipPath="url(#l)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M17.824 26.621c.145 1.336-.61 2.207-1.93 2.348-1.37.148-2.25-.555-2.39-1.89-.14-1.317.574-2.114 1.945-2.259 1.324-.14 2.239.485 2.375 1.801Zm-2.422 16.813c-.32-.016-.46-.22-.488-.454-.055-.546.59-2.886-.129-11.664-.058-.57.14-.761 1.04-.953a4.12 4.12 0 0 1 .714-.125c.48-.054.707.04.852.461.68 2.028 1.226 4.649 1.605 7.504.215 1.356.348 2.57.469 3.934.023.453-.059.824-.7.945-.43.094-1.027.18-1.6.238-.65.067-1.278.11-1.763.121Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#m)">
      <g clipPath="url(#n)">
        <g clipPath="url(#o)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M23.121 42.508c-.363.012-.598-.106-.68-.438-.617-2.394-1.078-5.293-1.363-8.011-.445-4.149-.512-7.93-.226-9.168.085-.32.273-.582.609-.645.383-.094.809-.133 1.195-.18.434-.046.867-.07 1.16-.074.239-.02.446.102.668.344 1.457 1.578 4.332 5.547 7.223 10.98.066.137.164.176.258.16.144-.011.25-.167.226-.386-.668-6.223-2.308-10.445-2.398-11.3-.023-.267.102-.442.36-.5.382-.063.91-.15 1.46-.208.48-.05.961-.078 1.328-.09.34-.012.57.106.68.48.629 2.25 1.238 5.907 1.617 9.458.305 2.82.453 5.601.418 7.53-.02.485-.195.673-.695.774-.36.059-.957.149-1.508.207-.41.047-.797.067-1.058.067-.489-.02-.645-.102-.836-.559-2.149-4.21-4.446-7.949-6.461-10.676-.145-.203-.278-.285-.395-.27-.191.017-.289.25-.262.532.403 5.582 1.95 10.75 1.997 11.152.03.313-.149.446-.454.508-.336.059-.886.145-1.437.203-.527.055-1.035.086-1.422.102Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#p)">
      <g clipPath="url(#q)">
        <g clipPath="url(#r)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M46.703 21.508c.574-.059 1.129-.094 1.492-.082.27-.008.43.097.551.328.406.875 1.258 3.824 1.848 9.36.566 5.296-.516 8.816-5.578 9.35-4.371.47-6.797-2.042-7.403-7.702-.566-5.325-.199-8.64-.047-9.742.043-.297.243-.461.407-.504.359-.063.957-.153 1.53-.215a24.65 24.65 0 0 1 1.423-.102c.246 0 .433.149.437.442.02 1.054-.32 5.172.114 9.203.418 3.89 1.503 5.195 3.257 5.008 1.875-.2 2.473-1.641 2.055-5.532-.48-4.535-1.394-8.054-1.598-9.05-.054-.282-.027-.477.282-.56.304-.077.785-.151 1.242-.202Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#s)">
      <g clipPath="url(#t)">
        <g clipPath="url(#u)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M54.773 39.129c-.527.035-.828-.031-.91-.602a87.248 87.248 0 0 1-.457-3.57c-.453-4.246-.676-9.246-.222-13.441.054-.391.242-.649.578-.715.808-.156 2.488-.363 4.238-.543 1.969-.215 4.035-.406 5.223-.442.34-.007.476.122.492.313.031.312-.246 2.004-.496 2.851-.082.18-.188.286-.383.31-.14.01-.344-.04-.64-.102-.821-.157-2-.297-3.774-.106-.914.102-1.305.281-1.578.625-.38.574-.446 2.219-.305 3.527.059.551.273.746.754.696.84-.09 1.945-.617 3.527-.793 1.032-.11 1.871-.008 1.918.472.047.407-.144 1.825-.312 2.516-.067.297-.18.355-.375.379-.34.031-1.485-.25-2.711-.125-1.945.203-2.594.93-2.41 2.621.25 2.367.644 2.66 2.015 2.516 2.23-.243 3.957-.903 4.942-1.446.23-.12.316-.203.46-.215.141-.011.274.043.368.247.308.593.805 2.543.883 3.238.035.36-.18.597-.61.672-1.48.23-3.23.441-4.906.644-1.992.215-3.918.367-5.316.469Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#v)">
      <g clipPath="url(#w)">
        <g clipPath="url(#x)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M75.691 18.39c.41-.038.918-.046 1.282-.042.437 0 .644.125.847.66.586 1.676.961 3.347 1.145 5.02.59 5.535-1.059 10.562-4.434 13.257a.962.962 0 0 1-.554.227 1.146 1.146 0 0 1-.598-.106c-4.117-1.754-6.723-6.129-7.309-11.617-.199-1.863-.168-3.824.125-5.828.04-.32.22-.48.57-.566.383-.067.86-.137 1.317-.188a13.038 13.038 0 0 1 1.496-.082c.363-.016.527.133.55.375.044.406-.405 2.55-.007 6.25.313 2.914 1.277 5.586 2.984 7.02.157.105.286.183.41.171.094-.007.208-.093.313-.25 1.129-1.668 1.613-4.418 1.25-7.832-.394-3.695-1.195-5.254-1.25-5.777-.023-.266.106-.371.41-.457.453-.098.98-.176 1.457-.23Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#y)">
      <g clipPath="url(#z)">
        <g clipPath="url(#A)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M89.09 35.832c-5.23.559-7.184-2.465-7.621-6.543-.645-6.035 1.082-11.937 5.547-12.414 4.464-.477 7.394 4.93 8.039 10.941.437 4.102-.828 7.473-5.965 8.016Zm2.148-8.262c-.445-4.172-2.086-7.449-3.855-7.258-1.899.2-2.668 3.856-2.242 7.864.332 3.125 1.566 4.222 3.535 4.015 1.945-.203 2.894-1.539 2.562-4.613Zm0 0"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#B)">
      <g clipPath="url(#C)">
        <g clipPath="url(#D)">
          <path
            fill="var(--bs-secondary-contrast-color)"
            d="M99.879 15.934c.316.011.46.214.484.453.059.547-.59 2.883.13 11.66.062.574-.138.762-1.04.957a4.085 4.085 0 0 1-.71.125c-.481.05-.712-.047-.856-.465-.676-2.023-1.227-4.648-1.602-7.5a55.385 55.385 0 0 1-.469-3.934c-.027-.457.055-.824.7-.945.425-.094 1.023-.18 1.601-.242.645-.07 1.278-.105 1.758-.117Zm-2.426 16.812c-.14-1.34.617-2.21 1.934-2.351 1.367-.145 2.242.554 2.39 1.894.141 1.313-.578 2.11-1.949 2.258-1.32.14-2.234-.484-2.375-1.8Zm0 0"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default NewProductSVG;
