import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Col, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import {ShopProduct, ShopProductCategory} from '../../types/ShopProduct';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';

import styles from './StoreProduct.module.scss';

interface ExtraInfoItemsProps {
  title: string;
  info: string | number;
}

const ExtraInfoItems = ({title, info}: ExtraInfoItemsProps) => (
  <div>
    <Icon icon="square-check" />
    <p>
      <span>{`${title}:`}</span> {info}
    </p>
  </div>
);

interface CategoryTreeProps {
  categories: ShopProductCategory[];
}

const CategoryTree = ({categories}: CategoryTreeProps) => (
  <React.Fragment>
    {categories.map(
      (category) =>
        category.children && (
          <ul key={category.id}>
            <li>{category.title}</li>
            <li>
              <CategoryTree categories={category.children} />
            </li>
          </ul>
        ),
    )}
  </React.Fragment>
);

interface ProductDescriptionProps {
  product: ShopProduct;
}

const ProductDescription = ({product}: ProductDescriptionProps) => {
  const [show, setShow] = useState<boolean>(false);
  const {t} = useTranslation('pages');
  const {isMobile} = useDeviceWindow();

  return (
    <div className={styles.productDescription}>
      <p>
        {product.short_description}
        <OverlayTrigger
          show={show}
          trigger="click"
          placement={isMobile ? 'bottom' : 'right'}
          overlay={
            <Popover className={styles.popover}>
              <Row className="ms-0 me-0">
                <Col className={styles.title} xs={12}>
                  <h4>{product.name}</h4>
                  <button type="button" onClick={() => setShow(false)}>
                    <Icon icon="xmark" />
                  </button>
                </Col>
                <Col xs={12} className={styles.description}>
                  <p>{product.description}</p>
                </Col>
                <Col md={12} lg={6} className={styles.infoItems}>
                  {product.ref && (
                    <ExtraInfoItems
                      title={t(
                        'StoreProductPage.ProductDescription.extraInfoItem.sku',
                      )}
                      info={product.ref}
                    />
                  )}
                  {product.barcode && (
                    <ExtraInfoItems
                      title={t(
                        'StoreProductPage.ProductDescription.extraInfoItem.barcode',
                      )}
                      info={product.barcode}
                    />
                  )}
                  {product.width && (
                    <ExtraInfoItems
                      title={t(
                        'StoreProductPage.ProductDescription.extraInfoItem.width',
                      )}
                      info={Number(product.width)}
                    />
                  )}
                  {product.height && (
                    <ExtraInfoItems
                      title={t(
                        'StoreProductPage.ProductDescription.extraInfoItem.height',
                      )}
                      info={Number(product.height)}
                    />
                  )}
                  {product.brand && (
                    <ExtraInfoItems
                      title={t(
                        'StoreProductPage.ProductDescription.extraInfoItem.brand',
                      )}
                      info={product.brand}
                    />
                  )}
                  {Object.entries(product.custom_fields).length > 0
                    ? Object.entries(product.custom_fields)
                        .filter(([title]) => title !== '2bk_show')
                        .map(([title, info]) => (
                          <ExtraInfoItems
                            key={title}
                            title={title}
                            info={info}
                          />
                        ))
                    : null}
                </Col>
                {product.categories.length > 0 && (
                  <Col md={12} lg={6} className={styles.categories}>
                    <h6>
                      {t('StoreProductPage.ProductDescription.categoryTitle')}
                    </h6>
                    <CategoryTree categories={product.categories} />
                  </Col>
                )}
              </Row>
            </Popover>
          }
        >
          <button type="button" onClick={() => setShow(true)}>
            {t('StoreProductPage.ProductDescription.overlayButton')}
          </button>
        </OverlayTrigger>
      </p>
    </div>
  );
};

export default ProductDescription;
