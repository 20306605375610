import React, {useCallback, useMemo, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {useTable, useRowSelect, usePagination} from 'react-table';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import addSelectionColumn from './utils';
import {useDataTable} from './store';
import DataTableView from './DataTableView';

const LocalDataTable = ({
  // source,
  isLoading,
  isInitialized,
  setIsLoading,
  defaultPageSize,
  columns,
  buttons = [],
  allowSelection,
}) => {
  const Cell = useCallback(
    ({cell}) => (
      <React.Fragment>
        {buttons.map((button) => (
          <Button
            variant="link"
            onClick={async () => {
              await button.onClick(cell);
            }}
            key={button.key}
            disabled={
              button.isDisabledForCell ? button.isDisabledForCell(cell) : false
            }
          >
            <Icon icon={button.icon} />
          </Button>
        ))}
      </React.Fragment>
    ),
    [buttons],
  );

  const addButtonsColumn = useCallback(
    (hooks) => {
      if (buttons.length === 0) {
        return;
      }

      hooks.visibleColumns.push((col) => [
        ...col,
        {
          id: 'buttonsColumn',
          Header: ' ',
          Cell,
        },
      ]);
    },
    [Cell, buttons],
  );

  const [state] = useDataTable();

  const {
    pageCount,

    // common
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      // common
      columns,
      data: state.data,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
    },
    addSelectionColumn(allowSelection),
    addButtonsColumn,
    usePagination,
    useRowSelect,
  );

  // @HINT we don't suppor multiple groups
  const headerGroup = useMemo(() => headerGroups[0], [headerGroups]);

  useEffect(() => setIsLoading(false), [setIsLoading]);

  return (
    <DataTableView
      isLoading={isLoading}
      isInitialized={isInitialized}
      allowSelection={allowSelection}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroup={headerGroup}
      page={page}
      prepareRow={prepareRow}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageCount={pageCount}
      gotoPage={gotoPage}
      nextPage={nextPage}
      previousPage={previousPage}
      setPageSize={setPageSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
      scrollToTop
    />
  );
};

export default LocalDataTable;
