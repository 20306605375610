import React from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

const EditButtons = ({formProps, editing, setEditing, dark = false}) => (
  <div>
    {editing && (
      <Button
        onClick={() => {
          formProps.resetForm();
          setEditing(false);
        }}
        variant="secondary"
      >
        <Icon icon="fa-solid fa-xmark" />
      </Button>
    )}
    <Button
      className="ms-1"
      onClick={editing ? formProps.submitForm : () => setEditing(true)}
      variant="trasparent"
    >
      <Icon
        icon={`fa-regular ${editing ? 'fa-floppy-disk' : 'fa-pen-to-square'}`}
        className={dark ? `text-dark` : ``}
      />
    </Button>
  </div>
);

export default EditButtons;
