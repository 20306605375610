import React from 'react';
import {Container} from 'react-bootstrap';
import BuyButton from '../../components/BuyButton';
import {ShopProductsContainer} from '../../components/ShopProduct/Container';
import {ShopGridTable} from '../../components/ShopProduct';
import {useEcommerce} from '../../contexts/EcommerceContext';
import useSessionViewMode from '../../hooks/useSessionViewMode';

const StoreFavoritePage = () => {
  const [{uid}] = useEcommerce();

  const [favoritesSessionViewMode, setFavoritesSessionViewMode] =
    useSessionViewMode('favorites');

  return (
    <Container fluid className="px-1 px-lg-4 mt-2">
      <ShopProductsContainer productsEndpoint={`/blink/${uid}/favorites/`}>
        <ShopGridTable
          refreshAfterFavorite
          scrollToTop
          sessionViewMode={favoritesSessionViewMode}
          setSessionViewMode={setFavoritesSessionViewMode}
        />
      </ShopProductsContainer>
      <BuyButton />
    </Container>
  );
};

export default StoreFavoritePage;
