/* eslint react/no-array-index-key: off */
import React, {useCallback, useMemo, useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {Row, Col, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import Layoutbuttons from '../../layouts/utils';
import {useApi} from '../../contexts/ApiContext';
import styles from './StepConfigureColumns.module.scss';
import CheckField from '../../components/FormFields/CheckField';
import {FormModal} from '../../components/Modals';
import i18n from '../../translations/i18n';

const FIELD = 'field';

const PRODUCT_FIELDS_LABELS = {
  name: i18n.t('pages:ProductImportPage.ProductColumns.name'),
  short_description: i18n.t(
    'pages:ProductImportPage.ProductColumns.short_description',
  ),
  description: i18n.t('pages:ProductImportPage.ProductColumns.description'),
  default_code: i18n.t('pages:ProductImportPage.ProductColumns.default_code'),
  default_price: i18n.t('pages:ProductImportPage.ProductColumns.default_price'),
  offer_price: i18n.t('pages:ProductImportPage.ProductColumns.offer_price'),
  barcode: i18n.t('pages:ProductImportPage.ProductColumns.barcode'),
  brand: i18n.t('pages:ProductImportPage.ProductColumns.brand'),
  inventory: i18n.t('pages:ProductImportPage.ProductColumns.inventory'),
  tags: i18n.t('pages:ProductImportPage.ProductColumns.tags'),
  width: i18n.t('pages:ProductImportPage.ProductColumns.width'),
  height: i18n.t('pages:ProductImportPage.ProductColumns.height'),
  custom_fields: i18n.t('pages:ProductImportPage.ProductColumns.custom_fields'),
  volume_discounts: i18n.t(
    'pages:ProductImportPage.ProductColumns.volume_discounts',
  ),
  categories: i18n.t('pages:ProductImportPage.ProductColumns.categories'),
  packs: i18n.t('pages:ProductImportPage.ProductColumns.packs'),
  combinations: i18n.t('pages:ProductImportPage.ProductColumns.combinations'),
  images: i18n.t('pages:ProductImportPage.ProductColumns.images'),
  video: i18n.t('pages:ProductImportPage.ProductColumns.video'),
  variants: i18n.t('pages:ProductImportPage.ProductColumns.variants'),
  price_lists: i18n.t('pages:ProductImportPage.ProductColumns.price_lists'),
};

const ImportTypeForm = ({choices}) => (
  <Row className="position-relative">
    {choices?.map(({value, label, description}, idx) => (
      <Col className="position-relative" key={idx} xs={12}>
        <CheckField
          name="import_type"
          label={
            <div>
              <p className="fw-bold mb-0">{label}</p>
              <p>{description}</p>
            </div>
          }
          value={value}
          type="radio"
        />
      </Col>
    ))}
  </Row>
);

const FieldDrag = ({fileField, addFileFieldToMapping}) => {
  const [{className}, drag] = useDrag(
    () => ({
      type: FIELD,
      item: {fileField},
      end: (item, monitor) => {
        const result = monitor.getDropResult();
        if (result) {
          addFileFieldToMapping(item.fileField, result.mappingIndex);
        }
      },
      collect: (monitor) => ({
        className: monitor.isDragging() ? styles.dragging : '',
      }),
    }),
    [],
  );

  return (
    <div className={`${styles.fieldItem} ${className} text-reset`} ref={drag}>
      {fileField}
    </div>
  );
};

const FieldDrop = ({
  mappingIndex,
  required,
  label,
  fileFields,
  clearMapping,
}) => {
  const [, drop] = useDrop(() => ({
    accept: FIELD,
    drop: () => ({
      mappingIndex,
    }),
  }));

  return (
    <div
      ref={drop}
      className={`${styles.fieldItem} ${required ? styles.highlight : ''} ${
        fileFields.length !== 0 ? styles.matched : ''
      } text-reset`}
    >
      {fileFields.length > 0 && (
        <Icon
          onClick={() => clearMapping(mappingIndex)}
          icon="fa-solid fa-times-circle"
        />
      )}
      {fileFields.length > 0 ? (
        <div>
          {fileFields.join(', ')}
          &nbsp;
          <Icon icon="fa-solid fa-arrow-right-long" />
          &nbsp;
          {label}
        </div>
      ) : (
        <div>{label}</div>
      )}
    </div>
  );
};

const StepConfigureColumns = ({actions, data, setData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    type: null,
    initialValues: null,
    title: null,
    body: null,
    onSubmit: null,
  });
  const api = useApi();

  const {t} = useTranslation('pages');

  const IMPORT_TYPE_CHOICES = useMemo(
    () => [
      {
        value: 'update',
        label: t('ProductImportPage.ProductImportType.updateLabel'),
        description: t('ProductImportPage.ProductImportType.updateDescription'),
      },
      {
        value: 'replace',
        label: t('ProductImportPage.ProductImportType.replaceLabel'),
        description: t(
          'ProductImportPage.ProductImportType.replaceDescription',
        ),
      },
    ],
    [t],
  );

  const handleNext = useCallback(
    async (formValues) => {
      setIsLoading(true);
      try {
        const response = await api.put(`/product-import/${data.id}`, {
          fields_mapping: data.fields_mapping,
          import_type: formValues.import_type,
        });

        setData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setIsLoading(false);
        actions.handleNext();
      } catch (e) {
        setIsLoading(false);
        if (e.data?.fields_mapping) {
          e.data?.fields_mapping.forEach((error) => {
            if (error.file_fields) {
              error.file_fields.forEach((errorMessage) =>
                toast.error(errorMessage, {hideProgressBar: true}),
              );
            }
          });
        }
      }
    },
    [api, data, setData, actions],
  );

  const clearModalState = useCallback(
    () =>
      setModalState({
        type: null,
        initialValues: null,
        title: null,
        body: null,
        onSubmit: null,
      }),
    [setModalState],
  );

  const chooseImportType = useCallback(() => {
    setModalState({
      type: 'form',
      title: t('ProductImportPage.ProductImportType.choose'),
      initialValues: {import_type: 'update'},
      body: <ImportTypeForm choices={IMPORT_TYPE_CHOICES} />,
      onSubmit: handleNext,
    });
  }, [setModalState, handleNext, IMPORT_TYPE_CHOICES, t]);

  const fieldsMapping = data.fields_mapping;
  const fieldsMetadata = data.fields_metadata;
  const fieldsNames = data.fields_names;

  // Callback for adding a file field to the product field import mapping.
  const addFileFieldToMapping = useCallback(
    (fileField, mappingIndex) =>
      setData((prevState) => {
        const newState = {...prevState};
        newState.fields_mapping = [...prevState.fields_mapping];
        newState.fields_mapping[mappingIndex] = {
          ...newState.fields_mapping[mappingIndex],
        };

        const productFieldName = newState.fields_mapping[mappingIndex].name;
        const maxFileFields = fieldsMetadata[productFieldName].max_file_fields;
        if (maxFileFields > 1) {
          // If the product field supports more than a single field, we add as much as we can
          // and don't add any more if the max number is reached.
          const newFileFields = new Set(
            newState.fields_mapping[mappingIndex].file_fields,
          );
          if (newFileFields.size < maxFileFields) {
            newFileFields.add(fileField);
          }
          newState.fields_mapping[mappingIndex].file_fields = [
            ...newFileFields,
          ];
        } else {
          // If the product field supports only a single field, we replace it.
          newState.fields_mapping[mappingIndex].file_fields = [fileField];
        }

        return newState;
      }),
    [fieldsMetadata, setData],
  );

  // Callback for clearing the import mapping for a product field.
  const clearMapping = useCallback(
    (mappingIndex) =>
      setData((prevState) => {
        const newState = {...prevState};
        newState.fields_mapping = [...prevState.fields_mapping];
        newState.fields_mapping[mappingIndex] = {
          ...newState.fields_mapping[mappingIndex],
          file_fields: [],
        };
        return newState;
      }),
    [setData],
  );

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <h4 className="text-primary fw-bold">
          {t('ProductImportPage.ProductImport.Process')}
          <br />
          {t('ProductImportPage.ProductImport.Wait')}
        </h4>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Row className="text-center mb-1">
        <Col>
          <strong>{t('ProductImportPage.ProductMapping.fileColumns')}</strong>
        </Col>
        <Col>
          <strong>{t('ProductImportPage.ProductMapping.blFields')}</strong>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <div className={`${styles.fieldsCol} ${styles.fieldsColFrom}`}>
            {fieldsNames.map((fileField, index) => (
              <FieldDrag
                key={index}
                fileField={fileField}
                addFileFieldToMapping={addFileFieldToMapping}
              />
            ))}
          </div>
        </Col>
        <Col>
          <div className={`${styles.fieldsCol} ${styles.fieldsColTo}`}>
            {fieldsMapping.map((field, index) => (
              <FieldDrop
                key={index}
                mappingIndex={index}
                required={fieldsMetadata[field.name].required}
                label={PRODUCT_FIELDS_LABELS[field.name]}
                fileFields={field.file_fields}
                clearMapping={clearMapping}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Layoutbuttons
        handleBack={actions.handleBack}
        handleNext={chooseImportType}
      />
      <FormModal
        show={modalState.type === 'form'}
        closeModal={clearModalState}
        title={modalState.title}
        initialValues={modalState.initialValues}
        body={modalState.body}
        onSubmit={modalState.onSubmit}
      />
    </React.Fragment>
  );
};

export default StepConfigureColumns;
