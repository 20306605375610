import React from 'react';

const DataTableContext = React.createContext();

const types = {
  DATA_TABLE_SET_FILTERS: 'DATA_TABLE_SET_FILTERS',
  DATA_TABLE_SET_DATA: 'DATA_TABLE_SET_DATA',
  DATA_TABLE_SET_SEARCH: 'DATA_TABLE_SET_SEARCH',
  DATA_TABLE_SET_ORDER: 'DATA_TABLE_SET_ORDER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.DATA_TABLE_SET_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters,
      };
    }
    case types.DATA_TABLE_SET_DATA: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case types.DATA_TABLE_SET_SEARCH: {
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };
    }
    case types.DATA_TABLE_SET_ORDER: {
      return {
        ...state,
        ordering: action.payload.ordering,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useDataTable = () => {
  const context = React.useContext(DataTableContext);
  if (context === undefined) {
    throw new Error('`useDataTable` must be used within a `DataTableContext`.');
  }
  return context;
};

export {reducer, types, DataTableContext, useDataTable};
