import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import DataTree from '../DataTree';

import styles from './SearchParams.module.scss';

// Reads a comma separated string into an array of numbers (not NaN).
export const fromSearchParam = (val: string) => {
  const stringIds = val.split(',').map((untrimmedId) => untrimmedId.trim());
  const ids = stringIds.map((stringId) => parseInt(stringId, 10));
  return ids.filter((id) => !Number.isNaN(id));
};

// Turns an array of numbers into a comma separated string suitable for a URL search parameter.
const toSearchParam = (ids: number[]) => ids.join(',');

interface DeleteFiltersButtonProps {
  selectedCategories: Set<number>;
}

const DeleteFiltersButton = ({selectedCategories}: DeleteFiltersButtonProps) => {
  const [, setSearchParams] = useSearchParams();
  const {t} = useTranslation('components');

  const deleteFilters = useCallback(
    () =>
      setSearchParams((prevParams) => {
        prevParams.delete('categories');
        return prevParams;
      }),
    [setSearchParams],
  );

  return (
    <button type="button" onClick={() => deleteFilters()} disabled={selectedCategories.size === 0}>
      {t('SearchParamsCategorySelector.DeleteFiltersButton')}
    </button>
  );
};

const SearchParamsCategorySelector = () => {
  const [state] = useEcommerce();
  const [searchParams, setSearchParams] = useSearchParams();
  const {isMobile} = useDeviceWindow();
  const {t} = useTranslation('components');

  const categories = searchParams.get('categories') || '';
  const selectedCategories = useMemo(() => new Set(fromSearchParam(categories)), [categories]);

  const initialExpandedNodes = useCallback(
    (selectedCategoryId: number[], accum: Set<number>) => {
      selectedCategoryId.forEach((selectedCategory: number) => {
        const category = state.categories?.list.find((element) => element.id === selectedCategory);

        if (category?.parent) {
          accum.add(category.parent);
          initialExpandedNodes([category.parent], accum);
        }
      });

      return accum;
    },
    [state.categories],
  );

  const toggleSelection = useCallback(
    (nodeId: number) => {
      const newSelectedCategories = new Set(selectedCategories);
      if (newSelectedCategories.has(nodeId)) {
        newSelectedCategories.delete(nodeId);
      } else {
        newSelectedCategories.clear();
        newSelectedCategories.add(nodeId);
      }

      const newSearchParamCategories = toSearchParam([...newSelectedCategories]);
      setSearchParams((prevParams) => {
        if (newSearchParamCategories === '') {
          prevParams.delete('categories');
        } else {
          prevParams.set('categories', newSearchParamCategories);
          prevParams.delete('page');
        }
        return prevParams;
      });
    },
    [selectedCategories, setSearchParams],
  );

  if (state.categories?.tree.length === 0) {
    return null;
  }

  return (
    <div className={styles.quickFilter}>
      {isMobile ? (
        <h5>{t('SearchParamsCategorySelector.title')}</h5>
      ) : (
        <h4>{t('SearchParamsCategorySelector.title')}</h4>
      )}
      <div className="d-flex justify-content-between align-items-center pb-1">
        <DeleteFiltersButton selectedCategories={selectedCategories} />
      </div>
      {/* @ts-ignore beacuse of optional / undefined props in DataTree component */}
      <DataTree
        data={state.categories?.tree}
        selectedData={selectedCategories}
        handleSelect={toggleSelection}
        disableCheckbox
        initialExpandedNodes={initialExpandedNodes([...selectedCategories], new Set())}
      />
    </div>
  );
};

export default SearchParamsCategorySelector;
