import React from 'react';
import {Card} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {LinkContainer} from 'react-router-bootstrap';
import styles from './CallToAction.module.scss';

const CallToActionProductsAdd = () => (
  <LinkContainer to="/admin/products/import">
    <Card bg="dark" text="light" className={styles.card}>
      <Card.Body>
        <Card.Text>
          <Icon icon="fa-solid fa-file-arrow-up" className="text-secondary" />
          <span className="fs-3 fw-bold mb-0">+ Importar productos</span>
          <span className="fs-5 mb-0">Acción masiva</span>
        </Card.Text>
      </Card.Body>
    </Card>
  </LinkContainer>
);

export default CallToActionProductsAdd;
