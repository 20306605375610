import React, {RefObject, useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {scrollToRef} from '../../utils';
import Paginator from '../Paginator';

interface SearchParamsPaginatorProps {
  totalItems: number;
  perPage: number;
  scrollElement?: RefObject<HTMLElement> | null;
}
const SearchParamsPaginator = ({
  totalItems,
  perPage,
  scrollElement,
}: SearchParamsPaginatorProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page') || '1');

  const setPage = useCallback(
    (newValue: number) => {
      setSearchParams((prevParams) => {
        if (newValue === 1) {
          prevParams.delete('page');
        } else {
          prevParams.set('page', String(newValue));
        }
        return prevParams;
      });
      if (scrollElement) {
        scrollToRef(scrollElement);
      }
    },
    [setSearchParams, scrollElement],
  );

  return (
    <Paginator
      page={page}
      perPage={perPage}
      totalItems={totalItems}
      setPage={setPage}
    />
  );
};

export default SearchParamsPaginator;
