import React, {useMemo} from 'react';
import {Route, Routes, useNavigate} from 'react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useLayoutTitle} from '../../hooks/layout';
import DataTable from '../../components/DataTable';
import PurchaseOrdersFormPage from '../PurchaseOrdersFormPage';
import {asMap, formatDate, formatMoney} from '../../utils';
import {PURCHASE_ORDER_STATUSES} from '../../resources';
import {FILTERABLE_TYPES} from '../../components/DataTable/utils';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';

import styles from './PurchaseOrdersPage.module.scss';

const ORDER_STATUS = asMap(PURCHASE_ORDER_STATUSES, ([value]) => value);

const StatusCell = React.memo(({cell}) => (
  <span className={`${styles.cellStatus} ${styles[cell.value]}`}>
    <Icon icon="fa-solid fa-circle" />
    &nbsp;
    {ORDER_STATUS.get(cell.value)[1]}
  </span>
));

const PurchaseOrdersIndexPage = () => {
  const [utilsState] = useUtils();
  const navigate = useNavigate();
  const api = useApi();

  useLayoutTitle({
    label: 'Órdenes de compra',
    icon: 'fa-solid fa-bag-shopping',
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Código',
        accessor: 'code',
      },
      {
        Header: 'Enviada',
        accessor: (originalRow) =>
          originalRow.status === 'draft' ? '-' : formatDate(originalRow.submitted_on),
      },
      {
        Header: 'Nombre',
        accessor: (originalRow) =>
          originalRow.status === 'draft' ? '-' : originalRow.customer_name,
      },
      {
        Header: 'Empresa',
        accessor: (originalRow) =>
          originalRow.status === 'draft' ? '-' : originalRow.customer_company_name,
      },
      {
        Header: 'Total',
        accessor: (originalRow) =>
          formatMoney(originalRow.total, utilsState.settings?.COMPANY_CURRENCY),
      },
      {
        Header: 'Estado',
        Cell: StatusCell,
        accessor: 'status',
      },
    ],
    [utilsState],
  );

  const filterables = React.useMemo(
    () => [
      {
        field: 'status',
        label: 'Estado',
        type: FILTERABLE_TYPES.CHOICES,
        choices: PURCHASE_ORDER_STATUSES.map(([value, label]) => ({
          value,
          label,
        })),
      },
      {
        field: 'submitted_on',
        label: 'Enviada',
        type: FILTERABLE_TYPES.DATERANGE,
      },
    ],
    [],
  );

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {
      status: ['new'],
    };
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-eye',
        onClick: (cell) => navigate(`/admin/purchase-orders/edit/${cell.row.original.id}/`),
        key: 'edit',
      },
      {
        icon: 'fa-solid fa-download',
        onClick: (cell) => {
          const purchaseOrderId = cell.row.original.id;
          api.get(`/purchase-order/${purchaseOrderId}/download/`, {as: 'csv'});
        },
        key: 'download',
      },
      {
        icon: 'fa-solid fa-print',
        onClick: (cell) => {
          const purchaseOrderId = cell.row.original.id;
          api.get(`/purchase-order/${purchaseOrderId}/download/`, {as: 'pdf'});
        },
        key: 'print',
      },
      {
        icon: 'fa-brands fa-whatsapp',
        onClick: (cell) => {
          if (cell.row.original.customer_phone) {
            window.open(
              `https://api.whatsapp.com/send?phone=${cell.row.original.customer_phone}`,
              '_blank',
            );
          }
        },
        isDisabledForCell: (cell) => !cell.row.original.customer_phone,
        key: 'whatsapp',
      },
    ],
    [api, navigate],
  );

  const source = React.useMemo(
    () => ({
      type: 'remote',
      url: '/purchase-order',
    }),
    [],
  );

  return (
    <DataTable
      buttons={buttons}
      filterables={filterables}
      initialFilters={initialFilters}
      columns={columns}
      source={source}
      allowSelection={false}
      allowSearch
      searchPlaceholder="Busca órdenes de compra..."
    />
  );
};

const PurchaseOrdersPage = () => (
  <Routes>
    <Route path="/edit/:id" element={<PurchaseOrdersFormPage />} />
    <Route index element={<PurchaseOrdersIndexPage />} />
  </Routes>
);

export default PurchaseOrdersPage;
