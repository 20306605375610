import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {ObjectShape} from 'yup/lib/object';
import {useApi} from '../../contexts/ApiContext';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import RegisterForm from './RegisterForm';
import Woman from '../../assets/Woman';
import Man from '../../assets/Man';

import styles from './StoreRegisterUserPage.module.scss';

const StoreRegisterUserPage = () => {
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    user_phone: '',
    email: '',
    user_company_name: '',
    user_company_tax_id: '',
    user_shipping_address: '',
    custom_fields: {},
  });
  const api = useApi();
  const {isMobile} = useDeviceWindow();
  const navigate = useNavigate();
  const {t} = useTranslation('pages');

  useEffect(() => {
    const fetchCustomFields = async () => {
      const response = await api.get('/register/custom-field/');
      const initialValuesCustomFields = response.results.reduce(
        (accum: any, current: CustomField) => {
          accum[current.name as keyof CustomField] = '';

          return accum;
        },
        {},
      );
      setCustomFields(response.results);
      setInitialValues((prevState) => ({
        ...prevState,
        custom_fields: initialValuesCustomFields,
      }));
    };

    fetchCustomFields();
  }, [api]);

  const handleSubmit = useCallback(
    async (values: Object) => {
      try {
        const response = await api.post('/register/', values);
        navigate('/register/success/', {
          state: {user_name: response.first_name, email: response.email},
        });
      } catch (e: any) {
        toast.error(`${t('StoreRegisterUserPage.errorMessage')}`, {hideProgressBar: true});
      }
    },
    [api, navigate, t],
  );

  const customFieldsValidation = useMemo(
    () =>
      customFields.reduce((accum: ObjectShape, current: CustomField) => {
        if (current.type === 'url') {
          accum[current.name] = Yup.string().url();
        } else {
          accum[current.name] = Yup.string();
        }
        return accum;
      }, {}),
    [customFields],
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(`${t('StoreRegisterUserPage.form.errors.required')}`),
    last_name: Yup.string().required(`${t('StoreRegisterUserPage.form.errors.required')}`),
    email: Yup.string()
      .email()
      .required(`${t('StoreRegisterUserPage.form.errors.required')}`),
    user_phone: Yup.string(),
    user_company_name: Yup.string(),
    user_company_tax_id: Yup.string(),
    user_shipping_address: Yup.string(),
    custom_fields: Yup.object().shape(customFieldsValidation),
  });

  return (
    <Container fluid className={`px-lg-4 px-xs-2 mt-4 ${styles.register}`}>
      <Row>
        <Col xs={12} className="py-2 d-flex justify-content-center align-items-center">
          <Card>
            <Woman className="woman" />
            {!isMobile ? <Man className="man" /> : null}
            <h1 className="text-center pt-2">{t('StoreRegisterUserPage.title')}</h1>
            <Card.Body>
              <RegisterForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={handleSubmit}
                customFields={customFields}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreRegisterUserPage;
