import React, {FC} from 'react';
import {ShopProduct} from '../../types/ShopProduct';

const ProductHighlight: FC<{
  product: ShopProduct;
}> = ({product}) =>
  product.custom_fields?.['2bk_show'] ? (
    <div className="fw-bold text-muted pt-2">
      {product.custom_fields?.['2bk_show']}
    </div>
  ) : null;

export default ProductHighlight;
