import {useEffect} from 'react';

const useDebounce = (effect, delay) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const timeout = setTimeout(effect, delay);
    return () => clearTimeout(timeout);
  }, [effect, delay]);
};

export default useDebounce;
