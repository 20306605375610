import {v4 as uuid4} from 'uuid';
import {asMap} from '../../utils';

// Returns a custom field object with the given stats as well as a uuid.
export const makeCustomField = (customField) => ({
  uuid: uuid4(),
  field: customField?.field ? String(customField?.field) : '',
  value: customField?.value ? String(customField?.value) : '',
});

// Returns an empty custom field object.
export const makeEmptyCustomField = () => makeCustomField({});

// Returns a product pack object with the given attributes as well as a uuid.
export const makeProductPack = (productPack) => ({
  uuid: uuid4(),
  name: productPack?.name ? String(productPack?.name) : '',
  quantity: productPack?.quantity ? String(productPack?.quantity) : '',
});

// Returns an empty product pack object.
export const makeEmptyProductPack = () => makeProductPack({});

// Returns a combination object suitable for the forms.
export const makeCombination = (combination) => ({
  id: combination.id,
  pack: combination.pack,
  variants: combination.variants,
  price: combination.price ? String(combination.price) : '',
  code: combination.code ? String(combination.code) : '',
  inventory: combination.inventory,
});

// Returns a mapping of product option id to product option.
export const getProductOptionsMap = (productVariants) =>
  asMap(
    productVariants.reduce((accum, current) => {
      accum.push(...current.options);
      return accum;
    }, []),
    (option) => option.id,
  );

export const formatPrice = (product) => {
  if (product.volume_discounts.length > 0) {
    return {
      default_price: getVolumeDiscountPriceRange(
        product.default_price,
        product.volume_discounts.at(-1).discount,
      ),
      offer_price: getVolumeDiscountPriceRange(
        product.offer_price,
        product.volume_discounts.at(-1).discount,
      ),
    };
  }

  if (
    product.combinations.length > 0 &&
    product.combinations.some((combination) => combination.price)
  ) {
    return {
      default_price: getCombinationsPriceRange(
        product.default_price,
        product.combinations,
      ),
      offer_price: getCombinationsPriceRange(
        product.offer_price,
        product.combinations,
      ),
    };
  }

  return {
    default_price: getDefaultPriceRange(product.default_price),
    offer_price: getDefaultPriceRange(product.offer_price),
  };
};

const getVolumeDiscountPriceRange = (price, maxDiscount) => {
  if (price === null) {
    return null;
  }

  const min = price - price * (maxDiscount / 100);
  const max = price;

  return {
    min,
    max,
  };
};

const getCombinationsPriceRange = (price, combinations) => {
  if (price === null) {
    return null;
  }

  const prices = combinations.map((combination) => combination.price || price);
  const min = Math.min(price, ...prices);
  const max = Math.max(price, ...prices);

  return {
    min,
    max,
  };
};

const getDefaultPriceRange = (price) => {
  if (price === null) {
    return null;
  }

  return {
    min: price,
    max: price,
  };
};
