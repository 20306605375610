import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import TypeField from '../FormFields/TypeField';

import styles from './LoginFields.module.scss';

const PasswordField = () => {
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation('components');

  const toggleShowPassword = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <Form.Group className={`mb-1 position-relative ${styles.handlers}`}>
      <TypeField
        name="password"
        placeholder={t('PasswordField.Placeholder')}
        type={visible ? 'text' : 'password'}
      />
      <button type="button" tabIndex="-1" onClick={toggleShowPassword}>
        <Icon
          icon={visible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}
        />
      </button>
    </Form.Group>
  );
};

export default PasswordField;
