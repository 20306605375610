import React, {useMemo} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Route, Routes, useNavigate} from 'react-router';
import {Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import BlinksFormPage from '../BlinksFormPage';
import CallToActionBlinkCreate from '../../components/CallToAction/CallToActionBlinkCreate';
import {useLayoutTitle} from '../../hooks/layout';
import DataTable from '../../components/DataTable';
import {useApi} from '../../contexts/ApiContext';
import {copyToClipboard} from '../../utils';

import styles from './BlinksPage.module.scss';

const LinkCell = React.memo(({cell: {value}}) => (
  <a target="blank" href={`/blink/${value}/`}>
    {value}
  </a>
));
const StatusCell = React.memo(({cell}) => (
  <span className={`${styles.cellStatus} ${styles[cell.row.original.status]}`}>
    <Icon icon="fa-solid fa-circle" />
    &nbsp;
    {cell.value}
  </span>
));
const BlinksPageIndex = () => {
  const api = useApi();
  const navigate = useNavigate();
  const {t} = useTranslation('pages');
  const columns = React.useMemo(
    () => [
      {
        Header: t('BlinksPage.DataTable.headers.title'),
        accessor: 'title',
      },
      {
        Header: t('BlinksPage.DataTable.headers.link'),
        Cell: LinkCell,
        accessor: 'uid',
      },
      {
        Header: t('BlinksPage.DataTable.headers.status'),
        Cell: StatusCell,
        accessor: 'status_display',
      },
    ],
    [t],
  );

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-trash',
        onClick: async (cell) => api.delete(`/blink/${cell.row.original.id}/`),
        key: 'delete',
        refresh: true,
      },
      {
        icon: 'fa-solid fa-pencil',
        onClick: (cell) => navigate(`/admin/blinks/edit/${cell.row.original.id}/`),
        key: 'edit',
      },
      {
        icon: 'fa-solid fa-clipboard',
        onClick: (cell) => {
          const url = window.location;
          copyToClipboard(`${url.protocol}//${url.hostname}/blink/${cell.row.original.uid}`);
          toast.success(t('BlinksPage.DataTable.buttons.copy.success_message'), {
            hideProgressBar: true,
          });
        },
        key: 'copy',
      },
    ],
    [api, navigate, t],
  );

  const orderChoices = [
    {
      value: 'title',
      label: 'A → Z',
    },
    {
      value: '-title',
      label: 'Z → A',
    },
    {
      value: '-id',
      label: t('BlinksPage.DataTable.orderChoices.id.new'),
    },
    {
      value: 'id',
      label: t('BlinksPage.DataTable.orderChoices.id.old'),
    },
  ];

  return (
    <DataTable
      columns={columns}
      source={{
        type: 'remote',
        url: '/blink',
      }}
      buttons={buttons}
      orderChoices={orderChoices}
      allowOrder
    >
      <Row className="mt-4">
        <Col>
          <CallToActionBlinkCreate />
        </Col>
      </Row>
    </DataTable>
  );
};

const ProductsPage = () => {
  const {t} = useTranslation('pages');
  useLayoutTitle({
    label: t('BlinksPage.PageTitle'),
    icon: 'fa-solid fa-paper-plane',
  });

  return (
    <Routes>
      <Route path="/add" element={<BlinksFormPage />} />
      <Route path="/edit/:id" element={<BlinksFormPage />} />
      <Route index element={<BlinksPageIndex />} />
    </Routes>
  );
};

export default ProductsPage;
