import React, {useMemo, useCallback} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Container, Row, Nav, Col, Accordion} from 'react-bootstrap';
import {useAccordionButton} from 'react-bootstrap/AccordionButton';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {pathBase} from '../../utils';
import {useLayoutSidebarItems} from '../../hooks/layout';
import logo from '../../assets/logo.svg';

import styles from './SectionSideBar.module.scss';

const NavLink = ({
  children,
  icon,
  onClick,
  eventKey,
  className,
  href,
  target,
}) => (
  <Nav.Link
    className={className}
    eventKey={eventKey}
    onClick={onClick}
    href={href}
    target={target}
  >
    {icon ? <Icon icon={icon} /> : null} {children}
  </Nav.Link>
);

const NavItem = ({item, eventKey}) => {
  const location = useLocation();
  const activeNavItem = useMemo(() => location.pathname, [location]);

  const navigate = useNavigate();

  const onClick = useAccordionButton(
    eventKey,
    item.openInNewWindow
      ? () => window.open(item.path, '_blank')
      : () => navigate(item.path),
  );

  return (
    <Nav.Item>
      <NavLink
        eventKey={eventKey}
        icon={item.icon}
        onClick={onClick}
        href={item.href}
        target={item.target}
      >
        {item.label}
      </NavLink>
      {(item.children || []).length > 0 ? (
        <Accordion.Collapse eventKey={item.path}>
          <Nav activeKey={activeNavItem}>
            {item.children.map((child) => (
              <NavLink
                className={styles.subItem}
                eventKey={child.path}
                key={child.path}
                icon={child.icon}
                onClick={() => navigate(child.path)}
              >
                {child.label}
              </NavLink>
            ))}
          </Nav>
        </Accordion.Collapse>
      ) : null}
    </Nav.Item>
  );
};

const SectionSideBar = () => {
  const location = useLocation();
  // Determine the active nav item by looking at the current path and taking the first two
  // segments.
  const activeNavItem = useMemo(() => pathBase(location.pathname), [location]);

  const [SIDEBAR_TOP_ITEMS, SIDEBAR_BOTTOM_ITEMS] = useLayoutSidebarItems();

  const renderItem = useCallback(
    (item) => <NavItem key={item.label} item={item} eventKey={item.path} />,
    [],
  );

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.sideBarHeader}>
        <Col>
          <Link to="/admin">
            <h1>
              <img src={logo} alt="2Blink logo" />
            </h1>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion activeKey={activeNavItem}>
            <Nav activeKey={activeNavItem}>
              {SIDEBAR_TOP_ITEMS.map(renderItem)}
            </Nav>
          </Accordion>
        </Col>
      </Row>
      <Row className={styles.sideBarMain}>
        <Col>
          <Link to="/admin/blinks/add" className="btn btn-lg btn-secondary">
            <Icon className="align-middle" icon="fa-solid fa-circle-plus" />{' '}
            <span className="align-middle">Crear Blink</span>
          </Link>
        </Col>
      </Row>
      <Row className={styles.sideBarFooter}>
        <Col>
          <Nav activeKey={activeNavItem}>
            {SIDEBAR_BOTTOM_ITEMS.map(renderItem)}
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionSideBar;
