import {useEffect} from 'react';
import {useWebSocket} from '../contexts/WebSocketContext';

const useWebsocketChannel = (channel: string, callback: (props: ImporterNotification) => void) => {
  const websocket = useWebSocket();

  useEffect(() => {
    const listener = websocket?.addEventListener('message', (event: any) => {
      const notification = JSON.parse(event.data)?.notification;

      if (!notification) {
        return;
      }

      if (notification.type === channel) {
        callback(notification);
      }
    });

    return () => websocket?.removeEventListener('message', listener);
  }, [websocket, channel, callback]);

  return null;
};

export default useWebsocketChannel;
