/* eslint-disable react/jsx-props-no-spreading */
import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Form, Row, Col, Button, Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import styles from './ImageUpload.module.scss';
import {useApi} from '../../contexts/ApiContext';
import BannerCarousel from '../BannerCarousel';

const LinkModal = ({modalState, handleClose, allData, setValue}) => {
  const {currentIndex, show} = modalState;
  const [input, setInput] = useState('');

  const handleSave = () => {
    const currentData = [...allData];
    currentData[currentIndex] = {
      url: allData[currentIndex].url,
      link_to: input,
    };
    setValue(currentData);
    handleClose();
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onShow={() => setInput(allData[currentIndex].link_to || '')}
      >
        <Modal.Header>
          <Modal.Title>Editar enlace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            onKeyUp={handleKeypress}
            type="text"
            value={input}
            placeholder="https://example.com"
            autoFocus
            onChange={(event) => setInput(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ImageUpload = ({
  multiple,
  category,
  content,
  selected,
  setSelected,
  onUpload,
  setValue,
  onDelete,
  onSwap,
  enableLinkAttach,
  fullWidth,
  name = null,
}) => {
  const api = useApi();

  // TODO: refactor how settings fields are rendered/styled
  let TOTAL_IMAGES = 1;

  if (multiple) {
    TOTAL_IMAGES = 11;
  }
  if (enableLinkAttach) {
    TOTAL_IMAGES = 5;
  }

  const [linkModalState, setLinkModalState] = useState({
    show: false,
    currentIndex: null,
  });

  const closeLinkModal = () =>
    setLinkModalState({
      show: false,
      currentIndex: null,
    });

  const openLinkModal = (index) =>
    setLinkModalState({
      show: true,
      currentIndex: index,
    });

  const filesUpload = useCallback(
    (files) => {
      Promise.all(
        files.map(async (file) => {
          const response = await api.post('/image-upload/', {
            file_name: file.name,
            content_type: file.type,
            category,
          });
          const presignedPost = response.presigned_post;
          const fileUrl = response.file_url;
          const formData = new FormData();
          Object.entries(presignedPost.fields).forEach(([field, value]) => {
            formData.append(field, value);
          });
          formData.append('file', file);
          await fetch(presignedPost.url, {body: formData, method: 'post'});
          return fileUrl;
        }),
      ).then((fileUrls) => onUpload(fileUrls));
    },
    [api, category, onUpload],
  );

  const swapImages = useCallback(
    (index, newIndex, array) => {
      const newArray = [...array];
      newArray[index] = array[newIndex];
      newArray[newIndex] = array[index];
      onSwap(newArray);
    },
    [onSwap],
  );

  const selectImage = useCallback(
    (image) => {
      setSelected([...selected, image]);
    },
    [setSelected, selected],
  );

  const deselectImage = useCallback(
    (image) => {
      const arrFiltered = selected.filter((element) => element !== image);
      setSelected(arrFiltered);
    },
    [selected, setSelected],
  );

  const deleteImage = useCallback(
    (image) => {
      if (multiple) {
        const arrFiltered = content.filter((element) => element !== image);
        onDelete(arrFiltered);
      } else {
        onDelete('');
      }
      if (selected) {
        deselectImage(image);
      }
    },
    [content, onDelete, selected, multiple, deselectImage],
  );

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      if (content.length > 1 && !multiple) {
        toast.error('Para reemplazar ésta imagen debes eliminar la anterior', {
          hideProgressBar: true,
        });
      } else {
        filesUpload(acceptedFiles);
      }
    },
    [filesUpload, content, multiple],
  );

  const onDropRejected = useCallback(
    () => toast.error('File rejected', {hideProgressBar: true}),
    [],
  );

  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: multiple ? 0 : 1,
    accept: {
      'image/*': [],
    },
    maxSize: 10000000,
  });

  return (
    <React.Fragment>
      {enableLinkAttach ? (
        <LinkModal
          modalState={linkModalState}
          handleClose={closeLinkModal}
          allData={content}
          setValue={setValue}
        />
      ) : null}
      <div {...getRootProps()}>
        <Form.Control {...getInputProps()} name={name} />
        <Row>
          <Col
            xs={fullWidth ? 2 : 3}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className={`d-flex justify-content-center align-items-center my-1 ${styles.plusButton}`}
            >
              <Icon icon="fa-solid fa-square-plus" />
            </div>
          </Col>
          {Array(TOTAL_IMAGES)
            .fill(null)
            .map((e, index) =>
              content[index] ? (
                <Col
                  xs={fullWidth ? 2 : 3}
                  key={content[index].url || content[index]}
                  className={`${styles.imageContainer} d-flex justify-content-center align-items-center position-relative my-1`}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (selected) {
                      if (
                        selected.find((element) => element === content[index])
                      ) {
                        deselectImage(content[index]);
                      } else {
                        selectImage(content[index]);
                      }
                    }
                  }}
                >
                  <div className={styles.imageZone}>
                    <img
                      src={
                        multiple
                          ? content[index].url || content[index]
                          : content
                      }
                      alt={
                        multiple
                          ? content[index].url || content[index]
                          : content
                      }
                      width="100%"
                      // eslint-disable-next-line react/no-unknown-property
                      select={selected?.find(
                        (element) => element === content[index],
                      )}
                    />
                    <div className={styles.imageHeader}>
                      {enableLinkAttach ? (
                        <Button
                          size="sm"
                          variant="secondary"
                          className={`${styles.linkButton} ${
                            content[index]?.link_to ? styles.set : styles.nonSet
                          }`}
                        >
                          <Icon
                            icon={`fa-solid ${
                              content[index]?.link_to
                                ? 'fa-link'
                                : 'fa-link-slash'
                            }`}
                            onClick={(event) => {
                              event.stopPropagation();
                              openLinkModal(index);
                            }}
                          />
                        </Button>
                      ) : (
                        <div />
                      )}
                      {onDelete ? (
                        <Icon
                          icon="fa-solid fa-square-xmark"
                          role="button"
                          className={styles.closeButton}
                          onClick={(event) => {
                            deleteImage(content[index]);
                            event.stopPropagation();
                          }}
                        />
                      ) : null}
                    </div>
                    <div className={styles.imageFooter}>
                      {multiple && onSwap ? (
                        <div className={styles.sortButton}>
                          <Icon
                            icon="fa-solid fa-arrow-left"
                            role="button"
                            onClick={(event) => {
                              if (index !== 0) {
                                swapImages(index, index - 1, content);
                              }
                              event.stopPropagation();
                            }}
                            disabled={index === 0}
                          />
                          <Icon
                            icon="fa-solid fa-arrow-right"
                            role="button"
                            onClick={(event) => {
                              if (content.length !== index + 1) {
                                swapImages(index, index + 1, content);
                              }
                              event.stopPropagation();
                            }}
                            disabled={content.length === index + 1}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
              ) : (
                <Col
                  xs={fullWidth ? 2 : 3}
                  className="d-flex justify-content-center align-items-center"
                  key={`placeholder-${index + 1}`}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center my-1 ${styles.cameraPlaceholder}`}
                  >
                    <Icon icon="fa-solid fa-camera" />
                  </div>
                </Col>
              ),
            )}
        </Row>
      </div>
      {enableLinkAttach ? (
        <div>
          <hr className={styles.divider} />
          <div
            className={`d-flex flex-column justify-content-center text-center border border-disabled border-radius-50 rounded ${styles.carouselContainer}`}
          >
            <div>
              {content.length > 0 ? (
                <BannerCarousel content={content} maxHeight={350} />
              ) : (
                <div className="px-4">
                  <p className="fs-4">
                    Presione
                    <Icon className="px-1" icon="fa-solid fa-square-plus" />
                    para agregar imágenes.
                  </p>
                  <p className="fs-4">
                    Posteriormente, podrá configurar los enlaces haciendo clic
                    en el ícono correspondiente de cada imagen, si desea
                    redirigir al usuario cuando este haga clic.
                  </p>
                  <p className="fs-4"> Tamaño recomendado: 1200x300 píxeles.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ImageUpload;
