import React, {useCallback, useState} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {ShopProduct} from '../../types/ShopProduct';
import {useShopProductsContainer} from './Container';
import {ConfirmationModal} from '../Modals';

import styles from './ShopProduct.module.scss';

interface FavoriteProductButtonProps {
  product: ShopProduct;
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const FavoriteProductButton = ({
  product,
  toggleFavorite,
}: FavoriteProductButtonProps) => (
  <button
    type="button"
    onClick={() => toggleFavorite(product)}
    className={`${styles.favoriteButton} ${
      product.favorite ? styles.active : null
    }`}
  >
    <Icon icon={['fas', 'heart']} />
  </button>
);

interface DeleteButtonProps {
  deleteProduct: () => Promise<void>;
  confirmationText: string;
  disabled?: boolean;
}
const DeleteButton = ({
  deleteProduct,
  confirmationText,
  disabled = false,
}: DeleteButtonProps) => {
  const {retrieveProducts} = useShopProductsContainer();
  const [showModal, setShowModal] = useState(false);

  const deleteSingleProduct = useCallback(async () => {
    await deleteProduct();
    retrieveProducts();
  }, [deleteProduct, retrieveProducts]);

  return (
    <React.Fragment>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        disabled={disabled}
      >
        <Icon icon={['fas', 'trash']} />
      </button>
      <ConfirmationModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={confirmationText}
        onSubmit={deleteSingleProduct}
      />
    </React.Fragment>
  );
};

export {FavoriteProductButton, DeleteButton};
