import React, {FC, PropsWithChildren} from 'react';
import {Button, Card} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

type ControlCardProps = {
  title: string;
  handleEdit?: (values: any) => void;
  handleDelete?: (values: any) => void;
};

const ControlCard: FC<PropsWithChildren<ControlCardProps>> = ({
  children,
  title,
  handleEdit,
  handleDelete,
}) => (
  <Card>
    <Card.Header className="d-flex justify-content-between align-items-center">
      <p className="fs-5 mb-0">
        <strong>{title}</strong>
      </p>
      <div>
        <Button
          variant="secondary"
          disabled={!handleDelete}
          onClick={handleDelete}
        >
          <Icon icon="trash" />
        </Button>
        <Button variant="secondary" disabled={!handleEdit} onClick={handleEdit}>
          <Icon icon="pencil" />
        </Button>
      </div>
    </Card.Header>
    {children}
  </Card>
);

export default ControlCard;
