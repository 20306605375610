import {useField} from 'formik';
import React, {FC, ChangeEventHandler} from 'react';
import {Form} from 'react-bootstrap';

type SelectFieldProps = {
  name: string;
  options: string[];
  size?: 'lg' | 'sm';
  disabled?: boolean;
  onChange?: ChangeEventHandler<Element>;
};

const SelectField: FC<SelectFieldProps> = ({
  name,
  options,
  size,
  disabled,
  onChange,
}) => {
  const [fieldProps, metaProps] = useField(name);

  return (
    <React.Fragment>
      <Form.Select
        size={size || 'lg'}
        value={fieldProps.value || options[0][0]}
        name={name}
        onChange={onChange || fieldProps.onChange}
        onBlur={fieldProps.onBlur}
        isInvalid={Boolean(metaProps.touched && metaProps.error)}
        disabled={disabled}
      >
        {options.map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback tooltip type="invalid">
        {metaProps.error}
      </Form.Control.Feedback>
    </React.Fragment>
  );
};

export default SelectField;
