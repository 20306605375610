/* eslint react/jsx-props-no-spreading: off */
import React, {useEffect, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useDropzone} from 'react-dropzone';
import {Card, Alert, Spinner, Form, Button} from 'react-bootstrap';
import TextField from '../../components/FormFields/TextField';
import {useApi} from '../../contexts/ApiContext';
import styles from './StepSelectFile.module.scss';

const validationSchema = Yup.object().shape({
  file_url: Yup.string().required(),
});

const InputLinkForm = ({children, handleSubmit, data}) => (
  <Formik
    initialValues={{
      file_url: data.file_url || '',
    }}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {(props) => (
      <Form noValidate onSubmit={props.handleSubmit} className="d-flex">
        <div className="flex-column">
          <TextField
            name="file_url"
            placeholder="Pegue aquí su link"
            autoFocus
          />
          <div className="flex-row pt-2">{children}</div>
        </div>
      </Form>
    )}
  </Formik>
);

const BrowseMessage = ({
  children,
  data,
  setShowLinkForm,
  showLinkImport,
  handleSubmit,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.browseMessage}>
      <h1>Gestione sus productos</h1>
      <h2>
        Importe mas productos. Actualmente tiene{' '}
        <span className="text-secondary">{data.count_products}</span>{' '}
        {data.count_products > 0 ? 'productos.' : 'producto.'}
      </h2>
      {showLinkImport ? (
        <React.Fragment>
          <p>
            Copie aquí su link para subirlo.
            <br />
            Solo llevará unos segundos.
          </p>
          <InputLinkForm
            handleSubmit={handleSubmit}
            setShowLinkForm={setShowLinkForm}
            data={data}
          >
            <Button
              className="btn btn-lg btn-primary-inverse mx-1"
              type="button"
              onClick={() => setShowLinkForm(false)}
            >
              Volver
            </Button>
            <Button className="btn btn-lg btn-primary mx-1" type="submit">
              Cargar ahora
            </Button>
          </InputLinkForm>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            Arrastre y suelte un archivo para empezar a importar productos
            <br />
            Solo llevará unos segundos.
            <br />
            <Button
              className="text-decoration-none"
              variant="link"
              size="sm"
              onClick={() => navigate('history')}
            >
              <span className="text-muted">Ver historial de importaciones</span>
            </Button>
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <hr className={styles.divider} />
            <span className="text-primary fw-bold py-2 px-2">
              o en su lugar
            </span>
            <hr className={styles.divider} />
          </div>
          <div className="d-flex flex-column justify-content-center flex-wrap">
            {children}
            <Button
              className="btn d-flex btn-primary-inverse justify-content-center my-1"
              type="button"
              onClick={() => setShowLinkForm(true)}
            >
              Suba un archivo vía link
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const FilesMessage = ({children, data}) => (
  <div className={styles.filesMessage}>
    <h1>Asistente de configuración</h1>
    <h2>
      Su archivo tiene{' '}
      <span className="text-secondary">{data.fields_names.length}</span>{' '}
      {data.fields_names > 0 ? 'columna' : 'columnas'}
    </h2>
    <p>
      El asistente de importación le ayudará a asociar sus columnas con los
      campos disponibles en 2blink
    </p>
    {children}
  </div>
);

const StepSelectFile = ({data, setData, actions}) => {
  const [validatedFile, setValidatedFile] = useState(null);
  const [validatedLink, setValidatedLink] = useState(null);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    fileRejections,
    inputRef,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    maxSize: 200 * 1000000,
  });

  const handleNext = useCallback(() => {
    actions.handleNext();
  }, [actions]);

  const handleSubmit = useCallback(
    async (values) => {
      setIsLoading(true);
      try {
        const response = await api.post('/product-import/', {
          file_url: values.file_url,
        });

        setData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setIsLoading(false);
        setValidatedLink(values.file_url);
      } catch (e) {
        setIsLoading(false);
        toast.error(e.data, {hideProgressBar: true});
      }
    },
    [api, setData],
  );

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        setIsLoading(true);
        try {
          const response = await api.post('/product-import/', {
            file_content: reader.result,
          });

          setData((prevState) => ({
            ...prevState,
            ...response,
          }));

          setIsLoading(false);

          setValidatedFile(file);
        } catch (e) {
          setIsLoading(false);
          toast.error(e.data, {hideProgressBar: true});
        }
      };
      reader.onerror = () => {};
    });
  }, [api, acceptedFiles, setData, inputRef]);

  if (validatedFile === null && validatedLink === null && !isLoading) {
    return (
      <Card>
        <Card.Body>
          <div className={styles.containerBrowse}>
            {fileRejections.map((rejection) => (
              <Alert key={rejection.file.path} variant="danger">
                {rejection.errors.map((error) => error.message).join(', ')}
              </Alert>
            ))}
            <div {...getRootProps({className: styles.browseInput})}>
              <input {...getInputProps()} />
              <BrowseMessage
                data={data}
                setShowLinkForm={setShowLinkForm}
                showLinkImport={showLinkForm}
                handleSubmit={handleSubmit}
              >
                <button
                  className="btn btn-lg btn-outline-primary"
                  type="button"
                  onClick={open}
                >
                  Seleccione Archivo
                </button>
              </BrowseMessage>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <h4 className="text-primary fw-bold">
          Estamos procesando el archivo, esto puede tardar varios minutos.
          <br />
          Por favor, espere sin hacer clic en otras secciones.
        </h4>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className={styles.containerFiles}>
      <div className={styles.filesAccepted}>
        {validatedFile && (
          <Card key={validatedFile.path}>
            <Card.Body>
              {validatedFile && validatedFile.path}
              {' - '}
              {validatedFile && validatedFile.size} bytes
            </Card.Body>
          </Card>
        )}
        {validatedLink && (
          <Card key={validatedLink}>
            <Card.Body>
              <a
                href={validatedLink}
                download
                className="fw-bol d-flex justify-content-center"
              >
                {validatedLink}
              </a>
            </Card.Body>
          </Card>
        )}
      </div>
      <FilesMessage data={data}>
        <button
          disabled={data.fields_names.length === 0}
          className="btn btn-lg btn-primary"
          type="button"
          onClick={handleNext}
        >
          Comenzar
        </button>
      </FilesMessage>
    </div>
  );
};

export default StepSelectFile;
