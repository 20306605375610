import * as React from 'react';

const Man = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="290.883"
    height="781.924"
    viewBox="0 0 290.883 781.924"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <g
      id="Grupo_42082"
      data-name="Grupo 42082"
      transform="translate(-30.064 -40.542)"
    >
      <g
        id="Grupo_41875"
        data-name="Grupo 41875"
        transform="translate(30.569 41.066)"
      >
        <g id="freepik--Character--inject-2" transform="translate(0 0)">
          <path
            id="Trazado_1896"
            data-name="Trazado 1896"
            d="M645.75,720.8c-5.092-1.441-31.344-17.486-31.344-17.486a40.559,40.559,0,0,0,0-9.487c-.745-2.185-7.206-7.206-7.206-7.206s-5.836-53.225-5.836-78.757a448.479,448.479,0,0,0-5.837-63.457c-.72-2.906-20.416-206.392-20.416-206.392l-6.557-51.784-64.85,3.194-64.851-3.194-6.581,51.784s-19.7,203.486-20.416,206.392a452.293,452.293,0,0,0-5.836,63.457c0,25.532-5.837,78.757-5.837,78.757s-6.557,5.116-7.206,7.206a39.246,39.246,0,0,0,0,9.487S366.7,719.359,361.609,720.8s-2.93,5.837,2.906,8.767,18.975,4.372,24.811,4.372,14.579-10.208,16.813-11.673,1.465,7.206,1.465,7.206a22.4,22.4,0,0,0,8.022-1.465c2.906-1.465,8.022,1.465,8.743-7.206s.745-17.486.745-17.486a32.038,32.038,0,0,0,6.557-9.487c1.465-4.371,4.371-16.813,4.371-16.813l45.948-185.232,21.473-101.287,21.9,101.311,45.948,185.232s2.93,12.418,4.371,16.813a31.944,31.944,0,0,0,6.581,9.487s0,8.743.72,17.485,5.837,5.837,8.743,7.206a22.4,22.4,0,0,0,8.022,1.465s-.721-8.767,1.465-7.206,10.952,11.673,16.813,11.673,18.975-1.465,24.811-4.371S650.866,722.265,645.75,720.8Z"
            transform="translate(-358.749 46.938)"
            fill="#082032"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1897"
            data-name="Trazado 1897"
            d="M403.349,217.9S363.31,357.208,361.581,360.619s8.695,1.729,18.567,3.483,23.778-5.813,25.532-8.695,16.237-48.037,16.237-48.037Z"
            transform="translate(-355.046 -48.851)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Línea_323"
            data-name="Línea 323"
            x1="1.681"
            y1="4.035"
            transform="translate(48.448 210.145)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Línea_324"
            data-name="Línea 324"
            x1="11.193"
            y1="27.117"
            transform="translate(52.05 218.815)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1898"
            data-name="Trazado 1898"
            d="M379.23,267.52l3.171,2.4"
            transform="translate(-330.038 20.709)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1899"
            data-name="Trazado 1899"
            d="M366.9,267.185a94.731,94.731,0,0,1,13.282-3.915c1.345,0,7.566,4.131,12.634,7.638"
            transform="translate(-347.323 14.751)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1900"
            data-name="Trazado 1900"
            d="M462.258,214.52s45.828,122.5,46.981,127.059,2.4,7.542,1.753,12.754-8.719,8.695-10.448,8.695-14.411-8.118-19.719-10.424-20.9-9.872-20.9-9.872l-8.118-26.18Z"
            transform="translate(-228.29 -53.59)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Línea_325"
            data-name="Línea 325"
            y1="3.002"
            x2="0.769"
            transform="translate(236.514 197.655)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Línea_326"
            data-name="Línea 326"
            y1="38.19"
            x2="9.704"
            transform="translate(225.585 205.509)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Línea_327"
            data-name="Línea 327"
            x1="18.711"
            y2="11.697"
            transform="translate(234.952 265.556)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1901"
            data-name="Trazado 1901"
            d="M444.431,203.41l-65.571,34.8L407.3,389.05s-2.4,13.931-.576,16.813a15.321,15.321,0,0,0,4.636,4.635H539.569s8.695-8.118,8.695-9.872v-7.542l16.261-163.015-64.85-25.892Z"
            transform="translate(-330.557 -69.164)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1902"
            data-name="Trazado 1902"
            d="M414.036,214.247l8.622,24.283L411.01,386.268l18.206,28.846L444.9,383.746,428.712,238.53l10.136-21.761L424.172,200.58Z"
            transform="translate(-285.487 -73.132)"
            style={{fill: 'var(--bs-primary)'}}
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1903"
            data-name="Trazado 1903"
            d="M406.623,198.06l-1.513,16.189,13.667,22.241,10.112-24.283Z"
            transform="translate(-293.758 -76.664)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1904"
            data-name="Trazado 1904"
            d="M418.8,213.9l8.094,25.82,16.717-26.829-7.59-15.684Z"
            transform="translate(-274.566 -77.856)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1905"
            data-name="Trazado 1905"
            d="M404.345,176.558s-8.094-7.086-9.1,4.54S407.4,198.8,407.4,198.8Z"
            transform="translate(-307.668 -109.805)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1906"
            data-name="Trazado 1906"
            d="M430,174.624s5.044-7.59,8.6-1.537.5,15.18-2.017,20.752-6.077,6.077-6.077,6.077Z"
            transform="translate(-258.865 -114.957)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1907"
            data-name="Trazado 1907"
            d="M398.8,186.467s5.068,38.43,7.085,50.607a40.833,40.833,0,0,0,14.171,23.779c4.539,4.035,13.138,11.625,21.232,11.625s16.213-4.54,18.23-7.206,15.18-22.77,16.189-28.318,0-23.778,0-27.838-1.009-23.274-1.009-23.274,0-14.171-5.068-23.274-21.761-9.607-45.635-5.044-26.3,9.1-26.3,13.643S398.8,186.467,398.8,186.467Z"
            transform="translate(-304.145 -137.44)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1912"
            data-name="Trazado 1912"
            d="M475.145,178.843s-9.1,17.2-24.788,25.8-38.958,7.59-49.07,1.009-14.675-16.189-11.649-16.693,9.607-1.009,9.607-1.009-2.018-9.608.528-14.171,6.053-3.531,6.053-3.531,13.162-19.743,32.906-22.265a69.319,69.319,0,0,1,38.43,6.581c5.068,2.018,8.622,9.607,8.622,9.607a20.007,20.007,0,0,1,6.053,11.145c.5,6.053-4.035,25.292-4.035,25.292s.5,38.958.5,46.548-13.162,28.318-23.274,33.386a21.617,21.617,0,0,1-21.257,0c-4.035-2.018-20.224-18.206-24.283-24.283s-4.035-29.855-4.035-29.855,6.052,22.265,7.59,24.787,3.026-3.531,5.548-5.548,11.649-1.033,15.684-.528,7.086,1.033,9.1,0,12.153-6.557,16.813-6.557a45.394,45.394,0,0,1,11.625,2.522s0-18.206,1.009-26.8.5-16.813-1.009-18.735a22.434,22.434,0,0,1-5.068-8.094,30.328,30.328,0,0,1-1.609-8.6Z"
            transform="translate(-316.739 -147.518)"
            fill="#082032"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1914"
            data-name="Trazado 1914"
            d="M412.27,194.3a20.633,20.633,0,0,0,15.18-1.513"
            transform="translate(-283.72 -84.052)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1915"
            data-name="Trazado 1915"
            d="M437.47,285.89l-2.33-17.99,15.66,2.906Z"
            transform="translate(-251.659 21.242)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1916"
            data-name="Trazado 1916"
            d="M430.382,256.437a97.676,97.676,0,0,1-14.411-2.906c-5.212-1.729-8.695.6-4.636,5.813s18.494,7.47,20.224,7.47c0,0,2.882,15.084,11.025,17.99a42.343,42.343,0,0,0,12.754,2.906s4.059,5.789,9.271,6.365,17.414,9.3,17.414,9.3l5.813-13.354-12.778-10.448L462.3,257.518l-12.177-4.059L439.678,250Z"
            transform="translate(-287.517 -3.851)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1917"
            data-name="Trazado 1917"
            d="M456.755,273.907l-5.212,5.236a21.891,21.891,0,0,1,.576,4.636c0,2.4-4.059,2.4-6.965,2.4a15.131,15.131,0,0,1-8.695-2.4c-2.4-1.729-11.6-9.872-11.6-9.872l9.848-17.39-8.6,5.789a60.87,60.87,0,0,1-7.542,11.024c-3.483,3.483-4.636-.576-4.636-.576s3.459-11.025,5.789-13.931a132.588,132.588,0,0,1,10.448-9.848,22.869,22.869,0,0,1,6.965,0c4.059.576,12.177,5.212,15.66,7.542a19.5,19.5,0,0,1,6.965,9.271,18.7,18.7,0,0,1,.576,4.059"
            transform="translate(-281.393 -5.661)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1918"
            data-name="Trazado 1918"
            d="M426,276.182a10.93,10.93,0,0,1-.576-6.942,157.286,157.286,0,0,1,8.695-13.931"
            transform="translate(-265.723 3.592)"
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1919"
            data-name="Trazado 1919"
            d="M424.76,278.548s-.937-1.057-1.969-2.522-.192-4.083-.192-6c0-3.483,8.527-17.582,8.527-17.582"
            transform="translate(-269.694 -0.431)"
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1920"
            data-name="Trazado 1920"
            d="M519.647,263.109s14.411,22.049,13.931,27.862-4.059,18.566-9.3,18.566a448.245,448.245,0,0,1-44.675-5.813c-16.237-2.906-27.261-4.059-27.261-4.059L436.11,295.03l11.024-26.685,10.544,2.186A104.745,104.745,0,0,1,473,267.985c7.542-.576,32.714-6.605,32.714-6.605"
            transform="translate(-250.3 12.102)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1921"
            data-name="Trazado 1921"
            d="M447.849,266.29a33.625,33.625,0,0,1-4.659,18.566"
            transform="translate(-240.374 18.985)"
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1922"
            data-name="Trazado 1922"
            d="M370.756,279.538s-9.872,23.778-10.448,25.532,1.753,12.754,11.024,10.448,58.606-7.542,64.85-8.719l16.261-2.882h12.922c5.813,0,22.049,0,25.532-1.177l13.931-4.636c3.483-1.153,11.024-5.812,11.6-7.542s-1.753-3.483-7.542-2.906-23.8,1.153-23.8,1.153,16.261-6.365,21.473-8.695,3.483-4.636,3.483-4.636a30.044,30.044,0,0,0-9.848,1.729c-4.083,1.753-44.1,6.965-44.1,6.965l-4.66-4.059a34.081,34.081,0,0,0,2.4-10.448c0-4.636-2.4-8.118-5.212-6.941s-4.059,8.118-4.66,10.424-3.459,11.6-3.459,11.6l-1.177,5.236s-23.779-4.636-30.744-7.542-21.472-6.965-23.778-6.389-6.965,6.965-8.118,9.872"
            transform="translate(-356.679 13.664)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1923"
            data-name="Trazado 1923"
            d="M387.072,273.05s-3.867,8.526.769,13.931"
            transform="translate(-321.163 28.462)"
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1924"
            data-name="Trazado 1924"
            d="M389.87,263.6l17.678,35.884a3.939,3.939,0,0,0,3.459,2.186l64.394.649a3.891,3.891,0,0,0,3.675-5.284L465.024,260.4A3.867,3.867,0,0,0,461.4,258H393.352a3.891,3.891,0,0,0-3.483,5.6Z"
            transform="translate(-315.674 7.363)"
            style={{fill: 'var(--bs-secondary)'}}
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1925"
            data-name="Trazado 1925"
            d="M466.727,287.686c-5.813.576-23.8,1.153-23.8,1.153s16.261-6.365,21.473-8.695,3.483-4.636,3.483-4.636a30.043,30.043,0,0,0-9.848,1.729c-4.083,1.753-44.1,6.965-44.1,6.965l-4.66-4.059a34.088,34.088,0,0,0,2.4-10.448c0-4.635-2.4-8.118-5.212-6.941s-4.059,8.118-4.66,10.424-3.459,11.6-3.459,11.6l-1.177,5.236-6.125-1.249a20.536,20.536,0,0,0,.336,12.273,62.386,62.386,0,0,0,2.258,5.909l.624-.12,16.261-2.882h12.73c5.813,0,22.049,0,25.532-1.177l13.931-4.635c3.483-1.153,11.025-5.813,11.6-7.542S472.516,287.037,466.727,287.686Z"
            transform="translate(-314.567 13.706)"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_1926"
            data-name="Trazado 1926"
            d="M445.39,274.56s-12.009,5.716-13.763,5.716-22.337,2.4-22.337,2.4"
            transform="translate(-287.898 30.578)"
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Man;
