import {useEffect, useMemo} from 'react';
import {useLayoutButtons} from '../hooks/layout';

const LayoutButtons = ({handleBack, handleNext, extraButton}) => {
  const [, setButtons, clearButtons] = useLayoutButtons();

  const buttons = useMemo(() => {
    const results = [];
    if (extraButton) {
      results.push(extraButton);
    }
    return results
      .concat([
        {
          variant: 'outline-primary',
          label: 'Volver',
          onClick: handleBack,
        },
        {
          label: 'Continuar',
          onClick: handleNext,
        },
      ])
      .filter((button) => button.onClick);
  }, [handleBack, handleNext, extraButton]);

  useEffect(() => {
    setButtons(buttons);
    return () => {
      clearButtons();
    };
  }, [buttons, setButtons, clearButtons]);

  return null;
};

export default LayoutButtons;
