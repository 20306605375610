import React from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {SessionViewMode} from '../../types/ShopProduct';

import styles from './ShopProduct.module.scss';
import VIEW_MODE_OPTIONS from './constants';

interface ViewSwitchProps {
  viewMode: SessionViewMode;
  setViewMode: (item: any) => void;
}
const ViewSwitch = ({viewMode, setViewMode}: ViewSwitchProps) => (
  <div className={styles.viewButtons}>
    <button
      type="button"
      onClick={() => setViewMode(VIEW_MODE_OPTIONS.grid)}
      disabled={viewMode === VIEW_MODE_OPTIONS.grid}
    >
      <Icon icon="grip" />
    </button>
    <button
      type="button"
      onClick={() => setViewMode(VIEW_MODE_OPTIONS.table)}
      disabled={viewMode === VIEW_MODE_OPTIONS.table}
    >
      <Icon icon="list-ul" />
    </button>
  </div>
);

export default ViewSwitch;
