import {useEffect} from 'react';
import {useLocation, useNavigationType} from 'react-router';

/* Custom Hook to save and restore scroll position across route changes.
 *
 * This hook uses sessionStorage to store the scroll position for each pathname.
 * When navigating back (using the browser's back button), it restores the scroll position
 * to where it was previously for that pathname.
 */
const useScrollPosition = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(location.pathname);
    if (navigationType === 'POP' && savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, savedScrollPosition);
      }, 500);
    }

    const handleScroll = () => {
      sessionStorage.setItem(location.pathname, window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, navigationType]);
};

export default useScrollPosition;