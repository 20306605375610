import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useLayout} from '../../contexts/LayoutContext';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import CustomerCard from './CustomerCard';
import StatusCard from './StatusCard';
import CommentsCard from './CommentsCard';
import {
  ShopProductsContainer,
  PurchaseOrderItemsTable,
} from '../../components/ShopProduct';

import styles from './PurchaseOrdersFormPage.module.scss';

const CustomerInformation = ({purchaseOrder, editPurchaseOrder}) => (
  <Row className="mb-1">
    <Col xs={8}>
      <CustomerCard
        purchaseOrder={purchaseOrder}
        editPurchaseOrder={editPurchaseOrder}
      />
    </Col>
    <Col xs={4}>
      <StatusCard
        purchaseOrder={purchaseOrder}
        editPurchaseOrder={editPurchaseOrder}
      />
      <CommentsCard
        purchaseOrder={purchaseOrder}
        editPurchaseOrder={editPurchaseOrder}
      />
    </Col>
  </Row>
);

const PurchaseOrderActions = ({id, subtotal, discounts, quantity, total}) => {
  const api = useApi();
  const [utilsState] = useUtils();

  return (
    <div className={styles.purchaseOrderInfo}>
      <div>
        <Button
          variant="link"
          className="text-dark fs-5"
          onClick={() => {
            api.get(`/purchase-order/${id}/download/`, {as: 'csv'});
          }}
        >
          <Icon icon="fa-solid fa-download" />
        </Button>
        <Button
          variant="link"
          className="text-dark fs-5"
          onClick={() => {
            api.get(`/purchase-order/${id}/download/`, {as: 'pdf'});
          }}
        >
          <Icon icon="fa-solid fa-print" />
        </Button>
      </div>
      <OrderSummary
        subtotal={subtotal}
        discounts={discounts}
        quantity={quantity}
        total={total}
        currency={utilsState.settings.COMPANY_CURRENCY}
        taxesEnabled={false}
        isEcommerce={false}
        companyDiscounts={utilsState.settings.COMPANY_AMOUNT_DISCOUNT}
      />
    </div>
  );
};

const PurchaseOrdersFormPage = () => {
  const api = useApi();
  const [purchaseOrder, setPurchaseOrder] = useState();
  const params = useParams();
  const [, actions] = useLayout();

  const purchaseOrderId = params.id;

  useEffect(() => {
    if (purchaseOrder) {
      actions.setTitle({
        label: `Órden ${purchaseOrder.code}`,
        icon: 'fa-solid fa-bag-shopping',
      });

      actions.setTopBar(
        <PurchaseOrderActions
          id={purchaseOrderId}
          subtotal={purchaseOrder.subtotal}
          discounts={purchaseOrder.discounts}
          quantity={purchaseOrder.quantity}
          total={purchaseOrder.total}
        />,
      );
    }

    return () => {
      actions.setTitle(null);
      actions.setTopBar(null);
    };
  }, [purchaseOrder, actions, purchaseOrderId]);

  const fetchPurchaseOrder = useCallback(async () => {
    if (!purchaseOrderId) {
      return;
    }
    const result = await api.get(`/purchase-order/${purchaseOrderId}/`);
    setPurchaseOrder(result);
  }, [api, setPurchaseOrder, purchaseOrderId]);

  useEffect(() => {
    if (!purchaseOrderId) {
      return;
    }
    fetchPurchaseOrder();
  }, [purchaseOrderId, fetchPurchaseOrder]);

  const editPurchaseOrder = useCallback(
    async (id, newValues) => {
      const result = await api.put(`/purchase-order/${id}/`, newValues);
      setPurchaseOrder(result);
    },
    [api, setPurchaseOrder],
  );

  return (
    purchaseOrder && (
      <div>
        <CustomerInformation
          purchaseOrder={purchaseOrder}
          editPurchaseOrder={editPurchaseOrder}
        />
        <ShopProductsContainer
          productsEndpoint={`/purchase-order/${purchaseOrderId}/item/`}
        >
          <PurchaseOrderItemsTable
            setPurchaseOrder={setPurchaseOrder}
            priceList={!!purchaseOrder.customer_price_list_name}
          />
        </ShopProductsContainer>
      </div>
    )
  );
};

export default PurchaseOrdersFormPage;
