import React from 'react';
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import SortBy from './SortBy';
import SearchParamsCategorySelector from '../SearchParams/SearchParamsCategorySelector';

import styles from './ShopProduct.module.scss';

const MobileFilter = () => (
  <OverlayTrigger
    trigger="click"
    placement="bottom-end"
    rootClose
    overlay={
      <Popover className={styles.mobileFilter}>
        <Popover.Body>
          <SearchParamsCategorySelector />
          <SortBy />
        </Popover.Body>
      </Popover>
    }
  >
    <Button variant="primary">
      <Icon icon="filter" />
    </Button>
  </OverlayTrigger>
);

export default MobileFilter;
