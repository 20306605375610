import {useEffect} from 'react';
import {useApi} from '../contexts/ApiContext';
import {useSession} from '../contexts/SessionContext';

// Hook that fetches, sets and initializes the session if it wasn't already done and returns the
// latest state.
// It's intended to be a shortcut for fetching the session for the first time.
const useFetchSession = () => {
  const api = useApi();
  const [sessionState, sessionActions] = useSession();

  useEffect(() => {
    const fetchSession = async () => {
      const session = await api.getSession();
      sessionActions.setSession(session);
      sessionActions.init();
    };

    if (!sessionState.loaded) {
      fetchSession();
    }
  }, [sessionState.loaded, sessionActions, api]);

  return sessionState;
};

export default useFetchSession;
