import React, {useCallback, useMemo, useState} from 'react';
import {OverlayTrigger, Popover, Button, Row, Col, Card} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import LayoutButtons from '../../layouts/utils';
import DataTable from '../../components/DataTable/DataTable';
import {formatMoney, formatPercent, isObjectEmpty} from '../../utils';
import {FILTERABLE_TYPES} from '../../components/DataTable/utils';
import {FormModal} from '../../components/Modals';
import SwitchField from '../../components/FormFields/SwitchField';
import CheckField from '../../components/FormFields/CheckField';
import {useUtils} from '../../contexts/UtilsContext';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';

import styles from './StepFinal.module.scss';

const ScheduleForm = ({options, showForm}) => {
  const [field] = useField({name: 'setAsRecurring', type: 'checkbox'});
  const {t} = useTranslation('pages');

  return (
    <Row xs={12} className="position-relative">
      <Col>{t('ProductImportPage.SuccessImportDescription')}</Col>
      {showForm && (
        <Row className="pt-2">
          <SwitchField
            name="setAsRecurring"
            label={
              <div>
                <p className="fw-bold mb-0">{t('ProductImportPage.ScheduleSetup.setupPrompt')}</p>
                <p>{t('ProductImportPage.ScheduleSetup.setupDetail')}</p>
              </div>
            }
          />
        </Row>
      )}
      {field.checked && (
        <Card>
          <Card.Body className="py-2 px-2">
            {options.map((option) => (
              <CheckField
                key={option.value}
                name="schedule"
                value={option.value}
                label={option.label}
              />
            ))}
          </Card.Body>
        </Card>
      )}
    </Row>
  );
};

const PricesCell = (row, currency) =>
  row.original.data.price_lists ? (
    <OverlayTrigger
      trigger="click"
      placement="left"
      overlay={
        <Popover className={`${styles.popover} ${styles.popoverInfo}`}>
          <Popover.Body>
            {row.original.data.price_lists?.map((pl) => (
              <div key={pl.price_list_id}>{`${pl.name} : ${formatMoney(pl.amount, currency)}`}</div>
            ))}
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        className={styles.popoverExpand}
        variant={row.original.errors.price_lists ? 'outline-danger' : 'link'}
        disabled={row.original.errors.price_lists}
      >
        <Icon icon="fa-solid fa-file-invoice-dollar" />
      </Button>
    </OverlayTrigger>
  ) : null;

const ValidCell = React.memo(({row}) => (
  <div className="d-flex justify-content-center">
    {isObjectEmpty(row.original.errors) ? (
      <Icon className="text-success" icon="fa-solid fa-circle-check" />
    ) : (
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={
          <Popover className={styles.popover}>
            <Popover.Body>
              <ul>
                {Object.keys(row.original.errors).map((field) => (
                  <li key={field}>
                    <strong>{`${field}: `}</strong>
                    {row.original.errors[field].join(', ')}
                  </li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        }
      >
        <Button className={styles.popoverButton} variant="link">
          <Icon className="text-danger" icon="fa-solid fa-circle-xmark" />
        </Button>
      </OverlayTrigger>
    )}
  </div>
));

const renderPacks = (packs) => packs.map(({name, quantity}) => `${name} (${quantity})`).join(', ');

const renderVolumeDiscounts = (volumeDiscounts) =>
  volumeDiscounts.map(({min_quantity, discount}) => `>${min_quantity}: ${formatPercent(discount)}`);

const renderCustomFields = (customFields) =>
  Object.entries(customFields)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');

const StepFinal = ({actions, data}) => {
  const [modalState, setModalState] = useState({
    type: null,
    title: null,
    body: null,
    onSubmit: null,
  });
  const [utilsState] = useUtils();

  const {t} = useTranslation('pages');

  const closeModal = useCallback(
    () => setModalState((prevModalState) => ({...prevModalState, type: null})),
    [setModalState],
  );

  const scheduleHandleNext = useCallback(
    (values) => {
      actions.handleNext({schedule: [...values.schedule]});
    },
    [actions],
  );

  const triggerConfigureScheduleModal = useCallback(
    () =>
      setModalState({
        type: 'form',
        title: t('Commons.attention'),
        initialValues: {setAsRecurring: false, schedule: []},
        body: <ScheduleForm options={utilsState.scheduleOptions} showForm={data.file_url} />,
        onSubmit: scheduleHandleNext,
      }),
    [scheduleHandleNext, utilsState.scheduleOptions, data.file_url, t],
  );

  const renderVariants = (variants) =>
    variants
      .map((importedVariant) => {
        if (importedVariant.type === 'new') {
          const options = importedVariant.options.join(', ');
          const {name} = importedVariant;
          return t('ProductImportPage.Variants.newVariant', {name, options});
        }
        return t('ProductImportPage.Variants.existentVariant', {
          code: importedVariant.code,
        });
      })
      .join(', ');

  const currency = utilsState.settings?.COMPANY_CURRENCY;

  const productColumns = [
    {Header: t('ProductImportPage.ProductColumns.name'), accessor: 'data.name'},
    {
      Header: t('ProductImportPage.ProductColumns.short_description'),
      accessor: 'data.short_description',
    },
    {
      Header: t('ProductImportPage.ProductColumns.description'),
      accessor: 'data.description',
    },
    {
      Header: t('ProductImportPage.ProductColumns.default_code'),
      accessor: 'data.default_code',
    },
    {
      Header: t('ProductImportPage.ProductColumns.default_price'),
      accessor: (originalRow) => formatMoney(originalRow.data.default_price, currency),
    },
    {
      Header: t('ProductImportPage.ProductColumns.offer_price'),
      accessor: (originalRow) => formatMoney(originalRow.data.offer_price, currency),
    },
    {
      Header: t('ProductImportPage.ProductColumns.barcode'),
      accessor: 'data.barcode',
    },
    {Header: 'Brand', accessor: 'data.brand'},
    {Header: 'Inventory', accessor: 'data.inventory'},
    {
      Header: t('ProductImportPage.ProductColumns.tags'),
      accessor: (originalRow) => {
        if (Array.isArray(originalRow.data.tags)) {
          return originalRow.data.tags.some((tag) => tag === 'new')
            ? t('Commons.yes')
            : t('Commons.no');
        }
        return originalRow.data.tags;
      },
    },
    {
      Header: t('ProductImportPage.ProductColumns.width'),
      accessor: 'data.width',
    },
    {
      Header: t('ProductImportPage.ProductColumns.height'),
      accessor: 'data.height',
    },
    {
      Header: t('ProductImportPage.ProductColumns.volume_discounts'),
      accessor: (originalRow) => renderVolumeDiscounts(originalRow.data.volume_discounts),
    },
    {
      Header: t('ProductImportPage.ProductColumns.categories'),
      accessor: 'data.categories',
    },
    {
      Header: t('ProductImportPage.ProductColumns.packs'),
      accessor: (originalRow) => renderPacks(originalRow.data.packs),
    },
    {
      Header: t('ProductImportPage.ProductColumns.video'),
      accessor: 'data.video',
    },
    {
      Header: t('ProductImportPage.ProductColumns.custom_fields'),
      accessor: (originalRow) => renderCustomFields(originalRow.data.custom_fields),
    },
    {
      Header: t('ProductImportPage.ProductColumns.variants'),
      accessor: (originalRow) => renderVariants(originalRow.data.variants),
    },
    {
      Header: t('ProductImportPage.ProductColumns.price_lists'),
      Cell: ({row}) => PricesCell(row, currency),
    },
  ];

  const columns = [
    {
      Header: t('ProductImportPage.ProductColumns.valid'),
      Cell: ValidCell,
    },
    ...productColumns,
  ];

  const filterables = [
    {
      field: 'show_products',
      label: t('ProductImportPage.Products'),
      type: FILTERABLE_TYPES.CHOICES,
      choices: [
        {
          value: 'valid',
          label: t('ProductImportPage.ProductImportFilters.showValids'),
        },
        {
          value: 'invalid',
          label: t('ProductImportPage.ProductImportFilters.showNonValids'),
        },
      ],
    },
  ];

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {
      show_products: [],
    };
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        source={{
          type: 'remote',
          url: `/product-import/${data.id}/preview/`,
        }}
        filterables={filterables}
        initialFilters={initialFilters}
        allowSelection={false}
      />
      <FormModal
        show={modalState.type === 'form'}
        closeModal={closeModal}
        title={modalState.title}
        initialValues={modalState.initialValues}
        body={modalState.body}
        onSubmit={modalState.onSubmit}
      />
      <LayoutButtons handleBack={actions.handleBack} handleNext={triggerConfigureScheduleModal} />
    </React.Fragment>
  );
};

export default StepFinal;
