import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useApi} from '../../contexts/ApiContext';
import {useUtils} from '../../contexts/UtilsContext';
import Stepper from '../../components/Stepper';
import StepDetails from './StepDetails';
import StepPacks from './StepPacks';
import StepFinal from './StepFinal';
import {makeCombination, makeCustomField, makeProductPack} from './utils';
import StepVolumeDiscounts from './StepVolumeDiscounts';
import {asMap, makeVolumeDiscount} from '../../utils';
import StepMultimedia from './StepMultimedia';
import {useLayoutTitle} from '../../hooks/layout';
import StepPriceLists from './StepPriceLists';

const ProductsFormPage = () => {
  const api = useApi();
  const [utilsState] = useUtils();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') || '';
  const {t} = useTranslation('pages');

  // Data to be shared between all the steps:
  const [data, setData] = useState({
    product: {
      name: '',
      short_description: '',
      description: '',
      default_code: '',
      default_price: '',
      offer_price: null,
      barcode: '',
      brand: '',
      inventory: true,
      width: '',
      height: '',
      custom_fields: [],
      volume_discounts: [],
      categories: new Set(),
      combinations: [],
      packs: [],
      variants: [],
      images: [],
      video: '',
      tags: [],
      prices: [],
    },
    // Whether volume discounts are enabled for the product or not.
    useVolumeDiscounts: false,
    // Categories the user is able to choose from.
    // Flag that indicates whether the packs or variants of the product changed since the last
    // time the combinations were set, in which case, they have to be reset.
    shouldResetCombinations: false,
    // Flag that indicates whether the inventory of the combinations is disabled or not
    shouldDisableCombinationsInventory: false,
    shouldResetCombinationsInventory: false,
    variants: [],
    countVariants: null,
  });

  const allCategories = useMemo(
    () => ({
      asTree: utilsState.categories ? utilsState.categories.tree : [],
      asMap: utilsState.categories
        ? asMap(utilsState.categories.list, (category) => category.id)
        : new Map(),
    }),
    [utilsState.categories],
  );

  const navigate = useNavigate();
  const params = useParams();
  const PER_PAGE = 30;

  useEffect(() => {
    const fetchProduct = async (id) => {
      const product = await api.get(`/product/${id}`);
      // Normalize the combination data for the form.
      product.combinations = product.combinations.map((combination) =>
        makeCombination(combination),
      );
      // Normalize the packs data for the form.
      product.packs = product.packs.map((pack) => makeProductPack(pack));
      // Normalize the volume discounts data for the form.
      product.volume_discounts = product.volume_discounts.map(
        (volumeDiscount) => makeVolumeDiscount(volumeDiscount),
      );
      // Transform the custom fields mapping into an array of objects for the form.
      product.custom_fields = Object.entries(product.custom_fields).reduce(
        (acc, [field, value]) => {
          acc.push(makeCustomField({field, value}));
          return acc;
        },
        [],
      );

      setData((prevState) => ({
        ...prevState,
        useVolumeDiscounts: product.volume_discounts.length > 0,
        product,
      }));
    };

    if (params.id) {
      fetchProduct(params.id);
    }
  }, [api, setData, params.id]);

  useEffect(() => {
    const fetchVariants = async () => {
      // TODO: remove the `per_page` query param after we figured out the plan for dealing with
      // massive amounts of variants.
      // See: https://2blink.atlassian.net/browse/BL-317
      const response = await api.get('/variant/', {
        per_page: PER_PAGE,
        search,
      });

      const variants = response.results;

      setData((prevState) => ({
        ...prevState,
        variants,
        countVariants: response.count > PER_PAGE && response.count - PER_PAGE,
      }));
    };

    fetchVariants();
  }, [api, setData, search]);

  const handleSubmit = useCallback(
    async (values) => {
      const {product} = values;

      const requestParams = {
        name: product.name,
        short_description: product.short_description,
        description: product.description,
        default_code: product.default_code,
        default_price: product.default_price,
        offer_price: product.offer_price,
        barcode: product.barcode,
        brand: product.brand,
        inventory: product.inventory,
        width: product.width,
        height: product.height,
        custom_fields: product.custom_fields.reduce((accum, customField) => {
          accum[customField.field] = customField.value;
          return accum;
        }, {}),
        // Only send the volume discounts array if the product uses volume discounts.
        volume_discounts: values.useVolumeDiscounts
          ? product.volume_discounts
          : [],
        categories: [...product.categories],
        combinations: product.combinations,
        packs: product.packs,
        variants: product.variants.map((variant) => variant.id),
        images: product.images,
        video: product.video,
        tags: product.tags,
        prices: product.prices,
      };

      try {
        if (product.id) {
          await api.put(`/product/${product.id}/`, requestParams);
        } else {
          await api.post('/product/', requestParams);
        }
        navigate(-1);
      } catch (e) {
        toast.error(e.data, {hideProgressBar: true});
      }
    },
    [api, navigate],
  );

  const pageTitle = data.product.name
    ? `${data.product.name} - ${data.product.default_code}`
    : 'Nuevo producto';

  useLayoutTitle({
    label: pageTitle,
    icon: 'fa-solid fa-cart-shopping',
  });

  return (
    <Stepper setData={setData} data={data} handleSubmit={handleSubmit}>
      <Stepper.Step
        label={t('ProductsFormPage.Stepper.Steps.stepDetails.label')}
      >
        <StepDetails allCategories={allCategories} />
      </Stepper.Step>
      <Stepper.Step
        label={t('ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.label')}
      >
        <StepVolumeDiscounts />
      </Stepper.Step>
      <Stepper.Step label={t('ProductsFormPage.Stepper.Steps.stepPacks.label')}>
        <StepPacks />
      </Stepper.Step>
      <Stepper.Step
        label={t('ProductsFormPage.Stepper.Steps.stepPriceLists.label')}
      >
        <StepPriceLists />
      </Stepper.Step>
      <Stepper.Step
        label={t('ProductsFormPage.Stepper.Steps.stepMultimedia.label')}
      >
        <StepMultimedia />
      </Stepper.Step>
      <Stepper.Step label={t('ProductsFormPage.Stepper.Steps.stepFinal.label')}>
        <StepFinal allCategories={allCategories} />
      </Stepper.Step>
    </Stepper>
  );
};

export default ProductsFormPage;
