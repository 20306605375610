import React, {useCallback, useMemo} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {FieldArray} from 'formik';

const TableFormHeader = ({columns}) => (
  <Row>
    {columns.map((field, idx) => (
      // No problem with this because the number of fields is stable.
      // eslint-disable-next-line react/no-array-index-key
      <Col key={idx}>{field.header}</Col>
    ))}
    {/* empty column for deleting each element */}
    <Col sm={1} />
  </Row>
);

const TableFormRow = ({
  columns, // Array of objects describing the fields (header, formik name and component)
  elementName, // Formik name for the array element
  deleteElement, // Handler for deleting each array element
  deleteEnabled, // Whether the array element is deletable or not
  disabled, // Whether the form is disabled or not
}) => (
  <Row className="mb-1 position-relative">
    {columns.map((field, idx) => (
      // No problem with this because the number of fields is stable.
      // eslint-disable-next-line react/no-array-index-key
      <Col key={idx}>
        {React.cloneElement(field.component, {
          name: `${elementName}.${field.name}`,
          disabled,
        })}
      </Col>
    ))}
    <Col className="d-flex align-items-center justify-content-center" sm={1}>
      <Button
        size="sm"
        variant="secondary"
        onClick={deleteElement}
        disabled={disabled || !deleteEnabled}
        title="Delete"
      >
        <Icon icon="fa-solid fa-trash" />
      </Button>
    </Col>
  </Row>
);

const TableFormFieldArray = ({
  name, // Formik's array value name
  push, // arrayHelpers.push
  remove, // arrayHelpers.remove
  form, // Formik form bag
  columns, // Array of objects describing the fields (header, formik name and component)
  makeElement, // Callback for creating a new empty element
  minElements, // Minimum amount of elements in the array
  maxElements, // Maximum amount of elements in the array
  elementKey, // Callback for retrieving the key of each array element
  disabled, // Whether the whole table form is disabled or not
}) => {
  const elements = form.values[name];

  // Adds an element to the field array.
  const addElement = useCallback(() => {
    push(makeElement());
  }, [push, makeElement]);

  // Deletes an element from the field array.
  const deleteElement = useCallback(
    (idx) => {
      remove(idx);
    },
    [remove],
  );

  // Determines whether the delete element button is enabled or not.
  const deleteEnabled = useMemo(
    () => elements.length > (minElements || 0),
    [elements, minElements],
  );

  // Determines whether the add element button is enabled or not.
  const addEnabled = useMemo(
    () => (maxElements ? elements.length < maxElements : true),
    [elements, maxElements],
  );

  return (
    <React.Fragment>
      {elements.length > 0 ? <TableFormHeader columns={columns} /> : null}
      {elements.map((arrayElement, idx) => (
        <TableFormRow
          key={elementKey ? elementKey(arrayElement) : idx}
          columns={columns}
          elementName={`${name}[${idx}]`}
          deleteElement={() => deleteElement(idx)}
          deleteEnabled={deleteEnabled}
          disabled={disabled}
        />
      ))}
      <Button
        size="sm"
        onClick={addElement}
        title="Add"
        disabled={disabled || !addEnabled}
        className="d-block"
      >
        <Icon icon="fa-solid fa-plus" />
      </Button>
    </React.Fragment>
  );
};

const TableForm = ({
  name,
  columns,
  makeElement,
  minElements,
  maxElements,
  elementKey,
  disabled,
}) => (
  <FieldArray
    name={name}
    render={({push, remove, form}) => (
      <TableFormFieldArray
        name={name}
        push={push}
        remove={remove}
        form={form}
        columns={columns}
        makeElement={makeElement}
        minElements={minElements}
        maxElements={maxElements}
        elementKey={elementKey}
        disabled={disabled}
      />
    )}
  />
);

export default TableForm;
