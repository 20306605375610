import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import {useEcommerce} from '../../contexts/EcommerceContext';

import styles from './ShopProduct.module.scss';

const SelectedCategoryTitle = () => {
  const [state] = useEcommerce();
  const [searchParams] = useSearchParams();

  const categoryParam = searchParams.get('categories') || '';

  const selectedCategoryTitle = state.categories?.map.get(
    Number(categoryParam),
  )?.title;

  if (!categoryParam) {
    return null;
  }

  return (
    <Col
      xs={12}
      className={`d-flex justify-content-center align-items-center my-2 ${styles.categoryIndicator}`}
    >
      <hr className="flex-grow-1 flex-shrink-1 m-0" />
      <h2 className="flex-grow-0 flex-shrink-1 mb-0 px-1 px-md-2">
        {selectedCategoryTitle}
      </h2>
      <hr className="flex-grow-1 flex-shrink-1 m-0" />
    </Col>
  );
};

export default SelectedCategoryTitle;
