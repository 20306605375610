import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {useFormikContext} from 'formik';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {useUtils} from '../../contexts/UtilsContext';
import {useApi} from '../../contexts/ApiContext';
import {ConfirmationModal, FormModal} from '../../components/Modals';
import TextField from '../../components/FormFields/TextField';
import SelectField from '../../components/FormFields/SelectField';
import SwitchField from '../../components/FormFields/SwitchField';
import TableForm from '../../components/TableForm';
import {makeEmptyCustomFieldOptions} from './utils';
import {useLayout} from '../../contexts/LayoutContext';
import {useLayoutTitle} from '../../hooks/layout';
import ControlCard from '../../components/Grid/ControlCard';

import styles from './UsersCustomFieldsPage.module.scss';

const CustomFieldForm = () => {
  const {t} = useTranslation('pages');
  const formik = useFormikContext();

  return (
    <Row>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>{t('UsersCustomFieldsPage.form.fields.label')}</Form.Label>
        <TextField name="label" />
      </Form.Group>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>{t('UsersCustomFieldsPage.form.fields.type')}</Form.Label>
        <SelectField
          name="type"
          options={[
            ['text', t('UsersCustomFieldsPage.constants.types.text')],
            ['select', t('UsersCustomFieldsPage.constants.types.select')],
            ['url', t('UsersCustomFieldsPage.constants.types.url')],
          ]}
        />
      </Form.Group>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>
          {t('UsersCustomFieldsPage.form.fields.visible')}
        </Form.Label>
        <SwitchField name="visible" />
      </Form.Group>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>{t('UsersCustomFieldsPage.form.fields.edit')}</Form.Label>
        <SwitchField name="edit_permission" />
      </Form.Group>
      {formik.values.type === 'select' ? (
        <Form.Group className="mb-2 position-relative">
          <Form.Label>
            {t('UsersCustomFieldsPage.form.fields.options')}
          </Form.Label>
          <TableForm
            name="options"
            columns={[
              {
                name: 'option',
                component: <TextField />,
              },
            ]}
            makeElement={makeEmptyCustomFieldOptions}
            minElements={0}
            elementKey={(elem) => elem.uuid}
            disabled={formik.initialValues.options.length > 0}
          />
          {formik.errors.options &&
          typeof formik.errors.options === 'string' ? (
            <div className="invalid-tooltip d-flex">
              {formik.errors.options}
            </div>
          ) : null}
        </Form.Group>
      ) : null}
    </Row>
  );
};

const CallToActionButtons = ({createModal}) => {
  const {t} = useTranslation('pages');

  return (
    <Button
      variant="secondary"
      onClick={createModal}
      className={styles.ctaButton}
    >
      <Icon icon="fa-solid fa-list" /> + {t('UsersCustomFieldsPage.addButton')}
    </Button>
  );
};

const UsersCustomFieldsPage = () => {
  const api = useApi();
  const [, actions] = useLayout();
  const [utilsState, utilsActions] = useUtils();
  const [modal, setModal] = useState({
    type: '',
    onClose: null,
    onSubmit: null,
    initialValues: {},
  });
  const {t} = useTranslation('pages');

  useLayoutTitle({
    label: t('UsersCustomFieldsPage.title'),
    icon: 'fa-solid fa-user',
  });

  const fetchCustomFields = useCallback(async () => {
    const response = await api.get('/user/custom-field');

    utilsActions.setUserCustomFields(response.results);
  }, [api, utilsActions]);

  const closeModal = () =>
    setModal({
      type: '',
      onClose: null,
      onSubmit: null,
      initialValues: {
        label: '',
        type: 'text',
        edit_permission: false,
        visible: false,
        options: [],
      },
    });

  const createCustomField = useCallback(
    () =>
      setModal({
        type: 'form',
        title: t('UsersCustomFieldsPage.modals.form.createTitle'),
        onClose: closeModal,
        onSubmit: async (values) => {
          try {
            await api.post('/user/custom-field', values);
            toast.success(t('UsersCustomFieldsPage.form.success.create'), {
              hideProgressBar: true,
            });
            fetchCustomFields();
          } catch (e) {
            toast.error(`${t('UsersCustomFieldsPage.form.errors.default')}`, {
              hideProgressBar: true,
            });
          }
        },
        initialValues: {
          label: '',
          type: 'text',
          edit_permission: false,
          visible: false,
          options: [],
        },
      }),
    [api, fetchCustomFields, t],
  );

  const editCustomField = (customField) =>
    setModal({
      type: 'form',
      title: t('UsersCustomFieldsPage.modals.form.editTitle'),
      onClose: closeModal,
      onSubmit: async (values) => {
        try {
          await api.put(`/user/custom-field/${customField.id}`, values);
          toast.success(t('UsersCustomFieldsPage.form.success.edit'), {
            hideProgressBar: true,
          });
          fetchCustomFields();
        } catch (e) {
          toast.error(`${t('UsersCustomFieldsPage.form.errors.default')}`, {
            hideProgressBar: true,
          });
        }
      },
      initialValues: {
        label: customField.label,
        type: customField.type,
        edit_permission: customField.edit_permission,
        visible: customField.visible,
        options: customField.options,
      },
    });

  const deleteCustomField = (customField) =>
    setModal({
      type: 'confirmation',
      onClose: closeModal,
      onSubmit: async () => {
        try {
          await api.delete(`/user/custom-field/${customField.id}`);
          toast.success(t('UsersCustomFieldsPage.form.success.delete'), {
            hideProgressBar: true,
          });
          fetchCustomFields();
        } catch (e) {
          Object.values(e.data).map((error) =>
            toast.error(`Error: ${error.join(', ')}`, {hideProgressBar: true}),
          );
        }
      },
    });

  useEffect(() => {
    actions.setTopBar(<CallToActionButtons createModal={createCustomField} />);

    return () => {
      actions.setTopBar(null);
    };
  }, [actions, createCustomField]);

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(
      t('UsersCustomFieldsPage.form.errors.required'),
    ),
    type: Yup.string(),
    edit_permission: Yup.bool(),
    options: Yup.array()
      .of(
        Yup.object().shape({
          option: Yup.string().required(
            t('UsersCustomFieldsPage.form.errors.required'),
          ),
        }),
      )
      .when('type', {
        is: 'select',
        then: (schema) =>
          schema.min(1, t('UsersCustomFieldsPage.form.errors.array')),
      }),
  });

  return (
    <React.Fragment>
      <FormModal
        show={modal.type === 'form'}
        title={modal.title}
        closeModal={modal.onClose}
        body={<CustomFieldForm />}
        onSubmit={modal.onSubmit}
        initialValues={modal.initialValues}
        validationSchema={validationSchema}
      />
      <ConfirmationModal
        show={modal.type === 'confirmation'}
        closeModal={modal.onClose}
        title={t('UsersCustomFieldsPage.modals.confirmation.deleteTitle')}
        body={null}
        onSubmit={modal.onSubmit}
      />
      <Row className={styles.customFields}>
        {utilsState.userCustomFields.map((customField) => (
          <Col key={customField.id} xs={4}>
            <ControlCard
              title={customField.label}
              handleDelete={() => deleteCustomField(customField)}
              handleEdit={() => editCustomField(customField)}
            >
              <Card.Body>
                <Card.Text>
                  {t('UsersCustomFieldsPage.card.content.type')}:{' '}
                  {t(
                    `UsersCustomFieldsPage.constants.types.${customField.type}`,
                  )}
                </Card.Text>
                <Card.Text>
                  {t('UsersCustomFieldsPage.card.content.visible')}:{' '}
                  {customField.visible
                    ? t('UsersCustomFieldsPage.constants.yes')
                    : t('UsersCustomFieldsPage.constants.no')}
                </Card.Text>
                <Card.Text>
                  {t('UsersCustomFieldsPage.card.content.edit')}:{' '}
                  {customField.edit_permission
                    ? t('UsersCustomFieldsPage.constants.yes')
                    : t('UsersCustomFieldsPage.constants.no')}
                </Card.Text>
                <Card.Text>
                  {t('UsersCustomFieldsPage.card.content.options')}:{' '}
                  {customField.options.length > 0
                    ? customField.options
                        .reduce((accum, current) => {
                          accum.push(current.option);
                          return accum;
                        }, [])
                        .join(', ')
                    : '-'}
                </Card.Text>
              </Card.Body>
            </ControlCard>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default UsersCustomFieldsPage;
