import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useApi} from '../../contexts/ApiContext';
import {ShopProduct} from '../../types/ShopProduct';
import {fromSearchParam} from '../SearchParams/SearchParamsCategorySelector';

type ShopProductMutation = (prevProduct: ShopProduct) => ShopProduct;
type MutateShopProduct = (
  productId: number,
  mutation: ShopProductMutation,
) => void;
interface DisplayedProducts {
  products: ShopProduct[];
  totalProducts: number;
  mutateProduct: MutateShopProduct;
  retrieveProducts: () => Promise<void>;
}

const ShopProductsContainerContext = createContext<
  DisplayedProducts | undefined
>(undefined);

const useShopProductsContainer = (): DisplayedProducts => {
  const context = useContext(ShopProductsContainerContext);
  if (context === undefined) {
    throw new Error(
      '`useShopProductsContainer` must be used within a `ShopProductsContainerContext.Provider`' +
        'component',
    );
  }
  return context;
};

interface ShopProductsContainerProps extends PropsWithChildren {
  productsEndpoint: string;
}

const ShopProductsContainer = ({
  children,
  productsEndpoint,
}: ShopProductsContainerProps) => {
  const api = useApi();
  const [products, setProducts] = useState<ShopProduct[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [searchParams] = useSearchParams();

  const search = searchParams.get('search') || '';
  const ordering =
    searchParams.get('ordering') || 'min_path';
  const page = searchParams.get('page') || '1';
  const categories = searchParams.get('categories') || '';

  const PER_PAGE = 72;
  const retrieveProducts = useCallback(async () => {
    const arrCategories = fromSearchParam(categories);
    const result = await api.get(productsEndpoint, {
      ordering,
      search,
      filters: JSON.stringify([{categories: arrCategories}]),
      page: Number(page),
      per_page: PER_PAGE,
    });

    setTotalProducts(result.count);
    setProducts(result.results);
  }, [api, productsEndpoint, ordering, search, page, categories]);

  useEffect(() => {
    retrieveProducts();
  }, [retrieveProducts]);

  const mutateProduct = useCallback<MutateShopProduct>(
    (productId, mutation) => {
      setProducts((prevProducts) =>
        prevProducts.map((product) => {
          if (product.id === productId) {
            return mutation(product);
          }
          return product;
        }),
      );
    },
    [],
  );

  const containerState = useMemo(
    () => ({
      products,
      totalProducts,
      mutateProduct,
      retrieveProducts,
    }),
    [products, totalProducts, mutateProduct, retrieveProducts],
  );

  return (
    <ShopProductsContainerContext.Provider value={containerState}>
      {children}
    </ShopProductsContainerContext.Provider>
  );
};

export {ShopProductsContainer, useShopProductsContainer};
