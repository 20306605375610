import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Accordion, Button, Card, Form, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {toast} from 'react-toastify';

import * as Yup from 'yup';
import {useField} from 'formik';
import {v4 as uuid4} from 'uuid';
import {useSearchParams} from 'react-router-dom';
import {useApi} from '../../contexts/ApiContext';
import styles from './ProductsVariants.module.scss';
import TextField from '../../components/FormFields/TextField';
import {
  MODAL_TYPES,
  ConfirmationModal,
  FormModal,
} from '../../components/Modals';
import {useLayoutTitle} from '../../hooks/layout';
import SearchParamsSearchBar from '../../components/SearchParams/SearchParamsSearchBar';
import SearchParamsPaginator from '../../components/SearchParams/SearchParamsPaginator';

const variantValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
});

const optionValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const codeGenerator = (value, uuid) => {
  const variantLetters = value.split('').filter((element) => element !== ' ');
  const sliceLetters = variantLetters.slice(
    0,
    Math.ceil(variantLetters.length / 2),
  );
  return sliceLetters.length === 0
    ? ''
    : `${sliceLetters.join('').toLowerCase()}-${uuid}`;
};

const VariantForm = () => {
  const [fieldProps] = useField('name');
  const [, , helpers] = useField('code');
  const [uuid] = useState(uuid4().slice(-8));

  const handleKeyUp = useCallback(() => {
    helpers.setValue(codeGenerator(fieldProps.value, uuid));
  }, [uuid, helpers, fieldProps]);

  return (
    <Col>
      <Row>
        <Form.Group className="mb-2 position-relative">
          <Form.Label>Nombre</Form.Label>
          <TextField autoFocus name="name" onKeyUp={handleKeyUp} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-2 position-relative">
          <Form.Label>Código</Form.Label>
          <TextField name="code" />
        </Form.Group>
      </Row>
    </Col>
  );
};

const OptionForm = () => (
  <Col>
    <Row>
      <Form.Group className="mb-2 position-relative">
        <Form.Label>Nombre de la opción</Form.Label>
        <TextField autoFocus name="name" />
      </Form.Group>
    </Row>
  </Col>
);

const VariantOptions = ({options, editOption, deleteOption}) => {
  if (options.length === 0) {
    return <p>Esta variante no tiene opciones creadas</p>;
  }

  return (
    <ul>
      {options.map((option) => (
        <li key={option.id} className={styles.option}>
          {option.name}
          <Icon
            className={styles['inline-action']}
            title="Edit option"
            onClick={() => editOption(option.id)}
            icon="fa-solid fa-pen-to-square"
          />
          <Icon
            className={styles['inline-action']}
            title="Delete option"
            onClick={() => deleteOption(option.id)}
            icon="fa-solid fa-trash"
          />
        </li>
      ))}
    </ul>
  );
};

const VariantAccordionItem = ({
  variant,
  editVariant,
  deleteVariant,
  setModalState,
  fetchVariants,
}) => {
  const api = useApi();

  const createOption = useCallback(() => {
    setModalState({
      type: MODAL_TYPES.form,
      title: `Crear opción para la variante ${variant.name}`,
      initialValues: {name: ''},
      body: <OptionForm />,
      onSubmit: async (values) => {
        try {
          await api.post('/option/', {name: values.name, variant: variant.id});
          fetchVariants();
        } catch (e) {
          toast.error('Error creating option', {hideProgressBar: true});
        }
      },
      validationSchema: optionValidationSchema,
    });
  }, [setModalState, api, fetchVariants, variant]);

  const editOption = useCallback(
    (optionId) => {
      const option = variant.options.find((elem) => elem.id === optionId);
      setModalState({
        type: MODAL_TYPES.form,
        title: 'Edit variant option',
        initialValues: {name: option.name},
        body: <OptionForm />,
        onSubmit: async (values) => {
          try {
            await api.put(`/option/${optionId}/`, values);
            fetchVariants();
          } catch (e) {
            toast.error('Error editing option', {hideProgressBar: true});
          }
        },
        validationSchema: optionValidationSchema,
      });
    },
    [setModalState, api, fetchVariants, variant],
  );

  const deleteOption = useCallback(
    (optionId) => {
      setModalState({
        type: MODAL_TYPES.confirmation,
        title: 'Confirm option deletion',
        body: null,
        onSubmit: async () => {
          try {
            await api.delete(`/option/${optionId}/`);
            fetchVariants();
          } catch (e) {
            toast.error('Error deleting option', {hideProgressBar: true});
          }
        },
      });
    },
    [setModalState, api, fetchVariants],
  );

  return (
    <Accordion.Item eventKey={variant.id}>
      <Accordion.Header className={styles.variant}>
        <div className="w-100 d-flex flex-row justify-content-between">
          <span>
            {variant.name}
            <Icon
              className={styles['inline-action']}
              title="Edit variant"
              onClick={(event) => {
                editVariant(variant.id);
                event.stopPropagation();
              }}
              icon="fa-solid fa-pen-to-square"
            />
            <Icon
              className={styles['inline-action']}
              title="Delete variant"
              onClick={(event) => {
                deleteVariant(variant.id);
                event.stopPropagation();
              }}
              icon="fa-solid fa-trash"
            />
          </span>
          <span className="me-1">
            <code>{variant.code}</code>
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <VariantOptions
          options={variant.options}
          editOption={editOption}
          deleteOption={deleteOption}
        />
        <Button
          variant="secondary"
          size="sm"
          onClick={createOption}
          title="Add option"
        >
          <Icon icon="fa-solid fa-plus" />
          <span className="mx-1">Crear opción</span>
        </Button>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const VariantsAccordion = ({
  variants,
  editVariant,
  deleteVariant,
  setModalState,
  fetchVariants,
}) => {
  if (variants.length === 0) {
    return <p>No tienes variantes creadas</p>;
  }

  return (
    <Accordion className="mt-2">
      {variants.map((variant) => (
        <VariantAccordionItem
          key={variant.id}
          variant={variant}
          editVariant={editVariant}
          deleteVariant={deleteVariant}
          setModalState={setModalState}
          fetchVariants={fetchVariants}
        />
      ))}
    </Accordion>
  );
};

const ProductsVariantsPage = () => {
  const api = useApi();
  const [searchParams] = useSearchParams();
  const [countVariants, setCountVariants] = useState(null);
  const [variants, setVariants] = useState([]);
  const [modalState, setModalState] = useState({
    type: null,
    initialValues: null,
    title: null,
    body: null,
    onSubmit: null,
  });
  useLayoutTitle({
    label: 'Variantes',
    icon: 'fa-solid fa-cart-shopping',
  });

  const search = searchParams.get('search') || '';
  const page = searchParams.get('page') || '1';
  const PER_PAGE = 30;

  const fetchVariants = useCallback(async () => {
    // TODO: remove the `per_page` query param after we figured out the plan for dealing with
    // massive amounts of variants.
    // See: https://2blink.atlassian.net/browse/BL-317
    const response = await api.get('/variant/', {
      page,
      per_page: PER_PAGE,
      search,
    });
    setVariants(response.results);
    setCountVariants(response.count);
  }, [api, search, page]);

  useEffect(() => {
    fetchVariants();
  }, [fetchVariants]);

  const clearModalState = useCallback(
    () =>
      setModalState({
        type: null,
        initialValues: null,
        title: null,
        body: null,
        onSubmit: null,
      }),
    [setModalState],
  );

  const createVariant = useCallback(() => {
    setModalState({
      type: MODAL_TYPES.form,
      title: 'Crear variante',
      initialValues: {name: '', code: ''},
      body: <VariantForm />,
      onSubmit: async (values) => {
        try {
          await api.post('/variant/', values);
          fetchVariants();
        } catch (e) {
          toast.error('Error creating variant', {hideProgressBar: true});
        }
      },
      validationSchema: variantValidationSchema,
    });
  }, [setModalState, api, fetchVariants]);

  const editVariant = useCallback(
    (variantId) => {
      const variant = variants.find((elem) => elem.id === variantId);
      setModalState({
        type: MODAL_TYPES.form,
        title: 'Editar variante',
        initialValues: {name: variant.name, code: variant.code},
        body: <VariantForm />,
        onSubmit: async (values) => {
          try {
            await api.put(`/variant/${variantId}/`, values);
            fetchVariants();
          } catch (e) {
            toast.error('Error editing variant', {hideProgressBar: true});
          }
        },
        validationSchema: variantValidationSchema,
      });
    },
    [setModalState, api, fetchVariants, variants],
  );

  const deleteVariant = useCallback(
    (variantId) => {
      setModalState({
        type: MODAL_TYPES.confirmation,
        title: 'Desea eliminar la variante?',
        body: null,
        onSubmit: async () => {
          try {
            await api.delete(`/variant/${variantId}/`);
            fetchVariants();
          } catch (e) {
            toast.error('Error deleting variant', {hideProgressBar: true});
          }
        },
      });
    },
    [setModalState, api, fetchVariants],
  );

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <div className={styles.title}>
            <h1>Gestor de Variantes</h1>
            <p>
              Creá variantes y definí sus valores. Además, podés crearles un
              código interno para re utilizarlas.
            </p>
          </div>
          <Row>
            <Col>
              <SearchParamsSearchBar />
            </Col>
            <Col>
              <Button
                size="sm"
                className="mt-1 position-relative d-block ms-auto"
                onClick={createVariant}
                title="Add variant"
              >
                <Icon icon="fa-solid fa-plus" />
                <span className="mx-1">Crear variante</span>
              </Button>
            </Col>
          </Row>
          <VariantsAccordion
            variants={variants}
            editVariant={editVariant}
            deleteVariant={deleteVariant}
            setModalState={setModalState}
            fetchVariants={fetchVariants}
          />
          <SearchParamsPaginator
            totalItems={countVariants}
            perPage={PER_PAGE}
          />
        </Card.Body>
      </Card>
      <FormModal
        show={modalState.type === MODAL_TYPES.form}
        closeModal={clearModalState}
        title={modalState.title}
        initialValues={modalState.initialValues}
        body={modalState.body}
        onSubmit={modalState.onSubmit}
        validationSchema={modalState.validationSchema}
      />
      <ConfirmationModal
        show={modalState.type === MODAL_TYPES.confirmation}
        closeModal={clearModalState}
        title={modalState.title}
        body={modalState.body}
        onSubmit={modalState.onSubmit}
      />
    </Fragment>
  );
};

export default ProductsVariantsPage;
