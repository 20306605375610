import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {BrowserRouter, Navigate} from 'react-router-dom';
import {Route, Routes, useLocation} from 'react-router';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import {MantineProvider} from '@mantine/core';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {ApiProvider, useApi} from './contexts/ApiContext';
import {WebSocketProvider} from './contexts/WebSocketContext';
import {SessionProvider, useSession} from './contexts/SessionContext';
import {LayoutProvider} from './contexts/LayoutContext';
import {EcommerceProvider} from './contexts/EcommerceContext';
import {UtilsProvider} from './contexts/UtilsContext';
import {DeviceWindowProvider} from './contexts/DeviceWindowContext';
import PrivateLayout from './layouts/PrivateLayout';
import StorePage from './pages/StorePage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import DashboardPage from './pages/DashboardPage';
import ProductsPage from './pages/ProductsPage';
import PurchaseOrdersPage from './pages/PurchaseOrdersPage';
import SettingsPage from './pages/SettingsPage';
import HelpPage from './pages/HelpPage';
import useFetchSession from './hooks/useFetchSession';
import BlinksPage from './pages/BlinksPage';
import UsersPage from './pages/UsersPage';
import ProtectedRoutes from './components/ProtectedRoutes';

import './icons';
import './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

const AppWrapper = () => (
  <WebSocketProvider>
    <BrowserRouter basename={document.BLINK_BASE}>
      <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}>
        <DndProvider backend={HTML5Backend}>
          <SessionProvider>
            <QueryClientProvider client={queryClient}>
              <MantineProvider>
                <ApiProvider>
                  <LayoutProvider>
                    <DeviceWindowProvider>
                      <App />
                    </DeviceWindowProvider>
                    <ToastContainer />
                  </LayoutProvider>
                </ApiProvider>
              </MantineProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </SessionProvider>
        </DndProvider>
      </ThemeProvider>
    </BrowserRouter>
  </WebSocketProvider>
);

// Component that defines all the root routes of the app.
const App = () => {
  const sessionState = useFetchSession();

  if (!sessionState.loaded) {
    return null;
  }

  return (
    <Routes>
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/admin/*" element={<AdminApp />} />
      <Route path="/*" element={<StoreApp />} />
      <Route path="*" element={<StoreApp />} />
    </Routes>
  );
};

// Component for the admin app.
// Only available for registered users.
const AdminApp = () => {
  const [, , selectors] = useSession();

  // If the user is not authenticated with a registered user, prompt for login.
  if (!selectors.isAdminSession && !selectors.isSellerSession) {
    return <LoginPage />;
  }

  // The user is authenticated with a registered user, so just display the admin app.
  return (
    <UtilsProvider>
      <PrivateLayout>
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route element={<ProtectedRoutes isAllowed={selectors.isAdminSession} to="/admin" />}>
            <Route path="/products/*" element={<ProductsPage />} />
          </Route>
          <Route path="/blinks/*" element={<BlinksPage />} />
          <Route path="/users/*" element={<UsersPage />} />
          <Route path="/purchase-orders/*" element={<PurchaseOrdersPage />} />
          <Route element={<ProtectedRoutes isAllowed={selectors.isAdminSession} to="/admin" />}>
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
          <Route path="/help" element={<HelpPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/*" element={<Navigate to="/admin" />} />
        </Routes>
      </PrivateLayout>
    </UtilsProvider>
  );
};

// Component for the store app.
// Only available for anonymous customers or registered customers.
const StoreApp = () => {
  const api = useApi();
  const [, sessionActions, selectors] = useSession();
  const location = useLocation();

  useEffect(() => {
    window.fbq('track', 'PageView');
  }, [location]);

  useEffect(() => {
    const customerLogin = async () => {
      await api.login();
      const session = await api.getSession();
      sessionActions.setSession(session);
    };

    if (selectors.isFullAnonymousSession) {
      customerLogin();
    }
  }, [api, sessionActions, selectors]);

  if (selectors.isFullAnonymousSession) {
    return null;
  }

  // The user is either authenticated as a registered user or as an anonymous customer, so just
  // display the store app.
  return (
    <EcommerceProvider>
      <StorePage />
    </EcommerceProvider>
  );
};

export default AppWrapper;
