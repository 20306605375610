import {useField} from 'formik';
import React from 'react';
import {Form} from 'react-bootstrap';

const SwitchField = ({name, label, disabled}) => {
  const [fieldProps] = useField(name);

  return (
    <Form.Check
      type="switch"
      name={fieldProps.name}
      label={label}
      checked={fieldProps.value}
      onChange={fieldProps.onChange}
      disabled={disabled || false}
    />
  );
};

export default SwitchField;
