import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CombinationQuantitySetter,
  ShopProduct,
  ShopProductVolumeDiscount,
} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';
import {DefaultCombinationInput} from './QuantityInputs';

import styles from './ShopProduct.module.scss';

interface VolumeDiscountCardProps {
  product: ShopProduct;
  volumeDiscount: ShopProductVolumeDiscount;
  currency: string;
  hidePrices?: boolean;
}
const VolumeDiscountCard = ({
  product,
  volumeDiscount,
  currency,
  hidePrices,
}: VolumeDiscountCardProps) => {
  const {t} = useTranslation('components');

  const price = parseFloat(product.offer_price || product.default_price);
  const discountedPrice = price - price * (parseFloat(volumeDiscount.discount) / 100);
  const volumeDiscountQuantities = product.volume_discounts.map(
    (volDiscount) => volDiscount.min_quantity,
  );
  const nextMinQuantities = volumeDiscountQuantities.filter(
    (minQuantity) => minQuantity > volumeDiscount.min_quantity,
  );
  const maxAmount = nextMinQuantities.length > 0 ? nextMinQuantities[0] : undefined;
  const minAmount = volumeDiscount.min_quantity;
  const isActive = maxAmount
    ? product.quantity >= minAmount && product.quantity < maxAmount
    : product.quantity >= minAmount;

  return (
    <div className={isActive ? styles.active : undefined}>
      {hidePrices ? (
        <p>{t('VolumeDiscount.Card.text.price.mustLogin')}</p>
      ) : (
        <p>{formatMoney(discountedPrice, currency)} x u.</p>
      )}
      {maxAmount ? (
        <p>
          de {minAmount} a {maxAmount - 1} u.
        </p>
      ) : (
        <p>desde {minAmount} u.</p>
      )}
    </div>
  );
};

// Returns a new array of volume discounts with the implicit first range included.
const getVolumeDiscountsWithImplicitFirstRange = (volumeDiscounts: ShopProductVolumeDiscount[]) => [
  {discount: '0.00', min_quantity: 0},
  ...volumeDiscounts,
];

interface VolumeDiscountsProps {
  product: ShopProduct;
  currency: string;
  setCombinationQuantity?: CombinationQuantitySetter;
  direction: 'row' | 'column';
  hidePrices?: boolean;
}
const VolumeDiscounts = ({
  product,
  currency,
  setCombinationQuantity,
  direction,
  hidePrices
}: VolumeDiscountsProps) => {
  // TODO: remove this and instead add an input when needed
  const inputEnabled = !!setCombinationQuantity;

  // TODO: return null when the product has no volume discounts.
  const volumeDiscountsContainerClassName = useMemo(() => {
    if (direction === 'row' && inputEnabled) {
      return styles.productVolumeDiscountRow;
    }

    if (direction === 'row' && !inputEnabled) {
      return styles.productVolumeDiscountRowReview;
    }

    return styles.productVolumeDiscountColumn;
  }, [direction, inputEnabled]);

  return (
    <div
      className={
        direction === 'row'
          ? `${styles.productQuantityVolumeDiscount} ${styles.single}`
          : styles.productQuantityVolumeDiscount
      }
    >
      {inputEnabled && (
        <div className={styles.productQuantity}>
          <DefaultCombinationInput
            product={product}
            setCombinationQuantity={setCombinationQuantity}
            enableTooltip
          />
        </div>
      )}
      {product.volume_discounts.length > 0 && (
        <div className={volumeDiscountsContainerClassName}>
          {getVolumeDiscountsWithImplicitFirstRange(product.volume_discounts).map(
            (volumeDiscount) => (
              <VolumeDiscountCard
                key={volumeDiscount.min_quantity}
                product={product}
                volumeDiscount={volumeDiscount}
                currency={currency}
                hidePrices={hidePrices}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default VolumeDiscounts;
