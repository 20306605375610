import React from 'react';
import {Spinner} from 'react-bootstrap';

import styles from './PageLoader.module.scss';

type PageLoaderProps = {
  isLoading: boolean;
};

const PageLoader = ({isLoading}: PageLoaderProps) =>
  isLoading ? (
    <div className={styles.loader}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : null;

export default PageLoader;
