import DOMPurify from 'dompurify';
import {v4 as uuid4} from 'uuid';

// Strips the trailing and leading slashes from the given URL path.
const pathStripSlashes = (path) => path.replace(/^\/|\/$/g, '');

// Removes all the URL segments except the first two ones of a given URL path.
const pathBase = (path) => {
  const segments = pathStripSlashes(path).split('/');
  const firstTwoSegments = segments.slice(0, 2).join('/');
  return `/${firstTwoSegments}`;
};

const isObjectEmpty = (obj) =>
  Object.keys(obj).filter((key) => obj[key] !== undefined).length === 0;

// Converts the given array to an ES6 Map, getting the key for each element through getKey
// arr: V[]
// keyAccessor: V -> K
// returns Map<K, V>
const asMap = (arr, getKey) =>
  arr.reduce((accum, current) => {
    accum.set(getKey(current), current);
    return accum;
  }, new Map());

// Replacer function for JSON.stringify that converts all Set values into Arrays.
const replaceSetsWithArrays = (key, value) => {
  if (value instanceof Set) {
    return [...value];
  }
  return value;
};

// Returns a volume discount object with the given stats as well as a uuid.
const makeVolumeDiscount = (volumeDiscount) => ({
  uuid: uuid4(),
  min_quantity: volumeDiscount?.min_quantity ? String(volumeDiscount?.min_quantity) : '',
  discount: volumeDiscount?.discount ? String(volumeDiscount?.discount) : '',
});

// Returns an empty volume discount object.
const makeEmptyVolumeDiscount = () => makeVolumeDiscount({});

// Formats the given amount of money according to the given currency ISO code.
const formatMoney = (amount, currency = 'USD') =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  }).format(amount);

// Formats date into ISOString
const formatDateISO = (dateString) => {
  const date = new Date(dateString);
  const isoString = date.toISOString();
  return isoString;
};

// Formats the given date in an appropriate style.
const formatDate = (date) => new Date(date).toLocaleString();

// Formats the given date in an appropriate style.
const formatDateDay = (date) => new Date(date).toLocaleDateString(navigator.language);

// Formats the given date in an appropriate style.
const formatDateTime = (date) =>
  new Date(date).toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

// Formats the given number between 0 and 100 as a percentage.
const formatPercent = (amount) =>
  new Intl.NumberFormat('en-EN', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount / 100);

const scrollToRef = (scrollRef) => {
  scrollRef.current?.scrollIntoView({behavior: 'auto', block: 'end'});
};

const redirectToResetPassword = () => {
  const url = `/auth/password-reset/`;
  window.location.href = url;
};

const sanitize = (dirtyData) => {
  const allowedTags = JSON.parse(document.BLINK_ALLOWED_TAGS.replace(/&quot;/g, '"'));

  if (allowedTags.length === 0) {
    throw new Error(`Non-defined tag whitelist`);
  }

  const purifyConfig = {
    FORBID_TAGS: ['{{', '{%'],
    ALLOWED_TAGS: allowedTags,
    SAFE_FOR_TEMPLATES: true,
    RETURN_TRUSTED_TYPE: true,
    KEEP_CONTENT: true,
  };

  const cleanData = DOMPurify.sanitize(dirtyData, purifyConfig);

  return cleanData;
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

const getVideoId = (str, prefixes) => {
  const cleaned = str.replace(/^(https?:)?\/\/(www\.)?/, '');
  let res;

  prefixes.forEach((prefix) => {
    if (cleaned.startsWith(prefix)) {
      res = cleaned.substring(prefix.length);
    }
  });

  return res;
};

const getYouTubeId = (url) =>
  getVideoId(url, ['youtube.com/watch?v=', 'youtu.be/', 'youtube.com/embed/']);

const getVimeoId = (url) => getVideoId(url, ['vimeo.com/', 'player.vimeo.com/video/']);

const getVideoThumbnail = (url) => {
  if (getYouTubeId(url)) {
    return `http://img.youtube.com/vi/${getYouTubeId(url)}/0.jpg`;
  }
  if (getVimeoId(url)) {
    return `https://vumbnail.com/${getVimeoId(url)}.jpg`;
  }
  return undefined;
};

const customerAllowedToCheckout = (ecommerceSettings, sessionSelectors) =>
  !(
    sessionSelectors.isAnonymousCustomerSession &&
    ecommerceSettings.COMPANY_ANONYMOUS_CUSTOMER_RESTRICTIONS === 'ANONYMOUS_BROWSE_NO_CHECKOUT'
  );

const cartMeetsMinimumPurchase = (ecommerceState) => {
  const cartTotal = Number(ecommerceState.cart.total);
  const minimumPurchase = Number(ecommerceState.settings.COMPANY_MINIMUM_PURCHASE);

  return cartTotal >= minimumPurchase && cartTotal > 0;
};

const customerAllowedToSeePrices = (ecommerceSettings, sessionSelectors) =>
  !(
    sessionSelectors.isAnonymousCustomerSession &&
    ecommerceSettings.COMPANY_ANONYMOUS_CUSTOMER_RESTRICTIONS === 'ANONYMOUS_BROWSE_NO_PRICES'
  );

export {
  pathStripSlashes,
  pathBase,
  isObjectEmpty,
  asMap,
  replaceSetsWithArrays,
  makeVolumeDiscount,
  makeEmptyVolumeDiscount,
  formatMoney,
  formatDate,
  formatDateISO,
  formatDateDay,
  formatDateTime,
  formatPercent,
  scrollToRef,
  redirectToResetPassword,
  sanitize,
  copyToClipboard,
  getVideoThumbnail,
  customerAllowedToCheckout,
  cartMeetsMinimumPurchase,
  customerAllowedToSeePrices,
};
