import React, {useCallback, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import SearchBar from '../SearchBar';

const SearchParamsSearchBar = () => {
  const [isTyping, setIsTyping] = useState(false);
  const [typedValue, setTypedValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get('search') || '';

  // Search input and handler for the SearchBar component.
  const value = isTyping ? typedValue : search;
  const setValue = useCallback((newValue: string) => {
    setIsTyping(true);
    setTypedValue(newValue);
  }, []);

  // Callback that clears the `search` and `page` URL search parameters.
  const clearSearch = useCallback(() => {
    setSearchParams((prevParams) => {
      prevParams.delete('search');
      prevParams.delete('page');
      return prevParams;
    });
  }, [setSearchParams]);

  // Callback that signals that the user stopped typing and changes the `search` and `page` URL
  // search parameters.
  const changeSearchQuery = useCallback(() => {
    if (!isTyping) {
      return;
    }
    if (typedValue === search) {
      // Don't reset the `page` URL search param when the typed value matches the original URL
      // search parameter.
      setIsTyping(false);
      return;
    }
    setSearchParams((prevParams) => {
      if (typedValue === '') {
        prevParams.delete('search');
      } else {
        prevParams.set('search', typedValue);
      }
      prevParams.delete('page');
      return prevParams;
    });
    setIsTyping(false);
  }, [isTyping, typedValue, search, setSearchParams]);

  useDebounce(changeSearchQuery, 800);

  return (
    <SearchBar
      value={value}
      setValue={setValue}
      placeholder="Busca productos por nombre o código"
      handleClear={clearSearch}
    />
  );
};

export default SearchParamsSearchBar;
