import {ShopProductCombination} from '../../types/ShopProduct';

export interface CombinationsFilter {
  pack?: string;
  variants: {variant: number; option: number}[];
}

const combinationMatchesVariantsFilter = (
  filters: CombinationsFilter,
  combination: ShopProductCombination,
): boolean =>
  filters.variants.every(
    ({variant, option}) =>
      combination.variants.findIndex(
        (combinationVariant) =>
          combinationVariant.variant === variant &&
          combinationVariant.option === option,
      ) !== -1,
  );

const combinationMatchesPackFilter = (
  filters: CombinationsFilter,
  combination: ShopProductCombination,
): boolean => !filters.pack || filters.pack === combination.pack;

const combinationMatchesFilters = (
  filters: CombinationsFilter,
  combination: ShopProductCombination,
) =>
  combinationMatchesVariantsFilter(filters, combination) &&
  combinationMatchesPackFilter(filters, combination);

export default combinationMatchesFilters;
