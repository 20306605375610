import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import i18n from '../../translations/i18n';
import LayoutButtons from '../../layouts/utils';
import {makeEmptyVolumeDiscount} from '../../utils';
import NumberField from '../../components/FormFields/NumberField';
import TableForm from '../../components/TableForm/TableForm';
import SwitchField from '../../components/FormFields/SwitchField';

import styles from './ProductsFormPage.module.scss';

const volumeDiscountsColumns = [
  {
    header: `${i18n.t(
      'pages:ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.fields.volumeDiscounts.fields.minQuantity.header',
    )}`,
    name: 'min_quantity',
    component: <NumberField />,
  },
  {
    header: `${i18n.t(
      'pages:ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.fields.volumeDiscounts.fields.discount.header',
    )}`,
    name: 'discount',
    component: <NumberField />,
  },
];

const validationSchema = Yup.object().shape({
  useVolumeDiscounts: Yup.boolean(),
  volume_discounts: Yup.array().when('useVolumeDiscounts', {
    is: true,
    then: (schema) =>
      schema
        .of(
          Yup.object().shape({
            min_quantity: Yup.number()
              .typeError(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.type',
                )}`,
              )
              .integer(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.integer',
                )}`,
              )
              .min(
                1,
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.type',
                  {
                    quantity: 1,
                  },
                )}`,
              )
              .required(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.required',
                )}`,
              ),
            discount: Yup.number()
              .typeError(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.type',
                )}`,
              )
              .positive(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.positive',
                )}`,
              )
              .lessThan(
                100,
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.number.less',
                  {
                    quantity: '100%',
                  },
                )}`,
              )
              .required(
                `${i18n.t(
                  'pages:ProductsFormPage.form.validations.messages.required',
                )}`,
              ),
          }),
        )
        .min(1, 'At least one volume discount is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const StepVolumeDiscounts = (props) => {
  const {t} = useTranslation('pages');

  return (
    <Formik
      enableReinitialize
      initialValues={{
        useVolumeDiscounts: props.data.useVolumeDiscounts,
        volume_discounts:
          props.data.product.volume_discounts.length > 0
            ? props.data.product.volume_discounts
            : [makeEmptyVolumeDiscount()],
      }}
      onSubmit={(values) => {
        props.setData((prevState) => {
          const prevProduct = prevState.product;
          const newProduct = {
            ...prevProduct,
            volume_discounts: values.useVolumeDiscounts
              ? values.volume_discounts
              : [],
          };

          return {
            ...prevState,
            product: newProduct,
            useVolumeDiscounts: values.useVolumeDiscounts,
          };
        });
        props.actions.handleNext();
      }}
      validationSchema={validationSchema}
    >
      {(form) => (
        <Form noValidate onSubmit={form.handleSubmit}>
          <Row>
            <Col xs={12} className={styles.stepTitle}>
              <h1>
                {t('ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.title')}
              </h1>
              <p>
                {t(
                  'ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.description',
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Form.Group>
              <Form.Label>
                {t(
                  'ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.fields.useVolumeDiscounts.label',
                )}
              </Form.Label>
              <SwitchField
                name="useVolumeDiscounts"
                label={`${t(
                  'ProductsFormPage.Stepper.Steps.stepVolumeDiscounts.fields.useVolumeDiscounts.field.label',
                )}`}
              />
            </Form.Group>
          </Row>
          <TableForm
            name="volume_discounts"
            columns={volumeDiscountsColumns}
            makeElement={makeEmptyVolumeDiscount}
            minElements={1}
            maxElements={5}
            elementKey={(elem) => elem.uuid}
            disabled={!form.values.useVolumeDiscounts}
          />
          <LayoutButtons
            handleBack={props.actions.handleBack}
            handleNext={form.submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StepVolumeDiscounts;
