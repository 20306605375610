import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {formatMoney} from '../../utils';

import styles from './StoreCheckoutPage.module.scss';

const CheckoutSummary = () => {
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');
  const taxesEnabled = state.settings.COMPANY_PRICE_WITH_TAXES;

  return (
    <div className={styles.summary}>
      <div>
        <Card bg="primary" className="me-0 me-lg-2 mb-1 mb-lg-0">
          <Card.Body>
            <h5>{t('StoreCheckoutPage.CheckoutSummary.cartSummary')}</h5>
            <h2 className="mb-0 fw-bold">
              {formatMoney(state.cart.total, state.settings.COMPANY_CURRENCY)}
              {taxesEnabled ? <span>+IVA</span> : null}
            </h2>
          </Card.Body>
        </Card>
        <Card bg="secondary">
          <Card.Body>
            <h5>{t('StoreCheckoutPage.CheckoutSummary.units')}</h5>
            <h2 className="mb-0 fw-bold">{state.cart.quantity}</h2>
          </Card.Body>
        </Card>
      </div>
      <h1>{t('StoreCheckoutPage.CheckoutSummary.cartTitle')}</h1>
      {!state.settings.COMPANY_CHECKOUT_MESSAGE && (
        <h5>{t('StoreCheckoutPage.CheckoutSummary.cartSubtitle')}</h5>
      )}
    </div>
  );
};

export default CheckoutSummary;
