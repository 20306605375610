import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import {useEcommerce} from '../../contexts/EcommerceContext';

import styles from './ShopProduct.module.scss';

type CategoryBreadcrumProps = {
  categoryId: number;
};

const CategoryBreadcrumb = ({categoryId}: CategoryBreadcrumProps) => {
  const [state] = useEcommerce();
  const [searchParams, setSearchParams] = useSearchParams();

  const category = state.categories?.map.get(categoryId);

  const toogleSelectCategory = (catId: string) => {
    setSearchParams((prevParams) => {
      if (searchParams.get('categories') === catId) {
        prevParams.delete('categories');
      } else {
        prevParams.set('categories', catId);
        prevParams.delete('page');
      }
      return prevParams;
    });
  };

  if (!category) {
    return null;
  }

  return (
    <React.Fragment>
      {category.parent ? <CategoryBreadcrumb categoryId={category.parent} /> : null}
      <button type="button" onClick={() => toogleSelectCategory(category.id.toString())}>
        {category.parent ? '>' : null} {category.title}
      </button>
    </React.Fragment>
  );
};

const SelectedCategoryBreadcrumb = () => {
  const [searchParams] = useSearchParams();

  const categoryParam = searchParams.get('categories') || '';

  if (!categoryParam) {
    return null;
  }

  return (
    <Col
      xs={12}
      className={`d-flex justify-content-center align-items-center ${styles.breadcrumb}`}
    >
      <CategoryBreadcrumb categoryId={Number(categoryParam)} />
    </Col>
  );
};

export default SelectedCategoryBreadcrumb;
