/* eslint-disable react/jsx-props-no-spreading */
import React, {PropsWithChildren, useMemo} from 'react';
import {Accordion, Row} from 'react-bootstrap';
import {
  useTable,
  useRowSelect,
  HeaderGroup,
  TableBodyPropGetter,
  TableBodyProps as ReactTableBodyProps,
  Row as ReactTableRow,
  Column,
} from 'react-table';
import {useShopProductsContainer} from './Container';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';
import addSelectionColumn from './useRowSelectHook';
import {ExpandedRowContentProps} from './ExpandedRow';

import styles from './ShopProduct.module.scss';

interface TableHeaderProps {
  headerGroup: HeaderGroup<ShopProduct>;
}
const TableHeader = ({headerGroup}: TableHeaderProps) => (
  <thead>
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => (
        <th
          {...column.getHeaderProps({
            style: {maxWidth: column.maxWidth},
          })}
        >
          {column.render('Header')}
        </th>
      ))}
    </tr>
  </thead>
);

interface TableExpandedRowProps extends PropsWithChildren {
  product: ShopProduct;
  tableRowColumnSpan: number;
}
const TableExpandedRow = ({
  product,
  tableRowColumnSpan,
  children,
}: TableExpandedRowProps) => (
  <Accordion.Collapse
    eventKey={product.ref}
    as="tr"
    className={styles.expandedRow}
  >
    <td colSpan={tableRowColumnSpan}>{children}</td>
  </Accordion.Collapse>
);

interface TableRowProps {
  row: ReactTableRow<ShopProduct>;
  currency: string;
  tableRowColumnSpan: number;
  setCombinationQuantity?: CombinationQuantitySetter;
  ExpandedRowContentComponent?: React.ElementType<ExpandedRowContentProps>;
}
const TableRow = ({
  row,
  currency,
  tableRowColumnSpan,
  setCombinationQuantity,
  ExpandedRowContentComponent,
}: TableRowProps) => (
  <React.Fragment>
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps({
            style: {maxWidth: cell.column.maxWidth},
          })}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
    {ExpandedRowContentComponent ? (
      <TableExpandedRow
        product={row.original}
        tableRowColumnSpan={tableRowColumnSpan}
      >
        <ExpandedRowContentComponent
          product={row.original}
          currency={currency}
          setCombinationQuantity={setCombinationQuantity}
        />
      </TableExpandedRow>
    ) : null}
  </React.Fragment>
);

interface TableBodyProps extends PropsWithChildren {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<ShopProduct>,
  ) => ReactTableBodyProps;
}
const TableBody = ({getTableBodyProps, children}: TableBodyProps) => (
  <tbody {...getTableBodyProps()}>{children}</tbody>
);

interface TableEmptyProps {
  tableRowColumnSpan: number;
}
const TableEmpty = ({tableRowColumnSpan}: TableEmptyProps) => (
  <tr>
    <td className={styles.tableInfo} colSpan={tableRowColumnSpan}>
      No se encontraron resultados
    </td>
  </tr>
);

interface TableProps {
  currency: string;
  setCombinationQuantity?: CombinationQuantitySetter;
  enableSelection?: boolean;
  columns: Column<ShopProduct>[];
  ExpandedRowContentComponent?: React.ElementType<ExpandedRowContentProps>;
}
const Table = ({
  currency,
  setCombinationQuantity,
  enableSelection = false,
  columns,
  ExpandedRowContentComponent,
}: TableProps) => {
  const {products} = useShopProductsContainer();

  const {getTableProps, getTableBodyProps, prepareRow, headerGroups, rows} =
    useTable(
      {
        columns,
        data: products,
      },
      useRowSelect,
      addSelectionColumn(enableSelection),
    );

  const headerGroup = useMemo(() => headerGroups[0], [headerGroups]);
  const columnSpan = headerGroup.headers.length;

  return (
    <Row className="g-2">
      <div className="table-responsive">
        <Accordion>
          <table className={`${styles.table} table`} {...getTableProps()}>
            <TableHeader headerGroup={headerGroup} />
            <TableBody getTableBodyProps={getTableBodyProps}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    currency={currency}
                    tableRowColumnSpan={columnSpan}
                    setCombinationQuantity={setCombinationQuantity}
                    ExpandedRowContentComponent={ExpandedRowContentComponent}
                  />
                );
              })}
              {products.length === 0 ? (
                <TableEmpty tableRowColumnSpan={columnSpan} />
              ) : null}
            </TableBody>
          </table>
        </Accordion>
      </div>
    </Row>
  );
};

export default Table;
