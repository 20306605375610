import React, {useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {formatDate, formatMoney} from '../../utils';
import DataTable from '../../components/DataTable';
import {PURCHASE_ORDER_STATUSES} from '../../resources';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';
import {useEcommerce} from '../../contexts/EcommerceContext';
import i18n from '../../translations/i18n';

import styles from './StoreUserPurchaseOrdersPage.module.scss';

const FILTERED_STATUS = PURCHASE_ORDER_STATUSES.filter(([value]) => value !== 'draft');

const FILTERS_LABELS = {
  draft: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.draft`),
  new: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.new`),
  in_preparation: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.in_preparation`),
  invoiced: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.invoiced`),
  shipped: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.shipped`),
  cancelled: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.cancelled`),
  delivered: i18n.t(`pages:StoreUserPurchaseOrdersPage.status.delivered`),
};

const StoreUserPurchaseOrdersPage = () => {
  const [state] = useEcommerce();
  const navigate = useNavigate();
  const {t} = useTranslation('pages');

  const statusCell = ({value}) => (
    <span className={`${styles.status} ${styles[value]}`}>
      <Icon icon="fa-solid fa-circle" />
      &nbsp;
      <Trans i18nKey={`StoreUserPurchaseOrdersPage.status.${value}`} />
    </span>
  );

  const columns = useMemo(
    () => [
      {
        Header: t('StoreUserPurchaseOrdersPage.DataTable.columns.headers.code'),
        accessor: 'code',
      },
      {
        Header: t('StoreUserPurchaseOrdersPage.DataTable.columns.headers.submitted_on'),
        accessor: 'submitted_on',
        Cell: ({value}) => formatDate(value),
      },
      {
        Header: t('StoreUserPurchaseOrdersPage.DataTable.columns.headers.total'),
        accessor: 'total',
        Cell: ({value}) => formatMoney(value, state.settings?.COMPANY_CURRENCY),
      },
      {
        Header: t('StoreUserPurchaseOrdersPage.DataTable.columns.headers.status'),
        accessor: 'status',
        Cell: statusCell,
      },
    ],
    [t, state],
  );

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-eye',
        onClick: (cell) => navigate(`/user/orders/${cell.row.original.id}/`),
        key: 'edit',
      },
    ],
    [navigate],
  );

  const filterables = [
    {
      field: 'status',
      label: t('StoreUserPurchaseOrdersPage.DataTable.filters.labels.status'),
      type: 'choices',
      choices: FILTERED_STATUS.map(([value]) => ({
        value,
        label: FILTERS_LABELS[value],
      })),
    },
    {
      field: 'submitted_on',
      label: t('StoreUserPurchaseOrdersPage.DataTable.filters.labels.submitted_on'),
      type: 'daterange',
    },
  ];
  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {};
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  const source = {
    type: 'remote',
    url: '/user/purchase-order',
  };

  return (
    <div className="mt-2 px-1 px-lg-4">
      <h1>
        <Icon icon="fas fa-bag-shopping" /> {t('StoreUserPurchaseOrdersPage.title')}
      </h1>
      <DataTable
        columns={columns}
        buttons={buttons}
        source={source}
        filterables={filterables}
        initialFilters={initialFilters}
        allowSelection={false}
        allowSearch
        searchPlaceholder={t('StoreUserPurchaseOrdersPage.DataTable.search_bar.placeholder')}
      />
    </div>
  );
};

export default StoreUserPurchaseOrdersPage;
