import React, {useMemo} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Col, Row, Tab, Form} from 'react-bootstrap';
import SelectField from '../../components/FormFields/SelectField';
import SwitchField from '../../components/FormFields/SwitchField';
import TextField from '../../components/FormFields/TextField';
import TextareaField from '../../components/FormFields/TextareaField';
import NumberField from '../../components/FormFields/NumberField';
import TableForm from '../../components/TableForm/TableForm';
import RichTextField from '../../components/FormFields/RichTextField';
import ImageUploadField from '../../components/FormFields/ImageUploadField';
import {makeEmptyVolumeDiscount} from '../../utils';
import TypeField from '../../components/FormFields/TypeField';

const SettingsForm = React.forwardRef(({group, settings, initialValues, handleSubmit}, ref) => {
  const formRef = ref;

  const renderField = (setting) => {
    switch (setting.options.type) {
      case 'select':
        return <SelectField name={setting.name} options={setting.options.choices} />;
      case 'image':
        return (
          <ImageUploadField
            name={setting.name}
            multiple={setting.options.multiple}
            category="settings"
            enableLinkAttach={setting.options.enable_attach_link}
            fullWidth={setting.options.full_width}
          />
        );
      case 'switch':
        return <SwitchField name={setting.name} />;
      case 'analytics':
      case 'adwords':
        return <TextField name={setting.name} />;
      case 'textarea':
        return <TextareaField name={setting.name} />;
      case 'richtextarea':
        return (
          <RichTextField
            name={setting.name}
            maxCharacters={setting.options.max_characters}
            availableControls={setting.options.available_controls}
          />
        );
      case 'discount':
        return (
          <TableForm
            name={setting.name}
            columns={[
              {
                header: 'Minimum Amount',
                name: 'min_amount',
                component: <NumberField symbol="$" />,
              },
              {
                header: 'Discount',
                name: 'discount',
                component: <NumberField symbol="%" />,
              },
            ]}
            makeElement={makeEmptyVolumeDiscount}
            minElements={0}
            maxElements={5}
            elementKey={(elem) => elem.uuid}
          />
        );
      case 'minimum_purchase':
        return <NumberField name={setting.name} symbol="$" />;
      default:
        return <TypeField name={setting.name} type={setting.options.type} />;
    }
  };

  const validationSchema = useMemo(() => {
    const shape = settings.reduce((accum, current) => {
      switch (current.options.type) {
        case 'email':
          accum[current.name] = Yup.string().email('Please enter a valid email');
          break;
        case 'textarea':
          accum[current.name] = current.options.max_characters
            ? Yup.string().max(
                current.options.max_characters,
                `The limit is ${current.options.max_characters} characters`,
              )
            : Yup.string();
          break;
        case 'richtextarea':
          accum[current.name] = current.options.max_characters
            ? Yup.string().max(
                current.options.max_characters,
                `The limit is ${current.options.max_characters} characters`,
              )
            : Yup.string();
          break;
        case 'url':
          accum[current.name] = Yup.string().url('Please enter a valid url');
          break;
        case 'image':
          accum[current.name] = current.options.multiple ? Yup.array() : Yup.string();
          break;
        case 'analytics':
          accum[current.name] = Yup.string().matches(
            /^([U])([A])([-])([0-9]{9})([-])([0-9]{1})+$/,
            'Please enter a valid Google Analytics ID, e.g: UA-XXXXXXXXX-X',
          );
          break;
        case 'adwords':
          accum[current.name] = Yup.string().matches(
            /^(GT|G|AW)-\w*$/,
            'Please enter a valid Google Adwords ID, e.g: AW-XXXXXXXXX',
          );
          break;
        case 'discount':
          accum[current.name] = Yup.array().of(
            Yup.object().shape({
              min_amount: Yup.number()
                .typeError('Must be a number')
                .min(0, 'Must be a positive amount')
                .required('Required'),
              discount: Yup.number()
                .typeError('Must be a number')
                .positive('Must be positive')
                .lessThan(100, 'Must be less than 100%')
                .required('Required'),
            }),
          );
          break;
        case 'minimum_purchase':
          accum[current.name] = Yup.number()
            .typeError('Must be a number')
            .min(0, 'Must be a positive amount');
          break;
        default:
          accum[current.name] = Yup.string();
      }
      accum[current.name].withMutation((schema) =>
        current.options.required ? schema.required('Required') : schema.notRequired(),
      );
      return accum;
    }, {});

    return Yup.object().shape(shape);
  }, [settings]);

  return (
    <Tab.Pane key={group} eventKey={group}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        handleChange
        handleBlur
      >
        {(form) => (
          <Form
            ref={() => {
              formRef.current[group] = form;
            }}
            noValidate
            onSubmit={form.handleSubmit}
          >
            <Row>
              {settings.map((setting) => (
                <Col
                  xs={setting.options.full_width ? 12 : 6}
                  key={setting.name}
                  className={
                    setting.options.ordering !== undefined
                      ? `order-${setting.options.ordering}`
                      : null
                  }
                >
                  <Form.Group className="mb-2 position-relative">
                    <Form.Label>{setting.label}</Form.Label>
                    {renderField(setting)}
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </Form>
        )}
      </Formik>
    </Tab.Pane>
  );
});

export default SettingsForm;
