import React, {useMemo} from 'react';
import {Pagination} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import styles from './Paginator.module.scss';

const EllipsisPaginator = ({page, totalPages, setPagination}) => {
  const arrayPages = useMemo(() => {
    if (page <= 5) {
      return [2, 3, 4, 5];
    }

    if (page >= totalPages - 4) {
      return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1];
    }

    return [page - 2, page - 1, page, page + 1, page + 2];
  }, [page, totalPages]);

  return (
    <React.Fragment>
      <Pagination.Item active={page === 1} onClick={() => setPagination(1)}>
        1
      </Pagination.Item>
      {page > 5 && <Pagination.Ellipsis />}
      {arrayPages.map((item) => (
        <Pagination.Item
          key={item}
          active={page === item}
          onClick={() => setPagination(item)}
        >
          {item}
        </Pagination.Item>
      ))}
      {page < totalPages - 4 && <Pagination.Ellipsis />}
      <Pagination.Item
        active={page === totalPages}
        onClick={() => setPagination(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    </React.Fragment>
  );
};

const SimplePaginator = ({page, totalPages, setPagination}) => {
  const arrayPages = useMemo(
    () =>
      Array(totalPages)
        .fill()
        .map((_, i) => i + 1),
    [totalPages],
  );

  return (
    <React.Fragment>
      {arrayPages.map((item) => (
        <Pagination.Item
          key={item}
          active={page === item}
          onClick={() => setPagination(item)}
        >
          {item}
        </Pagination.Item>
      ))}
    </React.Fragment>
  );
};

const Paginator = ({page, perPage, totalItems, setPage}) => {
  const TOTAL_PAGES = Math.ceil(totalItems / perPage);

  if (TOTAL_PAGES <= 1) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center my-2">
      <Pagination className={styles.pagination}>
        <Pagination.Item onClick={() => setPage(1)} disabled={page === 1}>
          <Icon icon="fa-solid fa-angles-left" />
        </Pagination.Item>
        <Pagination.Item
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <Icon icon="fa-solid fa-angle-left" />
        </Pagination.Item>
        {TOTAL_PAGES > 10 ? (
          <EllipsisPaginator
            page={page}
            totalPages={TOTAL_PAGES}
            setPagination={setPage}
          />
        ) : (
          <SimplePaginator
            page={page}
            totalPages={TOTAL_PAGES}
            setPagination={setPage}
          />
        )}
        <Pagination.Item
          onClick={() => setPage(page + 1)}
          disabled={page === TOTAL_PAGES}
        >
          <Icon icon="fa-solid fa-angle-right" />
        </Pagination.Item>
        <Pagination.Item
          onClick={() => setPage(TOTAL_PAGES)}
          disabled={page === TOTAL_PAGES}
        >
          <Icon icon="fa-solid fa-angles-right" />
        </Pagination.Item>
      </Pagination>
    </div>
  );
};

export default Paginator;
