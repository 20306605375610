import React, {useState} from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {SerializerValidatedData, InvalidImporterData} from './types';
import PageLoader from '../../components/PageLoader';
import ManualQuickOrder from './ManualQuickOrder';
import ImportQuickOrder from './ImportQuickOrder';

import styles from './StoreQuickOrderPage.module.scss';

type StoreQuickOrderPageProps = {
  fetchStore: (uid: string) => Promise<void>;
};

const StoreQuickOrderPage = ({fetchStore}: StoreQuickOrderPageProps) => {
  const {t} = useTranslation('pages');
  const [validImporterData, setValidImporterData] = useState<SerializerValidatedData[]>([]);
  const [invalidImporterData, setInvalidImporterData] = useState<InvalidImporterData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <Container fluid className="px-1 px-lg-4 mt-2">
      {/*
        TODO: This PageLoader is a new component to use a full screen loader in pages,
        but, will be intresting to implement this component in StorePage, in the top level of
        our app and use useEcommerce hook to manage the loading state, until we implement
        react query.
      */}
      <PageLoader isLoading={isLoading} />
      <Row className="mb-2">
        <Col xs={12} className={styles.title}>
          <h1>{t('StoreQuickOrderPage.title')}</h1>
          <Button variant="primary" onClick={() => navigate('/cart/')}>
            {t('StoreQuickOrderPage.goToCart')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={7} className="mb-md-0 mb-2">
          <h3>{t('StoreQuickOrderPage.ImportQuickOrder.title')}</h3>
          <ImportQuickOrder
            setValidImporterData={setValidImporterData}
            setInvalidImporterData={setInvalidImporterData}
            fetchStore={fetchStore}
            setIsLoading={setIsLoading}
          />
          <h3 className="mt-3">{t('StoreQuickOrderPage.ManualQuickOrder.title')}</h3>
          <ManualQuickOrder
            validImporterData={validImporterData}
            setValidImporterData={setValidImporterData}
          />
        </Col>
        <Col md={12} lg={5}>
          <Card className="mb-2 mt-md-4 mt-2">
            <Card.Body className={styles.resultsCard}>
              {/*
                TODO: Implement DataTable component with type 'local' here.
                We need refactor the component to typescript and implement some logic in DataTableView
                to hide the top bar table where we show the results quantity, order type selector and per page selector.
              */}
              <h4>{t('StoreQuickOrderPage.validProducts.title')}</h4>
              <div>
                <span>{t('StoreQuickOrderPage.products.columns.default_code')}</span>
                <span>{t('StoreQuickOrderPage.products.columns.quantity')}</span>
              </div>
              {validImporterData.map((row) => (
                <div key={row.product_id}>
                  <p>
                    {row.default_code} - {row.product_name}
                  </p>
                  <p>{row.quantity}</p>
                </div>
              ))}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body className={styles.resultsCard}>
              <h4>{t('StoreQuickOrderPage.invalidProducts.title')}</h4>
              <div>
                <span>{t('StoreQuickOrderPage.products.columns.default_code')}</span>
                <span>{t('StoreQuickOrderPage.products.columns.error')}</span>
              </div>
              {invalidImporterData.map((row) => (
                <div key={row.data.default_code}>
                  <p>{row.data.default_code}</p>
                  <p
                    title={
                      row.error.default_code?.at(0) ||
                      row.error.quantity?.at(0) ||
                      row.error.non_field_errors?.at(0)
                    }
                  >
                    {row.error.default_code?.at(0) ||
                      row.error.quantity?.at(0) ||
                      row.error.non_field_errors?.at(0)}
                  </p>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreQuickOrderPage;
