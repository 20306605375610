import React, {useCallback, useState} from 'react';
import {Form, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import useDebounce from '../../hooks/useDebounce';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';

import styles from './ShopProduct.module.scss';

const parseInputQuantity = (value: string) =>
  value === '' ? 0 : parseInt(value, 10);

const isValidQuantity = (quantity: number, packQuantity: number) =>
  quantity >= 0 && quantity % packQuantity === 0;

interface QuantityInputProps {
  productId: number;
  combinationId: string;
  quantity: number;
  packQuantity: number;
  setCombinationQuantity: CombinationQuantitySetter;
  disabled?: boolean;
  enableTooltip?: boolean;
}
const QuantityInput = ({
  productId,
  combinationId,
  quantity,
  packQuantity,
  setCombinationQuantity,
  disabled = false,
  enableTooltip = false, // Show a tooltip when the pack quantity != 1.
}: QuantityInputProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [typedQuantity, setTypedQuantity] = useState(quantity.toString());
  const [waitingApi, setWaitingApi] = useState(false);
  const shownQuantity =
    isTyping || waitingApi ? typedQuantity : quantity.toString();

  const inputValue = parseInt(shownQuantity, 10).toString();

  // Handler for changing the quantity to the given value.
  const setQuantity = useCallback(
    async (newQuantity: number) => {
      setWaitingApi(true);
      try {
        await setCombinationQuantity(productId, combinationId, newQuantity);
      } finally {
        setWaitingApi(false);
      }
    },
    [combinationId, productId, setCombinationQuantity],
  );

  // Handler for changing the quantity according to the typed value after a debounce.
  const handleDebounceTypedQuantity = useCallback(() => {
    if (!isTyping) {
      return;
    }
    if (isValidQuantity(parseInt(typedQuantity, 10), packQuantity)) {
      setQuantity(parseInt(typedQuantity, 10));
      setIsTyping(false);
    } else {
      setIsTyping(false);
      setTypedQuantity(quantity.toString());
    }
  }, [setQuantity, packQuantity, typedQuantity, quantity, isTyping]);

  // Handler for the user input.
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventInputValue = parseInputQuantity(event.target.value);
      if (eventInputValue === quantity) {
        setIsTyping(false);
      } else {
        setIsTyping(true);
        setTypedQuantity(eventInputValue.toString());
      }
    },
    [quantity],
  );

  useDebounce(handleDebounceTypedQuantity, 800);

  const decrementDisabled =
    disabled || waitingApi || isTyping || quantity === 0;
  const incrementDisabled = disabled || waitingApi || isTyping;

  const isInputInvalid = !isValidQuantity(
    parseInt(inputValue, 10),
    packQuantity,
  );

  return (
    <InputGroup className={styles.inputQuantity}>
      <button
        type="button"
        onClick={() => {
          const desiredQuantity = quantity - packQuantity;
          setTypedQuantity(desiredQuantity.toString());
          setQuantity(desiredQuantity);
        }}
        disabled={decrementDisabled}
      >
        <Icon icon="minus" />
      </button>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        show={showTooltip && packQuantity !== 1}
        onToggle={() =>
          enableTooltip
            ? setShowTooltip((prevState) => !prevState)
            : setShowTooltip(false)
        }
        overlay={<Tooltip>El pack es de {packQuantity} unidades.</Tooltip>}
      >
        <Form.Control
          type="number"
          value={inputValue}
          name="quantity"
          onChange={handleInputChange}
          disabled={waitingApi || disabled}
          isInvalid={isInputInvalid}
          inputMode="numeric"
        />
      </OverlayTrigger>
      <button
        type="button"
        onClick={() => {
          const desiredQuantity = quantity + packQuantity;
          setTypedQuantity(desiredQuantity.toString());
          setQuantity(desiredQuantity);
        }}
        disabled={incrementDisabled}
      >
        <Icon icon="plus" />
      </button>
    </InputGroup>
  );
};
interface DefaultCombinationInputProps {
  product: ShopProduct;
  setCombinationQuantity: CombinationQuantitySetter;
  enableTooltip?: boolean;
}
const DefaultCombinationInput = ({
  product,
  setCombinationQuantity,
  enableTooltip = false,
}: DefaultCombinationInputProps) => {
  const combination = product.combinations[0];
  const combinationId = combination.id;
  const combinationPackName = combination.pack;
  const combinationPack = product.packs.find(
    (pack) => pack.name === combinationPackName,
  );
  const combinationPackQuantity = combinationPack!.quantity;

  const hasMultipleCombinations = product.combinations.length > 1;

  return (
    <QuantityInput
      productId={product.id}
      combinationId={combinationId}
      quantity={product.quantity}
      packQuantity={combinationPackQuantity}
      setCombinationQuantity={setCombinationQuantity}
      disabled={hasMultipleCombinations}
      enableTooltip={enableTooltip}
    />
  );
};
interface CombinationInputProps {
  product: ShopProduct;
  combinationId: string;
  setCombinationQuantity: CombinationQuantitySetter;
  enableTooltip?: boolean;
}
const CombinationInput = ({
  product,
  combinationId,
  setCombinationQuantity,
  enableTooltip = false,
}: CombinationInputProps) => {
  const combination = product.combinations.find(
    (comb) => comb.id === combinationId,
  );
  const combinationPackName = combination!.pack;
  const combinationPack = product.packs.find(
    (pack) => pack.name === combinationPackName,
  );
  const combinationPackQuantity = combinationPack!.quantity;
  const combinationQuantity = product.quantities[combinationId];

  return (
    <QuantityInput
      productId={product.id}
      combinationId={combinationId}
      quantity={combinationQuantity}
      packQuantity={combinationPackQuantity}
      setCombinationQuantity={setCombinationQuantity}
      enableTooltip={enableTooltip}
    />
  );
};

export {CombinationInput, DefaultCombinationInput};
