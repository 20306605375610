import * as React from 'react';

const Woman = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="529.118"
    height="398.713"
    viewBox="0 0 529.118 398.713"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <g
      id="Grupo_41885"
      data-name="Grupo 41885"
      transform="translate(-396.272 -681.5)"
    >
      <g id="freepik--Character--inject-93" transform="translate(396.61 681.5)">
        <path
          id="Trazado_1954"
          data-name="Trazado 1954"
          d="M276.956,389.24s-4.637,15.92-3.085,25.651c0,0,1.851,16.2,3.98,24.835s4.3,14.626-7.86,18.049-42.029-3.841-49.033-1.393-46.884-3.025-52.834-5.652-6.885-20.835,5.97-27.163S276.956,389.24,276.956,389.24Z"
          transform="translate(-3.51 -61.002)"
          fill="#fff"
        />
        <path
          id="Trazado_1955"
          data-name="Trazado 1955"
          d="M275.308,412.3s3.463,19.462,3.363,23a77.432,77.432,0,0,0-18.129-2.905c-8.2,0-32.775.318-42.526-1.154s-28.5-8.338-37.173-8.378a93.094,93.094,0,0,0-16.417,1.453s-1.015-6.945,12.676-10.567,63.58-14.109,63.58-14.109S251.826,411.62,275.308,412.3Z"
          transform="translate(-3.633 -50.706)"
          fill="#fff"
        />
        <path
          id="Trazado_1956"
          data-name="Trazado 1956"
          d="M344.167,402.954s-8.736-5.532-16.955-2.149-37.491,3.463-37.491,3.463-23.164-2.905-33.034,1.99-10.965,12.278-10.189,18.029,15.343,21.094,34.467,21.89,20.7-3.5,36.417-4.338c0,0,21.094-1.333,27.183-3.98s14.049-4.836,13.811-16.218S344.167,402.954,344.167,402.954Z"
          transform="translate(-246.297 -50.637)"
          fill="#082032"
        />
        <path
          id="Trazado_1957"
          data-name="Trazado 1957"
          d="M338.868,402.5s-8.855-5.015-24.5-.318-43.2-2.249-55.143,1.891-13.592,11.94-12.059,14.945,11.7,15.164,23,17.094,36.795.756,48.476,2.885c0,0,24.139,1.393,31.84-10.666S338.868,402.5,338.868,402.5Z"
          transform="translate(-240.999 -50.181)"
          fill="#fff"
        />
        <path
          id="Trazado_1958"
          data-name="Trazado 1958"
          d="M163.207,431.224l14.726-27.442L137.775,362.35l-36.238,4.318L81.02,397.931l5.114,17.93Z"
          transform="translate(263.051 -87.623)"
          fill="#082032"
        />
        <path
          id="Trazado_1959"
          data-name="Trazado 1959"
          d="M439.577,421.6s-1.114,51.322-34.387,56.874S253.175,426.26,253.175,426.26s-44.218,22.965-77.49,25.87-81.59,9.094-86.525,14.626-23.4-17.671-12.756-31.6c0,0,36.039-2.288,58.764-13.612s97.032-54.307,112-56.934S348.654,403,348.654,403l26.069,5.552Z"
          transform="translate(17.068 -85.505)"
          fill="#082032"
        />
        <path
          id="Trazado_1960"
          data-name="Trazado 1960"
          d="M238.807,419.8l6.388-20.4s-63.938-71.063-91.142-70.107-32,25.014-33.83,43.78,0,46.645,0,46.645l62.525,7.821,47.143,21.054,9.015-28.8"
          transform="translate(119.393 -120.357)"
          fill="#082032"
        />
        <path
          id="Trazado_1961"
          data-name="Trazado 1961"
          d="M169.281,392.352c0,1.114-2.189-32.4-11.8-47.382"
          transform="translate(119.548 -104.829)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1962"
          data-name="Trazado 1962"
          d="M168.417,344.69a92.02,92.02,0,0,1-.119,19.9"
          transform="translate(98.402 -105.106)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1963"
          data-name="Trazado 1963"
          d="M275.706,411.679s4.716,24.656,3.423,29.85c-1.652,6.587-18.885,6.726-23.78,6.945-10.826.458-21.631-.617-32.457-.438-8.239.139-16.457,1.015-24.716.9a55,55,0,0,1-24-4.955c-6.328-3.164-10.467-7.661-9.671-15.024,1.055-9.711,4.3-12.159,17.711-13.691S242.97,399.58,242.97,399.58,250.313,412.475,275.706,411.679Z"
          transform="translate(-4.309 -50.766)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1964"
          data-name="Trazado 1964"
          d="M200.447,422.908a70.743,70.743,0,0,1,0-27.86c3.144-14.169-21.233-1.831-21.233-1.831L166.1,407.366l1.612,3.423"
          transform="translate(70.95 -61.994)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1965"
          data-name="Trazado 1965"
          d="M277.359,428.626a6.089,6.089,0,0,0-4.676-6.169c-3.542-.935-7.841-.3-11.442-.2-6.229.2-12.457.458-18.686.517-20.974.179-41.332-6.467-62.287-7.263-3.682-.139-10.268-1.114-13.532,1.174-5.254,3.681,4.438,10.905,8.079,12.378a75.4,75.4,0,0,0,27.86,4.418c12.3-.577,31.561-.5,39.9-.4S266.553,436.367,277.359,428.626Z"
          transform="translate(-3.913 -35.315)"
          fill="#263238"
        />
        <path
          id="Trazado_1966"
          data-name="Trazado 1966"
          d="M278.369,423.849a44.355,44.355,0,0,0-17.313-2.906c-21.771-.1-45.77,1.194-66.545-6.527-9.194-3.423-24.218-5.254-29.691,0"
          transform="translate(-4.147 -39.334)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1967"
          data-name="Trazado 1967"
          d="M357.063,427.3s-.776,8.915-15.721,11.025-45.113,5.97-55.083,7.343-38.566-9.453-38.825-23.482,12.059-18.228,19.681-18.487,43.78,1.313,53.491-1.313,15.223-3.681,20.994-.259"
          transform="translate(-247.233 -50.388)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1968"
          data-name="Trazado 1968"
          d="M271.33,402.13s-8.5,6.507-9.293,13.054,16.258,19.4,31.482,20.716,34.725-1.373,43.461,1.99"
          transform="translate(-256.165 -48.241)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1969"
          data-name="Trazado 1969"
          d="M161.578,302.716s-11.622,8.617-17.233,4.915-17.91-17.711-17.91-17.711l-17.751,9.671-18.626,59.8L77.6,412.245l-19.9,9.731v8.457s11.7,12.119,23.88,15.721,25.472,7.1,35.5,4.756,29.253-68.774,29.253-68.774l48.357-70.665Z"
          transform="translate(292.699 -159.328)"
          fill="#fff"
        />
        <path
          id="Trazado_1970"
          data-name="Trazado 1970"
          d="M192.5,396.939l86.167-21.89L192.5,335.13,110.27,356.1Z"
          transform="translate(104.113 -114.571)"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1971"
          data-name="Trazado 1971"
          d="M253.308,425.461l-19.263-88.435L153.57,306.32,170.107,385.2Z"
          transform="translate(43.301 -143.092)"
          fill="#fff"
        />
        <path
          id="Trazado_1972"
          data-name="Trazado 1972"
          d="M280.971,363.946v4.836l-86.684,23.223-83.56-41.869-.458-4.816,84.017,40.636Z"
          transform="translate(101.805 -104.483)"
          fill="#082032"
        />
        <path
          id="Trazado_1973"
          data-name="Trazado 1973"
          d="M163.914,300.921a136.275,136.275,0,0,0-22.984,39.8c-7.721,22.268-22.228,69.391-20.974,87.56L89.25,404.4a187.2,187.2,0,0,0,3.323-46.247c-1.691-22.686-3.4-58.824,16.437-61.789,20.736-4.438,48.4-11.2,48.4-11.2Z"
          transform="translate(260.691 -164.04)"
          style={{fill: 'var(--bs-secondary)'}}
        />
        <path
          id="Trazado_1974"
          data-name="Trazado 1974"
          d="M125.507,288.62s31.143,16.676,35,23.4,6.109,4.736,4.657,15.363-9.174,45.272-11.044,56.973-8.219,62.227-8.219,62.227a110.755,110.755,0,0,1-17.691,5.97l40.7,53.193s-15.343,33.332-44.655,34.367-42.9-27.482-55.58-47.561S47.5,445.332,47.5,445.332L41.35,444.2l2.288-28.855,3.861.6s.816-40.238,17.91-70.565S125.507,288.62,125.507,288.62Z"
          transform="translate(351.018 -160.615)"
          style={{fill: 'var(--bs-secondary)'}}
        />
        <path
          id="Trazado_1975"
          data-name="Trazado 1975"
          d="M130.261,287.71s-13.552,6.925-14.945,15.641,3.065,19.522-3.443,23.78-14.149,5.154-17.91,16.5-2.627,63.8,0,72.794,6.368,0,6.368,0L104.65,393.5l8.875-32.815,8.776-24.636,7.96-17.074,9.552-19.442Z"
          transform="translate(280.216 -161.516)"
          style={{fill: 'var(--bs-secondary)'}}
          opacity="0.2"
        />
        <path
          id="Trazado_1976"
          data-name="Trazado 1976"
          d="M168.5,385.695s-12.676,1.672-16.915-.159-7.96-2.746-10.945-11.1-8.557-10.925-14.547-11.323-19.542-3.045-21.89-9.572l5.87-.418a13.928,13.928,0,0,1-5.453-2.985h2.468s11.781-6.189,17.791-7.681,12.437-3.045,18.109,1.851,8.716,15.442,11.025,18.189,8.418,6.149,16.437,6.129Z"
          transform="translate(224.409 -108.778)"
          fill="#fff"
        />
        <path
          id="Trazado_1977"
          data-name="Trazado 1977"
          d="M169.11,379.841s-17.194,3-22.925-3.741-3.741-16.3-16.477-18.507-23.522-4.975-24.119-9.95c0,0,26.188-2.488,31.84,1.99"
          transform="translate(222.979 -102.865)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1978"
          data-name="Trazado 1978"
          d="M124.907,350.473a8.7,8.7,0,0,1-4.657-2.726l11.761-2.249c4.119-.8,8.239-2.169,12.5-1.99a14.686,14.686,0,0,1,9.751,5.393"
          transform="translate(208.657 -106.292)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1979"
          data-name="Trazado 1979"
          d="M104.71,349.067l13.631-5.194c6.268-2.388,13.651-4.7,19.363-1.174a19.262,19.262,0,0,1,5.632,6.109,67.367,67.367,0,0,1,4.4,7.96,29.672,29.672,0,0,0,3.224,5.97c3.721,4.7,9.472,5.87,15.462,6.129"
          transform="translate(227.421 -108.866)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1980"
          data-name="Trazado 1980"
          d="M117.03,348.792s-17.293,6.766-24.477,15.383c0,0-17.333,11.781-24.537-1.174s-7.96-39.044-7.96-39.044L53.35,309.052l2.587-39.979,18.746-28.158L92.473,227.84,111.8,265.132l22.447,15.283-2.567,41.79Z"
          transform="translate(361.803 -220.787)"
          fill="#fff"
        />
        <path
          id="Trazado_1983"
          data-name="Trazado 1983"
          d="M56.2,244.49s-1.114,3.622,0,14.447-1.552,24.656,1.99,34.367,9.552,24.517,26.049,25.293c12.656,0,25.87-17.91,27.5-29.671,0,0,15.263-7.164,13.452-17.313s-11.6-7.423-14.169,0"
          transform="translate(366.018 -204.303)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1986"
          data-name="Trazado 1986"
          d="M62.542,310.944c-4.418-11.224,3.821-44.874,3.821-44.874"
          transform="translate(413.982 -182.939)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1990"
          data-name="Trazado 1990"
          d="M82.869,280.44s3.542,7.084-2.109,22.586"
          transform="translate(357.538 -168.713)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1991"
          data-name="Trazado 1991"
          d="M95.566,285.835s-38.447-14.248-37.81-38.327c0,0-6.408,15.542-20.855,23.561,0,0,4.139-22.009,8.955-31.581s33.551-17.094,44.556-14.766,32.218,6.746,36.616,42.208,0,62.128,10.348,76.356,8.8,38.825-6.229,42.188A31.6,31.6,0,0,0,123.585,373c-5.433-5.174-32.377-20.178-32.377-20.178s-1.174-26.945,4.318-45.392c0,0,11.5-3.542,10.885-15.82C105.3,282.054,95.566,285.835,95.566,285.835Z"
          transform="translate(384.78 -224.296)"
          fill="#082032"
        />
        <path
          id="Trazado_1992"
          data-name="Trazado 1992"
          d="M118.477,231a86.268,86.268,0,0,0-14.209,19.363c-8.338,14.587-17.552,31.044-21.731,47.322a49.949,49.949,0,0,0,5.831,38.268,35.188,35.188,0,0,1,2.905,5.114,26.8,26.8,0,0,1,1.512,6.865c.7,5.313,1.512,10.826,4.418,15.343s8.517,7.721,13.671,6.169A21.173,21.173,0,0,0,122.2,358.6c3.98-10.846,6.487-17.751,4.736-29.173,0,0-18.208-16.039-18.069-34.487s-3.144-31.163,4.338-40.317S118.477,231,118.477,231Z"
          transform="translate(313.472 -217.658)"
          fill="#082032"
        />
        <path
          id="Trazado_1993"
          data-name="Trazado 1993"
          d="M126.888,288.88s25.233,13.174,35.5,22.885.975,32.158-3.283,54.924-7.562,44.456-11.323,79.6c-36.894,17.154-71.64,3.224-104.554-2.428l2.288-28.775c4.8.259,29.85,7.661,51.74,1.194,11.3-3.363,20.517,1.532,28.3,10.388"
          transform="translate(349.139 -160.358)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1994"
          data-name="Trazado 1994"
          d="M92.93,357.153c-11.243-5.532-21.412-7.96-31.84-3.343"
          transform="translate(387.714 -98.485)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1995"
          data-name="Trazado 1995"
          d="M70.41,380.672c6.507-17.572,8.1-37.929,11.124-57.292"
          transform="translate(380.563 -126.203)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1996"
          data-name="Trazado 1996"
          d="M83.536,308.1A71.958,71.958,0,0,0,52.91,343.442"
          transform="translate(413.386 -141.33)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1997"
          data-name="Trazado 1997"
          d="M70.594,314.69l-5.074,9.751"
          transform="translate(401.234 -134.806)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1998"
          data-name="Trazado 1998"
          d="M140.9,288.62s-50.128,28.815-64.894,66.625-12.756,60.237-12.756,60.237"
          transform="translate(335.623 -160.615)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_1999"
          data-name="Trazado 1999"
          d="M123.407,288.62c5.353,11.5.378,24.935-7.283,34.089-7.96,9.552-20.994,14.726-33.074,16.617-1.313.2-2.885.517-3.323,1.771a3.184,3.184,0,0,0,0,1.99c1.811,5.97,7.383,10.706,7.263,16.915a19.164,19.164,0,0,1-2.129,7.3,435.912,435.912,0,0,1-22.825,44.078"
          transform="translate(353.118 -160.615)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2000"
          data-name="Trazado 2000"
          d="M57.63,231.44a105.469,105.469,0,0,0,13.93,36.894"
          transform="translate(415.97 -217.223)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
          opacity="0.5"
        />
        <path
          id="Trazado_2001"
          data-name="Trazado 2001"
          d="M44.85,234.41c7.2,16.815,3.781,36.019,4.537,54.307a115.957,115.957,0,0,0,12.378,47.322"
          transform="translate(451.197 -214.283)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
          opacity="0.5"
        />
        <path
          id="Trazado_2002"
          data-name="Trazado 2002"
          d="M41.36,367.36s25.352,68.595,49.75,86.167,51.481,8.159,71.64-25.731L122.074,374.7"
          transform="translate(357.157 -82.663)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2003"
          data-name="Trazado 2003"
          d="M90.962,372.09A68.655,68.655,0,0,1,65.47,410.1"
          transform="translate(380.966 -77.981)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2004"
          data-name="Trazado 2004"
          d="M64.119,371.82a79.3,79.3,0,0,1-1.652,33.491"
          transform="translate(412.725 -78.248)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2005"
          data-name="Trazado 2005"
          d="M137.931,285.69s-23.124,24.676-37.213,67.461S84.48,419.437,84.48,419.437"
          transform="translate(296.167 -163.516)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2006"
          data-name="Trazado 2006"
          d="M101.678,307.229s-14.965,9.95-22.348-2.905-1.99-17.213-1.99-17.213"
          transform="translate(350.41 -162.11)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2007"
          data-name="Trazado 2007"
          d="M145.529,284.864c-4.676-.756-9.373.876-13.751,2.667a52.377,52.377,0,0,0-14.567,8.239,22.327,22.327,0,0,0-8.1,14.248c-.617,6.149,1.99,12.955-1.214,18.268-3.582,5.97-12.477,6.507-16.676,12.039a20.138,20.138,0,0,0-3.144,9.432A224.054,224.054,0,0,0,87.82,408.7"
          transform="translate(285.544 -164.521)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2008"
          data-name="Trazado 2008"
          d="M129.511,303.5A58.866,58.866,0,0,0,116.5,319.8a28.218,28.218,0,0,0-2.448,20.338c.756,2.468,1.99,4.8,2.587,7.283a38.9,38.9,0,0,1,1.015,7.283l1.99,25.87"
          transform="translate(247.514 -145.884)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2009"
          data-name="Trazado 2009"
          d="M163.357,283.64c-9.771,2.925-19.4,6.686-29.352,8.816s-19.363,2.667-28.835,6.388a18.908,18.908,0,0,0-3.98,1.99c-8.139,5.691-8.079,17.154-8.995,25.87-2.786,26.566,4.517,46.426-2.945,72.078"
          transform="translate(261.249 -165.545)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2010"
          data-name="Trazado 2010"
          d="M149.1,379.789s-24.935,2.647-47.9-18.149"
          transform="translate(251.726 -88.326)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2011"
          data-name="Trazado 2011"
          d="M96.316,343.491c4.577-6.686,7.7-14.786,6.507-22.785-.776-5.194-3.3-9.95-5.572-14.646A75.978,75.978,0,0,1,90.346,286.8a35.681,35.681,0,0,1,1.672-20.159,56.058,56.058,0,0,1,8.059-12.378"
          transform="translate(320.827 -194.631)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
          opacity="0.5"
        />
        <path
          id="Trazado_2012"
          data-name="Trazado 2012"
          d="M253.308,425.461,153.57,306.32,170.107,385.2Z"
          transform="translate(43.301 -143.092)"
          fill="#263238"
        />
        <path
          id="Trazado_2013"
          data-name="Trazado 2013"
          d="M253.308,425.461,153.57,306.32,170.107,385.2Z"
          transform="translate(43.301 -143.092)"
          fill="#fff"
          opacity="0.8"
        />
        <path
          id="Trazado_2014"
          data-name="Trazado 2014"
          d="M180.148,340.284c1.373,5.373,5.373,8.975,8.935,8.059s5.293-5.97,3.98-11.383-5.393-8.975-8.935-8.059S178.755,334.911,180.148,340.284Z"
          transform="translate(51.15 -120.879)"
          style={{fill: 'var(--bs-primary)'}}
        />
        <path
          id="Trazado_2015"
          data-name="Trazado 2015"
          d="M253.308,425.461l-19.263-88.435L153.57,306.32,170.107,385.2Z"
          transform="translate(43.301 -143.092)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2016"
          data-name="Trazado 2016"
          d="M255.31,424.184l-18.865-87.858-81.51-30.646-3,.756,80.853,31.223,19.263,88.435Z"
          transform="translate(44.563 -143.726)"
          fill="#263238"
        />
        <path
          id="Trazado_2017"
          data-name="Trazado 2017"
          d="M140.4,288.62s7.084,19.522-7.283,34.089-36.4,18.408-36.4,18.408v1.99s9.552,12.955,6.865,19.621-27.362,55.541-27.362,55.541l-13.93-2.328a141.667,141.667,0,0,1,15.283-65.67C94.948,317.236,140.4,288.62,140.4,288.62Z"
          transform="translate(336.126 -160.615)"
          style={{fill: 'var(--bs-secondary)'}}
          opacity="0.2"
        />
        <path
          id="Trazado_2021"
          data-name="Trazado 2021"
          d="M178.862,355.754,144.893,338.74l-7.383,1.652"
          transform="translate(149.707 -110.997)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2022"
          data-name="Trazado 2022"
          d="M122.97,373.567l60.078-15.84-17.253-6.706"
          transform="translate(174.456 -98.84)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_331"
          data-name="Línea 331"
          x2="56.038"
          y2="28.099"
          transform="translate(288.033 234.111)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_332"
          data-name="Línea 332"
          x2="41.73"
          y2="20.935"
          transform="translate(288.889 244.758)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_333"
          data-name="Línea 333"
          x2="27.78"
          y2="13.93"
          transform="translate(290.699 255.245)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_334"
          data-name="Línea 334"
          x1="54.108"
          y2="14.029"
          transform="translate(294.401 253.971)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_335"
          data-name="Línea 335"
          x1="41.352"
          y2="10.746"
          transform="translate(291.794 249.534)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_336"
          data-name="Línea 336"
          x1="34.069"
          y2="8.836"
          transform="translate(290.699 242.867)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_337"
          data-name="Línea 337"
          x1="25.512"
          y2="6.627"
          transform="translate(288.889 237.673)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <line
          id="Línea_338"
          data-name="Línea 338"
          x1="16.557"
          y2="4.278"
          transform="translate(287.217 232.758)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2023"
          data-name="Trazado 2023"
          d="M201.29,370.5c-10.985,3.821-19.9,7.96-19.9,7.96"
          transform="translate(39.958 -79.555)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2024"
          data-name="Trazado 2024"
          d="M237.844,398.509s-95.36-32.337-113.429-31.143a73.244,73.244,0,0,0-13.074,2.289"
          transform="translate(142.803 -82.689)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
        <path
          id="Trazado_2025"
          data-name="Trazado 2025"
          d="M128.047,385.448c-3.98-4.418-6.467-6.706-8.637-10.268"
          transform="translate(236.541 -74.921)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.069"
        />
      </g>
    </g>
  </svg>
);

export default Woman;
