import React, {RefObject} from 'react';
import {useShopProductsContainer} from './Container';
import SearchParamsPaginator from '../SearchParams/SearchParamsPaginator';

interface PaginatorProps {
  perPage: number;
  scrollElement?: RefObject<HTMLElement> | null;
}
const Paginator = ({perPage, scrollElement}: PaginatorProps) => {
  const {totalProducts} = useShopProductsContainer();

  return (
    <SearchParamsPaginator
      perPage={perPage}
      totalItems={totalProducts}
      scrollElement={scrollElement}
    />
  );
};

export default Paginator;
