import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Col, Row, Container, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {formatMoney} from '../../utils';
import CheckoutForm from './CheckoutForm';
import CheckoutSummary from './CheckoutSummary';
import CheckoutCartReviewView from './CheckoutCartReviewView';
import man from './man.svg';

import styles from './StoreCheckoutPage.module.scss';
import SanitizedHTML from '../../components/SanitizedHTML/SanitizedHTML';

interface ManAsideProps {
  children: React.ReactNode;
  className?: string;
}

const ManAside = ({children, className}: ManAsideProps) => (
  <Col xs={12} className={`${styles.checkout} ${className}`}>
    <div className={styles.image}>
      <img src={man} alt="man" />
    </div>
    {children}
  </Col>
);

interface SuccessMessageProps {
  purchaseOrder: PurchaseOrder;
}

const SuccessMessage = ({purchaseOrder}: SuccessMessageProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages');
  const [state] = useEcommerce();
  const whatsappMessage = `Hola, quería hacer una consulta sobre la reciente orden de compra ${purchaseOrder.code}`;

  const phoneNumber =
    state.seller?.user_phone || state.settings.COMPANY_PHONE_NUMBER_OPERATION || null;

  return (
    <div className={styles.form}>
      <h3>
        {t('StoreCheckoutPage.SuccessMessage.title', {
          purchaseOrderCode: purchaseOrder.code,
        })}
      </h3>
      <SanitizedHTML
        dirtyHTML={state.settings.COMPANY_CHECKOUT_CONFIRMATION_MESSAGE}
      /> 
      <Button variant="primary" onClick={() => navigate('/')}>
        {t('StoreCheckoutPage.SuccessMessage.button')}
      </Button>
      {phoneNumber && !state.settings.COMPANY_PHONE_NUMBER_OPERATION_ALWAYS_SHOW && (
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
            whatsappMessage,
          )}&app_absent=0`}
          target="_blank"
          rel="noreferrer"
          className={styles.whatsapp}
        >
          <Icon icon={['fab', 'whatsapp']} />
        </a>
      )}
    </div>
  );
};

const ErrorCard = () => {
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');
  return (
    <Col>
      <Card bg="white">
        <Card.Body className="d-flex justify-content-center align-items-center flex-column">
          <Icon icon="circle-xmark" color="red" fontSize="4rem" />
          <h5 className="mt-2">
            {Number(state.settings.COMPANY_MINIMUM_PURCHASE) === 0
              ? `${t('StoreCheckoutPage.ErrorCard.noItemsError')}`
              : `${
                  (t('StoreCheckoutPage.ErrorCard.minAmountError'),
                  {
                    minAmount: formatMoney(
                      state.settings.COMPANY_MINIMUM_PURCHASE,
                      state.settings.COMPANY_CURRENCY,
                    ),
                  })
                }`}
          </h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

interface PurchaseOrder {
  code: string;
}

const CheckoutView = ({fetchStore}: StoreCheckoutPageProps) => {
  const [state] = useEcommerce();
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(null);

  const allowCheckout = useMemo(
    () =>
      Number(state.cart.total) >= Number(state.settings.COMPANY_MINIMUM_PURCHASE) &&
      Number(state.cart.total) > 0,
    [state.settings.COMPANY_MINIMUM_PURCHASE, state.cart.total],
  );

  if (purchaseOrder !== null) {
    return (
      <ManAside className={styles.success}>
        <SuccessMessage purchaseOrder={purchaseOrder} />
      </ManAside>
    );
  }

  if (!allowCheckout) {
    return <ErrorCard />;
  }

  return (
    <ManAside>
      <CheckoutSummary />
      <CheckoutForm setPurchaseOrder={setPurchaseOrder} fetchStore={fetchStore} />
    </ManAside>
  );
};

interface StoreCheckoutPageProps {
  fetchStore: (uid: string) => Promise<void>;
}

const StoreCheckoutView = ({fetchStore}: StoreCheckoutPageProps) => (
  <Container fluid className="mt-2 mt-lg-4 px-lg-4 px-xs-2 h-100">
    <Row>
      <CheckoutView fetchStore={fetchStore} />
    </Row>
  </Container>
);

const StoreCheckoutPage = ({fetchStore}: StoreCheckoutPageProps) => {
  const api = useApi();
  const [state] = useEcommerce();
  const [cartReviewItems, setCartReviewItems] = useState<CartReviewItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {uid} = state;
  const navigate = useNavigate();

  const fetchCartReview = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/blink/${uid}/review`);

      setCartReviewItems(response);
    } catch (error: any) {
      toast.error(error.data, {hideProgressBar: true});
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  }, [api, uid, navigate]);

  useEffect(() => {
    fetchCartReview();
  }, [fetchCartReview]);

  if (isLoading) {
    return null;
  }

  if (cartReviewItems.length > 0) {
    return (
      <CheckoutCartReviewView fetchCartReview={fetchCartReview} cartReviewItems={cartReviewItems} />
    );
  }

  return <StoreCheckoutView fetchStore={fetchStore} />;
};

export default StoreCheckoutPage;
