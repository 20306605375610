import React, {useMemo} from 'react';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import styles from './SearchBar.module.scss';

const SearchBar = ({
  value,
  setValue,
  size = 'lg',
  placeholder = "Let's start searching!",
  handleClear,
}) => {
  const hasContent = useMemo(() => value !== '', [value]);

  return (
    <div className={`position-relative ${styles.searchBar}`}>
      <Form.Control
        className={styles.formControl}
        type="text"
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          if (setValue) {
            setValue(event.target.value);
          }
        }}
      />
      <Icon icon="fa-solid fa-magnifying-glass" />
      {hasContent && (
        <Icon
          className={styles.clearSearchBarIcon}
          icon="fa-solid fa-xmark"
          onClick={() => {
            if (handleClear) {
              handleClear();
            } else if (setValue) {
              setValue('');
            }
          }}
        />
      )}
    </div>
  );
};

export default SearchBar;
