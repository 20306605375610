import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Alert, Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {useSession} from '../../contexts/SessionContext';
import TextareaField from '../../components/FormFields/TextareaField';
import TextField from '../../components/FormFields/TextField';
import TypeField from '../../components/FormFields/TypeField';

import styles from './StoreCheckoutPage.module.scss';

const validationSchema = Yup.object().shape({
  customer_name: Yup.string().required('Required'),
  customer_company_name: Yup.string().required('Required'),
  customer_email: Yup.string().email('Invalid email').required('Required'),
  customer_phone: Yup.string(),
  shipping_address: Yup.string(),
  company_tax_id: Yup.string(),
  comments: Yup.string().max(500, 'Maximum 500 characters'),
});

const CheckoutForm = ({setPurchaseOrder, fetchStore}) => {
  const api = useApi();
  const [state] = useEcommerce();
  const [sessionState] = useSession();
  const navigate = useNavigate();
  const {t} = useTranslation('pages');

  const {uid} = state;

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const result = await api.post(`/blink/${uid}/confirm`, values);
        fetchStore(uid);
        setPurchaseOrder(result);
      } catch (error) {
        if (error.data.products) {
          navigate('/review');
        } else {
          toast.error(
            `${t('StoreCheckoutPage.CheckoutForm.createPurchaseOrderError')}`,
            {
              hideProgressBar: true,
            },
          );
        }
      }
    },
    [api, uid, setPurchaseOrder, fetchStore, t, navigate],
  );

  return (
    <div className={styles.form}>
      {state.settings.COMPANY_CHECKOUT_MESSAGE && (
        <Alert variant="info">{state.settings.COMPANY_CHECKOUT_MESSAGE}</Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          customer_name: sessionState.data.user.info?.fullname || '',
          customer_company_name:
            sessionState.data.user.info?.company_name || '',
          customer_email: sessionState.data.user.email || '',
          customer_phone: sessionState.data.user.info?.phone || '',
          shipping_address: sessionState.data.user.info?.shipping_address || '',
          company_tax_id: sessionState.data.user.info?.company_tax_id || '',
          comments: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(form) => (
          <Form noValidate onSubmit={form.handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="mb-1 position-relative">
                  <TextField
                    name="customer_name"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.customerName',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TextField
                    name="customer_company_name"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.customerCompanyName',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TypeField
                    name="customer_email"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.customerEmail',
                    )}
                    type="email"
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TextField
                    name="customer_phone"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.customerPhone',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TextField
                    name="shipping_address"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.shippingAddress',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TextField
                    name="company_tax_id"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.companyTaxID',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                  <TextareaField
                    name="comments"
                    placeholder={t(
                      'StoreCheckoutPage.CheckoutForm.placeholders.comments',
                    )}
                    disabled={form.isSubmitting}
                  />
                </Form.Group>
              </Col>
              <Button
                size="lg"
                type="submit"
                disabled={form.isSubmitting}
                className="d-flex mx-auto"
              >
                {form.isSubmitting ? (
                  <Spinner animation="border" />
                ) : (
                  `${t('StoreCheckoutPage.CheckoutForm.sendButton')}`
                )}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckoutForm;
