import React, {useState, useEffect, useCallback} from 'react';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import Stepper from '../../components/Stepper';
import StepConfigureProducts from './StepConfigureProducts';
import StepDetails from './StepDetails';
import StepFinal from './StepFinal';

const BlinksFormPage = () => {
  const api = useApi();
  const [data, setData] = useState({
    filters: [],
    title: '',
    countProducts: 0,
    id: null,
    uid: null,
  });
  const navigate = useNavigate();
  const params = useParams();
  const {t} = useTranslation('pages');

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const result = await api.get(`/blink/${id}`);
        setData((prevState) => ({
          ...prevState,
          ...result,
        }));
      } catch (e) {
        toast.error(e.data, {hideProgressBar: true});
        navigate('/admin/blinks', {replace: true});
      }
    };

    if (params.id) {
      fetchData(params.id);
    }
  }, [api, setData, params.id, navigate]);

  const handleSubmit = useCallback(
    () => navigate('/admin/blinks', {replace: true}),
    [navigate],
  );

  return (
    <Stepper setData={setData} data={data} handleSubmit={handleSubmit}>
      <Stepper.Step
        label={t('BlinksFormPage.Stepper.Step.labels.select_product')}
      >
        <StepConfigureProducts />
      </Stepper.Step>
      <Stepper.Step
        label={t('BlinksFormPage.Stepper.Step.labels.bussines_conditions')}
      >
        <StepDetails />
      </Stepper.Step>
      <Stepper.Step label={t('BlinksFormPage.Stepper.Step.labels.ready')}>
        <StepFinal />
      </Stepper.Step>
    </Stepper>
  );
};

export default BlinksFormPage;
